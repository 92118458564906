<div
    class="flex flex-col md:pt-0 md:px-0 md:justify-center items-center border-gray-50 bg-transparent min-w-full no-zoom">
    <!-- <a href="https://wa.me/5215542004088" target="_blank"
        class="fixed bottom-4 right-4 flex items-center justify-center text-white  py-2 px-4 rounded-lg gap-3 whatsapp-container cursor-pointer ">
        <img class="h-5 w-5" [src]="whatsappImage" fill="none">
        <span>{{"loginWhatsappMessage" | translate}}</span>
    </a> -->

    <div class="fixed h-10 z-50 w-full flex justify-end items-center p-4 md:px-0 info-navbar md:pr-5 nav-transition"
        [ngClass]="{
        'hidden': isMenuOpen,
        '-top-10': scrolled,
        'top-0': !scrolled
      }">
        <div class="flex items-center text-right px-2">
            <a id="callToPhone" href="tel:+525542004088" class="flex items-center whitespace-nowrap">
                <img [src]="smartPhoneImage" alt="Smartphone Icon" class="w-4 h-4 mr-2">
                <span class="sticky-navbar-text">(+52) 5542 004088</span>
            </a>
        </div>
        <div class="flex items-center text-right px-2">
            <a id="whatsToPhone" href="https://wa.me/5215542004088" class="flex items-center whitespace-nowrap"
                target="_blank">
                <img [src]="whatsappImage" alt="WhatsApp Icon" class="w-4 h-4 mr-2">
                <span class="sticky-navbar-text">(+52) 5542 004088</span>
            </a>
        </div>
        <div class="relative mr-1" #dropdownMenu appClickOutside (clickOutside)="dropdownOpen = false">
            <button id="toggleDropdownLogin" (click)="toggleDropdown()"
                class="sticky-navbar-text p-1 rounded flex items-center">
                <img [src]="languageImage" alt="Language Icon" class="w-4 h-4 ml-2 mr-2">
                {{ currentLangShort }}
            </button>
            <div *ngIf="dropdownOpen" class="absolute right-0 mt-2 w-40 shadow-lg rounded selector-container">
                <div id="changeLanguageEng" (click)="changeLanguage('eng')"
                    class="sticky-navbar-text p-2 dropdown-container cursor-pointer">English
                </div>
                <div id="changeLanguageEsp" (click)="changeLanguage('esp')"
                    class="sticky-navbar-text p-2 dropdown-container cursor-pointer">Español
                </div>
            </div>
        </div>
    </div>

    <div id="navbar" class="fixed z-40 h-12 md:h-16 w-full flex flex-col lg:flex-row items-center justify-center nav-transition"
        [ngClass]="scrolled ? 'top-2' : 'top-12'">
        <div class="bg-white shadow-2xl rounded-b-2xl py-2 px-5 w-full max-w-nav">
            <!-- Navbar Móvil -->
            <div class="flex items-center justify-between lg:hidden px-4">
                <button id="toggleMenuSide" (click)="toggleMenu()">
                    <img [src]="menuIcon" alt="Menú" width="38px" height="38px" />
                </button>
                <img class="haibu-logo my-2" [src]="haibuImage" alt="">
            </div>

            <!-- Menú Móvil -->
            <div class="mobile-menu fixed top-0 w-full h-full bg-white shadow-lg p-6 transition-transform duration-300 ease-in-out transform"
                [ngClass]="isMenuOpen ? 'left-0' : '-left-full'">
                <div class="flex justify-between items-center border-b pb-4">
                    <img class="menu-haibu-logo" [src]="haibuImage" alt="Haibu Logo" />
                    <button id="toggleMenuClose" (click)="toggleMenu()">
                        <img [src]="closeIcon" alt="Cerrar" width="32px" height="32px">
                    </button>
                </div>
                <div class="flex flex-col items-center space-y-4 mt-4 text-lg">
                    <div class="cursor-pointer edit-text2 text-gray-600" (click)="scrollToElement('home'); selected = 0" [ngClass]="selected == 0 ? 'selected' : ''">
                        {{"loginHome" | translate}}
                    </div>
                    <div class="cursor-pointer edit-text2 text-gray-600" (click)="scrollToElement('rompe'); selected = 1" [ngClass]="selected == 1 ? 'selected' : ''">
                        {{"loginPuzzle" | translate}}
                    </div>
                    <div class="cursor-pointer edit-text2 text-gray-600" (click)="scrollToElement('modules'); selected = 2" [ngClass]="selected == 2 ? 'selected' : ''">
                        {{"loginModules" | translate}}
                    </div>
                    <div class="cursor-pointer edit-text2 text-gray-600" (click)="scrollToElement('price'); selected = 3" [ngClass]="selected == 3 ? 'selected' : ''">
                        {{"loginPrices" | translate}}
                    </div>
                    <div class="cursor-pointer edit-text2 text-gray-600" (click)="scrollToElement('demo'); selected = 4" [ngClass]="selected == 4 ? 'selected' : ''">
                        {{"loginAskDemo" | translate}}
                    </div>
                    <div class="cursor-pointer edit-text2 text-gray-600" (click)="scrollToElement('blog'); selected = 5" [ngClass]="selected == 5 ? 'selected' : ''">
                        {{"loginBlog" | translate}}
                    </div>
                    <app-custom-primary-button id="openLoginModal" (click)="openLoginModal()" color="secondary"
                        size="small" class="w-full mt-4">
                        {{"loginEnter" | translate}}
                    </app-custom-primary-button>
                </div>
            </div>

            <!-- Navbar Escritorio -->
            <div class="hidden lg:flex justify-center items-center px-6 gap-6">
                <img class="haibu-logo my-2" [src]="haibuImage" alt="">
                <div class="flex items-center space-x-6 text-base">
                    <div id="scrollToElementRompe2" [ngClass]="selected == 0 ? 'selected' : ''" (click)="selected = 0"
                        class="text-gray-600 rounded-md edit-text2 cursor-pointer text-center py-2 whitespace-nowrap"
                        (click)="scrollToElement('home')">
                        {{"loginHome" | translate}}
                    </div>
                    <div id="scrollToElementRompe2" [ngClass]="selected == 1 ? 'selected' : ''" (click)="selected = 1"
                        class="text-gray-600 rounded-md edit-text2 cursor-pointer text-center py-2 whitespace-nowrap"
                        (click)="scrollToElement('rompe')">
                        {{"loginPuzzle" | translate}}
                    </div>
                    <div id="scrollToElementModules2" [ngClass]="selected == 2 ? 'selected' : ''" (click)="selected = 2"
                        class="text-gray-600 rounded-md edit-text2 cursor-pointer text-center py-2 whitespace-nowrap"
                        (click)="scrollToElement('modules')">
                        {{"loginModules" | translate}}
                    </div>
                    <div id="scrollToElementPrice2" [ngClass]="selected == 3 ? 'selected' : ''" (click)="selected = 3"
                        class="text-gray-600 rounded-md edit-text2 cursor-pointer text-center py-2 whitespace-nowrap"
                        (click)="scrollToElement('price')">
                        {{"loginPrices" | translate}}
                    </div>
                    <div id="scrollToElementDemo2" [ngClass]="selected == 4 ? 'selected' : ''" (click)="selected = 4"
                        class="text-gray-600 rounded-md edit-text2 cursor-pointer text-center py-2 whitespace-nowrap"
                        (click)="scrollToElement('demo')">
                        {{"loginAskDemo" | translate}}
                    </div>
                    <div id="redirectToBlog2" [ngClass]="selected == 5 ? 'selected' : ''" (click)="selected = 5"
                        class="text-gray-600 rounded-md edit-text2 cursor-pointer text-center py-2 whitespace-nowrap"
                        (click)="scrollToElement('blog')">
                        {{"loginBlog" | translate}}
                    </div>
                </div>
                <app-custom-primary-button id="openLoginModal2" (click)="openLoginModal()" color="primary"
                    size="auto-light" class="w-auto" textPadding="15px" textAlign="inherit">
                    <div class="whitespace-nowrap">
                        {{"loginEnter" | translate}}
                    </div>
                </app-custom-primary-button>
            </div>
        </div>
    </div>

</div>
<div tourAnchor="home.candidatelist.duplicateModal">
    <div class="ml-auto w-full text-right justify-end -mt-4 -mr-3">
        <button id="closeModalChangeStatus2" mat-icon-button (click)="closeModal()" tabindex="-1">
            <mat-icon class="close-x-primary">close</mat-icon>
        </button>
    </div>

    <div class="pb-2 text-center title-text-modal">
        {{ 'candidateDuplicate' | translate }}
    </div>

    <div class="md:px-6 px-0 pt-3">
        <p class="descriptionDuplicateText">
            {{ 'candidateDuplicateMessage' | translate }}
        </p>
        <div class="pb-3 flex flex-col">
            <label for="selectCompany" class="title-input-text">
                {{"yourCompaniesDuplicate" | translate}}
            </label>
            <mat-select [(ngModel)]="selectedCompany" class="borde brown-border p-3 w-full centered-text"
                name="selectCompany" (selectionChange)="getVacancies($event)" disableOptionCentering>

                <ngx-mat-select-search class="searcher" [formControl]="companySearchControl"
                    [placeholderLabel]="'search' | translate"
                    [noEntriesFoundLabel]="'NO_RESULTADOS' | translate"></ngx-mat-select-search>

                <mat-option *ngFor="let company of companies" [value]="company.value">
                    <div class="flex items-center comp-name">
                        <img *ngIf="company.comP_IS_AGENCY" [src]="store" alt="trash">
                        <img *ngIf="company.comP_IS_MINI" [src]="personsearch" alt="trash">
                        <img *ngIf="!company.comP_IS_AGENCY && !company.comP_IS_MINI" [src]="personsearch"
                            alt="trash">&nbsp;
                        <span *ngIf="company.parenT_COMPANY_NAME">{{ company.parenT_COMPANY_NAME }} /&nbsp;</span>
                        <span>{{ company.label }}</span>
                    </div>
                </mat-option>
            </mat-select>
            <mat-error *ngIf="companyRequiredError" class="text-xs">{{ companyRequiredError }}</mat-error>
            <mat-error *ngIf="companyEmptyError" class="text-xs">{{ companyEmptyError }}</mat-error>
            <mat-error *ngIf="selectedCompany && !canCreate">{{ 'cantAddCandidateError' | translate }}</mat-error>
        </div>
        <div *ngIf="selectedCompany && vacancies && totalVacancies > 0" class="pb-3 flex flex-col">
            <label for="selectedVacancy" class="title-input-text">
                {{"companyVacanciesDuplicate" | translate}}
            </label>
            <mat-select [(ngModel)]="selectedVacancy" class="borde brown-border p-3 w-full centered-text"
                name="selectedVacancy" disableOptionCentering>
                <ngx-mat-select-search class="searcher" [formControl]="vacancySearchControl"
                    [placeholderLabel]="'search' | translate"
                    [noEntriesFoundLabel]="'NO_RESULTADOS' | translate"></ngx-mat-select-search>
                <mat-option *ngFor="let vacancy of vacancies" [value]="vacancy.value">
                    {{ vacancy.label }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="vacancyRequiredError && !companyEmptyError" class="text-xs">{{ vacancyRequiredError
                }}</mat-error>
        </div>
    </div>

    <div class="md:px-6 px-0 flex justify-between pt-4">
        <app-custom-primary-button id="addQuestion" size="filter-size" color="secondary" class="text-center"
            [textPadding]="'16px'" [textAlign]="'inherit'" (click)="closeModal()">
            <span class="btn-label">{{ 'cancelDuplicateTest' | translate }}</span>
        </app-custom-primary-button>
        <app-custom-primary-button id="saveQuestions" type="submit" size="filter-size" color="primary"
            class="text-center" [textPadding]="'16px'" [textAlign]="'inherit'" (click)="duplicateCandidate()">
            <div class="w-full flex justify-center gap-2">
                <mat-spinner *ngIf="sending" diameter="20" color="primary"></mat-spinner>
                <span class="btn-label">{{ 'saveDuplicateTest' | translate }}</span>
            </div>
        </app-custom-primary-button>
    </div>
</div>
<div class="modal-user">
  <div class="ml-auto w-full text-right justify-end -mt-4 -mr-3">
    <button id="closeModalChangeStatus2" mat-icon-button (click)="closeModal()" tabindex="-1">
      <mat-icon class="close-x-primary">close</mat-icon>
    </button>
  </div>

  <div class="px-3 pb-3 green-title text-center">
    {{ message }}
  </div>

  <div *ngIf="newUser || existingUserBool" class="subtitle-text">
    {{ messageText }}
  </div>

  <form autocomplete="off" [formGroup]="miFormulario" (ngSubmit)="asignaValores()">
    <div *ngIf="newUser && step == 0" class="flex flex-wrap -mx-2 pt-2">

      <!-- Email -->
      <div class="w-full px-2 mb-2">
        <div class="flex flex-col">
          <label class="title-input-text" for="correoprev">
            {{ 'addDialogEmail' | translate }}
          </label>
          <div class="input-container mobile-responsive">
            <input id="addDialogEmail" formControlName="correoprev" class="custom-input" type="text" name="correoprev"
              maxlength="100"
              oninput="this.value = this.value.split('').filter(char => /^[a-zA-Z0-9._%+-@]*$/.test(char)).join('').trimStart();"
              autocapitalize="none" />
            <div class="text-left error-title" *ngIf="campoNoValido('correoprev')">
              {{ emailErrorMsg2 }}
            </div>
          </div>
        </div>
      </div>

    </div>
    <div *ngIf="(newUser && step == 1) || (!newUser && data)" class="flex flex-wrap -mx-2 pt-2">

      <!-- Nombre -->
      <div class="w-full md:w-1/3 px-2 mb-4">
        <div class="flex flex-col">
          <label class="title-input-text" for="usuario">
            {{ 'addDialogName' | translate }}
          </label>
          <div class="input-container mobile-responsive">
            <input *ngIf="existingUserBool" id="addDialogName" formControlName="nombre" class="custom-input" type="text"
              name="usuario" maxlength="50" disabled />
            <input *ngIf="!existingUserBool" id="addDialogName" formControlName="nombre" class="custom-input"
              type="text" name="usuario" maxlength="50"
              oninput='this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.\-\/()´"]+/g, "").trimStart();' />
            <div class="text-left error-title" *ngIf="campoNoValido('nombre')">
              {{ nameErrorMsg }}
            </div>
          </div>
        </div>
      </div>

      <!-- Apellido -->
      <div class="w-full md:w-1/3 px-2 mb-4">
        <div class="flex flex-col">
          <label class="title-input-text" for="apellidos">
            {{ 'addDialogLastName' | translate }}
          </label>
          <div class="input-container mobile-responsive">
            <input *ngIf="existingUserBool" id="addDialogLastName" formControlName="apellidos" class="custom-input"
              type="text" name="apellidos" maxlength="50" disabled />
            <input *ngIf="!existingUserBool" id="addDialogLastName" formControlName="apellidos" class="custom-input"
              type="text" name="apellidos" maxlength="50"
              oninput='this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.\-\/()´"]+/g, "").trimStart();' />
            <div class="text-left error-title" *ngIf="campoNoValido('apellidos')">
              {{ lastNameErrorMsg }}
            </div>
          </div>
        </div>
      </div>

      <!-- Segundo apellido -->
      <div class="w-full md:w-1/3 px-2 mb-4">
        <div class="flex flex-col">
          <label class="title-input-text" for="secondLastName">
            {{ 'addDialogSecondLastName' | translate }}
          </label>
          <div class="input-container mobile-responsive">
            <input *ngIf="existingUserBool" id="addDialogSecondLastName" formControlName="secondLastName"
              class="custom-input" type="text" name="secondLastName" maxlength="50" disabled />
            <input *ngIf="!existingUserBool" id="addDialogSecondLastName" formControlName="secondLastName"
              class="custom-input" type="text" name="secondLastName" maxlength="50"
              oninput='this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.\-\/()´"]+/g, "").trimStart();' />
            <div class="text-left error-title" *ngIf="campoNoValido('secondLastName')">
              {{ secondLastNameErrorMsg }}
            </div>
          </div>
        </div>
      </div>

      <!-- Email -->
      <div class="w-full md:w-1/3 px-2 mb-4">
        <div class="flex flex-col">
          <label class="title-input-text" for="correo">
            {{ 'addDialogEmail' | translate }}
          </label>
          <div class="input-container mobile-responsive">
            <input *ngIf="existingUserBool || newUser" id="addDialogEmail" formControlName="correo" class="custom-input"
              type="text" name="correo" maxlength="100"
              oninput="this.value = this.value.split('').filter(char => /^[a-zA-Z0-9._%+-@]*$/.test(char)).join('').trimStart();"
              autocapitalize="none" disabled />
            <input *ngIf="!existingUserBool && !newUser" id="addDialogEmail" formControlName="correo"
              class="custom-input" type="text" name="correo" maxlength="100"
              oninput="this.value = this.value.split('').filter(char => /^[a-zA-Z0-9._%+-@]*$/.test(char)).join('').trimStart();"
              autocapitalize="none" />
            <div class="text-left error-title" *ngIf="campoNoValido('correo')">
              {{ emailErrorMsg }}
            </div>
          </div>
        </div>
      </div>

      <!-- Número de teléfono -->
      <div class="w-full md:w-1/3 px-2 mb-4">
        <div class="flex flex-col">
          <label class="title-input-text" for="phone">
            {{ 'addDialogNumber' | translate }}
          </label>
          <div class="input-container mobile-responsive">
            <app-phone-lada-select [phone]="miFormulario.get('phone').value"
              [placeholder]="'candidatePlaceHolderPhone' | translate" [disabled]="existingUserBool"
              (phoneval)="miFormulario.get('phone').setValue($event)"></app-phone-lada-select>
            <div class="text-left error-title" *ngIf="campoNoValido('phone')">
              {{ phoneErrorMsg }}
            </div>
          </div>
        </div>
      </div>


      <!-- Fecha de nacimiento -->
      <div class="w-full md:w-1/3 px-2 mb-4">
        <div class="flex flex-col">
          <label class="title-input-text" for="birthDate">
            {{ 'addDialogBirthDate' | translate }}
          </label>
          <app-lang-datepicker *ngIf="!miFormulario.get('birthDate')?.value" id="addDialogBirthDate"
            [date]="miFormulario.get('birthDate')?.value" [max]="maxDate"
            (datechange)="miFormulario.get('birthDate')?.setValue($event)"
            [disabled]="existingUserBool"></app-lang-datepicker>
          <app-lang-datepicker *ngIf="miFormulario.get('birthDate')?.value" id="addDialogBirthDate"
            [date]="miFormulario.get('birthDate')?.value" [max]="maxDate"
            (datechange)="miFormulario.get('birthDate')?.setValue($event)"
            [disabled]="existingUserBool"></app-lang-datepicker>
        </div>
      </div>

      <!-- Rol -->
      <div class="w-full md:w-1/3 px-2 mb-4">
        <div class="flex flex-col">
          <label class="title-input-text" for="rol">
            {{ 'addDialogRol' | translate }}
          </label>
          <div class="input-container mobile-responsive">
            <mat-select id="addDialogRol" [(ngModel)]="selectedRole" formControlName="rol" class="custom-input">
              <mat-option *ngFor="let role of allRoles" [value]="role.id">
                {{getTranslatedRole(role.name)}}
              </mat-option>
            </mat-select>

            <div class="text-left error-title" *ngIf="campoNoValido('rol')">
              {{ 'addDialogRolAlertObligatory' | translate }}
            </div>
          </div>
        </div>
      </div>

      <!-- Opción para generación de contraseña -->
      <div *ngIf="newUser" class="w-full md:w-1/3 px-2 mb-4">
        <div class="flex flex-col">
          <label class="title-input-text">
            {{ 'addDialogGeneratePassword' | translate }}
          </label>
          <mat-select id="addDialogGeneratePassword" formControlName="passwordOption" class="custom-input">
            <mat-option [value]="true">{{ 'addDialogPasswordFirstOption' | translate }}</mat-option>
            <mat-option [value]="false">{{ 'addDialogPasswordSecondOption' | translate }}</mat-option>
          </mat-select>
        </div>
      </div>

      <!-- Contenedor para Contraseña y Confirmar contraseña que inicia en una nueva fila cuando se muestra -->
      <div *ngIf="!generateAutomatically && !existingUserBool" class="w-full flex flex-wrap">
        <!-- Contraseña -->
        <div class="w-full md:w-1/3 px-2 mb-4">
          <div class="flex flex-col">
            <label class="title-input-text" for="password">
              {{ 'addDialogPassword' | translate }}
            </label>
            <div class="input-container mobile-responsive relative" style="height: 48px;">
              <input id="addDialogPassword" formControlName="password" class="custom-input pr-10"
                [type]="hide ? 'password' : 'text'" name="password" />
              <mat-icon
                class="cursor-pointer gray-me p-1.5 icon-password absolute right-0 top-1/2 transform -translate-y-1/2 mr-3"
                matSuffix (click)="hide = !hide">
                {{ hide ? "visibility_off" : "visibility" }}
              </mat-icon>
            </div>
            <div class="text-left error-title" *ngIf="campoNoValido('password')">
              {{ passwordErrorMsg }}
            </div>
          </div>
        </div>

        <!-- Confirmar contraseña -->
        <div class="w-full md:w-1/3 px-2 mb-4">
          <div class="flex flex-col">
            <label class="title-input-text" for="password2">
              {{ 'addDialogConfirmPassword' | translate }}
            </label>
            <div class="input-container mobile-responsive relative" style="height: 48px;">
              <input id="addDialogConfirmPassword" formControlName="password2" class="custom-input pr-10"
                [type]="hide2 ? 'password' : 'text'" name="password2" />
              <mat-icon
                class="cursor-pointer gray-me p-1.5 icon-password absolute right-0 top-1/2 transform -translate-y-1/2 mr-3"
                matSuffix (click)="hide2 = !hide2">
                {{ hide2 ? "visibility_off" : "visibility" }}
              </mat-icon>
            </div>
            <div class="text-left error-title" *ngIf="campoNoValido('password2')">
              {{ 'addDialogPasswordMatch' | translate }}
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="flex justify-center md:w-auto mt-6">
      <app-custom-primary-button id="addDialogSave" type="submit" size="small" width="120px" height="48px"
        color="primary" [disabled]="sending">
        <span *ngIf="!sending">{{ 'addDialogSave' | translate }}</span>
        <div *ngIf="sending" class="w-full flex justify-center">
          <mat-spinner diameter="20" color="primary"></mat-spinner>
        </div>
      </app-custom-primary-button>
    </div>

  </form>
</div>
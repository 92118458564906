<div tourAnchor="home.candidatelist.shareModal">
  <div class="ml-auto w-full text-right justify-end -mt-4 -mr-3">
    <button id="closeModalChangeStatus2" mat-icon-button (click)="closeModal()" tabindex="-1">
      <mat-icon class="close-x-primary">close</mat-icon>
    </button>
  </div>

  <div class="pb-2 text-center title-text-modal">
    {{ 'candidateShareMultiple' | translate }}
  </div>

  <div class="md:px-6 px-0 pt-3">
    <p class="descriptionDuplicateText">
      {{ 'candidateShareMultipleMessage' | translate }}
    </p>

    <div class="my-1">
      <form [formGroup]="miFormulario">
        <!-- Participantes -->
        <div class="mb-2">
          <!-- Participantes -->
          <div class="flex flex-col w-full">
            <div class="pb-1 title-input-text flex items-center">
              <span class="title-input-text">{{'emailTo' | translate}}:</span>
              <mat-icon [matTooltip]="'usersOfYourCompanyTooltip' | translate" class="help-icon">help</mat-icon>
            </div>
            <div>
              <div class="w-full flex items-center gap-4">
                <mat-select #select1 id="addParticipantsShare" [ngModel]="null" name="participantSelection"
                  class="borde brown-border p-3 w-full centered-text" (selectionChange)="onParticipantSelected($event)"
                  (focus)="onFocus($event)" (blur)="onBlur($event)" disableOptionCentering>
                  <ngx-mat-select-search class="searcher" [formControl]="userSearchControl"
                    [placeholderLabel]="'search' | translate"
                    [noEntriesFoundLabel]="'NO_RESULTADOS' | translate"></ngx-mat-select-search>
                  <mat-option *ngFor="let usuario of usuariosFiltrados" [value]="usuario">
                    {{usuario.nombre}} ({{usuario.email}})
                  </mat-option>
                  <mat-option [value]="userSearchControl.value">
                    {{ 'emailAdd' | translate }} {{ userSearchControl.value }}
                  </mat-option>
                </mat-select>
                <div *ngIf="!activeCC && googleAccount && isAdvanced" class="flex items-center gap-3 text-gray-500">
                  <div (click)="activeActionCC()" class="cursor-pointer">
                    {{ 'emailCC' | translate }}
                  </div>
                  <div (click)="activeActionCC()" class="cursor-pointer">
                    {{ 'emailBCC' | translate }}
                  </div>
                </div>
              </div>
              <mat-error class="text-xs" *ngIf="emptyUsers && selectedUsers.length <= 0">
                {{ 'requiredFieldParticipants' | translate }}
              </mat-error>
            </div>
          </div>

          <!-- Participantes seleccionados -->
          <div class="flex flex-wrap">
            <span *ngFor="let user of selectedUsers;"
              class="tag-primary px-2.5 py-0.5 rounded-full mt-3 mr-2 flex items-center">
              <span>{{ user.email }}</span>
              <div class="flex items-center justify-center">
                <mat-icon id="removeUserVacancyDialog3" (click)="removeUser(user)"
                  class="cursor-pointer close-x">cancel</mat-icon>
              </div>
            </span>
          </div>
        </div>

        <!-- CC -->
        <div *ngIf="googleAccount && isAdvanced && activeCC" class="mb-2">
          <!-- Participantes -->
          <div class="flex flex-col w-full">
            <div class="pb-1 title-input-text flex items-center">
              <span class="title-input-text">{{'emailCC' | translate}}:</span>
            </div>
            <div>
              <mat-select #select2 id="addParticipantsShare" [ngModel]="null" name="participantSelection"
                class="borde brown-border p-3 w-full centered-text" (selectionChange)="onParticipantSelected2($event)"
                (focus)="onFocus($event)" (blur)="onBlur($event)" disableOptionCentering>
                <ngx-mat-select-search class="searcher" [formControl]="userSearchControl2"
                  [placeholderLabel]="'search' | translate"
                  [noEntriesFoundLabel]="'NO_RESULTADOS' | translate"></ngx-mat-select-search>
                <mat-option *ngFor="let usuario of usuariosFiltrados2" [value]="usuario">
                  {{usuario.nombre}} ({{usuario.email}})
                </mat-option>
                <mat-option [value]="userSearchControl2.value">
                  {{ 'emailAdd' | translate }} {{ userSearchControl2.value }}
                </mat-option>
              </mat-select>
            </div>
          </div>

          <!-- Participantes seleccionados -->
          <div class="flex flex-wrap">
            <span *ngFor="let user of selectedUsers2;"
              class="tag-primary px-2.5 py-0.5 rounded-full mt-3 mr-2 flex items-center">
              <span>{{ user.email }}</span>
              <div class="flex items-center justify-center">
                <mat-icon id="removeUserVacancyDialog3" (click)="removeUser2(user)"
                  class="cursor-pointer close-x">cancel</mat-icon>
              </div>
            </span>
          </div>
        </div>

        <!-- BCC -->
        <div *ngIf="googleAccount && isAdvanced && activeCC" class="mb-2">
          <!-- Participantes -->
          <div class="flex flex-col w-full">
            <div class="pb-1 title-input-text flex items-center">
              <span class="title-input-text">{{'emailBCC' | translate}}:</span>
            </div>
            <div>
              <mat-select #select3 id="addParticipantsShare" [ngModel]="null" name="participantSelection"
                class="borde brown-border p-3 w-full centered-text" (selectionChange)="onParticipantSelected3($event)"
                (focus)="onFocus($event)" (blur)="onBlur($event)" disableOptionCentering>
                <ngx-mat-select-search class="searcher" [formControl]="userSearchControl3"
                  [placeholderLabel]="'search' | translate"
                  [noEntriesFoundLabel]="'NO_RESULTADOS' | translate"></ngx-mat-select-search>
                <mat-option *ngFor="let usuario of usuariosFiltrados3" [value]="usuario">
                  {{usuario.nombre}} ({{usuario.email}})
                </mat-option>
                <mat-option [value]="userSearchControl3.value">
                  {{ 'emailAdd' | translate }} {{ userSearchControl3.value }}
                </mat-option>
              </mat-select>
            </div>
          </div>

          <!-- Participantes seleccionados -->
          <div class="flex flex-wrap">
            <span *ngFor="let user of selectedUsers3;"
              class="tag-primary px-2.5 py-0.5 rounded-full mt-3 mr-2 flex items-center">
              <span>{{ user.email }}</span>
              <div class="flex items-center justify-center">
                <mat-icon id="removeUserVacancyDialog3" (click)="removeUser3(user)"
                  class="cursor-pointer close-x">cancel</mat-icon>
              </div>
            </span>
          </div>
        </div>

        <!-- Asunto -->
        <div class="py-1 w-full">
          <label class="pr-6 input-text">
            {{ 'changeStatusSubject' | translate }}:
          </label>
          <!-- Contenedor flex para el input y el icono de edición -->
          <div class="flex justify-between items-center pb-4 w-full">
            <!-- Input cuando se puede editar -->
            <input *ngIf="editSubjectMode" id="changeStatusSubject" class="borde p-3 centered-text brown-border w-full"
              [placeholder]="'placeholderMessage' | translate" maxlength="2000"
              oninput="this.value = this.value.trimStart();" formControlName="subject">

            <!-- Texto deshabilitado -->
            <div *ngIf="!editSubjectMode" class="flex items-center w-full mt-1">
              <span class="text-gray-600 text-base">{{ miFormulario.get('subject').value }}</span>
            </div>

            <!-- Ícono de edición a la derecha -->
            <div *ngIf="!editSubjectMode" class="cursor-pointer flex items-center ml-2" (click)="toggleEditSubject()">
              <img [src]="editIcon" alt="Icono" class="w-6 h-6">
            </div>
            <!-- Ícono de close a la derecha -->
            <div *ngIf="editSubjectMode" class="cursor-pointer flex items-center ml-2" (click)="toggleEditSubject()">
              <img [src]="closeIcon" alt="Icono" class="w-6 h-6">
            </div>
          </div>

          <!-- Mostrar error si es necesario -->
          <mat-error class="text-xs mt-1"
            *ngIf="miFormulario.get('subject')?.hasError('required') && miFormulario.get('subject').touched">
            {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('changeStatusSubject' | translate) } }}
          </mat-error>
        </div>

        <!-- Mensaje -->
        <div *ngIf="!googleAccount || !isAdvanced" class="py-1 w-full">
          <label class="pr-6 input-text">
            {{ 'changeStatusMessage' | translate }}:
          </label>
          <div class="flex flex-col pb-4 w-full">
            <input id="changeStatusMessage" class="borde p-3 centered-text brown-border w-full"
              [placeholder]="'placeholderMessage' | translate" maxlength="2000"
              oninput="this.value = this.value.trimStart();" formControlName="message">
            <mat-error class="text-xs mt-1"
              *ngIf="miFormulario.get('message').hasError('required') && miFormulario.get('message').touched">
              {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('changeStatusMessage' | translate) } }}
            </mat-error>
          </div>
        </div>

        <!-- Editor de texto con ngx-quill -->
        <div *ngIf="googleAccount && isAdvanced" class="mb-3">
          <div class="pb-1 title-input-text flex items-center">
            <span class="title-input-text">{{'emailMessage' | translate}}:</span>
          </div>
          <quill-editor #quillEditor [modules]="editorOptions" placeholder=""
            [styles]="{borderRadius: '0px 0px 8px 8px', height: '30vh'}" formControlName="message" format="html"
            theme="snow" (onEditorCreated)="onEditorCreated($event)"></quill-editor>

          <div
            *ngIf="miFormulario.get('message').invalid && (miFormulario.get('message').dirty || miFormulario.get('message').touched);"
            class="text-red-500 text-xs mt-1">
            <span *ngIf="miFormulario.get('message').errors['required']">
              {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('emailMessage' | translate) } }}
            </span>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="md:px-6 px-0 flex justify-center pt-4">
    <app-custom-primary-button id="saveQuestions" type="submit" size="filter-size" color="primary" class="text-center"
      [textPadding]="'20px'" [textAlign]="'inherit'" (click)="shareCandidates()">
      <div class="w-full flex justify-center gap-2">
        <mat-spinner *ngIf="sending" diameter="20" color="primary"></mat-spinner>
        <span class="btn-label">{{ 'saveShareCandidates' | translate }}</span>
      </div>
    </app-custom-primary-button>
  </div>
</div>
import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-lang-timepicker',
  templateUrl: './lang-timepicker.component.html',
  styleUrls: ['./lang-timepicker.component.css'],
})
export class LangTimepickerComponent implements OnInit {
  @Input() editForm!: FormGroup; // Formulario reactivo
  @Input() disabled: boolean = false;
  @Input() tabindex: number = 0;
  @Input() height: string = 'h-10';
  @Input() format24h: boolean = false; // Si es true, usa 24h, si no, usa 12h
  hourSearchControl = new FormControl();
  minuteSearchControl = new FormControl();

  hours: number[] = [];
  minutes: number[] = [];

  constructor(
    private fb: FormBuilder,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.initTimeOptions();
  }

  private initTimeOptions(): void {
    this.hours = this.format24h
      ? Array.from({ length: 24 }, (_, i) => i)
      : Array.from({ length: 12 }, (_, i) => i + 1);
    // si los minutos recibidos no son múltiplos de 5, se listan todos los minutos
    // de lo contrario, se listan solo los múltiplos de 5
    if (this.editForm.value.minute % 5 !== 0)
      this.minutes = Array.from({ length: 60 }, (_, i) => i);
    else {
      this.minutes = Array.from({ length: 12 }, (_, i) => i * 5);
    }

    const hourAux = this.hours;
    const minuteAux = this.minutes;

    this.hourSearchControl.valueChanges.subscribe((value) => {
      const normalizedValue = value.replace(/^0+/, ''); // Elimina ceros a la izquierda

      this.hours = hourAux.filter((hour) => {
        const hourStr = hour.toString();
        const paddedHourStr = hourStr.padStart(2, '0'); // Asegurar formato 2 dígitos
        return (
          hourStr.includes(value) ||
          paddedHourStr.includes(value) ||
          hourStr.includes(normalizedValue)
        );
      });
    });

    this.minuteSearchControl.valueChanges.subscribe((value) => {
      const normalizedValue = value.replace(/^0+/, ''); // Elimina ceros a la izquierda

      this.minutes = minuteAux.filter((minute) => {
        const minuteStr = minute.toString();
        const paddedMinuteStr = minuteStr.padStart(2, '0'); // Asegurar formato 2 dígitos
        return (
          minuteStr.includes(value) ||
          paddedMinuteStr.includes(value) ||
          minuteStr.includes(normalizedValue)
        );
      });
    });
  }

  onHourChange(event: any) {}

  onMinuteChange(event: any) {}

  onAmPmChange(event: any) {}

  onFocus(event: FocusEvent) {
    const target = event.target as HTMLElement;
    this.renderer.addClass(target, 'focused');
  }

  onBlur(event: FocusEvent) {
    const target = event.target as HTMLElement;
    this.renderer.removeClass(target, 'focused');
  }
}

<div *ngIf="title" class="py-1 px-5 m-0 title-dialog border-b border-gray-400" mat-dialog-title>
  <div class="flex justify-between items-center pb-0">
    <div class="title-doc-text whitespace-nowrap overflow-ellipsis w-full overflow-hidden">{{ title | translate }}</div>
    <button id="closeBtnDocuView" mat-icon-button mat-dialog-close>
      <mat-icon class="close-x-primary" aria-label="Cerrar">close</mat-icon>
    </button>
  </div>
</div>
<div class="container flex items-center justify-center relative">

  <!-- <iframe *ngIf="!imgsource && !pdfUrl" [src]="pdfsource" frameborder="0" width="100%" height="100%">
    {{ 'documentViewerTitle' | translate }}
  </iframe> -->

  <ng2-pdfjs-viewer *ngIf="!imgsource && pdfUrl" [pdfSrc]="pdfUrl" class="pdf-iframe"></ng2-pdfjs-viewer>

  <div *ngIf="imgsource" class="image-cont w-full max-h-full">
    <img [src]="imgsource" class="max-w-full image-doc mx-auto" />
  </div>

  <div *ngIf="activeFile > 0"
    class="absolute left-1 top-1/2 transform -translate-y-1/2 cursor-pointer bg-primary rounded-full w-8 h-8 flex items-center justify-center opacity-50 hover:opacity-100 transition-all duration-300 ease-in-out"
    (click)="prevDoc()">
    <!-- Flecha izquierda -->
    <mat-icon class="text-white">keyboard_arrow_left</mat-icon>
  </div>

  <div *ngIf="fileList?.length > (activeFile + 1)"
    class="absolute right-1 top-1/2 transform -translate-y-1/2 cursor-pointer bg-primary rounded-full w-8 h-8 flex items-center justify-center opacity-50 hover:opacity-100 transition-all duration-300 ease-in-out"
    (click)="nextDoc()">
    <!-- Flecha izquierda -->
    <mat-icon class="text-white">keyboard_arrow_right</mat-icon>
  </div>
</div>
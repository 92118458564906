import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CalendarEvent } from 'angular-calendar';
import { PuzzleService } from 'src/app/services/puzzle.service';
import { AddOrEditEventCalendarComponent } from '../add-or-edit-event-calendar/add-or-edit-event-calendar.component';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import { DayCalendarEventsComponent } from '../day-calendar-events/day-calendar-events.component';

@Component({
  selector: 'app-calendar-dialog',
  templateUrl: './calendar-dialog.component.html',
  styleUrls: ['./calendar-dialog.component.css'],
})
export class CalendarDialogComponent implements OnInit {
  viewDate: Date = new Date();
  view: 'month' | 'week' = 'month';
  events: CalendarEvent[] = [];
  locale: string = 'es';
  customTemplateStrings: any = {};

  constructor(
    private puzzleService: PuzzleService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.translate.onLangChange.subscribe(() => {
      this.loadTranslations();
    });

    this.loadTranslations();

    const lang = this.translate.currentLang == 'esp' ? 'es' : 'en';
    this.translate.use(this.translate.currentLang);
    this.locale = lang;

    registerLocaleData(lang === 'es' ? localeEs : localeEn);

    if (data?.email) {
      this.openModal(null, data.email);
    }
  }

  ngOnInit(): void {
    this.getEvents();
  }

  getEvents(): void {
    let startDate = new Date(this.viewDate);
    let endDate = new Date(this.viewDate);

    if (this.view === 'month') {
      // startDate una semana antes del primer día del mes
      startDate.setDate(1);
      startDate.setDate(startDate.getDate() - startDate.getDay());
      // endDate una semana después del último día del mes
      endDate.setMonth(endDate.getMonth() + 1);
      endDate.setDate(1);
      endDate.setDate(endDate.getDate() + 8 - endDate.getDay());
    } else {
      startDate.setDate(startDate.getDate() - startDate.getDay());
      endDate.setDate(startDate.getDate() + 8);
    }

    let startDateString = startDate.toISOString().split('T')[0];
    let endDateString = endDate.toISOString().split('T')[0];

    const params = {
      StartDate: startDateString,
      EndDate: endDateString,
      maxResults: 1000,
    };

    this.puzzleService.getCalendarEvents(params).subscribe({
      next: (events) => {
        this.events = events.map((event) => {
          const isAllDay = !!event.start.date; // Determina si es un evento de todo el día

          const start = event.start.dateTime
            ? new Date(event.start.dateTime)
            : new Date(`${event.start.date}T00:00:00`); // Para eventos allDay

          let end = event.end?.dateTime
            ? new Date(event.end.dateTime)
            : new Date(`${event.end.date}T00:00:00`); // Para eventos allDay

          // Si es un evento all-day, restamos un día al "end"
          if (isAllDay) {
            // si el dia de fin es mayor al dia de inicio, restamos un día
            if (end.getDate() > start.getDate()) {
              end.setDate(end.getDate() - 1);
            }
          }

          return {
            start,
            end,
            title: event.summary,
            color: { primary: '#1e90ff', secondary: '#D1E8FF' },
            allDay: isAllDay,
            id: event.id,
            description: event.description,
            location: event.location,
            hangoutLink: event.hangoutLink,
            attendees: event.attendees,
          };
        });
      },
      error: (error) => {
        console.error('Error al obtener eventos:', error);
      },
    });
  }

  setView(viewName: 'month' | 'week'): void {
    this.view = viewName;
    this.getEvents();
  }

  nextPeriod(): void {
    if (this.view === 'month') {
      // Creamos una copia y le sumamos 1 mes
      const newDate = new Date(this.viewDate);
      newDate.setMonth(newDate.getMonth() + 1);
      this.viewDate = newDate;
    } else {
      // Creamos una copia y le sumamos 7 días
      const newDate = new Date(this.viewDate);
      newDate.setDate(newDate.getDate() + 7);
      this.viewDate = newDate;
    }
    this.getEvents();
  }

  prevPeriod(): void {
    if (this.view === 'month') {
      const newDate = new Date(this.viewDate);
      newDate.setMonth(newDate.getMonth() - 1);
      this.viewDate = newDate;
    } else {
      const newDate = new Date(this.viewDate);
      newDate.setDate(newDate.getDate() - 7);
      this.viewDate = newDate;
    }
    this.getEvents();
  }

  getFormattedDate(): string {
    const locale = this.translate.currentLang === 'esp' ? 'es-MX' : 'en-US';
    let text = this.viewDate.toLocaleDateString(locale, {
      month: 'long',
      year: 'numeric',
    });
    // Capitalize text
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  getFormattedDay(): string {
    // Determina el locale según el idioma actual (ajusta según tu lógica)
    const locale = this.translate.currentLang === 'esp' ? 'es-MX' : 'en-US';

    // Formatea el día con weekday corto, día numérico y mes corto (opcional)
    let text = this.viewDate.toLocaleDateString(locale, {
      weekday: 'short', // 'Mon' / 'lun.'
      day: 'numeric', // '6'
      month: 'short', // 'Mar' / 'mar.'
    });

    // Capitaliza la primera letra
    // (por si quieres la inicial en mayúscula)
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  openModal(data?: any, email?: string, keepHour = false): void {
    let eventData = null;
    let startDate = new Date();

    if (data) {
      if ('start' in data) {
        // Es un evento existente
        eventData = data;
        startDate = new Date(data.start);
      } else {
        // Es un clic en día/hora sin evento
        startDate = new Date(data);
      }
    }

    const dialogRef = this.dialog.open(AddOrEditEventCalendarComponent, {
      width: '500px',
      maxHeight: '95dvh',
      data: {
        event: eventData,
        startDate: startDate,
        emails: email ? [email] : null,
        summary: email ? this.translate.instant('interview') : null,
        keepHour: keepHour,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getEvents(); // Recargar eventos después de agregar/editar
      }
    });
  }

  handleEventClick(event: any, mouseEvent: MouseEvent): void {
    mouseEvent.stopPropagation(); // Evita que el evento se propague al día
    this.openModal(event); // Usa tu método para abrir el modal
  }

  private loadTranslations(): void {
    this.translate.get('calendar').subscribe((translations) => {
      this.customTemplateStrings = {
        month: translations.month,
        week: translations.week,
        day: translations.day,
        today: translations.today,
        previous: translations.previous,
        next: translations.next,
        weekDays: translations.weekDays,
        months: translations.months,
      };
    });

    this.locale = this.translate.currentLang || 'es';
  }

  closeModal(): void {
    this.dialog.closeAll();
  }

  // Método para alternar la visibilidad del menú. Se pasa la lista completa de eventos para ese día.
  toggleMenu(event: Event, day: any): void {
    event.stopPropagation();
    const dialog = this.dialog.open(DayCalendarEventsComponent, {
      width: '400px',
      maxHeight: '95vh',
      data: day,
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.openModal(result);
      }
    });
  }

  getCustomDayLetter(date: Date): string {
    // getDay() devuelve un número 0-6, siendo 0 = domingo
    const dayIndex = date.getDay();

    // Retorna la letra que desees:
    switch (dayIndex) {
      case 0:
        return this.translate.instant('calendarSunday'); // Domingo
      case 1:
        return this.translate.instant('calendarMonday'); // Lunes
      case 2:
        return this.translate.instant('calendarTuesday'); // Martes
      case 3:
        return this.translate.instant('calendarWednesday'); // Miércoles
      case 4:
        return this.translate.instant('calendarThursday'); // Jueves
      case 5:
        return this.translate.instant('calendarFriday'); // Viernes
      case 6:
        return this.translate.instant('calendarSaturday'); // Sábado
      default:
        return '?';
    }
  }

  isDifferentDay(eventDate: Date, referenceDate: Date): boolean {
    return new Date(eventDate).toDateString() !== new Date(referenceDate).toDateString();
  }
  
  formatDate(date: Date): string {
    const d = new Date(date);
    const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short' };
    return d.toLocaleDateString(this.translate.currentLang, options).toUpperCase();
  }
}

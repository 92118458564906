<form [formGroup]="editForm" class="flex items-start w-full time-picker" [ngClass]="height">
    <!-- Selector de Hora -->
    <mat-select id="hour" formControlName="hour" [placeholder]="'hour' | translate"
        (selectionChange)="onHourChange($event)" [disabled]="disabled" [ngClass]="height" (focus)="onFocus($event)" (blur)="onBlur($event)"
        [tabIndex]="tabindex" class="text-sm p-2 block w-1/3 border bg-white rounded-l-lg time-select altura-48">
        <ngx-mat-select-search class="searcher" [formControl]="hourSearchControl"
                  [placeholderLabel]="'search' | translate"
                  [noEntriesFoundLabel]="'NO_RESULTADOS' | translate"></ngx-mat-select-search>
        <mat-option (click)="$event.stopPropagation()" *ngFor="let hour of hours" [value]="hour">
            {{ hour | number:'2.0' }}
        </mat-option>
    </mat-select>

    <!-- Selector de Minutos -->
    <mat-select id="minute" formControlName="minute" [placeholder]="'minute' | translate"
        (selectionChange)="onMinuteChange($event)" [disabled]="disabled" [ngClass]="height" (focus)="onFocus($event)" (blur)="onBlur($event)"
        [tabIndex]="tabindex" class="h-select text-sm p-2 block w-1/3 border bg-white time-select altura-48 -mx-1">
        <ngx-mat-select-search class="searcher" [formControl]="minuteSearchControl"
                  [placeholderLabel]="'search' | translate"
                  [noEntriesFoundLabel]="'NO_RESULTADOS' | translate"></ngx-mat-select-search>
        <mat-option (click)="$event.stopPropagation()" *ngFor="let minute of minutes" [value]="minute">
            {{ minute | number:'2.0' }}
        </mat-option>
    </mat-select>

    <!-- Selector AM/PM (Solo si no es formato 24h) -->
    <mat-select *ngIf="!format24h" id="ampm" formControlName="ampm" [placeholder]="'ampm' | translate"
        (selectionChange)="onAmPmChange($event)" [disabled]="disabled" [ngClass]="height" (focus)="onFocus($event)" (blur)="onBlur($event)"
        [tabIndex]="tabindex" class="h-select text-sm p-2 block w-1/3 border bg-white rounded-r-lg time-select altura-48">
        <mat-option (click)="$event.stopPropagation()" value="AM">AM</mat-option>
        <mat-option (click)="$event.stopPropagation()" value="PM">PM</mat-option>
    </mat-select>
</form>
<div>
    <div class="ml-auto w-full text-right justify-end -mt-5 -mr-5">
        <div class="flex items-center justify-end">
            <button *ngIf="event?.id;" id="deleteEventBtn" mat-icon-button (click)="deleteEvent(event.id)" tabindex="-1">
                <mat-icon class="close-x-primary">delete</mat-icon>
            </button>
            <button id="closeModalChangeStatus2" mat-icon-button (click)="closeModal()" tabindex="-1">
                <mat-icon class="close-x-primary">close</mat-icon>
            </button>
        </div>
    </div>

    <!-- Header -->
    <div class="pb-2 text-center title-text-modal">
        <span *ngIf="!event?.id; else update">{{ 'newEventCalendar' | translate }}</span>
        <ng-template #update>
            {{ 'editEventCalendar' | translate }}
        </ng-template>
    </div>

    <form [formGroup]="miFormulario">
        <!-- Campo Titulo -->
        <div class="flex flex-col mb-3">
            <div class="title-input-text flex items-center">
                <span class="title-input-text">{{'eventSummary' | translate}}:</span>
            </div>
            <div class="flex">
                <input id="eventSummary" formControlName="summary" class="input-h p-2 w-full mt-1 borde" type="text"
                    tabindex="1" [placeholder]="('eventSummary' | translate)" name="summary" maxlength="100"
                    oninput='this.value = this.value.trimStart();' />
            </div>
            <div *ngIf="miFormulario.get('summary').invalid && (miFormulario.get('summary').dirty || miFormulario.get('summary').touched)"
                class="text-red-500 text-xs mt-1">
                <span *ngIf="miFormulario.get('summary').errors['required']">
                    {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('eventSummary' | translate) } }}
                </span>
            </div>
        </div>

        <!-- Campo Descripcion -->
        <div class="flex flex-col mb-3">
            <div class="title-input-text flex items-center">
                <span class="title-input-text">{{'eventDescription' | translate}}:</span>
            </div>
            <div class="flex">
                <input id="eventDescription" formControlName="description" class="input-h p-2 w-full mt-1 borde"
                    type="text" tabindex="1" [placeholder]="('eventDescription' | translate)" name="description"
                    maxlength="100"
                    oninput='this.value = this.value.trimStart();' />
            </div>
            <div *ngIf="miFormulario.get('description').invalid && (miFormulario.get('description').dirty || miFormulario.get('description').touched)"
                class="text-red-500 text-xs mt-1">
                <span *ngIf="miFormulario.get('description').errors['required']">
                    {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('eventDescription' | translate) } }}
                </span>
            </div>
        </div>

        <!-- Campo Fecha Inicio -->
        <div class="mb-3">
            <div *ngIf="!miFormulario.get('allDay').value" class="title-input-text flex items-center mb-1">
                <span class="title-input-text">{{ 'eventDateStart' | translate }}:</span>
            </div>
            <div class="w-full flex gap-3 flex-col md:flex-row">
                <div class="w-full">
                    <div *ngIf="miFormulario.get('allDay').value" class="title-input-text flex items-center mb-1">
                        <span class="title-input-text">{{ 'eventDateStart' | translate }}:</span>
                    </div>
                    <app-lang-datepicker class="w-full" id="startInput" [borde]="(isStartInvalid) ? 'red' : ''"
                        [date]="startDate" (datechange)="startDate = $event" [lang]="currentLang">
                    </app-lang-datepicker>
                </div>
                <div class="w-full">
                    <app-lang-timepicker *ngIf="!miFormulario.get('allDay').value"
                        [editForm]="hourStartForm"></app-lang-timepicker>

                    <div *ngIf="miFormulario.get('allDay').value">
                        <div class="title-input-text flex items-center mb-1">
                            <span class="title-input-text">{{ 'eventDateEnd' | translate }}:</span>
                        </div>

                        <app-lang-datepicker class="w-full" id="endInput" [borde]="(isEndInvalid) ? 'red' : ''"
                            [date]="endDate" (datechange)="endDate = $event" [lang]="currentLang">
                        </app-lang-datepicker>
                    </div>
                </div>
            </div>
        </div>

        <!-- Campo Fecha Fin -->
        <div class="mb-3" *ngIf="!miFormulario.get('allDay').value">
            <div class="title-input-text flex items-center mb-1">
                <span class="title-input-text">{{'eventDateEnd' | translate}}:</span>
            </div>
            <div class="w-full flex gap-3 flex-col md:flex-row">
                <div class="w-full">
                    <app-lang-datepicker class="w-full" id="endInput" [borde]="(isEndInvalid) ? 'red' : ''"
                        [date]="endDate" (datechange)="endDate = $event" [lang]="currentLang">
                    </app-lang-datepicker>
                </div>
                <div class="w-full">
                    <app-lang-timepicker [editForm]="hourEndForm"></app-lang-timepicker>
                </div>
            </div>
        </div>

        <!-- Campo AllDay -->
        <div class="mb-3">
            <div class="flex items-center">
                <mat-checkbox formControlName="allDay" class="w-full" name="allDay" (change)="toggleAllDay($event)">
                    <div class="title-input-text flex items-center">
                        <span class="title-input-text">{{'eventAllDay' | translate}}</span>
                    </div>
                </mat-checkbox>
            </div>
        </div>

        <!-- Link reunion -->
        <div *ngIf="miFormulario.get('hangoutLink').value" class="flex flex-col mb-3">
            <div class="title-input-text flex items-center">
                <span class="title-input-text">{{'eventLink' | translate}}:</span>
            </div>
            <div class="flex">
                <a [href]="miFormulario.get('hangoutLink').value" target="_blank">
                    <span class="text-primary hover:text-yellow-800">{{ miFormulario.get('hangoutLink').value }}</span>
                </a>
            </div>
        </div>

        <!-- Campo ubicacion -->
        <div class="flex flex-col mb-3">
            <div class="title-input-text flex items-center">
                <span class="title-input-text">{{'eventLocation' | translate}}:</span>
            </div>
            <div class="flex">
                <input id="eventLocation" formControlName="location" class="input-h p-2 w-full mt-1 borde" type="text"
                    tabindex="1" [placeholder]="('eventLocation' | translate)" name="location" maxlength="100"
                    oninput='this.value = this.value.trimStart();' />
            </div>
            <div *ngIf="miFormulario.get('location').invalid && (miFormulario.get('location').dirty || miFormulario.get('location').touched)"
                class="text-red-500 text-xs mt-1">
                <span *ngIf="miFormulario.get('location').errors['required']">
                    {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('eventLocation' | translate) } }}
                </span>
            </div>
        </div>

        <div class="mb-3">
            <!-- Participantes -->
            <div class="flex flex-col w-full">
                <div class="pb-1 title-input-text flex items-center">
                    <span class="title-input-text">{{'eventParticipants' | translate}}:</span>
                </div>
                <div>
                    <div class="w-full flex items-center gap-4">
                        <mat-select #select4 id="addParticipantsShare" [(ngModel)]="selectedParticipant"
                            name="participantSelection" class="borde p-3 w-full centered-text"
                            (selectionChange)="onParticipantSelected($event)" (focus)="onFocus($event)"
                            (blur)="onBlur($event)" disableOptionCentering tabindex="0"
                            [placeholder]="'eventParticipants' | translate">
                            <ngx-mat-select-search class="searcher" [formControl]="userSearchControl"
                                [placeholderLabel]="'search' | translate"
                                [noEntriesFoundLabel]="'NO_RESULTADOS' | translate"></ngx-mat-select-search>
                            <mat-option *ngFor="let usuario of usuariosFiltrados" [value]="usuario.email">
                                {{usuario.nombre}} ({{usuario.email}})
                            </mat-option>
                            <mat-option [value]="userSearchControl.value">
                                {{ 'emailAdd' | translate }} {{ userSearchControl.value }}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>

                <div *ngIf="toError && selectedUsers.length < 1" class="text-red-500 text-xs mt-1">
                    <span *ngIf="miFormulario.get('body').errors['required']">
                        {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('eventParticipants' | translate) } }}
                    </span>
                </div>
            </div>

            <!-- Participantes seleccionados -->
            <div class="flex flex-wrap">
                <span *ngFor="let mail of selectedUsers; let i = index;"
                    class="tag-primary px-2.5 py-0.5 rounded-full mt-3 mr-2 flex items-center">
                    <span>{{ mail }}</span>
                    <div class="flex items-center justify-center">
                        <mat-icon id="removeUserVacancyDialog3" (click)="removeUser(mail)"
                            class="cursor-pointer close-x">cancel</mat-icon>
                    </div>
                </span>
            </div>
        </div>

        <!-- Botón Enviar -->
        <div class="mt-6 flex justify-center">
            <button (click)="sendEvent()"
                class="bg-primary text-white px-6 py-2 rounded-lg hover:bg-yellow-800 transition-all ease-in-out w-full md:w-auto">
                <span *ngIf="!loading">{{ "sendEvent" | translate }}</span>
                <div *ngIf="loading" class="w-full flex justify-center">
                    <mat-spinner diameter="20" color="primary"></mat-spinner>
                </div>
            </button>
        </div>
    </form>
</div>
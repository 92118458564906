import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Country } from '../interfaces/country';
import { Token } from '../interfaces/token';
import { UserLogin } from '../interfaces/user-login';
import { LocalService } from './local.service';
import { Company } from '../interfaces/company';
import { environment } from 'src/environments/environment.prod';
import { User } from '../interfaces/user';
import { ActivatedRoute, Router } from '@angular/router';
import { UserStatus } from '../interfaces/user-status';
import { APIResponse } from '../interfaces/response';
import { ChangePassword } from '../interfaces/change-password';
import { CompanyStatus } from '../interfaces/company-status';
import { Status } from '../interfaces/status';
import { Department } from '../interfaces/department';
import { Job } from '../interfaces/job';
import { Branch } from '../interfaces/branch';
import { Candidate } from '../interfaces/Candidate';
import { candidateStatus } from '../interfaces/candidateStatus';
import { CandidateResponse } from '../interfaces/CandidateResponse';
import { UpdateCandidateStaPro } from '../interfaces/UpdateCandidateStaPro';
import { CandidateComment } from '../interfaces/CandidateComment';
import { Gender } from '../interfaces/Gender';
import { DocumentationTypeInfo } from '../interfaces/DocumentationTypeInfo';
import { CandidateDocument } from '../interfaces/CandidateDocument';
import { PassChange } from '../interfaces/PassChange';
import { TokenValidationBody } from '../interfaces/TokenValidationBody';
import { DocumentsToSend } from '../interfaces/DocumentsToSend';
import { DemoRequest } from '../interfaces/DemoRequest';
import { UserLimits } from '../interfaces/UserLimits';
import { NotificationUser } from '../interfaces/NotificationUser';
import { AccessLink } from '../interfaces/access-link';
import { CandidateCommentsSummary } from '../interfaces/candidate-comments-summary';
import { BassetClient } from '../interfaces/basset-client';
import { BassetResponse } from '../interfaces/basset-response';
import { CandidateBassetDocument } from '../interfaces/candidate-basset-document';
import { ResetPassword } from '../interfaces/password_reset';
import { Vacancy } from '../interfaces/vacancy';
import { VacancyCreate } from '../interfaces/vacancy-create';
import { CandidateRequest } from '../interfaces/CandidateRequest';
import { Role, userRole } from '../interfaces/role';
import { CandidateUpdateData } from '../interfaces/candidate-external-edit';
import { Source } from '../interfaces/source';
import { ReasonForDiscard } from '../interfaces/reason-for-discard';
import { constants } from 'src/app/services/constants';
import { CompanySubdomain } from '../interfaces/CompanySubdomain';
import {
  CandidateAccount,
  CandidateAccountResponse,
} from '../interfaces/CandidateAccount';
import { BlogResponse } from '../interfaces/blog';
import { MatDialog } from '@angular/material/dialog';
import { FeaturesDialogComponent } from '../dialogs/features-dialog/features-dialog.component';
import { VacancyComment } from '../interfaces/VacancyNote';

@Injectable({
  providedIn: 'root',
})
export class PuzzleService {
  private baseUrl = environment.apiUrl;
  private bassetUrl = environment.apiDevelBassetUrl;
  // private baseUrl = GlobalConstants.baseUrl;

  token: Token;
  accessTokenExpire: Date;
  refreshTokenExpire: Date;
  actualDate: Date = new Date();

  userLogin: UserLogin = {
    UserName: null,
    Password: null,
    RefreshToken: '',
    AccessToken: '',
  };

  constructor(
    private http: HttpClient,
    private localService: LocalService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
  ) {
    let tokencito = this.localService.getJsonValue('token');
    this.token = tokencito;
  }

  reloading = false;

  isMenuOpen = false;
  private selectedSubject = new BehaviorSubject<number | null>(null);
  selected$ = this.selectedSubject.asObservable();

  setSelected(value: number) {
    if (this.selectedSubject.value !== value) {
      // Evita llamar next() si el valor no cambia
      this.selectedSubject.next(value);
    }
  }

  checkToken(refresh: boolean = true) {
    if (this.localService.getJsonValue('token')) {
      this.token = this.localService.getJsonValue('token');
      this.refreshTokenExpire = new Date(this.token.data.refreshTokenExpire);
      this.actualDate = new Date();

      const actualPlan = this.localService.getJsonValue('actualPlan');
      if (
        actualPlan &&
        actualPlan != 'Paid' &&
        this.token.data.comP_SUBSCRIPTION_STATUS == 'Paid'
      ) {
        this.localService.removeJsonValue('actualPlan');
        // mostrar mensaje de plan pagado con features FeaturesDialogComponent
        this.dialog.open(FeaturesDialogComponent, {
          panelClass: 'freemium-dialog',
          width: '500px',
          maxWidth: '95vw',
          data: { plan: this.token?.data?.comP_CURRENT_PLAN },
        });
      }

      // Calcular y mostrar el tiempo restante del refresh token
      const timeLeftRefreshToken = Math.floor(
        (this.refreshTokenExpire.getTime() - this.actualDate.getTime()) /
          (1000 * 60),
      );

      // Refrescar token si es necesario
      if (refresh && this.token.data.roleId != constants.superadmin) {
        this.refresh(
          this.token.data.refreshToken,
          this.token.data.companyId,
        ).subscribe(
          (newToken) => {
            if (newToken.isSuccess) {
              const currentToken = this.token;
              const tokenChanged = this.hasTokenChangedSignificantly(
                currentToken.data,
                newToken.data,
              );

              // Actualizamos el token en el localStorage
              this.localService.setJsonValue('token', newToken);

              // Solo refrescar la ventana si hubo cambios significativos
              if (tokenChanged && !this.reloading) {
                // save actualPlan
                if (this.token.data.comP_SUBSCRIPTION_STATUS != 'Paid') {
                  this.localService.setJsonValue(
                    'actualPlan',
                    this.token.data.comP_SUBSCRIPTION_STATUS,
                  );
                }
                window.location.reload();
              }
            } else {
              if (newToken.message === 'User not found or inactive') {
                this.localService.clearToken();
                this.router.navigate(['login']);
              }
            }
          },
          (err) => {
            console.error('Error refreshing token:', err);
          },
        );
      }

      if (this.token.data.roleId != constants.superadmin) {
        // Validar si el token ha expirado
        if (this.actualDate > this.refreshTokenExpire) {
          this.localService.clearToken();
          this.router.navigate(['login']);
        } else {
          this.accessTokenExpire = new Date(this.token.data.accessTokenExpire);

          // Calcular y mostrar el tiempo restante del access token
          /* const timeLeftAccessToken = Math.floor((this.accessTokenExpire.getTime() - this.actualDate.getTime()) / (1000 * 60)); */

          if (!this.reloading) {
            if (this.actualDate > this.accessTokenExpire) {
              this.reloading = true;

              this.forceRefreshLogin(
                this.token.data.refreshToken,
                this.token.data.companyId,
              ).subscribe(
                (token) => {
                  if (token.isSuccess) {
                    this.reloading = false;
                    this.localService.clearToken();
                    this.localService.setJsonValue('token', token);

                    const tokenChanged = this.hasTokenChangedSignificantly(
                      this.token.data,
                      token.data,
                    );

                    // Si el token ha cambiado significativamente, refrescar la pantalla
                    if (tokenChanged && !this.reloading) {
                      // save actualPlan
                      if (this.token.data.comP_SUBSCRIPTION_STATUS != 'Paid') {
                        this.localService.setJsonValue(
                          'actualPlan',
                          this.token.data.comP_SUBSCRIPTION_STATUS,
                        );
                      }
                      window.location.reload();
                    }
                  } else {
                    if (token.message === 'User not found or inactive') {
                      this.localService.clearToken();
                      this.router.navigate(['login']);
                    }
                  }
                },
                (error) => {
                  this.reloading = false;
                  console.error('Error en login:', error);
                },
              );
            } else {
              this.reloading = false;
            }
          }
        }

        if (!this.token.data.companyId) {
          this.localService.clearToken();
          this.router.navigate(['login']);
        }
      }
    } else {
      this.localService.clearToken();
      if (!this.router.url.includes('candidateDocuments')) {
        this.router.navigate(['login']);
      }
    }
  }

  // Mover deepEqual dentro de la clase
  deepEqual(obj1, obj2): boolean {
    if (obj1 === obj2) return true; // Mismo objeto o valor primitivo
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') return false; // Uno no es un objeto

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false; // Diferente número de propiedades

    for (const key of keys1) {
      if (!keys2.includes(key)) return false; // Propiedad faltante
      if (!this.deepEqual(obj1[key], obj2[key])) return false; // Recursivamente comparar las propiedades
    }

    return true;
  }

  hasTokenChangedSignificantly(currentTokenData, newTokenData): boolean {
    const ignoredFields = [
      'refreshToken',
      'refreshTokenExpire',
      'refreshTokenExpireIn', // Agregamos este campo
      'accessToken',
      'accessTokenExpire',
      'accessTokenExpireIn', // Agregamos este campo
      'accessTokenType',
    ];

    let hasChanged = false;

    // Compara los datos del token ignorando las propiedades mencionadas
    for (const key in newTokenData) {
      if (newTokenData.hasOwnProperty(key) && !ignoredFields.includes(key)) {
        if (key === 'permissions') {
          // Comparación profunda para los permisos
          if (!this.deepEqual(newTokenData[key], currentTokenData[key])) {
            hasChanged = true;
          }
        } else if (newTokenData[key] !== currentTokenData[key]) {
          hasChanged = true;
        }
      }
    }

    return hasChanged;
  }

  /*  forceRefresh() {
 
     if (!this.reloading) {
 
       this.reloading = true;
       this.userLogin.AccessToken = this.token.data.accessToken;
       this.userLogin.RefreshToken = this.token.data.refreshToken;
       this.login(this.userLogin).subscribe(token => {
         this.reloading = false;
         this.localService.clearToken();
         this.localService.setJsonValue('token', token);
         window.location.reload();
       }, error => {
         this.reloading = false;
 
       });
 
     }
 
   } */

  setToken(token: Token) {
    this.token = token;
  }

  login(userLogin: UserLogin): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    return this.http.post<any>(`${this.baseUrl}/api/v1/GetToken`, userLogin);
  }

  refresh(token: string, company: string): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    return this.http.post<any>(`${this.baseUrl}/api/v1/RefreshToken`, {
      RefreshToken: token,
      CompanyId: company,
    });
  }

  forceRefreshLogin(token: string, company: string): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    return this.http.post<any>(`${this.baseUrl}/api/v1/RefreshTokenAlways`, {
      RefreshToken: token,
      CompanyId: company,
    });
  }

  forceRefreshLoginSuperAdmin(token: string): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    return this.http.post<any>(
      `${this.baseUrl}/api/v1/RefreshTokenSuperAdmin`,
      { RefreshToken: token, CompanyId: null },
    );
  }

  getCountries(): Observable<Country[]> {
    // const headers = { 'Authorization': 'Bearer ' + this.token.data.accessToken };
    return this.http.get<Country[]>(
      `${this.baseUrl}/api/Country/GetAll` /* , { headers } */,
    );
  }

  getCountriesOther(refresh?: boolean): Observable<Country> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.get<Country>(
      `${this.baseUrl}/api/Country/GetAllMexicoStatesAndCitiesWithOtherOption`,
      { headers },
    );
  }

  getTerms(key: string): Observable<any> {
    return this.http.get(
      `${this.baseUrl}/api/UserEmail/GetTermsAndConditions?key=${key}`,
    );
  }

  getAllCompanies(
    pageSize: number,
    pageNumber: number,
    nombre?: string,
    status?: string,
    fecha1?: string,
    fecha2?: string,
    planFilters?: {
      CompanyIsHeadHunter?: boolean;
      CompanyIsMiddleMan?: boolean;
      CompanyIsFreemium?: boolean;
      CompanyIsEntry?: boolean;
      CompanyIsJobsPlan?: boolean;
      CompanyIsAdvanced?: boolean;
      CompanyIsHHAdvanced?: boolean;
    },
  ): Observable<Company[]> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };

    let params = new HttpParams()
      .set('companyFilter.PageSize', pageSize.toString())
      .set('companyFilter.PageNumber', pageNumber.toString());

    if (nombre) {
      params = params.set('companyFilter.CompanyName', nombre);
    }

    if (status) {
      params = params.set(
        'companyFilter.CompanyActive',
        status === 'Activos' ? 'true' : 'false',
      );
    }

    if (fecha1 && fecha2) {
      params = params
        .set('companyFilter.CompanyStartDate', fecha1)
        .set('companyFilter.CompanyEndDate', fecha2);
    }

    // Aplicar los filtros de plan si están definidos
    if (planFilters) {
      if (planFilters.CompanyIsHeadHunter)
        params = params.set('companyFilter.CompanyIsHeadHunter', 'true');
      if (planFilters.CompanyIsMiddleMan)
        params = params.set('companyFilter.CompanyIsMiddleMan', 'true');
      if (planFilters.CompanyIsFreemium)
        params = params.set('companyFilter.CompanyIsFreemium', 'true');
      if (planFilters.CompanyIsEntry)
        params = params.set('companyFilter.CompanyIsEntry', 'true');
      if (planFilters.CompanyIsJobsPlan)
        params = params.set('companyFilter.CompanyIsJobsPlan', 'true');
      if (planFilters.CompanyIsAdvanced)
        params = params.set('companyFilter.CompanyIsAdvanced', 'true');
      if (planFilters.CompanyIsHHAdvanced)
        params = params.set('companyFilter.CompanyIsHHAdvanced', 'true');
    }

    return this.http.get<Company[]>(`${this.baseUrl}/api/Company/GetAll`, {
      headers,
      params,
    });
  }

  getAllUsers(
    pageSize: number,
    pageNumber: number,
    nombre?: string,
    status?: string,
    company?: string,
    rol?: string,
    nameOrder?: boolean,
    avoidFilter: boolean = false,
    includeInAnalytics?: boolean,
    excludeClienteRole: boolean = false,
  ): Observable<User[]> {
    let params: string = '';

    params += `ApiUserFilter.PageSize=${pageSize}&ApiUserFilter.PageNumber=${pageNumber}`;

    if (nameOrder) params += `&apiUserFilter.NameOrder=${nameOrder}`;

    if (company) params += `&ApiUserFilter.CompanyID=${company}`;

    if (rol && rol != '') params += `&ApiUserFilter.Rol=${rol}`;

    if (status == 'Activos') params += '&ApiUserFilter.Status=1';
    else if (status == 'Inactivos') params += '&ApiUserFilter.Status=0';

    if (nombre != null && nombre.length > 1 && status != null)
      params += `&ApiUserFilter.FullName=${nombre}`;
    else if (nombre != null && nombre.length > 1)
      params += `ApiUserFilter.FullName=${nombre}`;

    if (includeInAnalytics)
      params += `&apiUserFilter.IncludeInAnalytics=${includeInAnalytics}`;

    params += `&apiUserFilter.ExcludeClienteRole=${excludeClienteRole}`;

    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };

    return this.http.get<User[]>(`${this.baseUrl}/api/User/GetAll?${params}`, {
      headers,
    });
  }

  getUser(id: string) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.get<User[]>(
      `${this.baseUrl}/api/User/GetAll?ApiUserFilter.userId=${id}`,
      { headers },
    );
  }

  getUserbyID(iduser: string): Observable<User> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.get<User>(
      `${this.baseUrl}/api/User/GetUserById?userId=${iduser}`,
      { headers },
    );
  }

  createCompany(company: Company): Observable<APIResponse> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/Company/CreateOrUpdate`,
      company,
      { headers },
    );
  }

  createSubdomain(company: CompanySubdomain): Observable<APIResponse> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/Company/UpdateCompanySubdomain`,
      company,
      { headers },
    );
  }

  getCompanyById(id: string, refresh?: boolean): Observable<Company> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.get<Company>(
      `${this.baseUrl}/api/Company/GetDetailByID?id=${id}`,
      { headers },
    );
  }

  changeClientStatus(companyStatus: CompanyStatus) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/Company/UpdateCompanyStatus`,
      companyStatus,
      { headers },
    );
  }

  editUser(user: User): Observable<APIResponse> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(`${this.baseUrl}/api/User/Edit`, user, {
      headers,
    });
  }

  createUser(user: User): Observable<APIResponse> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/User/Create`,
      user,
      { headers },
    );
  }

  deleteUser(user: User): Observable<APIResponse> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/User/Delete`,
      user,
      { headers },
    );
  }

  editCompanyStatus(user: User): Observable<APIResponse> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/Company/UpdateCompanyStatus`,
      user,
      { headers },
    );
  }

  editPassword(changePassword: ChangePassword): Observable<APIResponse> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/User/ChangePassword`,
      changePassword,
      { headers },
    );
  }

  resetPassword(resetPassword: ResetPassword): Observable<APIResponse> {
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/ResetPassword/ResetPassword`,
      resetPassword,
    );
  }

  editUserStatus(user: UserStatus) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/User/UpdateStatus`,
      user,
      { headers },
    );
  }

  recoverPassword(email: any) {
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/UserEmail/SendEmailChangePassword`,
      email,
    );
  }

  /* Estatus */

  getStatus(
    pageSize?: number,
    pageNumber?: number,
    id?: string,
    name?: string,
    refresh?: boolean,
  ): Observable<Status[]> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    let params: string = '';
    params += `&statusFilter.STS_ACTIVE=true`;
    if (pageSize) params += `&statusFilter.PageSize=${pageSize}`;
    if (pageNumber) params += `&statusFilter.PageNumber=${pageNumber}`;
    if (id) params += `&statusFilter.STS_COMPANY_ID=${id}`;
    if (name) params += `&statusFilter.STS_NAME=${name}`;
    return this.http.get<Status[]>(
      `${this.baseUrl}/api/Status/GetAll?${params}`,
      { headers },
    );
  }

  getCandidateStatus(refresh?: boolean): Observable<candidateStatus[]> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };

    return this.http.get<candidateStatus[]>(
      `${this.baseUrl}/api/StatusCandidate/GetAll`,
      { headers },
    );
  }

  createOrEditStatus(status: Status) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/Status/CreateOrUpdate`,
      status,
      { headers },
    );
  }

  reorderStatus(status: Status[]) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/Status/UpdateOrder`,
      status,
      { headers },
    );
  }

  deleteStatus(status: Status) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/Status/UpdateActive`,
      status,
      { headers },
    );
  }

  /* Departamentos */

  getDepartments(
    pageSize: number,
    pageNumber: number,
    id?: string,
    name?: string,
  ): Observable<Department[]> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    let params: string = '';
    params += `departmentFilter.PageSize=${pageSize}&departmentFilter.PageNumber=${pageNumber}`;
    params += `&departmentFilter.DEP_ACTIVE=true`;
    if (id) params += `&departmentFilter.DEP_COMPANY_ID=${id}`;
    if (name) params += `&departmentFilter.DEP_NAME=${name}`;
    return this.http.get<Department[]>(
      `${this.baseUrl}/api/Department/GetAll?${params}`,
      { headers },
    );
  }

  createOrEditDepartment(department: Department) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/Department/CreateOrUpdate`,
      department,
      { headers },
    );
  }

  reorderDepartment(department: Department[]) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/Department/UpdateOrder`,
      department,
      { headers },
    );
  }

  deleteDepartment(department: Department) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/Department/UpdateActive`,
      department,
      { headers },
    );
  }

  /* Jobs */

  getJob(
    pageSize?: number,
    pageNumber?: number,
    id?: string,
    name?: string,
    questFilter?: boolean,
  ): Observable<Job[]> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };

    let params: string = '';
    params += `&jobFilter.JOB_ACTIVE=true`;
    if (pageSize) params += `&jobFilter.PageSize=${pageSize}`;
    if (pageNumber) params += `&jobFilter.PageNumber=${pageNumber}`;
    if (id) params += `&jobFilter.JOB_COMPANY_ID=${id}`;
    if (name) params += `&jobFilter.JOB_NAME=${name}`;
    if (questFilter) params += `&jobFilter.JOB_NO_QUESTIONARY=true`;

    return this.http.get<Job[]>(`${this.baseUrl}/api/Job/GetAll?${params}`, {
      headers,
    });
  }

  getJobQuest(
    pageSize?: number,
    pageNumber?: number,
    id?: string,
    name?: string,
  ): Observable<Job[]> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };

    let params: string = '';
    params += `&jobFilter.JOB_ACTIVE=true`;
    if (pageSize) params += `&jobFilter.PageSize=${pageSize}`;
    if (pageNumber) params += `&jobFilter.PageNumber=${pageNumber}`;
    if (id) params += `&jobFilter.JOB_COMPANY_ID=${id}`;
    if (name) params += `&jobFilter.JOB_NAME=${name}`;

    return this.http.get<Job[]>(
      `${this.baseUrl}/api/Job/GetJobsWithQuestionnaires?${params}`,
      { headers },
    );
  }

  createOrEditJob(job: Job) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/Job/CreateOrUpdate`,
      job,
      { headers },
    );
  }

  createOrEditJobDocument(jobDoc: FormData) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/Job/CreateOrUpdateJobDocument`,
      jobDoc,
      { headers },
    );
  }

  reorderJob(job: Job[]) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/Job/UpdateOrder`,
      job,
      { headers },
    );
  }

  deleteJob(job: any) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/Job/UpdateActive`,
      job,
      { headers },
    );
  }

  /* Sucursales */

  getBranch(
    pageSize: number,
    pageNumber: number,
    id?: string,
    name?: string,
  ): Observable<Branch[]> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    let params: string = '';
    params += `branchOfficeFilter.PageSize=${pageSize}&branchOfficeFilter.PageNumber=${pageNumber}`;
    params += `&branchOfficeFilter.BROF_ACTIVE=true`;
    if (id) params += `&branchOfficeFilter.BROF_COMPANY_ID=${id}`;
    if (name) params += `&branchOfficeFilter.BROF_NAME=${name}`;
    return this.http.get<Branch[]>(
      `${this.baseUrl}/api/branchOffice/GetAll?${params}`,
      { headers },
    );
  }

  createOrEditBranch(branch: Branch) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/branchOffice/CreateOrUpdate`,
      branch,
      { headers },
    );
  }

  reorderBranch(branch: Branch[]) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/branchOffice/UpdateOrder`,
      branch,
      { headers },
    );
  }

  deleteBranch(branch: Branch) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/branchOffice/UpdateActive`,
      branch,
      { headers },
    );
  }

  getAllCandidates(
    iduser: string,
    pageSize: number,
    pageNumber: number,
    companyId: string,
    candidateName: string,
    reclutadorName: string,
    candidateStatus: string,
    candidateProgress: string,
    selectedVacancyId: string,
    candidateDepartment: string,
    candidateBranch: string,
    cds: string,
    cde: string,
    entityid?: string,
    globalSearch?: string,
    recruiterId?: string,
    visibility?: boolean,
    candidatesIds?: string[],
  ): Observable<CandidateResponse> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };

    let params = new HttpParams()
      .set('apiCandidateFilter.PageSize', pageSize + '')
      .set('apiCandidateFilter.PageNumber', pageNumber + '')
      .set('apiCandidateFilter.CDT_COMPANY_ID', companyId);

    params = params.set('apiCandidateFilter.CDT_ACTIVE', 'true');

    params = params.set('apiCandidateFilter.USER_ID', iduser);

    if (globalSearch != null && globalSearch.trim() != '') {
      params = params.set('apiCandidateFilter.GeneralSearch', globalSearch);
    }

    if (candidateName != null && candidateName.trim() != '') {
      params = params.set('apiCandidateFilter.CDT_FULL_NAME', candidateName);
    }

    if (reclutadorName != null && reclutadorName.trim() != '') {
      params = params.set('apiCandidateFilter.RECRUITER_NAME', reclutadorName);
    }

    if (candidateStatus != null && candidateStatus.trim() != '') {
      params = params.set(
        'apiCandidateFilter.CDT_STATUS_CANDIDATE_ID',
        candidateStatus,
      );
    }

    if (candidateProgress != null && candidateProgress.trim() != '') {
      params = params.set(
        'apiCandidateFilter.CDT_STATUS_ID',
        candidateProgress,
      );
    }

    if (selectedVacancyId != null && selectedVacancyId.trim() != '') {
      params = params.set(
        'apiCandidateFilter.CDT_VACANT_ID',
        selectedVacancyId,
      );
    }

    if (candidateDepartment != null && candidateDepartment.trim() != '') {
      params = params.set(
        'apiCandidateFilter.CDT_DEPARTMENT_ID',
        candidateDepartment,
      );
    }

    if (candidateBranch != null && candidateBranch.trim() != '') {
      params = params.set(
        'apiCandidateFilter.CDT_BRANCH_OFFICE_ID',
        candidateBranch,
      );
    }

    if (cds != null && cds.trim() != '' && cde != null && cde.trim() != '') {
      params = params.set('apiCandidateFilter.CREATED_DATE_START', cds);
      params = params.set('apiCandidateFilter.CREATED_DATE_END', cde);
    }

    if (entityid != null && entityid.trim() !== '') {
      params = params.set('apiCandidateFilter.CANDIDATE_ID', entityid);
    }

    if (recruiterId != null && recruiterId.trim() != '') {
      params = params.set('apiCandidateFilter.CreatedBy', recruiterId);
    }

    if (visibility != null) {
      params = params.set(
        'apiCandidateFilter.CDT_VISIBILITY',
        visibility.toString(),
      );
    }

    if (candidatesIds != null && candidatesIds.length > 0) {
      for (let i = 0; i < candidatesIds.length; i++) {
        if (candidatesIds[i] != null && candidatesIds[i].trim() != '') {
          params = params.append(
            'apiCandidateFilter.CANDIDATE_ID',
            candidatesIds[i],
          );
        }
      }
    }

    let tokencito = this.localService.getJsonValue('token');
    const relevantPermissions = ['cd002', 'v005', 'v006'];
    const userPermissions = tokencito.data.permissions
      .map((permission) => permission.code)
      .filter((code) => relevantPermissions.includes(code));

    const permissionsString = userPermissions.join(',');
    if (permissionsString.length > 0) {
      params = params.set('apiCandidateFilter.CODE', permissionsString);
    }
    return this.http.get<CandidateResponse>(
      this.baseUrl + '/api/Candidate/GetAll',
      { headers, params },
    );
  }

  updateCandidateStatus(requestBody: UpdateCandidateStaPro) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/Candidate/UpdateStatusCandidate',
      requestBody,
      { headers },
    );
  }

  updateCandidateProgress(requestBody: UpdateCandidateStaPro) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/Candidate/UpdateProgressCandidate',
      requestBody,
      { headers },
    );
  }

  deleteCandidate(requestBody: UpdateCandidateStaPro) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/Candidate/UpdateActive',
      requestBody,
      { headers },
    );
  }

  getCandidateComments(candidateId: string): Observable<CandidateComment[]> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };

    let params = new HttpParams().set('candidateId', candidateId);

    return this.http.get<CandidateComment[]>(
      this.baseUrl + '/api/CandidateComments/GetCandidateCommentsByCandidateId',
      { headers, params },
    );
  }

  createCandidateComment(requestBody: CandidateComment) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/CandidateComments/CreateOrUpdate',
      requestBody,
      { headers },
    );
  }

  uploadCommentFile(formData: FormData): Observable<APIResponse> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/CandidateComments/UploadCommentFile',
      formData,
      { headers },
    );
  }

  getCandidate(candidateId: string, refresh?: boolean): Observable<Candidate> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };

    let params = new HttpParams().set('candidateId', candidateId);

    return this.http.get<Candidate>(
      this.baseUrl + '/api/Candidate/GetCandidateInfoById',
      { headers, params },
    );
  }

  getCandidateExternal(candidateId: string): Observable<Candidate> {
    let params = new HttpParams().set('candidateId', candidateId);

    return this.http.get<Candidate>(
      this.baseUrl +
        '/api/PersonalDocumentationTypeExternal/GetCandidateInfoByIdExternal',
      { params },
    );
  }

  createEditCandidate(requestBody: Candidate) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/Candidate/CreateOrUpdate',
      requestBody,
      { headers },
    );
  }

  editCandidateExternal(requestBody: CandidateUpdateData) {
    return this.http.post<APIResponse>(
      this.baseUrl +
        '/api/CandidateExternal/CreateOrUdpateCandidateExternalToken',
      requestBody,
    );
  }

  createCandidateWithExternalToken(
    token: string,
    candidate: CandidateRequest,
  ): Observable<APIResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
    const requestBody = {
      Token: token,
      Candidate: candidate,
    };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/CandidateDocumentsExternal/CreateCandidateWithToken',
      requestBody,
      { headers },
    );
  }

  createNotificationExternalToken(id: string): Observable<APIResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
    const requestBody = {
      CDOC_CANDIDATE_ID: id,
    };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/CandidateDocumentsExternal/NotifyCandidateDocuments',
      requestBody,
      { headers },
    );
  }

  createNotifyCandidate(id: string): Observable<APIResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
    const requestBody = {
      CDT_ID: id,
    };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/CandidateDocumentsExternal/NotifyCandidate',
      requestBody,
      { headers },
    );
  }

  createEditCandidateWithFiles(requestBody: FormData) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/Candidate/CreateOrUpdateWithFiles',
      requestBody,
      { headers },
    );
  }

  getGenders(checkToken: boolean = true): Observable<Gender[]> {
    if (checkToken && this.token) {
      const headers = {
        Authorization: 'Bearer ' + this.token.data.accessToken,
      };
      return this.http.get<Gender[]>(this.baseUrl + '/api/Genders/GetAll', {
        headers,
      });
    } else {
      return this.http.get<Gender[]>(this.baseUrl + '/api/Genders/GetAll');
    }
  }

  getJobs(companyId: string, refresh?: boolean): Observable<Job[]> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    let params = new HttpParams().set('jobFilter.JOB_COMPANY_ID', companyId);

    params = params.set('jobFilter.JOB_ACTIVE', 'true');

    return this.http.get<Job[]>(this.baseUrl + '/api/Job/GetAll', {
      headers,
      params,
    });
  }

  getVacancies(
    companyId: string,
    activeStatus?: boolean,
    orderByJobName?: boolean,
    pageNumber?: number,
    pageSize?: number,
    vacancyId?: string,
    timezoneOffset?: number,
    filterGreen?: boolean,
    filterYellow?: boolean,
    filterRed?: boolean,
    filterClose?: boolean,
    filters?: any,
    permissionCodeV?: any,
  ): Observable<Vacancy[]> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    let params = new HttpParams().set('VACANT_COMPANY_ID', companyId);

    if (activeStatus !== undefined) {
      params = params.set('VACANT_ACTIVE', activeStatus.toString());
    }

    if (orderByJobName !== undefined) {
      params = params.set('OrderByJobName', orderByJobName.toString());
    }

    if (pageNumber !== undefined && pageSize !== undefined) {
      params = params.append('PageNumber', pageNumber.toString());
      params = params.append('PageSize', pageSize.toString());
    }

    if (vacancyId !== undefined) {
      params = params.set('VACANT_ID', vacancyId);
    }

    if (timezoneOffset !== undefined) {
      params = params.set('UserDateTimeOffset', timezoneOffset.toString());
    }

    if (filterGreen !== undefined) {
      params = params.set('FilterGreen', filterGreen.toString());
    }

    if (filterYellow !== undefined) {
      params = params.set('FilterYellow', filterYellow.toString());
    }

    if (filterRed !== undefined) {
      params = params.set('FilterRed', filterRed.toString());
    }

    if (filterClose !== undefined) {
      params = params.set('FilterClose', filterClose.toString());
    }

    if (filters && filters !== undefined) {
      for (let filter in filters) {
        if (
          filters[filter] &&
          filters[filter] !== undefined &&
          filters[filter] !== ''
        ) {
          params = params.set(filter, filters[filter]);
        }
      }
    }

    if (permissionCodeV !== undefined) {
      params = params.set('PermissionCode', permissionCodeV);
    } else {
      let tokencito = this.localService.getJsonValue('token');
      const relevantPermissions = ['v005', 'v006'];
      const userPermissions = tokencito.data.permissions
        .map((permission) => permission.code)
        .filter((code) => relevantPermissions.includes(code));

      let permissionCode = '';
      if (userPermissions.includes('v006')) {
        permissionCode = 'v006'; // ver solo donde participa
      } else if (userPermissions.includes('v005')) {
        permissionCode = 'v005'; // ver todos
      }

      if (permissionCode.length > 0) {
        params = params.set('PermissionCode', permissionCode);
      }
    }
    return this.http.get<Vacancy[]>(this.baseUrl + '/api/Vacancy/GetAll', {
      headers,
      params,
    });
  }

  addVacancy(vacancyData: VacancyCreate) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/Vacancy/AddOrUpdateVacant',
      vacancyData,
      { headers },
    );
  }

  getDepartmentsOrdered(
    companyId: string,
    refresh?: boolean,
  ): Observable<Department[]> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    let params = new HttpParams().set(
      'departmentFilter.DEP_COMPANY_ID',
      companyId,
    );

    params = params.set('departmentFilter.DEP_ACTIVE', 'true');

    return this.http.get<Department[]>(
      this.baseUrl + '/api/Department/GetAll',
      { headers, params },
    );
  }

  getBranchsOrdered(
    companyId: string,
    refresh?: boolean,
  ): Observable<Branch[]> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    let params = new HttpParams().set(
      'branchOfficeFilter.BROF_COMPANY_ID',
      companyId,
    );

    params = params.set('branchOfficeFilter.BROF_ACTIVE', 'true');

    return this.http.get<Branch[]>(this.baseUrl + '/api/branchOffice/GetAll', {
      headers,
      params,
    });
  }

  getInnerDocuments(companyId?: string): Observable<DocumentationTypeInfo[]> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    let params = new HttpParams().set(
      'personalDocumentationTypeFilter.pdT_COMPANY_ID',
      companyId,
    );

    return this.http.get<DocumentationTypeInfo[]>(
      this.baseUrl + '/api/PersonalDocumentationType/GetAll',
      { headers, params },
    );
  }

  getInnerDocumentsExternal(
    companyId: string,
    candidateId?: string,
  ): Observable<DocumentationTypeInfo[]> {
    let params = new HttpParams().set(
      'personalDocumentationTypeFilter.pdT_COMPANY_ID',
      companyId,
    );
    params = params.set('personalDocumentationTypeFilter.pdT_ACTIVE', 'true');
    if (candidateId) {
      params = params.set('personalDocumentationTypeFilter.Uploaded', 'false');
      params = params.set(
        'personalDocumentationTypeFilter.CDOC_CANDIDATE_ID',
        candidateId,
      );
    }
    return this.http.get<DocumentationTypeInfo[]>(
      this.baseUrl + '/api/PersonalDocumentationTypeExternal/GetAll',
      { params },
    );
  }

  getCandidateDocumentsExternal(candidateId): Observable<CandidateDocument[]> {
    let params = new HttpParams().set('candidateId', candidateId);
    return this.http.get<CandidateDocument[]>(
      this.baseUrl + '/api/CandidateDocumentsExternal/GetAllByCandidateId',
      { params },
    );
  }

  getCandidateDocuments(
    candidateId,
    refresh?: boolean,
  ): Observable<CandidateDocument[]> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    let params = new HttpParams().set('candidateId', candidateId);

    return this.http.get<CandidateDocument[]>(
      this.baseUrl + '/api/CandidateDocuments/GetAllByCandidateId',
      { headers, params },
    );
  }

  sendDocs(requestBody: FormData) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/CandidateDocuments/Create',
      requestBody,
      { headers },
    );
  }

  sendDocsExternal(requestBody: FormData, checkToken: boolean = true) {
    /*     let options = {};

        if (checkToken && this.token) {
          const headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this.token.data.accessToken
          });
          options = { headers: headers };
        } */

    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/CandidateDocumentsExternal/Create`,
      requestBody /* options */,
    );
  }

  deleteDoc(requestBody: CandidateDocument) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };

    return this.http.post<APIResponse>(
      this.baseUrl + '/api/CandidateDocuments/UpdateDocumentActive',
      requestBody,
      { headers },
    );
  }

  deleteDocExternal(requestBody: CandidateDocument) {
    /*
     */
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/CandidateDocumentsExternal/UpdateDocumentActive',
      requestBody,
      {},
    );
  }

  updatePass(requestBody: PassChange) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };

    return this.http.post<APIResponse>(
      this.baseUrl + '/api/User/ChangePasswordProfile',
      requestBody,
      { headers },
    );
  }

  editProfile(requestBody: FormData) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/User/EditProfile',
      requestBody,
      { headers },
    );
  }

  editProfileNoPhoto(requestBody: User) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };

    return this.http.post<APIResponse>(
      this.baseUrl + '/api/User/Edit',
      requestBody,
      { headers },
    );
  }

  getProfileById(userid): Observable<User> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    let params = new HttpParams().set('userId', userid);

    return this.http.get<User>(this.baseUrl + '/api/User/GetUserById', {
      headers,
      params,
    });
  }

  createCandidateToken(requestBody: string) {
    const headers = {
      Authorization: 'Bearer ' + this.token.data.accessToken,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/TokenCandidateDocument/CreateTemporalToken',
      "'" + requestBody + "'",
      { headers },
    );
  }

  validateCandidateToken(requestBody: TokenValidationBody) {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/TokenCandidateDocument/ValidateTemporalToken',
      requestBody,
      { headers },
    );
  }

  documentsToSend(requestBody: DocumentsToSend) {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/CandidateObligatoryDocumentsCount/Create',
      requestBody,
      { headers },
    );
  }

  createVacancyToken(
    vacancyId: string,
    emails?: string[],
  ): Observable<APIResponse> {
    const headers = {
      Authorization: 'Bearer ' + this.token.data.accessToken,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    const requestBody =
      emails && emails.length > 0
        ? { vacancyId: vacancyId, emails: emails }
        : { vacancyId: vacancyId };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/TokenCandidateDocument/CreateVacancyToken`,
      requestBody,
      { headers },
    );
  }

  validateVacancyToken(token: string): Observable<APIResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });

    const requestBody = { ShortId: token };

    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/TokenCandidateDocument/ValidateVacancyToken`,
      requestBody,
      { headers },
    );
  }

  demoRequest(requestBody: DemoRequest) {
    //
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/UserEmail/SendEmailRequestDemo',
      requestBody,
      { headers },
    );
  }

  getUserLimits(): Observable<UserLimits[]> {
    //
    return this.http.get<UserLimits[]>(
      this.baseUrl + '/api/UserLimitDemo/GetAll',
    );
  }

  getDemoCountries(): Observable<Country[]> {
    //
    return this.http.get<Country[]>(this.baseUrl + '/api/CountryDemo/GetAll');
  }

  getCountryDemo(): Observable<Country> {
    return this.http.get<Country>(
      `${this.baseUrl}/api/CountryDemo/GetAllMexicoStatesAndCitiesWithOtherOption2`,
    );
  }
  uploadCompanyLogo(requestBody: FormData) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/Company/UploadCompanyLogo',
      requestBody,
      { headers },
    );
  }

  getCompanyDocuments(
    pageSize?: number,
    pageNumber?: number,
    id?: string,
    name?: string,
  ): Observable<CandidateDocument[]> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };

    let params: string = '';
    params += `&statusFilter.STS_ACTIVE=true`;
    if (pageSize) params += `&statusFilter.PageSize=${pageSize}`;
    if (pageNumber) params += `&statusFilter.PageNumber=${pageNumber}`;
    if (id) params += `&statusFilter.STS_COMPANY_ID=${id}`;
    if (name) params += `&statusFilter.STS_NAME=${name}`;
    return this.http.get<CandidateDocument[]>(
      `${this.baseUrl}/api/Status/GetAll?${params}`,
      { headers },
    );
  }

  getDocumentEntry(
    pageSize: number,
    pageNumber: number,
    companyId: string,
    name: string,
    active: boolean,
  ): Observable<DocumentationTypeInfo[]> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };

    let params = new HttpParams().set(
      'personalDocumentationTypeFilter.pdT_COMPANY_ID',
      companyId,
    );

    if (pageNumber) {
      params = params.set(
        'personalDocumentationTypeFilter.PageNumber',
        pageNumber + '',
      );
    }
    if (pageSize) {
      params = params.set(
        'personalDocumentationTypeFilter.PageSize',
        pageSize + '',
      );
    }
    if (name) {
      params = params.set('personalDocumentationTypeFilter.pdT_NAME', name);
    }
    if (active) {
      params = params.set(
        'personalDocumentationTypeFilter.pdT_ACTIVE',
        String(active),
      );
    }

    return this.http.get<DocumentationTypeInfo[]>(
      this.baseUrl + '/api/PersonalDocumentationType/GetAll',
      { headers, params },
    );
  }

  getOrderedDocumentEntry(
    companyId: string,
    active: boolean,
  ): Observable<DocumentationTypeInfo[]> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };

    let params = new HttpParams().set(
      'personalDocumentationTypeFilter.pdT_COMPANY_ID',
      companyId,
    );

    if (active) {
      params = params.set(
        'personalDocumentationTypeFilter.pdT_ACTIVE',
        String(active),
      );
    }

    return this.http.get<DocumentationTypeInfo[]>(
      this.baseUrl + '/api/PersonalDocumentationType/GetAllOrganized',
      { headers, params },
    );
  }

  createOrEditDocumentEntry(dti: DocumentationTypeInfo) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/PersonalDocumentationType/CreateOrUpdate',
      dti,
      { headers },
    );
  }

  reorderDocumentEntry(dti: DocumentationTypeInfo[]) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/PersonalDocumentationType/UpdateOrder',
      dti,
      { headers },
    );
  }

  deleteDocumentEntry(dti: DocumentationTypeInfo) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/PersonalDocumentationType/UpdateActive',
      dti,
      { headers },
    );
  }

  generateExcel(
    iduser: string,
    companyId: string,
    candidateName: string,
    reclutadorName: string,
    candidateStatus: string,
    candidateProgress: string,
    selectedVacancyId: string,
    candidateDepartment: string,
    candidateBranch: string,
    cds: string,
    cde: string,
    entityid?: string,
    globalSearch?: string,
    recruiterId?: string,
    visibility?: boolean,
    candidatesIds?: any[],
  ) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    // let params = new HttpParams().set('personalDocumentationTypeFilter.pdT_COMPANY_ID',companyId);
    let params = new HttpParams().set(
      'apiCandidateFilter.CDT_COMPANY_ID',
      companyId,
    );

    params = params.set('apiCandidateFilter.CDT_ACTIVE', 'true');

    params = params.set('apiCandidateFilter.USER_ID', iduser);

    if (globalSearch != null && globalSearch.trim() != '') {
      params = params.set('apiCandidateFilter.GeneralSearch', globalSearch);
    }

    if (candidateName != null && candidateName.trim() != '') {
      params = params.set('apiCandidateFilter.CDT_FULL_NAME', candidateName);
    }

    if (reclutadorName != null && reclutadorName.trim() != '') {
      params = params.set('apiCandidateFilter.RECRUITER_NAME', reclutadorName);
    }

    if (candidateStatus != null && candidateStatus.trim() != '') {
      params = params.set(
        'apiCandidateFilter.CDT_STATUS_CANDIDATE_ID',
        candidateStatus,
      );
    }

    if (candidateProgress != null && candidateProgress.trim() != '') {
      params = params.set(
        'apiCandidateFilter.CDT_STATUS_ID',
        candidateProgress,
      );
    }

    if (selectedVacancyId != null && selectedVacancyId.trim() != '') {
      params = params.set(
        'apiCandidateFilter.CDT_VACANT_ID',
        selectedVacancyId,
      );
    }

    if (candidateDepartment != null && candidateDepartment.trim() != '') {
      params = params.set(
        'apiCandidateFilter.CDT_DEPARTMENT_ID',
        candidateDepartment,
      );
    }

    if (candidateBranch != null && candidateBranch.trim() != '') {
      params = params.set(
        'apiCandidateFilter.CDT_BRANCH_OFFICE_ID',
        candidateBranch,
      );
    }

    if (cds != null && cds.trim() != '' && cde != null && cde.trim() != '') {
      params = params.set('apiCandidateFilter.CREATED_DATE_START', cds);
      params = params.set('apiCandidateFilter.CREATED_DATE_END', cde);
    }

    if (entityid != null && entityid.trim() !== '') {
      params = params.set('apiCandidateFilter.CANDIDATE_ID', entityid);
    }

    if (recruiterId != null && recruiterId.trim() != '') {
      params = params.set('apiCandidateFilter.CreatedBy', recruiterId);
    }

    if (visibility != null) {
      params = params.set(
        'apiCandidateFilter.CDT_VISIBILITY',
        visibility.toString(),
      );
    }

    if (candidatesIds != null && candidatesIds.length > 0) {
      for (let i = 0; i < candidatesIds.length; i++) {
        if (candidatesIds[i] != null && candidatesIds[i].trim() != '') {
          params = params.append(
            'apiCandidateFilter.CANDIDATE_ID',
            candidatesIds[i],
          );
        }
      }
    }

    let tokencito = this.localService.getJsonValue('token');
    const relevantPermissions = ['cd002', 'v005', 'v006'];
    const userPermissions = tokencito.data.permissions
      .map((permission) => permission.code)
      .filter((code) => relevantPermissions.includes(code));

    const permissionList = tokencito.data.permissions.map(
      (permission) => permission.code,
    );
    //si tiene permiso vc010
    let salaryPermission = permissionList.includes('vc010');
    params = params.set(
      'apiCandidateFilter.PermissionCodeSalary',
      salaryPermission.toString(),
    );

    //si tiene permiso cc01
    let contanctPermission = permissionList.includes('cc01');
    params = params.set(
      'apiCandidateFilter.PermissionCodeContact',
      contanctPermission.toString(),
    );

    const permissionsString = userPermissions.join(',');
    if (permissionsString.length > 0) {
      params = params.set('apiCandidateFilter.CODE', permissionsString);
    }
    return this.http.get<APIResponse>(
      this.baseUrl + '/api/Candidate/GenerateCandidateExcel',
      { headers, params },
    );
  }

  validateLink(ssp) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    let params = new HttpParams().set('ssp_id', ssp);
    return this.http.get<APIResponse>(
      this.baseUrl + '/api/SystemProcess/ValidateExportCandidateDueDate',
      { headers, params },
    );
  }

  notiCounter = false;

  getNotificationsByUser(userid: string, pageNumber: number, pageSize: number) {
    if (!this.notiCounter) {
      this.notiCounter = true;
      this.checkToken();
    } else {
      this.notiCounter = false;
    }
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    let params = new HttpParams().set('ntF_USER_ID', userid);
    params = params.set('PageNumber', pageNumber.toString());
    params = params.set('PageSize', pageSize.toString());
    return this.http.get<NotificationUser[]>(
      this.baseUrl + '/api/Notification/GetAllByUserId',
      { headers, params },
    );
  }

  //TODO Unificar endpoints
  getNewNotificationsCountByUserId(userid: string) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    let params = new HttpParams().set('ntFS_USER_ID', userid);
    return this.http.get<number>(
      this.baseUrl + '/api/Notification/GetNewNotificationsCountByUserId',
      { headers, params },
    );
  }

  updateNotificationRead(notification: NotificationUser) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/Notification/UpdateIsRead',
      notification,
      { headers },
    );
  }

  updateNotificationBadge(notification: NotificationUser) {
    let object: any = {
      NTFS_USER_ID: notification.ntF_USER_ID,
    };
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/Notification/ResetNotificationSummaryCount',
      object,
      { headers },
    );
  }

  requestAccessLink(accessLink: AccessLink) {
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/TokenCandidateDocument/RequestAccessLink',
      accessLink,
    );
  }

  resetCandidateComments(
    candidateComments: CandidateCommentsSummary,
    refresh?: boolean,
  ) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/CandidateComments/ResetCandidateCommentsSummary',
      candidateComments,
      { headers },
    );
  }

  getRompeCabezasBassetClients(
    pageSize?: number,
    pageNumber?: number,
    id?: string,
  ) {
    let params = new HttpParams().set('bassetClientFilter.BSSC_COMPANY_ID', id);
    params = params.set('bassetClientFilter.BSSC_ACTIVE', 'true');
    params = params.set('bassetClientFilter.PageNumber', `${pageNumber}`);
    params = params.set('bassetClientFilter.PageSize', `${pageSize}`);
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.get<BassetClient[]>(
      this.baseUrl + '/api/BassetClient/GetAll',
      { headers, params },
    );
  }

  getRompeCabezasBassetClientsDropdown(id: string) {
    let params = new HttpParams().set('bassetClientFilter.BSSC_COMPANY_ID', id);
    params = params.set('bassetClientFilter.BSSC_ACTIVE', 'true');
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.get<BassetClient[]>(
      this.baseUrl + '/api/BassetClient/GetAllOrganized',
      { headers, params },
    );
  }

  getBassetClients() {
    const headers = { 'api-key': 'EH_#@.2022_PkMP' };
    return this.http.get<BassetResponse>(
      this.bassetUrl + '/controllers/WebAPI/GetClients.php',
      { headers },
    );
  }

  createRompecabezasBassetClient(bassetClient: BassetClient) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/BassetClient/Create',
      bassetClient,
      { headers },
    );
  }

  reorderRompecabezasBassetClient(bassetClient: BassetClient[]) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/BassetClient/UpdateOrder',
      bassetClient,
      { headers },
    );
  }

  updateActiveRompecabezasBassetClient(bassetClient: BassetClient) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/BassetClient/UpdateActive',
      bassetClient,
      { headers },
    );
  }

  getBassetRazonesSociales(formData: FormData) {
    const headers = { 'api-key': 'EH_#@.2022_PkMP' };
    return this.http.post<BassetResponse>(
      this.bassetUrl + '/controllers/WebAPI/GetRS.php',
      formData,
      { headers },
    );
  }

  validateCandidateFields(candidate: Candidate) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl +
        '/api/BassetStudies/ValidateCandidateFieldsForBassetStudies',
      candidate,
      { headers },
    );
  }

  changeBassetStatus(company: Company) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/Company/UpdateCompanyBassetAccess',
      company,
      { headers },
    );
  }

  changeAgencyStatus(company: Company) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/Company/UpdateAgencyStatus',
      company,
      { headers },
    );
  }

  changeToMiddlePlan(company: Company) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/Company/UpdateCompanyToMidPlan',
      company,
      { headers },
    );
  }

  changeToFullPlan(company: Company) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/Company/UpdateCompanyToFullPlan',
      company,
      { headers },
    );
  }

  changeToAdvancedPlan(company: Company) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/Company/UpdateCompanyToAdvancePlan',
      company,
      { headers },
    );
  }

  changeToAdvancedHHPlan(company: Company) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/Company/UpdateCompanyToHHAdvancePlan',
      company,
      { headers },
    );
  }

  changeToBasicPlan(company: Company) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/Company/UpdateCompanyToBasicPlan',
      company,
      { headers },
    );
  }

  changeToFreemiumPlan(company: Company) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/Company/UpdateCompanyToFreemiumPlan',
      company,
      { headers },
    );
  }

  changeToJobsPlan(company: Company) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/Company/UpdateCompanyToHaibuJobsPlan',
      company,
      { headers },
    );
  }

  requestES(formData: FormData) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<BassetResponse>(
      this.baseUrl + '/api/BassetStudies/RequestEstudioSocioEconomico',
      formData,
      { headers },
    );
  }

  requestRL(formData: FormData) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<BassetResponse>(
      this.baseUrl + '/api/BassetStudies/RequestReferenciaLaboral',
      formData,
      { headers },
    );
  }

  requestPI(formData: FormData) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<BassetResponse>(
      this.baseUrl + '/api/BassetStudies/RequestPerfilesDeIntegridad',
      formData,
      { headers },
    );
  }

  requestIL(formData: FormData) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<BassetResponse>(
      this.baseUrl + '/api/BassetStudies/RequestInvestigacionLaboral',
      formData,
      { headers },
    );
  }

  requestPP(formData: FormData) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<BassetResponse>(
      this.baseUrl + '/api/BassetStudies/RequestPruebasPsicometricas',
      formData,
      { headers },
    );
  }

  getAllByCandidateId(candidate: string, refresh?: boolean) {
    let params = new HttpParams().set('candidateId', candidate);
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.get<CandidateBassetDocument[]>(
      this.baseUrl + '/api/BassetStudies/GetAllByCandidateId',
      { headers, params },
    );
  }

  getDocumentStatus(formData: FormData) {
    const headers = { 'api-key': 'EH_#@.2022_PkMP' };
    return this.http.post<BassetResponse>(
      this.bassetUrl + '/controllers/WebAPI/GetStatus.php',
      formData,
      { headers },
    );
  }

  getPP() {
    const headers = { 'api-key': 'EH_#@.2022_PkMP' };
    return this.http.get<BassetResponse>(
      this.bassetUrl + '/controllers/WebAPI/GetPruebasPs.php',
      { headers },
    );
  }

  // downloadExcel( processId: string ){
  //
  //   // let params = new HttpParams().set('processID', processId);
  //   const headers = { 'Authorization': 'Bearer ' + this.token.data.accessToken };
  //   return this.http.get<any>(this.baseUrl+`/api/Candidate/DownloadCandidateExcel?processId=${processId}`, {headers/* , params */} );
  // }

  getRoles(pageSize?: number, pageNumber?: number, id?: string, name?: string) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };

    let params: string = '';
    if (pageSize) params += `&rolFilter.PageSize=${pageSize}`;
    if (pageNumber) params += `&rolFilter.PageNumber=${pageNumber}`;
    if (id) params += `&rolFilter.CompanyID=${id}`;
    if (name) params += `&rolFilter.Name=${name}`;

    return this.http.get<userRole[]>(
      `${this.baseUrl}/api/Rol/GetAll?${params}`,
      { headers },
    );
  }

  getRoleById(roleId: string) {
    let params = new HttpParams().set('roleId', roleId);
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.get<any[]>(this.baseUrl + '/api/Rol/GetRoleById', {
      headers,
      params,
    });
  }

  createOrEditRole(role: any) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/Rol/CreateOrUpdateRole`,
      role,
      { headers },
    );
  }

  deleteRole(role: any) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/Rol/DeleteRole`,
      role,
      { headers },
    );
  }

  addRoleToStats(role: any) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/Rol/UpdateRoleAnalyticsStatus`,
      role,
      { headers },
    );
  }

  /* Fuentes de reclutamiento */

  getSources(
    pageSize: number,
    pageNumber: number,
    companyId?: string,
    name?: string,
  ): Observable<Source[]> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    let params: string = '';
    if (companyId) params += `RecruitmentSourceCompanyId=${companyId}`;
    params += `&PageSize=${pageSize}&PageNumber=${pageNumber}`;
    params += `&RecruitmentSourceActive=true`;
    if (name) params += `&RecruitmentSourceName=${name}`;
    return this.http.get<Source[]>(
      `${this.baseUrl}/api/RecruiterSource/GetAll?${params}`,
      { headers },
    );
  }

  getSourcesExt(
    pageSize: number,
    pageNumber: number,
    companyId?: string,
    name?: string,
  ): Observable<Source[]> {
    let params: string = '';
    if (companyId) params += `RecruitmentSourceCompanyId=${companyId}`;
    params += `&PageSize=${pageSize}&PageNumber=${pageNumber}`;
    params += `&RecruitmentSourceActive=true`;
    if (name) params += `&RecruitmentSourceName=${name}`;
    return this.http.get<Source[]>(
      `${this.baseUrl}/api/RecruiterSourceExternal/GetAll?${params}`,
    );
  }

  createOrEditSource(source: Source) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/RecruiterSource/CreateOrUpdate`,
      source,
      { headers },
    );
  }

  reorderSource(source: Source[]) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/RecruiterSource/UpdateOrder`,
      source,
      { headers },
    );
  }

  deleteSource(source: any) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/RecruiterSource/UpdateActiveStatus`,
      source,
      { headers },
    );
  }

  /* Razones de descarte */

  getReasonsForDiscard(
    pageSize: number,
    pageNumber: number,
    companyId?: string,
    name?: string,
  ): Observable<ReasonForDiscard[]> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    let params: string = '';
    if (companyId) params += `DropSourceCompanyId=${companyId}`;
    params += `&PageSize=${pageSize}&PageNumber=${pageNumber}`;
    params += `&DropSourceActive=true`;
    if (name) params += `&DropSourceName=${name}`;
    return this.http.get<ReasonForDiscard[]>(
      `${this.baseUrl}/api/DropSource/GetAll?${params}`,
      { headers },
    );
  }

  createOrEditReasonForDiscard(reason: ReasonForDiscard) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/DropSource/CreateOrUpdate`,
      reason,
      { headers },
    );
  }

  reorderReasonsForDiscard(reason: ReasonForDiscard[]) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/DropSource/UpdateOrder`,
      reason,
      { headers },
    );
  }

  deleteReasonForDiscard(reason: any) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/DropSource/UpdateActiveStatus`,
      reason,
      { headers },
    );
  }

  /* Dashboard */

  getCandidatesSummary(
    companyId: string,
    startDate: string,
    endDate: string,
    timezoneOffset: any,
    includeCurrentUser: boolean,
    userSelected?: string,
  ): Observable<any[]> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    let params: string = '';
    if (companyId) params += `companyId=${companyId}`;
    if (startDate) params += `&startDate=${startDate}`;
    if (endDate) params += `&endDate=${endDate}`;
    if (timezoneOffset) params += `&userDateTimeOffset=${timezoneOffset}`;
    if (includeCurrentUser)
      params += `&includeCurrentUser=${includeCurrentUser}`;
    if (userSelected) params += `&UserId=${userSelected}`;
    return this.http.get<any[]>(
      `${this.baseUrl}/api/Dashboard/GetCandidateSummary?${params}`,
      { headers },
    );
  }

  getVacancySummary(
    companyId: string,
    startDate: string,
    endDate: string,
    timezoneOffset: any,
    includeCurrentUser: boolean,
    userSelected?: string,
  ): Observable<any[]> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    let params: string = '';
    if (companyId) params += `companyId=${companyId}`;
    if (startDate) params += `&startDate=${startDate}`;
    if (endDate) params += `&endDate=${endDate}`;
    if (timezoneOffset) params += `&userDateTimeOffset=${timezoneOffset}`;
    if (includeCurrentUser)
      params += `&includeCurrentUser=${includeCurrentUser}`;
    if (userSelected) params += `&UserId=${userSelected}`;
    return this.http.get<any[]>(
      `${this.baseUrl}/api/Dashboard/getVacancySummary?${params}`,
      { headers },
    );
  }

  getUserAnalytics(
    companyId: string,
    startDate: string,
    endDate: string,
    pageSize: number,
    pageNumber: number,
    timezoneOffset: any,
    includeCurrentUser: boolean,
    userSelected?: string,
  ): Observable<any[]> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    let params: string = '';
    if (companyId) params += `companyId=${companyId}`;
    if (startDate) params += `&startDate=${startDate}`;
    if (endDate) params += `&endDate=${endDate}`;
    if (pageSize) params += `&pageSize=${pageSize}`;
    if (pageNumber) params += `&pageNumber=${pageNumber}`;
    if (timezoneOffset) params += `&userDateTimeOffset=${timezoneOffset}`;
    if (includeCurrentUser)
      params += `&includeCurrentUser=${includeCurrentUser}`;
    if (userSelected) params += `&UserId=${userSelected}`;
    return this.http.get<any[]>(
      `${this.baseUrl}/api/Dashboard/GetUserAnalytics?${params}`,
      { headers },
    );
  }

  getCandidateSummaryStates(
    companyId: string,
    startDate: string,
    endDate: string,
    pageSize: number,
    pageNumber: number,
    timezoneOffset: any,
    includeCurrentUser: boolean,
    userSelected?: string,
  ): Observable<any[]> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    let params: string = '';
    if (companyId) params += `companyId=${companyId}`;
    if (startDate) params += `&startDate=${startDate}`;
    if (endDate) params += `&endDate=${endDate}`;
    if (pageSize) params += `&pageSize=${pageSize}`;
    if (pageNumber) params += `&pageNumber=${pageNumber}`;
    if (timezoneOffset) params += `&userDateTimeOffset=${timezoneOffset}`;
    if (includeCurrentUser)
      params += `&includeCurrentUser=${includeCurrentUser}`;
    if (userSelected) params += `&UserId=${userSelected}`;
    return this.http.get<any[]>(
      `${this.baseUrl}/api/Dashboard/GetCandidateSummaryStates?${params}`,
      { headers },
    );
  }

  getRecruitmentSourceSummary(
    companyId: string,
    startDate: string,
    endDate: string,
    pageSize: number,
    pageNumber: number,
    timezoneOffset: any,
    includeCurrentUser: boolean,
    userSelected?: string,
  ): Observable<any[]> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    let params: string = '';
    if (companyId) params += `companyId=${companyId}`;
    if (startDate) params += `&startDate=${startDate}`;
    if (endDate) params += `&endDate=${endDate}`;
    if (pageSize) params += `&pageSize=${pageSize}`;
    if (pageNumber) params += `&pageNumber=${pageNumber}`;
    if (timezoneOffset) params += `&userDateTimeOffset=${timezoneOffset}`;
    if (includeCurrentUser)
      params += `&includeCurrentUser=${includeCurrentUser}`;
    if (userSelected) params += `&UserId=${userSelected}`;
    return this.http.get<any[]>(
      `${this.baseUrl}/api/Dashboard/GetRecruitmentSourceSummary?${params}`,
      { headers },
    );
  }

  getDropSourceSummary(
    companyId: string,
    startDate: string,
    endDate: string,
    pageSize: number,
    pageNumber: number,
    timezoneOffset: any,
    includeCurrentUser: boolean,
    userSelected?: string,
  ): Observable<any[]> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    let params: string = '';
    if (companyId) params += `companyId=${companyId}`;
    if (startDate) params += `&startDate=${startDate}`;
    if (endDate) params += `&endDate=${endDate}`;
    if (pageSize) params += `&pageSize=${pageSize}`;
    if (pageNumber) params += `&pageNumber=${pageNumber}`;
    if (timezoneOffset) params += `&userDateTimeOffset=${timezoneOffset}`;
    if (includeCurrentUser)
      params += `&includeCurrentUser=${includeCurrentUser}`;
    if (userSelected) params += `&UserId=${userSelected}`;
    return this.http.get<any[]>(
      `${this.baseUrl}/api/Dashboard/GetDropSourceSummary?${params}`,
      { headers },
    );
  }

  getAgencyAnalytics(
    companyId: string,
    startDate: string,
    endDate: string,
    pageSize: number,
    pageNumber: number,
    timezoneOffset: any,
  ): Observable<any[]> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    let params: string = '';
    if (companyId) params += `CompanyId=${companyId}`;
    if (startDate) params += `&startDate=${startDate}`;
    if (endDate) params += `&endDate=${endDate}`;
    if (pageSize) params += `&pageSize=${pageSize}`;
    if (pageNumber) params += `&pageNumber=${pageNumber}`;
    if (timezoneOffset) params += `&userDateTimeOffset=${timezoneOffset}`;
    return this.http.get<any[]>(
      `${this.baseUrl}/api/Dashboard/GetAllAnalytics?${params}`,
      { headers },
    );
  }

  downloadDashboardExcel(
    startDate: string,
    endDate: string,
    pageSize: number,
    pageNumber: number,
    timezoneOffset: any,
    companyId: string,
    isAgency: boolean = false,
    userSelected?: string,
  ) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    let body = {
      startDate: startDate,
      endDate: endDate,
      pageSize: pageSize,
      pageNumber: pageNumber,
      userDateTimeOffset: timezoneOffset,
      AllCompanies: isAgency,
      CompanyId: companyId,
    };
    if (userSelected) body['UserId'] = userSelected;
    return this.http.post<any>(
      `${this.baseUrl}/api/Dashboard/GenerateDashboardExcel`,
      body,
      { headers },
    );
  }

  /* Candidate photos */
  createCandidateExternalImage(image: FormData) {
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/CandidateDocumentsExternal/CreateImage`,
      image,
    );
  }

  getCandidateExternalInfo(candidateId: string) {
    let params = new HttpParams().set('candidateId', candidateId);
    return this.http.get<Candidate>(
      this.baseUrl + '/api/CandidateExternal/GetCandidateInfoByIdExternal',
      { params },
    );
  }

  createCandidateImage(image: FormData) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/CandidateDocuments/CreateImage',
      image,
      { headers },
    );
  }

  deleteCandidateImage(image: any) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/CandidateDocuments/UpdateImageStatus',
      image,
      { headers },
    );
  }

  /* Documents visibility */
  updateDocumentVisibility(requestBody: any) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/CandidateDocuments/UpdateDocumentVisibilityExternal',
      requestBody,
      { headers },
    );
  }

  getAllMiniCompaniesByCompany(
    companyId: string,
    companyActive: boolean,
    pageSize: number,
    pageNumber: number,
  ): Observable<any[]> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    let params: string = '';
    if (companyId) params += `CompanyId=${companyId}`;
    if (companyActive) params += `&CompanyActive=${companyActive}`;
    if (pageSize) params += `&PageSize=${pageSize}`;
    if (pageNumber) params += `&PageNumber=${pageNumber}`;
    return this.http.get<any[]>(
      `${this.baseUrl}/api/Company/GetAllMiniCompaniesByCompany?${params}`,
      { headers },
    );
  }

  createMiniCompany(comp: any) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/Company/CreateMiniCompany',
      comp,
      { headers },
    );
  }

  updateMiniCompanyStatus(requestBody: any) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/Company/UpdateMiniCompanyStatus',
      requestBody,
      { headers },
    );
  }

  getUserByEmail(email: any) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/User/GetUserByEmail',
      email,
      { headers },
    );
  }

  addUserToCompany(user: any) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/User/AddUserToCompany',
      user,
      { headers },
    );
  }

  getUserCompanies(): Observable<any[]> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.get<any[]>(`${this.baseUrl}/api/User/GetUserCompanies`, {
      headers,
    });
  }

  getTokenForCompany(user: any) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/v1/GetTokenForCompany',
      user,
      { headers },
    );
  }

  getUsersByParentAndMiniCompany(
    parentCompanyId: string,
    miniCompanyId: string,
    pageSize: number,
    pageNumber: number,
  ): Observable<any[]> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    let params: string = '';
    if (parentCompanyId) params += `ParentCompanyId=${parentCompanyId}`;
    if (miniCompanyId) params += `&MiniCompanyId=${miniCompanyId}`;
    if (pageSize) params += `&PageSize=${pageSize}`;
    if (pageNumber) params += `&PageNumber=${pageNumber}`;
    return this.http.get<any[]>(
      `${this.baseUrl}/api/Company/GetUsersByParentAndMiniCompany?${params}`,
      { headers },
    );
  }

  editMiniCompany(comp: any) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/Company/UpdateMiniCompany',
      comp,
      { headers },
    );
  }

  updateCandidateViability(candidate: any) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/Candidate/UpdateViability',
      candidate,
      { headers },
    );
  }

  getCandidateEducationalLevel(): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseUrl}/api/External/GetCandidateScholarShip`,
    );
  }

  getCandidateExperienceYears(): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseUrl}/api/External/GetCandidateExperienceYears`,
    );
  }

  getCandidateExperienceAreas(): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseUrl}/api/External/GetAllExperienceAreasAsync`,
    );
  }

  updateVacancyVisibility(candidate: any) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/Vacancy/UpdateVacancyVisibility',
      candidate,
      { headers },
    );
  }

  getPaymentPeriods(): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseUrl}/api/External/GetPaymentPeriods`,
    );
  }

  getHaibuStats(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}/api/External/GetHaibuSummary`);
  }

  updateCandidateViewStatus(candidateId: string) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/Candidate/UpdateCandidateViewStatus`,
      { candidateId },
      { headers },
    );
  }

  createOrUpdateQuestionnaire(questionnaire: any) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/Job/CreateOrUpdateQuestionnaire`,
      questionnaire,
      { headers },
    );
  }

  deleteQuestionnaire(questionnaire: any) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/Job/DeleteQuestionnaire`,
      questionnaire,
      { headers },
    );
  }

  saveCandidateResponses(response: any) {
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/External/SaveCandidateResponses`,
      response,
    );
  }

  getCandidateQuestionnaireResults(candidateId: any): Observable<any[]> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    let params: string = '';
    params += `CandidateId=${candidateId}`;

    return this.http.get<any[]>(
      `${this.baseUrl}/api/PersonalDocumentationType/GetCandidateQuestionnaireResults?${params}`,
      { headers },
    );
  }

  saveResponseResult(response: any) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/PersonalDocumentationType/EvaluateCandidateResponse`,
      response,
      { headers },
    );
  }

  downloadPdfCandidate(
    candidateId: string,
    companyId: string,
  ): Observable<Blob> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    let params: string = '';
    if (candidateId) params += `candidateId=${candidateId}`;
    if (companyId) params += `&companyId=${companyId}`;
    return this.http.get(
      `${this.baseUrl}/api/CandidateDocumentsExternal/DownloadCombinedPdfCandidateDocuments?${params}`,
      { headers, responseType: 'blob' },
    );
  }

  updateUserTutorialStatus() {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/User/UpdateUserHasSeenTutorial`,
      { ANU_HAS_SEEN_TUTORIAL: true },
      { headers },
    );
  }

  setUserLanguage(language: string) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/User/UpdateLanguagePreference`,
      { LanguagePreference: language },
      { headers },
    );
  }

  getTemplateEmailDropSource(companyId: any): Observable<any[]> {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    let params: string = '';
    params += `CompanyId=${companyId}`;

    return this.http.get<any[]>(
      `${this.baseUrl}/api/DropSource/GetTemplateEmailDropSource?${params}`,
      { headers },
    );
  }

  createOrUpdateEmailTemplate(company: any) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/DropSource/CreateOrUpdateEmailTemplate`,
      company,
      { headers },
    );
  }

  duplicateCandidate(candidate: any) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/Candidate/DuplicateCandidate`,
      candidate,
      { headers },
    );
  }

  shareCandidatesMail(candidates: any) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/Candidate/SendCandidatesEmails`,
      candidates,
      { headers },
    );
  }

  shareCandidatesGmail(candidates: any) {
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/Candidate/SendCandidateEmailsUsingGmail`,
      candidates,
    );
  }

  getCandidatesAccount(
    pageNumber: number,
    pageSize: number,
    generalSearch?: string,
    genderId?: string,
    scholarshipId?: string,
    yearsExperienceId?: string,
    experienceAreaId?: string,
    countryISO?: string,
    countryName?: string,
    stateCustom?: string,
    cityCustom?: string,
    minSalary?: number,
    maxSalary?: number,
    skills?: string[],
  ) {
    let params = new HttpParams()
      .set('V_PAGE_NUMBER', pageNumber.toString())
      .set('V_PAGE_SIZE', pageSize.toString());

    if (generalSearch) {
      params = params.set('GeneralSearch', generalSearch);
    }
    if (genderId) {
      params = params.set('GenderId', genderId);
    }
    if (scholarshipId) {
      params = params.set('ScholarshipId', scholarshipId);
    }
    if (yearsExperienceId) {
      params = params.set('YearsExperienceId', yearsExperienceId);
    }
    if (experienceAreaId) {
      params = params.set('ExperienceAreaId', experienceAreaId);
    }
    if (countryISO) {
      params = params.set('CountryISO', countryISO);
    }
    if (countryName) {
      params = params.set('CountryName', countryName);
    }
    if (stateCustom) {
      params = params.set('StateCustom', stateCustom);
    }
    if (cityCustom) {
      params = params.set('CityCustom', cityCustom);
    }
    if (minSalary) {
      params = params.set('MinSalary', minSalary.toString());
    }
    if (maxSalary) {
      params = params.set('MaxSalary', maxSalary.toString());
    }
    if (skills && skills.length > 0) {
      skills.forEach((skill) => {
        params = params.append('Skills', skill);
      });
    }

    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };

    return this.http.get<CandidateAccountResponse>(
      `${this.baseUrl}/api/CandidateProfile/GetCandidatesAccountsWithFilters`,
      { headers, params },
    );
  }

  addCandidateAccountToCompanyVacancy(
    vacancyId: string,
    companyId: string,
    candidateAccountId: string,
    SendNotification: boolean = false,
  ) {
    const headers = { Authorization: 'Bearer ' + this.token.data.accessToken };
    const body = {
      vacancyId: vacancyId,
      companyId: companyId,
      candidateAccountId: candidateAccountId,
      SendNotification: SendNotification,
    };
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/CandidateProfile/PostulateToVacancy`,
      body,
      { headers },
    );
  }

  registerFreeUser(data: any) {
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/Freemium/CreateFreemiumCompany`,
      data,
    );
  }

  registerFreeJobs(data: any) {
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/Freemium/CreateJobsFreemium`,
      data,
    );
  }

  sendVerificationCode(data: any) {
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/Freemium/CreateCodeVerificationAsync`,
      data,
    );
  }

  verifyAccount(data: any) {
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/Freemium/ValidateVerificationCodeAsync`,
      data,
    );
  }

  getBlogs(filters: {
    PageNumber: number;
    PageSize: number;
    GeneralSearch?: string;
    Title?: string;
    SeoDescription?: string;
    ActiveStatus?: boolean;
    ExcludeBlogId?: string;
  }): Observable<BlogResponse> {
    let params = new HttpParams()
      .set('PageNumber', filters.PageNumber.toString())
      .set('PageSize', filters.PageSize.toString());

    if (filters.GeneralSearch) {
      params = params.set('GeneralSearch', filters.GeneralSearch);
    }
    if (filters.Title) {
      params = params.set('Title', filters.Title);
    }
    if (filters.SeoDescription) {
      params = params.set('SeoDescription', filters.SeoDescription);
    }
    if (filters.ActiveStatus && filters.ActiveStatus !== undefined) {
      params = params.set('ActiveStatus', filters.ActiveStatus.toString());
    }

    if (filters.ExcludeBlogId) {
      // Añadir ExcludeBlogId solo si está definido
      params = params.set('ExcludeBlogId', filters.ExcludeBlogId);
    }

    return this.http.get<BlogResponse>(
      `${this.baseUrl}/api/Blog/GetBlogsAsync`,
      { params },
    );
  }

  deleteBlog(blogData: any) {
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/BlogAdmin/UpdateBlogAsync`,
      blogData,
    );
  }

  getBlogByIdAdmin(blogId: string) {
    let params = new HttpParams().set('BlogId', blogId);
    return this.http.get<any>(
      `${this.baseUrl}/api/BlogAdmin/GetBlogDetailsByIdForAdminAsync`,
      { params },
    );
  }

  getBlogById(blogSlug: string) {
    let params = new HttpParams().set('BlogSlug', blogSlug);
    return this.http.get<any>(
      `${this.baseUrl}/api/Blog/GetBlogDetailsByIdAsync`,
      { params },
    );
  }

  createOrUpdateBlog(blogData: FormData) {
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/BlogAdmin/CreateOrUpdateBlogAsync`,
      blogData,
    );
  }

  getNotificationPreferences() {
    return this.http.get<any>(
      `${this.baseUrl}/api/User/GetUserNotificationPreferences`,
    );
  }

  updateNotificationPreferences(data: any) {
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/User/UpdateUserNotificationPreferences`,
      data,
    );
  }

  registerFreeTrialCompany(data: any) {
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/Freemium/CreateDemoCompany`,
      data,
    );
  }

  upgradePlanStripe(data: any) {
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/stripe/create-stripe-session`,
      data,
    );
  }

  cancelPlanStripe(data: any) {
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/stripe/cancel-subscription`,
      data,
    );
  }

  customerPortalStripe(data: any) {
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/stripe/create-customer-portal-session`,
      data,
    );
  }

  getWorkingTypes() {
    return this.http.get<any[]>(
      `${this.baseUrl}/api/External/GetWorkingTypesAsync`,
    );
  }

  getWorkingModality() {
    return this.http.get<any[]>(
      `${this.baseUrl}/api/External/GetWorkingModality`,
    );
  }

  updateOrCreateCompanyPayment(data: any) {
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/Company/UpdateOrCreateCompLocalPayment`,
      data,
    );
  }

  uploadCompanyCSF(data: any) {
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/Company/UploadCompanyCSF`,
      data,
    );
  }

  uploadCatalogsFromExcel(data: any) {
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/ExcelCatalog/UploadCatalogsFromExcel`,
      data,
    );
  }

  uploadUsersFromExcel(data: any) {
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/ExcelCatalog/UploadUsersFromExcel`,
      data,
    );
  }

  uploadVacanciesFromExcel(data: any) {
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/ExcelCatalog/UploadVacanciesFromExcel`,
      data,
    );
  }

  generateVacancyExcel(
    companyId: string,
    activeStatus?: boolean,
    orderByJobName?: boolean,
    pageNumber?: number,
    pageSize?: number,
    vacancyId?: string,
    timezoneOffset?: number,
    filterGreen?: boolean,
    filterYellow?: boolean,
    filterRed?: boolean,
    filterClose?: boolean,
    filters?: any,
    permissionCodeV?: any,
  ) {
    let params = new HttpParams().set('VACANT_COMPANY_ID', companyId);

    if (activeStatus !== undefined) {
      params = params.set('VACANT_ACTIVE', activeStatus.toString());
    }

    if (orderByJobName !== undefined) {
      params = params.set('OrderByJobName', orderByJobName.toString());
    }

    if (pageNumber !== undefined && pageSize !== undefined) {
      params = params.append('PageNumber', pageNumber.toString());
      params = params.append('PageSize', pageSize.toString());
    }

    if (vacancyId != null && vacancyId !== undefined) {
      params = params.set('VACANT_ID', vacancyId);
    }

    if (timezoneOffset !== undefined) {
      params = params.set('UserDateTimeOffset', timezoneOffset.toString());
    }

    if (filterGreen !== undefined) {
      params = params.set('FilterGreen', filterGreen.toString());
    }

    if (filterYellow !== undefined) {
      params = params.set('FilterYellow', filterYellow.toString());
    }

    if (filterRed !== undefined) {
      params = params.set('FilterRed', filterRed.toString());
    }

    if (filterClose !== undefined) {
      params = params.set('FilterClose', filterClose.toString());
    }

    if (filters && filters !== undefined) {
      for (let filter in filters) {
        if (
          filters[filter] &&
          filters[filter] !== undefined &&
          filters[filter] !== ''
        ) {
          params = params.set(filter, filters[filter]);
        }
      }
    }

    if (permissionCodeV !== undefined) {
      params = params.set('PermissionCode', permissionCodeV);
    } else {
      let tokencito = this.localService.getJsonValue('token');
      const relevantPermissions = ['v005', 'v006'];
      const userPermissions = tokencito.data.permissions
        .map((permission) => permission.code)
        .filter((code) => relevantPermissions.includes(code));

      let permissionCode = '';
      if (userPermissions.includes('v006')) {
        permissionCode = 'v006'; // ver solo donde participa
      } else if (userPermissions.includes('v005')) {
        permissionCode = 'v005'; // ver todos
      }

      const permissionList = tokencito.data.permissions.map(
        (permission) => permission.code,
      );
      //si tiene permiso vc010
      let salaryPermission = permissionList.includes('vc010');
      params = params.set('PermissionCodeSalary', salaryPermission.toString());

      if (permissionCode.length > 0) {
        params = params.set('PermissionCode', permissionCode);
      }
    }
    return this.http.get<any>(
      `${this.baseUrl}/api/Vacancy/GenerateVacancyExcel`,
      { params },
    );
  }

  getVacanciesDropdown(params: any) {
    let tokencito = this.localService.getJsonValue('token');
    const relevantPermissions = ['v005', 'v006'];
    const userPermissions = tokencito.data.permissions
      .map((permission) => permission.code)
      .filter((code) => relevantPermissions.includes(code));

    let permissionCode = '';
    if (userPermissions.includes('v006')) {
      permissionCode = 'v006'; // ver solo donde participa
    } else if (userPermissions.includes('v005')) {
      permissionCode = 'v005'; // ver todos
    }

    if (permissionCode.length > 0) {
      params.PermissionCode = permissionCode;
    }

    return this.http.get<any[]>(
      `${this.baseUrl}/api/Vacancy/GetVacantSummary`,
      { params },
    );
  }

  getVacancyNotes(vacancyId: string): Observable<VacancyComment[]> {
    let params = new HttpParams().set('vacancyId', vacancyId);

    return this.http.get<VacancyComment[]>(
      this.baseUrl + '/api/VacancyNotes/GetVacancyNotesByVacancyId',
      { params },
    );
  }

  createVacancyComment(requestBody: VacancyComment) {
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/VacancyNotes/CreateOrUpdate',
      requestBody,
    );
  }

  resetVacancyComments(vacancyComments: any) {
    return this.http.post<APIResponse>(
      this.baseUrl + '/api/VacancyNotes/ResetVacancyCommentSummary',
      vacancyComments,
    );
  }

  linkGoogleAccount(data: any) {
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/google/save-gmail-account`,
      data,
    );
  }

  checkGoogleAccount() {
    return this.http.get<any>(`${this.baseUrl}/api/User/GetUserGmailAccount`);
  }

  sendEmailGoogleAccount(data: any) {
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/google/send-gmail`,
      data,
    );
  }

  getCalendarEvents(params: any) {
    return this.http.get<any[]>(
      `${this.baseUrl}/api/google/GetUserCalendarEvents`,
      { params },
    );
  }

  createCalendarEvent(data: any) {
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/google/CreateEvent`,
      data,
    );
  }

  editCalendarEvent(data: any) {
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/google/UpdateEvent`,
      data,
    );
  }

  deleteGoogleAccount() {
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/google/DeleteGmailAccount`,
      {},
    );
  }

  deleteEventGoogle(data: any) {
    return this.http.post<APIResponse>(
      `${this.baseUrl}/api/google/DeleteEvent/${data}`,
      {},
    );
  }
}

<div>
    <div class="ml-auto w-full text-right justify-end -mt-5 -mr-5">
        <button id="closeModalChangeStatus2" mat-icon-button (click)="closeModal()" tabindex="-1">
            <mat-icon class="close-x-primary">close</mat-icon>
        </button>
    </div>

    <!-- Header -->
    <div class="pb-2 text-center title-text-modal">
        {{ 'connectGoogle' | translate }}
    </div>

    <p class="descriptionDuplicateText text-center">
        {{ "conectGoogleNow" | translate }}
    </p>

    <div class="flex flex-col justify-center mt-4 gap-4">
        <figure>
            <img [src]="googleIcon" alt="Google Logo" class="w-40 h-40 mx-auto">
        </figure>
        <div class="text-gray-600 text-center">
            <span class="text-base">{{ 'gmailCalendar' | translate }}</span>
        </div>
    </div>

    <!-- Botón Enviar -->
    <div class="mt-6 flex justify-center">
        <button (click)="connectGoogleAccount()"
            class="bg-primary text-white px-6 py-2 rounded-lg hover:bg-yellow-800 transition-all ease-in-out w-full md:w-auto">
            {{ "conectGoogleAccount" | translate }}
        </button>
    </div>
</div>
import { Injectable } from '@angular/core';
import { PuzzleService } from './puzzle.service';

@Injectable({
  providedIn: 'root'
})
export class GoogleAccountService {

  googleAccount: boolean = false;

  constructor(
    private puzzleService: PuzzleService,
  ) {
    this.puzzleService.checkGoogleAccount().subscribe((response) => {
      this.googleAccount = response.isSuccess;
    });
  }

  getGoogleAccount(): boolean {
    return this.googleAccount;
  }
}

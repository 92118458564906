import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-response-small-dialog',
  templateUrl: './response-small-dialog.component.html',
  styleUrls: ['./response-small-dialog.component.css']
})
export class ResponseSmallDialogComponent implements OnInit {


  constructor( private dialogRef: MatDialogRef<ResponseSmallDialogComponent>, @Inject(MAT_DIALOG_DATA) public data:string[], @Inject(MAT_DIALOG_DATA) public response:string ) {}

  options: AnimationOptions = {
    path: '/assets/animations/checkmark.json',
  };

  options2: AnimationOptions = {
    path: '/assets/animations/error.json',
  };

  options3: AnimationOptions = {
    path: '/assets/animations/loading.json',
  };


  animationCreated(animationItem: AnimationItem): void {
  }

  ngOnInit(): void {
  }

  cerrar(){
    if (this.data[3]) {
      // copiar al portapapeles
      var aux = document.createElement("input");
      aux.setAttribute("value", this.data[3]);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);
    }
    this.dialogRef.close(true);
  }

}

import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class LocalService {

  constructor(private storageService: StorageService) { }

  // Set the json data to local 
  setJsonValue(key: string, value: any) {
    this.storageService.secureStorage.setItem(key, value);
  }

  // Get the json value from local 
  getJsonValue(key: string) {
    return this.storageService.secureStorage.getItem(key);
  }

  removeJsonValue(key: string) {
    this.storageService.secureStorage.removeItem(key);
  }

  // Clear the local 
  clearToken() {
    const cookiePreferences = this.storageService.secureStorage.getItem('cookiePreferences'); // Guarda el valor
    this.storageService.secureStorage.clear(); // Limpia todo el almacenamiento
    if (cookiePreferences) {
      this.storageService.secureStorage.setItem('cookiePreferences', cookiePreferences); // Restaura el valor
    }
  }
}

import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { CalendarDialogComponent } from 'src/app/dialogs/calendar-dialog/calendar-dialog.component';
import { ConfirmDialogComponent } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { GoogleAccountComponent } from 'src/app/dialogs/google-account/google-account.component';
import { MailDialogComponent } from 'src/app/dialogs/mail-dialog/mail-dialog.component';
import { ResponseDialogComponent } from 'src/app/dialogs/response-dialog/response-dialog.component';
import { GoogleAccountService } from 'src/app/services/google-account.service';
import { LocalService } from 'src/app/services/local.service';
import { PuzzleService } from 'src/app/services/puzzle.service';

@Component({
  selector: 'app-email-dropdown',
  templateUrl: './email-dropdown.component.html',
  styleUrls: ['./email-dropdown.component.css'],
})
export class EmailDropdownComponent implements OnInit {
  @Input() email: string;
  @Input() hover: boolean = false;
  @Input() tooltip: string = null;
  googleAccount: boolean = false;
  isAdvanced: boolean = false;
  outlook: SafeResourceUrl = '../../../assets/icons/outlook.svg';
  gmail: SafeResourceUrl = '../../assets/icons/gmail.svg';
  meet: SafeResourceUrl = '../../assets/icons/google-meet.svg';
  teams: SafeResourceUrl = '../../assets/icons/teams.svg';
  zoom: SafeResourceUrl = '../../assets/icons/zoom.svg';
  skype: SafeResourceUrl = '../../assets/icons/skype.svg';
  @ViewChild('secondMenuTrigger', { read: MatMenuTrigger })
  secondMenuTrigger: MatMenuTrigger;

  openSecondMenu(event: Event, trigger: MatMenuTrigger) {
    event.stopPropagation();
    trigger.openMenu();
  }

  constructor(
    private dialog: MatDialog,
    private puzzleService: PuzzleService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private google: GoogleAccountService,
    private localService: LocalService
  ) {
    this.googleAccount = this.google.getGoogleAccount();

    let tokencito = this.localService.getJsonValue('token');
    this.isAdvanced = tokencito.data.isAdvanced;
  }

  ngOnInit(): void {}

  goToGmail(url: string) {
    const advanced = this.isAdvanced;
    if (advanced) {
      if (this.googleAccount) {
        const dialog = this.dialog.open(MailDialogComponent, {
          width: '700px',
          maxWidth: '95vw',
          disableClose: true, // Evita que se cierre automáticamente
          data: {
            to: [this.email],
          },
        });

        // Manejar cierre manualmente
        dialog.backdropClick().subscribe(() => {
          this.confirmCloseDialog(dialog);
        });

        dialog.afterClosed().subscribe((result) => {
          if (result?.isSuccess) {
            this.openResponseDialog('Exito', result.message);
          }
        });
      } else {
        // Mostrar dialogo para ligar cuenta de google
        const dialog = this.dialog.open(GoogleAccountComponent, {
          width: '500px',
        });
      }
    } else {
      window.open(url, '_blank').focus();
    }
  }

  goToMeet(url: string) {
    const advanced = this.isAdvanced;
    if (advanced) {
      if (this.googleAccount) {
        const dialog = this.dialog.open(CalendarDialogComponent, {
          panelClass: 'custom-calendar-dialog',
          width: '95vw',
          maxWidth: '95vw',
          maxHeight: '95vh',
          data: {
            email: this.email
          }
        });
      } else {
        // Mostrar dialogo para ligar cuenta de google
        const dialog = this.dialog.open(GoogleAccountComponent, {
          width: '500px',
        });
      }
    } else {
      window.open(url, '_blank').focus();
    }
  }

  confirmCloseDialog(dialogRef: MatDialogRef<any>) {
    const title = this.translate.instant('closeDialog');
    const confirmMessage = this.translate.instant('closeDialogMessage');

    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: [title, confirmMessage],
    });

    confirmDialog.afterClosed().subscribe((result) => {
      if (result === true) {
        dialogRef.close(); // Solo cierra si el usuario confirma
      }
    });
  }

  openResponseDialog(response: string, message?: string) {
    let data: string[] = [];
    if (response == 'Error') {
      data.push('Error');
      data.push(message);
      const dialogRef = this.dialog.open(ResponseDialogComponent, {
        width: '500px',
        /* height: '400px', */
        data: data,
      });
    } else if (response == 'Exito') {
      data.push('Exito');
      data.push(message);

      const dialogRef = this.dialog.open(ResponseDialogComponent, {
        width: '500px',
        /* height: '400px', */
        data: data,
      });
    }
  }
}

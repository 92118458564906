import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-day-calendar-events',
  templateUrl: './day-calendar-events.component.html',
  styleUrls: ['./day-calendar-events.component.css'],
})
export class DayCalendarEventsComponent implements OnInit {
  day: any; // O el tipo que uses para tu objeto "day"
  currentLocale: string = 'es';

  constructor(
    private translate: TranslateService,
    private dialogRef: MatDialogRef<DayCalendarEventsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    // "data" contiene el objeto "day" que pasaste al abrir el dialog
    this.day = data;
    this.currentLocale =
      this.translate.currentLang === 'esp' ? 'es-MX' : 'en-US';

    this.translate.onLangChange.subscribe(() => {
      this.currentLocale =
        this.translate.currentLang === 'esp' ? 'es-MX' : 'en-US';
    });
  }

  ngOnInit(): void {}

  openEvent(event: any): void {
    // Aquí puedes abrir otro modal de edición o hacer cualquier otra acción
    this.dialogRef.close(event);
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  isDifferentDay(date1: Date, date2: Date): boolean {
    return new Date(date1).toDateString() !== new Date(date2).toDateString();
  }

  formatDate(date: Date): string {
    const d = new Date(date);
    const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short' };
    const lang = this.translate.currentLang === 'esp' ? 'es-MX' : 'en-US';
    return d.toLocaleDateString(lang, options).toUpperCase();
  }
}

<mat-toolbar class="z-10 shadow background-colorr">
  <div class="flex flex-row w-full h-full">
    <div class="w-1/3 flex justify-center md:justify-start items-center">
      <img class="w-16 m-4" [src]="logoImage" alt="" />
    </div>
    <div class="w-1/3 flex justify-center items-center">
    </div>
    <div class="w-1/3 flex justify-end items-center">
      <img class="w-16 m-4" src="../../assets/img/logo" alt="" />
    </div>
  </div>
  <div class="flex justify-end w-full md:w-2/4 py-8">
    <select (change)="changeLanguage($event.target.value)" [value]="currentLang">
      <option value="eng">English</option>
      <option value="esp">Español</option>
    </select>
  </div>
</mat-toolbar>


<div *ngIf="showControls" class="px-6 md:px-12 py-4 max-w-full">
  <div class="flex flex-col items-center">
    <div class="green-title text-center mb-4">
      {{ 'candidateDigitalExpedient' | translate }}
    </div>
    <div class="gray-subtitle w-full text-left" [innerHTML]="bilboard"></div>
  </div>

  <form action="" #myForm="ngForm">
    <div class="py-4">
      <div class="flex flex-col w-full wizard-container">
        <div class="flex justify-between items-center py-1.5 text-xl wizard-header"
          (click)="toogleWizardContainerExpedient()" (keydown)="handleKeydownAdress($event)" tabindex="1">
          <div class="dropdown-title pl-2">
            {{"candidateExternalTitleExpedient" | translate}}
          </div>
          <button type="button">
            <img [src]="expandIcon" alt="" [ngClass]="{'rotate-180': isWizardContainerDigitalExpedientExpanded}">
          </button>
        </div>
        <div *ngIf="isWizardContainerDigitalExpedientExpanded" class="flex flex-col flex-wrap">
          <div class="flex flex-col md:flex-row items-start w-full mt-4">

            <!-- Foto perfil -->
            <div class="flex md:pl-10 mt-4 pb-4 md:pb-0 w-full md:w-auto justify-center md:justify-start">
              <div class="w-36 h-36 relative">
                <!-- Borde redondo externo -->
                <div class="w-full h-full absolute rounded-full border image-container z-10"></div>
                <!-- Imagen del perfil del usuario -->
                <img *ngIf="userImage" class="w-full h-full absolute rounded-full object-cover" [src]="userImage"
                  alt="">
                <img *ngIf="!userImage" class="w-full h-full absolute rounded-full object-cover" [src]="defaultpfp"
                  alt="">
                <!-- Indicador en la parte inferior derecha -->
                <div class="p-1 absolute camera-position z-20">
                  <div class="flex justify-center items-center bg-circle-color rounded-full w-10 h-10">
                    <div id="selectNewLogoPicture" (click)="inputFileLogo.click()"
                      class="cursor-pointer w-6 h-6 flex justify-center items-center">
                      <img [src]="cameraIcon" alt="Upload Icon" class="w-6 h-6">
                    </div>
                  </div>
                  <input #inputFileLogo type="file" accept="image/*" (change)="readURL($event)" class="hidden">
                </div>
              </div>
            </div>
            <!-- Foto perfil END -->

            <div class="flex flex-col w-full">
              <div *ngIf="showControls" class="flex flex-col pb-3 w-full">
                <div class="px-4 flex flex-col md:flex-row flex-wrap">
                  <div class="py-2 px-6 w-full">
                    <div class="flex justify-start items-center">
                      <div class="text-left mr-4">
                        <label for="state" class="gray-subtitle pb-2 block">
                          {{ 'candidateDocumentsCandidateCatalogIntern' | translate }}
                        </label>
                      </div>
                    </div>
                    <input #fileSelect id="fileSelect" type="file"
                      accept=".pdf,.doc,.docx,application/msword,application/vnd.ms-excel,.xlsx,.xls,image/*,application/pdf"
                      (click)="fileSelect.value = null" (change)="fileChangeEvent($event)" class="hidden">

                    <div class="flex flex-col md:flex-row md:items-center w-full justify-between">
                      <app-custom-dropdown [items]="visibleInnerDocs" [(ngModel)]="selectedInnerDoc"
                        [translateLabels]="true" name="innerdoc" width="100%" class="w-full mb-4 md:mb-0"
                        displayProperty="pdT_NAME">
                      </app-custom-dropdown>

                      <div class="w-full md:max-w-xs pl-0 md:pl-4">
                        <app-custom-primary-button size="excel-size" color="secondary" type="button"
                          class="w-full whitespace-nowrap" width="100%" (click)="fileSelectClick()">
                          {{ "candidateDetailSaveDocuments" | translate }}
                        </app-custom-primary-button>
                      </div>
                    </div>

                    <div *ngIf="selectedInnerDoc" class="mt-4">
                      <div class="drag-drop-area border-dashed border-2 rounded-lg p-4 text-center cursor-pointer"
                        [ngClass]="dragging ? 'dragging' : 'border-gray-400'" (dragover)="onDragOver($event)"
                        (dragleave)="onDragLeave($event)" (drop)="onDrop($event)" (click)="fileSelectClick()">
                        <div class="py-10">
                          <div class="text-gray-500 m-0">
                            {{ "dragAndDrop" | translate }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="pt-6 w-full">
                      <div class="flex flex-wrap justify-between -mr-5">
                        <div *ngFor="let innerDoc of innerDocuments; let i = index"
                          class="flex py-2 pr-5 w-full md:w-1/2">
                          <a (click)="opendoc(innerDoc.cdoC_DOCUMENT_ROUTE)"
                            class="document-card w-full gray-subtitle flex items-center justify-between p-2">
                            <div class="flex items-center">
                              <img [src]="fileIcon" alt="Document Icon" class="mr-2">
                              {{ innerDoc.cdoC_DOCUMENT_NAME | translate }}
                            </div>
                            <a *ngIf="!showdocuments" (click)="deletedoc(innerDoc, 0)" class="flex items-center">
                              <img [src]="deleteIcon" alt="trash">
                            </a>
                          </a>
                        </div>
                      </div>
                      <div *ngIf="innerDocuments.length === 0" class="flex flex-col items-center justify-center px-2">
                        <img [src]="expedientImage" alt="Sin documentos" class="w-60 h-40 pt-2 pb-2">
                        <div class="text-left w-full">
                          <div class="green-title pb-4 pt-4">{{ "candidateWithoutEvaluationsTitle" | translate }}</div>
                          <div class="pb-2 gray-subtitle">
                            {{ "candidateWithoutEvaluationsMessage" | translate }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Esta sección es para el nuevo candidato Información de dirección (Opcional)-->
    <div class="flex flex-col py-3 w-full px-6 wizard-container mb-4">
      <div class="flex justify-between items-center py-1.5 text-xl wizard-header"
        (click)="toggleWizardContainerAdress()" (keydown)="handleKeydownAdress($event)" tabindex="9">
        <div class="dropdown-title pl-2">
          {{"candidateDetailAdressData" | translate}}
        </div>
        <button type="button">
          <img [src]="expandIcon" alt="" [ngClass]="{'rotate-180': isWizardContainerExpandedAdress}">
        </button>
      </div>
      <div *ngIf="isWizardContainerExpandedAdress" class="flex flex-col md:flex-row flex-wrap p-4">
        <div class="flex flex-col md:flex-row flex-wrap w-full">

          <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
            <label for="streetInput" class="title-input-text">
              {{"candidateDetailGeneralDataAdressStreet" | translate}}
            </label>
            <div class="input-container mobile-responsive">
              <input id="streetInput" type="text" class="custom-input input-width-height"
                [placeholder]="'candidatePlaceHolderStreet' | translate"
                [(ngModel)]="candidate?.cdT_ADDRESS_INFO.adR_STREET" name="street" maxlength="100" tabindex="10"
                oninput="this.value = this.value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();">
            </div>
          </div>
          <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
            <label for="extnumInput" class="title-input-text">
              {{"candidateDetailGeneralDataAdressNumber" | translate}}
            </label>
            <div class="input-container mobile-responsive">
              <input id="extnumInput" type="text" class="custom-input input-width-height"
                [placeholder]="'candidatePlaceHolderExtNumber' | translate"
                [(ngModel)]="candidate.cdT_ADDRESS_INFO.adR_STREET_NUMBER" name="extnum" maxlength="10" tabindex="11"
                oninput="this.value = this.value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();">
            </div>
          </div>

          <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
            <label for="intnumInput" class="title-input-text">
              {{"candidateDetailGeneralDataAdressNumberInt" | translate}}
            </label>
            <div class="input-container mobile-responsive">
              <input id="intnumInput" type="text" class="custom-input input-width-height"
                [placeholder]="'candidatePlaceHolderIntNumber' | translate"
                [(ngModel)]="candidate.cdT_ADDRESS_INFO.adR_SUITE_NUMBER" name="intnum" maxlength="10" tabindex="12"
                oninput="this.value = this.value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();">
            </div>
          </div>
          <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
            <label for="suburbInput" class="title-input-text">
              {{"candidateDetailGeneralDataAdressColony" | translate}}
            </label>
            <div class="input-container mobile-responsive">
              <input id="suburbInput" type="text" class="custom-input input-width-height"
                [placeholder]="'candidatePlaceHolderSuburb' | translate"
                [(ngModel)]="candidate.cdT_ADDRESS_INFO.adR_SUBURB" name="suburb" maxlength="100" tabindex="13"
                oninput="this.value = this.value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();">
            </div>
          </div>

          <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
            <div>
              <label for="stateSelect" class="title-input-text">
                {{"candidateDetailGeneralDataAddressState" | translate}}
              </label>
              <mat-select [(ngModel)]="selectedState" name="stateSelection"
                class="borde p-3 w-full md:w-2/3 centered-text brown-border" tabindex="14"
                (selectionChange)="onStateMatSelectChange($event)" disableOptionCentering>
                <ngx-mat-select-search class="searcher" [formControl]="stateSearchControl"
                  [placeholderLabel]="'search' | translate"
                  [noEntriesFoundLabel]="'NO_RESULTADOS' | translate"></ngx-mat-select-search>
                <mat-option *ngFor="let state of states; trackBy: trackByStateFn" [value]="state">
                  {{ state.stS_NAME }}
                </mat-option>
              </mat-select>
            </div>
          </div>

          <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
            <label for="citySelect" class="title-input-text">
              {{"candidateDetailGeneralDataAddressCity" | translate}}
            </label>
            <mat-select [(ngModel)]="selectedCity" name="citySelection"
              class="borde p-3 w-full md:w-2/3 centered-text brown-border" tabindex="15"
              (selectionChange)="onCityMatSelectChange($event)" disableOptionCentering>
              <ngx-mat-select-search class="searcher" [formControl]="citySearchControl"
                  [placeholderLabel]="'search' | translate"
                  [noEntriesFoundLabel]="'NO_RESULTADOS' | translate"></ngx-mat-select-search>
              <mat-option *ngFor="let city of cities; trackBy: trackByCityFn" [value]="city">
                {{ city.ctY_NAME }}
              </mat-option>
            </mat-select>
          </div>
          <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
            <label for="cpInput" class="title-input-text">
              {{ "candidateDetailGeneralDataAdressPostalCode" | translate }}
            </label>
            <div
              [ngClass]="{'error-border': candidate?.cdT_ADDRESS_INFO?.adR_CP && candidate?.cdT_ADDRESS_INFO?.adR_CP?.length < 5}"
              class="input-container mobile-responsive">
              <input id="cpInput" type="text" class="custom-input input-width-height"
                [placeholder]="'candidatePlaceHolderPostalCode' | translate"
                [(ngModel)]="candidate.cdT_ADDRESS_INFO.adR_CP" name="cp" maxlength="5"
                (keypress)="($event.charCode >= 48 && $event.charCode < 58 )" tabindex="16"
                oninput="this.value = this.value.replace(/[^0-9]/g, '').trim();">
            </div>
            <div *ngIf="candidate?.cdT_ADDRESS_INFO?.adR_CP && candidate?.cdT_ADDRESS_INFO?.adR_CP?.length < 5"
              class="text-red-500 text-xs mt-1">
              {{ "candidateDetailPostalCode" | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Esta sección es para el nuevo candidato Datos personales (Opcional)-->
    <div class="flex flex-col py-3 w-full px-6 wizard-container mb-4">
      <div class="flex justify-between items-center py-1.5 text-xl wizard-header"
        (click)="toggleWizardContainerGeneralOptional()" (keydown)="handleKeydownGeneralData($event)" tabindex="17">
        <div class="dropdown-title pl-2">
          {{"candidateDetailOptional" | translate}}
        </div>
        <button type="button">
          <img [src]="expandIcon" alt="" [ngClass]="{'rotate-180': isWizardContainerGeneralOptionalExpanded}">
        </button>
      </div>
      <div *ngIf="isWizardContainerGeneralOptionalExpanded" class="flex flex-col md:flex-row flex-wrap p-4">
        <div class="flex flex-col md:flex-row flex-wrap">

          <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
            <label for="streetInput" class="title-input-text">
              {{"candidateDetailGeneralDataCURP" | translate}}
            </label>
            <div class="input-container mobile-responsive">
              <input id="curpInput" type="text" class="custom-input input-width-height"
                [placeholder]="'candidatePlaceHolderCURP' | translate" [(ngModel)]="candidate.cdT_CURP" name="curp"
                maxlength="18" tabindex="18"
                oninput="this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s]/g, '').trim().toUpperCase();">
            </div>
          </div>
          <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
            <label for="extnumInput" class="title-input-text">
              {{"candidateDetailGeneralDataRFC" | translate}}
            </label>
            <div class="input-container mobile-responsive">
              <input id="rfcInput" type="text" class="custom-input input-width-height"
                [placeholder]="'candidatePlaceHolderRFC' | translate" [(ngModel)]="candidate.cdT_RFC" name="rfc"
                maxlength="13" tabindex="19"
                oninput="this.value = this.value.replace(/[^a-zA-ZÑñ\d]/g, '').toUpperCase();">
            </div>
          </div>

          <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
            <label for="intnumInput" class="title-input-text">
              {{"candidateDetailGeneralDataNSS" | translate}}
            </label>
            <div class="input-container mobile-responsive">
              <input id="nssInput" type="text" class="custom-input input-width-height"
                [placeholder]="'candidatePlaceHolderNSS' | translate"
                (keypress)="($event.charCode >= 48 && $event.charCode <= 57)"
                [(ngModel)]="candidate.cdT_SOCIAL_SECURITY_NUMBER" name="nss" maxlength="11" tabindex="20"
                oninput="this.value = this.value.replace(/[^0-9]/g, '').trim();">
            </div>
          </div>
          <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
            <label for="suburbInput" class="title-input-text">
              {{"candidateDetailGeneralDataBirthdate" | translate}}
            </label>
            <div class="h-full">
              <app-lang-datepicker #datePicker id="birthdateInput" [date]="candidate.cdT_BIRTH_DATE" [max]="maxDate"
                [tabindex]="21" (datechange)="candidate.cdT_BIRTH_DATE = $event"
                [lang]="currentLang"></app-lang-datepicker>
            </div>
          </div>

          <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
            <div>
              <label for="stateSelect" class="title-input-text">
                {{"candidateDetailGeneralDataBirthState" | translate}}
              </label>
              <mat-select [(ngModel)]="selectedBirthState" name="birthstateSelection"
                (selectionChange)="onStateMatSelectChange2($event)"
                class="borde p-3 w-full md:w-2/3 centered-text brown-border" tabindex="22" disableOptionCentering>
                <ngx-mat-select-search class="searcher" [formControl]="stateSearchControl2"
                  [placeholderLabel]="'search' | translate"
                  [noEntriesFoundLabel]="'NO_RESULTADOS' | translate"></ngx-mat-select-search>
                <mat-option *ngFor="let state of states2; trackBy: trackByBirthStateFn" [value]="state">
                  {{ state.stS_NAME }}
                </mat-option>
              </mat-select>
            </div>
          </div>

          <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
            <label for="birthcitySelect" class="title-input-text">
              {{"candidateDetailGeneralDataBirthCity" | translate}}
            </label>
            <mat-select [(ngModel)]="selectedBirthCity" name="birthcitySelection"
              (selectionChange)="onCityMatSelectChange2($event)"
              class="borde p-3 w-full md:w-2/3 centered-text brown-border" tabindex="23" disableOptionCentering>
              <ngx-mat-select-search class="searcher" [formControl]="citySearchControl2"
                  [placeholderLabel]="'search' | translate"
                  [noEntriesFoundLabel]="'NO_RESULTADOS' | translate"></ngx-mat-select-search>
              <mat-option *ngFor="let city of cities2; trackBy: trackByCityFn2" [value]="city">
                {{ city.ctY_NAME }}
              </mat-option>
            </mat-select>
          </div>

          <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
            <div>
              <label for="stateSelect" class="title-input-text">
                {{"candidateDetailGeneralDataProfessionalLicense" | translate}}
              </label>
              <input id="cedulaprofesionalInput" type="text" class="custom-input input-width-height"
                [placeholder]="'candidatePlaceHolderProfessionalLicense' | translate"
                (keypress)="($event.charCode >= 48 && $event.charCode < 58 )"
                [(ngModel)]="candidate.cdT_PROFESSIONAL_CERTIFICATE" name="cedulaprofesional" maxlength="8"
                tabindex="24" oninput="this.value = this.value.replace(/[^0-9]/g, '').trimStart();">
            </div>
          </div>

          <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
            <label for="birthcitySelect" class="title-input-text">
              {{"candidateDetailGeneralDataCredit" | translate}}
            </label>
            <input id="creditoinfonavitInput" type="text" class="custom-input input-width-height"
              [placeholder]="'candidatePlaceHolderCredit' | translate"
              (keypress)="($event.charCode >= 48 && $event.charCode < 58 )" [(ngModel)]="candidate.cdT_INFONAVIT_CREDIT"
              name="creditoinfonavit" maxlength="12" tabindex="25"
              oninput="this.value = this.value.replace(/[^0-9]/g, '').trim();">
          </div>

          <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
            <label for="bankInput" class="title-input-text">
              {{"candidateBank" | translate}}
            </label>
            <input id="BankInput" type="text" class="custom-input input-width-height"
              [placeholder]="'candidatePlaceHolderBank' | translate" [(ngModel)]="candidate.cdT_BANK" name="bankInput"
              maxlength="30" tabindex="29"
              oninput="this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s]/g, '').trimStart().toUpperCase();">
          </div>

          <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
            <label for="clabeInput" class="title-input-text">
              {{"candidateCLABE" | translate}}
            </label>
            <input id="creditoCLABEInput" type="text" class="custom-input input-width-height"
              [placeholder]="'candidatePlaceHolderCLABE' | translate"
              (keypress)="($event.charCode >= 48 && $event.charCode < 58 )" [(ngModel)]="candidate.cdT_BANK_CLABE"
              name="clabeInput" maxlength="18" tabindex="28" minlength="18"
              oninput="this.value = this.value.replace(/[^0-9]/g, '').trim();">
          </div>

        </div>
      </div>
    </div>

    <div class=" flex justify-end mt-6 w-full text-right">
      <app-custom-primary-button size="excel-size" color="primary" type="button"
        class="ml-auto w-full whitespace-nowrap" (click)="sendDocsAndEditCandidate(myForm)" [disabled]="sending">
        <span *ngIf="!sending">{{ "candidateExternalSaveButton" | translate }}</span>
        <div *ngIf="sending" class="w-full flex justify-center">
          <mat-spinner diameter="20" color="primary"></mat-spinner>
        </div>
      </app-custom-primary-button>
    </div>
  </form>

  <div *ngIf="!showControls" class="w-full">
    <!-- <div class="font-medium gray text-3xl mb-16 mt-10 md:pl-48 pl-3">
    Candidato: {{ accessLink.CANDIDATE_FULL_NAME }}
  </div> -->

    <div *ngIf="showRequestAccessLink" class="text-center text-2xl mb-10 mt-20 md:px-56 px-5 gray-text-color">
      {{ 'candidateDocumentsExpiredLink' | translate }}.
    </div>

    <div *ngIf="requestedAccessLink" class="text-center text-2xl mt-10 md:px-56 px-5 gray-text-color">
      {{ 'candidateDocumentsRequestLink' | translate }}.

    </div>

    <div *ngIf="showRequestAccessLink" class="flex justify-center md:mx-0 mx-3">
      <button mat-button class="backcyan text-2xl p-3 md:w-72 w-3/4 md:mx-0 mx-8 boton-request-access"
        (click)="requestAccessLink()" [disabled]="youCantClick">
        {{ 'candidateDocumentsRequestLinkButton' | translate }}.
      </button>

    </div>

    <div class="flex justify-center" *ngIf="requestedAccessLink">
      <img src="../../assets/img/videocollaboration.png" class="md:w-6/12 w-full" alt="">
    </div>


  </div>
import { Component, Inject, OnInit, OnDestroy, HostListener } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Candidate } from 'src/app/interfaces/Candidate';
import { LocalService } from 'src/app/services/local.service';
import { PuzzleService } from 'src/app/services/puzzle.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-docu-viewer',
  templateUrl: './docu-viewer.component.html',
  styleUrls: ['./docu-viewer.component.css'],
})
export class DocuViewerComponent implements OnInit, OnDestroy {
  pdfUrl: string;
  fileList: any;
  activeFile: number = 0;
  nameList: string[] = [];

  documentHeader: string = 'Curriculum vitáe';
  title: string;
  data: Candidate;
  pdfsource: SafeResourceUrl;
  imgsource: SafeResourceUrl;

  constructor(
    public sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private puzzleService: PuzzleService,
    private localService: LocalService,
    private dialogRef: MatDialogRef<DocuViewerComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) { data, header, title, list, index, nameList },
  ) {
    this.data = data;
    if (header != null) {
      this.documentHeader = header;
    }
    if (title != null) {
      this.title = title;
    }

    if (list) {
      this.fileList = list;
    }

    if (index) {
      this.activeFile = index;
    }

    if (nameList) {
      this.nameList = nameList;
    }

    this.checkFile();
  }

  ngOnInit(): void {
    document.addEventListener('keydown', this.handleKeydown);
  }

  ngOnDestroy(): void {
    document.removeEventListener('keydown', this.handleKeydown);
  }

  handleKeydown = (event: KeyboardEvent) => {
    if (event.key === 'ArrowLeft' && this.activeFile > 0) {
      this.prevDoc();
    } else if (event.key === 'ArrowRight' && this.fileList && this.fileList?.length > (this.activeFile + 1)) {
      this.nextDoc();
    }
  };

  prevDoc() {
    this.pdfUrl = null;
    this.imgsource = null;
    setTimeout(() => {
      if (this.activeFile > 0) {
        this.activeFile--;
      }
      this.data = this.fileList[this.activeFile];
      this.checkFile();
    }, 0);
  }

  nextDoc() {
    this.pdfUrl = null;
    this.imgsource = null;
    setTimeout(() => {
      if (this.fileList && this.activeFile < this.fileList?.length - 1) {
        this.activeFile++;
      }
      this.data = this.fileList[this.activeFile];
      this.checkFile();
    }, 0);
  }

  checkFile() {
    let re = /(?:\.([^.]+))?$/;

    if (this.data?.cv != null) {
      if (this.data.cdT_NAME) {
        this.title = `${this.translate.instant('CV')} - ${[
          this.data?.cdT_NAME,
          this.data?.cdT_PATERNAL_SURNAME,
          this.data?.cdT_MATERNAL_SURNAME,
        ]
          .filter(Boolean)
          .join(' ')}`;
      }
      this.pdfsource = this.sanitizer.bypassSecurityTrustResourceUrl(
        environment.imageUrl + this.data.cv.cdoC_DOCUMENT_ROUTE,
      );

      let exten = re.exec(this.data.cv.cdoC_DOCUMENT_ROUTE)[1];

      if (exten != null) {
        if (
          exten.toLowerCase().includes('gif') ||
          exten.toLowerCase().includes('jpg') ||
          exten.toLowerCase().includes('png') ||
          exten.toLowerCase().includes('webp') ||
          exten.toLowerCase().includes('jpeg')
        ) {
          this.imgsource = this.sanitizer.bypassSecurityTrustResourceUrl(
            environment.imageUrl + this.data.cv.cdoC_DOCUMENT_ROUTE,
          );
        } else if (exten.toLowerCase().includes('pdf')) {
          this.pdfUrl = environment.imageUrl + this.data.cv.cdoC_DOCUMENT_ROUTE;
        } else {
          const url = environment.imageUrl + this.data.cv.cdoC_DOCUMENT_ROUTE;
          window.open(url, '_blank');
          this.dialogRef.close();
        }
      }
    } else {
      if (this.nameList.length > 0) {
        this.title = this.nameList[this.activeFile];
      }

      this.pdfsource = this.sanitizer.bypassSecurityTrustResourceUrl(
        environment.imageUrl + this.data,
      );

      let exten = re.exec(this.data + '')[1];
      if (exten != null) {
        const paths = [
          'webapi.haibuhr.com/Storage',
          'webapi-devel.haibuhr.com/Storage',
        ];

        const normalizedUrl = this.data.toString().replace(/^https?:\/\//, '');
        const isMatch = paths.some((path) => normalizedUrl.startsWith(path));

        if (
          exten.toLowerCase().includes('gif') ||
          exten.toLowerCase().includes('jpg') ||
          exten.toLowerCase().includes('png') ||
          exten.toLowerCase().includes('webp') ||
          exten.toLowerCase().includes('jpeg')
        ) {
          this.imgsource = this.sanitizer.bypassSecurityTrustResourceUrl(
            isMatch ? this.data.toString() : environment.imageUrl + this.data,
          );
        } else if (exten.toLowerCase().includes('pdf')) {
          this.pdfUrl = isMatch ? this.data.toString() : environment.imageUrl + this.data;
        } else {
          let url = isMatch ? this.data.toString() : environment.imageUrl + this.data;
          window.open(url, '_blank');
          this.dialogRef.close();
        }
      }
    }
  }
}
<div class="flex justify-start md:py-0 normal-text break-words whitespace-normal w-full overflow-hidden"
    [ngClass]="hover ? 'option-hover-effect' : ''"
    style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
    <a [matMenuTriggerFor]="firstMenu" [matTooltip]="tooltip ? tooltip : ('emailHoverMessage' | translate)"
        class="block w-full">
        <span class="w-full block text-left overflow-hidden overflow-ellipsis">{{ email }}</span>
    </a>
</div>

<mat-menu #firstMenu="matMenu" class="mi-menu-personalizado">
    <div *ngIf="!googleAccount || !isAdvanced" mat-menu-item [matMenuTriggerFor]="mailMenu"
        class="flex items-center justify-start px-2 py-2 w-full option">
        <a class="flex items-center justify-start cursor-pointer">
            <mat-icon class="h-5 w-5">mail</mat-icon>
            <div class="px-2 actions-text md:flex">
                {{ 'sendMail' | translate }}
            </div>
        </a>
    </div>
    <div *ngIf="googleAccount && isAdvanced" mat-menu-item class="flex items-center justify-start px-2 py-2 w-full option"
        (click)="goToGmail('https://mail.google.com/mail/?view=cm&fs=1&to=' + email)">
        <a class="flex items-center justify-start cursor-pointer">
            <mat-icon class="h-5 w-5">mail</mat-icon>
            <div class="px-2 actions-text md:flex">
                {{ 'sendMail' | translate }}
            </div>
        </a>
    </div>

    <div *ngIf="!googleAccount || !isAdvanced" mat-menu-item [matMenuTriggerFor]="callMenu"
        class="flex items-center justify-start px-2 py-2 w-full option">
        <a class="flex items-center justify-start cursor-pointer">
            <mat-icon class="h-5 w-5">video_call</mat-icon>
            <div class="px-2 actions-text md:flex">
                {{ 'sendCall' | translate }}
            </div>
        </a>
    </div>
    <a *ngIf="googleAccount && isAdvanced" mat-menu-item
        (click)="goToMeet('https://calendar.google.com/calendar/render?action=TEMPLATE&add=' + email)" target="_blank"
        class="flex items-center justify-start cursor-pointer">
        <mat-icon class="h-5 w-5">video_call</mat-icon>
        <div class="px-2 actions-text md:flex">
            {{ 'sendCall' | translate }}
        </div>
    </a>
</mat-menu>

<mat-menu #mailMenu="matMenu" class="mi-menu-personalizado">
    <div mat-menu-item class="flex items-center justify-start px-2 py-2 w-full option"
        (click)="goToGmail('https://mail.google.com/mail/?view=cm&fs=1&to=' + email)">
        <a class="flex items-center justify-start cursor-pointer">
            <img [src]="gmail" alt="Link" class="h-5 w-5">
            <div class="px-2 actions-text md:flex">
                Gmail
            </div>
        </a>
    </div>
    <div *ngIf="!googleAccount || !isAdvanced" mat-menu-item class="flex items-center justify-start px-2 py-2 w-full option">
        <a [href]="'https://outlook.live.com/mail/0/deeplink/compose?to=' + email" target="_blank"
            class="flex items-center justify-start cursor-pointer">
            <img [src]="outlook" alt="Link" class="h-5 w-5">
            <div class="px-2 actions-text md:flex">
                Outlook
            </div>
        </a>
    </div>
</mat-menu>

<mat-menu #callMenu="matMenu" class="mi-menu-personalizado">
    <a mat-menu-item (click)="goToMeet('https://calendar.google.com/calendar/render?action=TEMPLATE&add=' + email)"
        target="_blank" class="flex items-center justify-start cursor-pointer option">
        <img [src]="meet" alt="Link" class="h-5 w-5">
        <div class="px-2 actions-text md:flex">
            Meet
        </div>
    </a>
    <a *ngIf="!googleAccount || !isAdvanced" mat-menu-item [href]="'https://outlook.live.com/calendar/0/deeplink/compose?to=' + email"
        target="_blank" class="flex items-center justify-start cursor-pointer option">
        <img [src]="skype" alt="Link" class="h-5 w-5">
        <div class="px-2 actions-text md:flex">
            Skype
        </div>
    </a>
    <!-- <a mat-menu-item [href]="'https://teams.microsoft.com/'" target="_blank"
        class="flex items-center justify-start cursor-pointer option">
        <img [src]="teams" alt="Link" class="h-5 w-5">
        <div class="px-2 actions-text md:flex">
            Teams
        </div>
    </a> -->
    <a *ngIf="!googleAccount || !isAdvanced" mat-menu-item [href]="'https://zoom.us/meeting/schedule'" target="_blank"
        class="flex items-center justify-start cursor-pointer option">
        <img [src]="zoom" alt="Link" class="h-5 w-5">
        <div class="px-2 actions-text md:flex">
            Zoom
        </div>
    </a>
</mat-menu>
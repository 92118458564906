import { ChangeDetectorRef, Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HomeComponent } from '../../home/home.component';
import { User } from '../../interfaces/user';
import { PuzzleService } from '../../services/puzzle.service';
import { InjectUser } from '../../interfaces/inject-user';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ValidatorService } from '../../services/validator.service';
import { userRole } from '../../interfaces/role';
import { TranslateService } from '@ngx-translate/core';
import { LocalService } from 'src/app/services/local.service';

@Component({
  selector: 'app-add-dialog',
  templateUrl: './add-dialog.component.html',
  styleUrls: ['./add-dialog.component.css'],
})

export class AddDialogComponent implements OnInit {

  maxDate = new Date();
  hide: boolean = true;
  hide2: boolean = true;
  generateAutomatically: boolean = true;
  selectedRole: any;
  miFormulario: FormGroup = this.fb.group(
    {
      nombre: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      apellidos: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
      secondLastName: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
      phone: ['', [this.phoneValidator()]],
      correo: [
        '',
        [
          Validators.required,
          Validators.pattern(this.validatorService.emailPattern),
        ],
      ],
      correoprev: [
        '',
        [
          Validators.required,
          Validators.pattern(this.validatorService.emailPattern),
        ],
      ],
      rol: ['', [Validators.required]],
      passwordOption: [true],
      birthDate: [''],
      password: [''],
      password2: ['']
    },
    {
      validators: [
        this.validatorService.camposIguales('password', 'password2'),
      ],
    }
  );
  password2: string = 'password2';
  password: string = 'password';
  id: string = '';
  allRoles: userRole[] = [];
  createUser: User = {} as User;
  editUser: User;
  newUser: boolean = false;
  message: string = '';
  messageText: string = '';
  roleTranslationMap = {
    'Administrador': 'administrador',
    'Ejecutivo': 'ejecutivo',
  };
  pageSize = 50;
  pageIndex = 0;
  sending: boolean = false;
  step: number = 0
  existingUser: any;
  existingUserBool: boolean = false;
  isAgency: boolean = false;
  isMini: boolean = false;
  // roleId: 'D5A355E2-B288-4F4F-A21E-386C86CC1A0A',
  // roleName: 'Admin'

  constructor(private ngZone: NgZone,
    private cd: ChangeDetectorRef,
    private validatorService: ValidatorService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private puzzleService: PuzzleService,
    private dialogRef: MatDialogRef<HomeComponent>,
    private localService: LocalService,
    @Inject(MAT_DIALOG_DATA) public data: InjectUser
  ) {
    this.messageText = this.translate.instant('addDialogSubtitleEmail');
    if (this.data.user) {
      let fechaOriginal = this.data.user.anU_Birth_Date; // Suponiendo que viene en formato '2019-06-04T00:00:00'
      let fechaFormateada = new Date(fechaOriginal).toISOString().split('T')[0];
      this.message = this.translate.instant('addDialogEditUser');
      this.createUser = this.data.user;
      this.step++
      this.miFormulario.reset({
        nombre: this.transformValue(this.data.user.anU_NAME),
        apellidos: this.transformValue(this.data.user.anU_LAST_NAME),
        correo: this.data.user.email,
        correoprev: this.data.user.email,
        rol: this.data.user.roleId,
        phone: this.data.user.phoneNumber,
        birthDate: fechaFormateada,
        secondLastName: this.transformValue(this.data.user.anU_SECOND_LAST_NAME),
        passwordOption: false,
        password: '',
        password2: ''
      });
    } else {
      this.message = this.translate.instant('addDialogNewUser');
      this.createUser.anU_COMPANY_ID = this.data.company;
      this.newUser = true;
      const randomPassword = this.generateRandomPassword();
      this.miFormulario.reset({
        passwordOption: true,
        password: randomPassword,
        password2: randomPassword
      });
    }
  }

  ngOnInit(): void {
    this.maxDate.setFullYear(this.maxDate.getFullYear() - 10);
    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);

    const id = urlParams.get('id');
    this.id = id

    let tokencito = this.localService.getJsonValue('token');
    if (tokencito.data.isMiniCompany) {
      this.isMini = true;
    }

    if (tokencito.data.isAgency) {
      this.isAgency = true;
    }

    if (id != '') {
      this.puzzleService.getRoles(this.pageSize, this.pageIndex, id).subscribe((response) => {
        this.allRoles = response;
        const rol = this.allRoles.filter(role => role.name == 'Cliente')[0]
        if (rol?.id && this.isMini && this.newUser) {
          this.selectedRole = rol.id
        } else if (!this.newUser) {
          this.selectedRole = this.data.user.roleId
        }
      });
    }

    this.miFormulario.get('passwordOption').valueChanges.subscribe(value => {
      this.ngZone.run(() => {
        this.handlePasswordOptionChange(value);
      });
      this.cd.detectChanges();
    });
  }

  asignaForm() {
    this.createUser.anU_NAME = this.miFormulario.get('nombre').value.trim();
    this.createUser.anU_LAST_NAME = this.miFormulario.get('apellidos').value.trim();
    this.createUser.anU_SECOND_LAST_NAME = this.miFormulario.get('secondLastName').value?.trim();
    this.createUser.phoneNumber = this.miFormulario.get('phone').value;
    this.createUser.anU_Birth_Date = this.miFormulario.get('birthDate').value;
    this.createUser.email = this.miFormulario.get('correo').value.trim();
    this.createUser.roleId = this.miFormulario.get('rol').value
    this.createUser.password = this.miFormulario.get('password').value
    this.createUser.ConfirmPassword = this.miFormulario.get('password2').value
  }

  asignaValores() {
    this.miFormulario.markAllAsTouched();
    if (!this.sending) {
      let mail = ''
      if (this.miFormulario.get('correoprev')?.value) {
        mail = this.miFormulario.get('correoprev').value.trim();
      }
      if (this.step == 0 && mail && this.isEmail(mail)) {
        const params = {
          Email: mail
        }
        this.sending = true;
        this.puzzleService.getUserByEmail(params).subscribe((res) => {
          if (res.data == null && res.messageCode == 'NEW_USER') {
            this.messageText = this.translate.instant('addDialogSubtitle');
            this.step++
            this.miFormulario.get('correo').setValue(mail)
            this.newUser = true;
          } else if (res.data?.id) {
            this.messageText = this.translate.instant('addDialogNewUserExist');
            this.step++
            this.existingUser = res.data
            this.existingUserBool = true;
            this.newUser = false;

            let fechaOriginal = this.existingUser.anU_Birth_Date; // Suponiendo que viene en formato '2019-06-04T00:00:00'
            let fechaFormateada = new Date(fechaOriginal).toISOString().split('T')[0];
            this.miFormulario.reset({
              nombre: this.existingUser.anU_NAME,
              apellidos: this.existingUser.anU_LAST_NAME,
              correo: this.existingUser.email,
              phone: this.existingUser.phoneNumber,
              birthDate: fechaFormateada,
              secondLastName: this.existingUser.anU_SECOND_LAST_NAME,
              passwordOption: false,
              password: '',
              password2: ''
            });
          }
          this.sending = false;
        })
      }
      else if (this.miFormulario.valid && this.step == 1 && !this.existingUserBool) {
        this.asignaForm();
        this.createUser.username = this.createUser.email;
        if (this.createUser.languagePreference !== undefined) {
          delete this.createUser.languagePreference;
        }
        this.sending = true;
        this.createUser.CompanyId = this.id
        if (this.newUser) {
          this.puzzleService.createUser(this.createUser).subscribe((response) => {
            if (response.isSuccess) {
              const message = this.translate.instant(response.message);
              this.dialogRef.close({ ...response, message: message });
            } else {
              const message = this.translate.instant(response.message);
              this.dialogRef.close({ ...response, message: message });
            }
          }),
            () => {
              this.sending = false;
            };
        } else {
          delete this.createUser.ConfirmPassword;
          this.puzzleService.editUser(this.createUser).subscribe((response) => {
            if (response.isSuccess) {
              this.dialogRef.close({ status: 'Exito', message: this.translate.instant(response.message) });
            } else {
              this.dialogRef.close({ status: 'Error', message: this.translate.instant(response.message) });
            }
          }),
            () => {
              this.sending = false;
            };
        }

      } else if (this.miFormulario.get('rol').value && this.step == 1 && this.existingUserBool) {
        const user = {
          UserId: this.existingUser.id,
          CompanyId: this.id,
          Role: this.miFormulario.get('rol').value
        }
        this.sending = true;
        this.puzzleService.addUserToCompany(user).subscribe((res) => {
          if (res.isSuccess) {
            this.dialogRef.close({ status: 'Exito', message: this.translate.instant('userAddedSuccesfully') });
          } else {
            if (res.messageCode == 'USER_ALREADY_IN_COMPANY') {
              this.dialogRef.close({ status: 'Error', message: this.translate.instant('userInCompany') });
            } else {
              this.dialogRef.close({ status: 'Error', message: res.message });
            }
          }
        });
      }
    }
  }

  cerrar() {
    this.dialogRef.close();
  }

  getTranslatedRole(role: string) {
    if (role === 'Administrador' || role === 'Ejecutivo' || role === 'Cliente') {
      return this.translate.instant(role.toLowerCase());
    }
    return role;
  }

  get emailErrorMsg(): string {
    const errors = this.miFormulario.get('correo')?.errors;
    if (errors?.required) {
      return this.translate.instant('addDialogEmailAlertObligatory');
    } else if (errors?.pattern) {
      return this.translate.instant('addDialogEmailAlertFormat');
    }
    return '';
  }

  get emailErrorMsg2(): string {
    const errors = this.miFormulario.get('correoprev')?.errors;
    if (errors?.required) {
      return this.translate.instant('addDialogEmailAlertObligatory');
    } else if (errors?.pattern) {
      return this.translate.instant('addDialogEmailAlertFormat');
    }
    return '';
  }

  get nameErrorMsg(): string {
    const errors = this.miFormulario.get('nombre')?.errors;
    if (errors?.required) {
      return this.translate.instant('addDialogNameAlertObligatory');
    } else if (errors?.maxlength) {
      return this.translate.instant('addDialogNameAlertMax');
    }
    return '';
  }

  get lastNameErrorMsg(): string {
    const errors = this.miFormulario.get('apellidos')?.errors;
    if (errors?.required) {
      return this.translate.instant('addDialogLastNameAlertObligatory');
    } else if (errors?.maxlength) {
      return this.translate.instant('addDialogLastNameAlertMax');
    }
    return '';
  }

  get secondLastNameErrorMsg(): string {
    const errors = this.miFormulario.get('secondLastName')?.errors;
    if (errors?.required) {
      return this.translate.instant('addDialogSecondLastNameAlertObligatory');
    } else if (errors?.maxlength) {
      return this.translate.instant('addDialogLastNameAlertMax');
    }
    return '';
  }

  campoNoValido(campo: string) {
    return (
      this.miFormulario.get(campo)?.invalid &&
      this.miFormulario.get(campo)?.touched
    );
  }

  generateRandomPassword(): string {
    const chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const passwordLength = 12;
    let password = "";
    for (let i = 0; i < passwordLength; i++) {
      const randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }
    return password;
  }

  private handlePasswordOptionChange(generateAutomatically: boolean): void {
    this.generateAutomatically = generateAutomatically;
    if (generateAutomatically) {
      const randomPassword = this.generateRandomPassword();
      this.miFormulario.patchValue({
        password: randomPassword,
        password2: randomPassword
      });
      // poner la contraseña como no obligatoria
      this.miFormulario.get('password')?.clearValidators();
    } else {
      this.miFormulario.patchValue({
        password: '',
        password2: ''
      });
      // hacer que el campo de contraseña sea obligatorio y minimo 6 caracteres
      this.miFormulario.get('password')?.setValidators([Validators.required, Validators.minLength(6)]);
    }
  }

  phoneValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value) {
        const isValidLength = value.length > 10;
        return isValidLength ? null : { 'telefonoInvalidLength': true };
      }
      return null;
    };
  }

  get phoneErrorMsg(): string {
    const errors = this.miFormulario.get('phone')?.errors;
    if (errors?.telefonoInvalidLength) {
      return this.translate.instant('El número de teléfono debe tener exactamente 10 dígitos.');
    }
    return '';
  }

  get passwordErrorMsg(): string {
    const errors = this.miFormulario.get('password')?.errors;
    if (errors?.required) {
      return this.translate.instant('addDialogPasswordAlertObligatory');
    } else if (errors?.minlength) {
      return this.translate.instant('addDialogPasswordAlertMin');
    }
    return '';
  }

  onlyNumbers(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  isEmail(email: string) {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/;
    return emailPattern.test(email);
  }

  private transformValue(value: string): string {
    if (value == null) {
      return '';
    }
    return value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();
  }

}


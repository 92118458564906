import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { DocuViewerComponent } from '../dialogs/docu-viewer/docu-viewer.component';
import { Candidate } from '../interfaces/Candidate';
import { CandidateDocument } from '../interfaces/CandidateDocument';
import { DocumentationTypeInfo } from '../interfaces/DocumentationTypeInfo';
import { DocumentsToSend } from '../interfaces/DocumentsToSend';
import { TokenValidationBody } from '../interfaces/TokenValidationBody';
import { constants } from '../services/constants';
import { PuzzleService } from '../services/puzzle.service';
import { AccessLink } from '../interfaces/access-link';
import { ResponseSmallDialogComponent } from '../dialogs/response-small-dialog/response-small-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { adR_STATE_ID_INFO } from '../interfaces/adr-state-id-info';
import { Country } from '../interfaces/country';
import { ctR_STATES } from '../interfaces/ctrstate';
import { stS_CITIES } from '../interfaces/stscity';
import { MatSelectChange } from '@angular/material/select';
import { CandidateUpdateData } from '../interfaces/candidate-external-edit';
import { LocalService } from '../services/local.service';
import { ImageCropDialogComponent } from '../dialogs/image-crop-dialog/image-crop-dialog.component';
import { NgForm } from '@angular/forms';
import { MultiPhotoDialogComponent } from '../dialogs/multi-photo-dialog/multi-photo-dialog.component';

@Component({
  selector: 'app-candidate-documents',
  templateUrl: './candidate-documents.component.html',
  styleUrls: ['./candidate-documents.component.css'],
})
export class CandidateDocumentsComponent implements OnInit {

  id: string;
  candidateName: string = '-';
  innerDocuments: CandidateDocument[] = [];
  selectedInnerDoc: DocumentationTypeInfo;
  fileloader: HTMLElement;
  innerDocs: DocumentationTypeInfo[];
  filteredInnerDocs: DocumentationTypeInfo[] = [];
  totalindex = 0;
  documentsFormsList = [];
  loading: MatDialogRef<ResponseSmallDialogComponent>;
  candidate: Candidate;
  bilboard: string = '';
  showControls: boolean = false;
  logoImage: SafeResourceUrl = '';
  accessLink: AccessLink = {
    tcdS_CANDIDATE_ID: '',
    CANDIDATE_FULL_NAME: ''
  };
  countries: Country;
  states: ctR_STATES[];
  cities: stS_CITIES[];
  states2: ctR_STATES[];
  cities2: stS_CITIES[];
  selectedCity: stS_CITIES;
  selectedState: adR_STATE_ID_INFO;
  selectedBirthCity: stS_CITIES;
  customstatesandcities: boolean = false;
  selectedBirthState: adR_STATE_ID_INFO;
  showRequestAccessLink: boolean = false;
  requestedAccessLink: boolean = false;
  youCantClick: boolean = false;
  currentLang: string;
  noPermittedFiles = false;
  noPermittedFilesArray = [];
  userImage;
  imageFile;
  expandIcon: SafeResourceUrl = '../../assets/icons/expand-more-icon.svg';
  expedientImage: SafeResourceUrl = '../../../assets/icons/expedient-not-found-icon.svg';
  fileIcon: SafeResourceUrl = '../../../assets/icons/file-icon.svg';
  deleteIcon: SafeResourceUrl = '../../../assets/icons/delete-orange-icon.svg';
  defaultpfp: SafeResourceUrl = '../../../assets/icons/default-pfp-icon.svg';
  cameraIcon: SafeResourceUrl = '../../../assets/icons/camera-icon.svg';
  companyName: string;
  maxDate = new Date();
  isWizardContainerExpandedAdress: boolean = false;
  isWizardContainerGeneralOptionalExpanded: boolean = false;
  isWizardContainerDigitalExpedientExpanded: boolean = true;
  dragging: boolean = false;
  @ViewChild('datePicker') datePicker: any;
  @ViewChild('inputFileLogo') inputFileLogo: ElementRef;

  constructor(
    public sanitizer: DomSanitizer,
    private swUpdate: SwUpdate,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private router: Router,
    private puzzleService: PuzzleService,
    private translate: TranslateService,
    private localService: LocalService
  ) {
    this.swUpdate.checkForUpdate();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.id = urlParams.get('id');
    this.candidate = this.initializeCandidate();
    if (this.id == null || this.id.trim() == '') {
      const invalidTokenMessage = this.translate.instant('candidateDocumentsTokenAlert');
      this.openResultDialog(0, invalidTokenMessage);
    } else {
      this.validateToken();
    }

  }

  ngOnInit(): void {
    // fecha maxima para el datepicker 10 años atras
    this.maxDate.setFullYear(this.maxDate.getFullYear() - 10);
    this.initializeLanguage();
  }

  private initializeCandidate(): Candidate {
    return {
      cdT_ID: '',
      cdT_ACTIVE: false,
      cdT_NUMBER: 0,
      cdT_NAME: '',
      cdT_PATERNAL_SURNAME: '',
      cdT_MATERNAL_SURNAME: '',
      cdT_EMAIL: '',
      cdT_PHONE_NUMBER_ONE: '',
      cdT_PHONE_NUMBER_TWO: '',
      cdT_CURP: '',
      cdT_RFC: '',
      cdT_SOCIAL_SECURITY_NUMBER: '',
      cdT_BIRTH_DATE: '',
      cdT_BIRTH_STATE_ID: null,
      cdT_BIRTH_STATE_CUSTOM: '',
      cdT_BIRTH_CITY_ID: null,
      cdT_BIRTH_CITY_CUSTOM: '',
      cdT_GENDER_ID: '',
      cdT_IMMEDIATE_BOSS: '',
      cdT_COMPANY_ID: '',
      cdT_BRANCH_OFFICE_ID: '',
      cdT_DEPARTMENT_ID: '',
      cdT_JOB_ID: '',
      cdT_STATUS_ID: '',
      cdT_VACANT_ID: '',
      cdT_ADDRESS_ID: '',
      cdT_STATUS_CANDIDATE_ID: '',
      cdT_HIRING_DATE: null,
      cdT_CREATED_DATE: new Date(),
      cdT_CREATED_DATE_THIN: new Date(),
      cdT_CREATED_BY: '',
      cdT_UPDATED_DATE: null,
      cdT_UPDATED_BY: null,
      totaL_COUNT: 0,
      totaL_PAGES: 0,
      cdT_BIRTH_STATE_INFO: null,
      cdT_BIRTH_CITY_INFO: null,
      cdT_GENDER_INFO: null,
      cdT_COMPANY_INFO: null,
      cdT_BRANCH_OFFICE_INFO: null,
      cdT_DEPARTMENT_INFO: null,
      cdT_JOB_INFO: null,
      cdT_STATUS_INFO: null,
      cdT_ADDRESS_INFO: {
        adR_CITY_ID: '',
        adR_STATE_ID: '',
        adR_COUNTRY_ID: '',
        adR_STATE_CUSTOM: false,
        adR_CITY_CUSTOM: false,
        adR_STREET: '',
        adR_SUBURB: '',
        adR_CP: '',
        adR_SUITE_NUMBER: '',
        adR_STREET_NUMBER: '',
        adR_COUNTRY_ID_INFO: null,
        adR_STATE_ID_INFO: null,
        adR_CITY_ID_INFO: null
      },
      cdT_STATUS_CANDIDATE_INFO: null,
      tkcD_TOKEN_CANDIDATE_DOCUMENT_INFO: null,
      cv: null,
      candidateCommentsSummary: null,
      cdT_PROFESSIONAL_CERTIFICATE: '',
      cdT_INFONAVIT_CREDIT: 0,
      selectedVacancy: null
    };
  }

  initializeLanguage() {
    if (this.localService.getJsonValue('lang')) {
      this.translate.use(this.localService.getJsonValue('lang'));
      this.currentLang = this.localService.getJsonValue('lang');
    } else {
      const browserLang = this.translate.getBrowserLang();
      let appLang = this.mapBrowserLangToAppLang(browserLang);
      this.translate.use(appLang);
      this.currentLang = appLang;
      this.localService.setJsonValue('lang', appLang);
    }
  }

  changeLanguage(lang: string): void {
    const newLang = lang;
    this.translate.use(newLang);
    this.currentLang = newLang;
    this.updateWelcomeMessage();
    this.localService.setJsonValue('lang', newLang);
    this.datePicker.changeLang(newLang);
  }

  mapBrowserLangToAppLang(browserLang: string): string {
    const langMap = {
      'en': 'eng',
      'es': 'esp'
    };
    return langMap[browserLang] || 'eng';
  }

  fileSelectClick() {
    if (this.fileloader == null) {
      this.fileloader = document.getElementById('fileSelect');
    }

    if (this.selectedInnerDoc != null) {
      this.fileloader.click();
    } else {
      this.translate.get('candidateDocumentsChooseFile').subscribe((translatedText: string) => {
        this.openResultDialog(0, translatedText);
      });
    }
  }

  updateWelcomeMessage(): void {
    this.translate.get('candidateDocumentsWelcomeMessage', { name: this.candidateName, company: this.companyName }).subscribe((translatedText: string) => {
      this.bilboard = translatedText;
    });

  }

  fileChangeEvent(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let docToAdd = {} as CandidateDocument;

      let file: File = fileList[0];

      // revisar si la ext del archivo es permitida
      // '.jpg', '.jpeg', '.gif', '.png', '.webp', '.doc', '.docx', '.pdf', '.csv', '.xls', and '.xlsx'.
      let ext = file.name.split('.').pop();
      let allowedExts = constants.allowedExt;
      if (!allowedExts.includes(ext.toLowerCase())) {
        let title = this.translate.instant("extErrorNotPermitted")
        let message = "<br>" + file.name + "<br><br>" + this.translate.instant("extErrorNotPermittedTypes");
        this.openResultDialog(0, title, message);
        return;
      }

      let fileSize = file.size;
      let maxFileSize = constants.maxFileSize;
      if (fileSize > maxFileSize) {
        let title = this.translate.instant("extErrorFileSize")
        let message = "<br>" + file.name + "<br><br>" + this.translate.instant("extErrorFileSizeMessage");
        this.openResultDialog(0, title, message);
        return;
      }

      // revisar si es imagen
      const imageExt = ['jpg', 'jpeg', 'gif', 'png', 'webp'];
      let isImage = imageExt.includes(ext.toLowerCase());
      if (isImage) {
        let dialog = this.dialog.open(MultiPhotoDialogComponent, {
          width: '600px',
          maxWidth: '90vw',
          maxHeight: '90vh',
          panelClass: 'compact-dialog',
          data: {
            name: this.selectedInnerDoc.pdT_NAME,
            file: file,
          },
        });

        dialog.afterClosed().subscribe((result: File) => {
          if (result) {
            file = result;

            let fileSize = file.size;
            let maxFileSize = constants.maxFileSize;
            if (fileSize > maxFileSize) {
              let title = this.translate.instant("extErrorFileSize")
              let message = "<br>" + file.name + "<br><br>" + this.translate.instant("extErrorFileSizeMessage");
              this.openResultDialog(0, title, message);
              return;
            }

            this.processFile(file);
          }
        });
        return;
      }

      let index = this.totalindex;
      this.totalindex++;

      docToAdd.cdoC_DOCUMENT_NAME = this.selectedInnerDoc.pdT_NAME;
      docToAdd.cdoC_INDEX = index;
      docToAdd.originalName = file.name;
      this.innerDocuments.push(docToAdd);

      let thisfrom = new FormData();

      thisfrom.append('cdOC_DOCUMENT_TYPE_ID', constants.candidateDocumentId);
      thisfrom.append(
        'cdOC_PERSONAL_DOCUMENTATION_TYPE_ID',
        this.selectedInnerDoc.pdT_ID
      );
      thisfrom.append('fileWrapper.file', file, file.name);
      thisfrom.append('name', this.selectedInnerDoc.pdT_NAME);

      this.documentsFormsList.push(thisfrom);

      for (let i = 0; i < this.innerDocs.length; i++) {
        if (this.innerDocs[i].pdT_ID == this.selectedInnerDoc.pdT_ID) {
          this.innerDocs[i].pdT_VISIBLE = false;
        }
      }
    }

    this.selectedInnerDoc = null;
  }

  processFile(file: File) {
    let index = this.totalindex;
    this.totalindex++;
    let docToAdd = {} as CandidateDocument;

    docToAdd.cdoC_DOCUMENT_NAME = this.selectedInnerDoc.pdT_NAME;
    docToAdd.cdoC_INDEX = index;
    docToAdd.originalName = file.name;

    this.innerDocuments.push(docToAdd);

    let thisfrom = new FormData();
    thisfrom.append('cdOC_DOCUMENT_TYPE_ID', constants.candidateDocumentId);
    thisfrom.append('cdOC_PERSONAL_DOCUMENTATION_TYPE_ID', this.selectedInnerDoc.pdT_ID);
    thisfrom.append('fileWrapper.file', file, file.name);
    thisfrom.append('name', this.selectedInnerDoc.pdT_NAME);

    this.documentsFormsList.push(thisfrom);

    for (let i = 0; i < this.innerDocs.length; i++) {
      if (this.innerDocs[i].pdT_ID == this.selectedInnerDoc.pdT_ID) {
        this.innerDocs[i].pdT_VISIBLE = false;
      }
    }

    this.selectedInnerDoc = null;
  }

  openResultDialog(mode: number, message?: string, content?: string) {
    let data: string[] = [];
    switch (mode) {
      case 0:
        {
          data.push('Error');
          data.push(message);
          if (content) data.push(content);
          return this.dialog.open(ResponseSmallDialogComponent, {
            width: '500px',
            data: data,
          });
        }
        break;
      case 1:
        {
          data.push('Exito');
          data.push(message);
          if (content) data.push(content);
          return this.dialog.open(ResponseSmallDialogComponent, {
            width: '500px',
            data: data,
          });
        }
        break;
      case 2:
        {
          data.push('loading');
          data.push(message);
          if (content) data.push(content);
          return this.dialog.open(ResponseSmallDialogComponent, {
            width: '500px',
            data: data,
          });
        }
        break;
    }
  }

  sendDocsAndEditCandidate(myForm: NgForm) {
    Object.values(myForm.controls).forEach((control) => {
      control.markAllAsTouched();
    });
    // Validación de CLABE, solo si tiene algún valor
    if (this.candidate.cdT_BANK_CLABE && this.candidate.cdT_BANK_CLABE.length < 18) {
      this.isWizardContainerGeneralOptionalExpanded = true;
      this.openResultDialog(0, this.translate.instant('extErrorClabeLength'));
      return;
    }

    // Validación de CURP, solo si tiene algún valor
    if (this.candidate.cdT_CURP && this.candidate.cdT_CURP.length < 18) {
      this.openResultDialog(0, this.translate.instant('invalidLengthKeyForm', { key: this.translate.instant('candidateDetailGeneralDataCURP'), number: 18 }));
      return;
    }

    // Validación de RFC, solo si tiene algún valor
    if (this.candidate.cdT_RFC && this.candidate.cdT_RFC.length < 12) {
      this.openResultDialog(0, this.translate.instant('invalidAtLeastLengthKeyForm', { key: this.translate.instant('candidateDetailGeneralDataRFC'), number: 12 }));
      return;
    }

    // Validación de NSS, solo si tiene algún valor
    if (this.candidate.cdT_SOCIAL_SECURITY_NUMBER && this.candidate.cdT_SOCIAL_SECURITY_NUMBER.length < 10) {
      this.openResultDialog(0, this.translate.instant('invalidLengthKeyForm', { key: this.translate.instant('candidateDetailGeneralDataNSS'), number: 10 }));
      return;
    }

    // Validación de Cédula Profesional, solo si tiene algún valor
    if (this.candidate.cdT_PROFESSIONAL_CERTIFICATE && this.candidate.cdT_PROFESSIONAL_CERTIFICATE.length < 8) {
      this.openResultDialog(0, this.translate.instant('invalidLengthKeyForm', { key: this.translate.instant('candidateDetailGeneralDataProfessionalLicense'), number: 8 }));
      return;
    }

    // Validación de Crédito, solo si tiene algún valor y si su longitud es menor a 12 dígitos
    if (this.candidate.cdT_INFONAVIT_CREDIT && this.candidate.cdT_INFONAVIT_CREDIT.toString().length < 12) {
      this.openResultDialog(0, this.translate.instant('invalidLengthKeyForm', { key: this.translate.instant('candidateDetailGeneralDataCredit'), number: 12 }));
      return;
    }

    if (this.candidate?.cdT_ADDRESS_INFO?.adR_CP && this.candidate?.cdT_ADDRESS_INFO?.adR_CP?.length < 5) {
      this.isWizardContainerExpandedAdress = true;
      this.openResultDialog(0, this.translate.instant('candidateDetailPostalCode'));
      return;
    }

    const candidateUpdateData: CandidateUpdateData = {
      candidateId: this.candidate.cdT_ID,
      candidate: {
        CDT_CURP: this.candidate.cdT_CURP,
        CDT_RFC: this.candidate.cdT_RFC,
        CDT_SOCIAL_SECURITY_NUMBER: this.candidate.cdT_SOCIAL_SECURITY_NUMBER,
        CDT_PROFESSIONAL_CERTIFICATE: this.candidate.cdT_PROFESSIONAL_CERTIFICATE,
        CDT_INFONAVIT_CREDIT: this.candidate.cdT_INFONAVIT_CREDIT,
        CDT_BIRTH_DATE: this.candidate.cdT_BIRTH_DATE,
        CDT_BIRTH_STATE_ID: this.candidate.cdT_BIRTH_STATE_ID,
        CDT_BIRTH_STATE_CUSTOM: this.candidate.cdT_BIRTH_STATE_CUSTOM,
        CDT_BIRTH_CITY_ID: this.candidate.cdT_BIRTH_CITY_ID,
        CDT_BIRTH_CITY_CUSTOM: this.candidate.cdT_BIRTH_CITY_CUSTOM,
        CDT_BANK_CLABE: this.candidate.cdT_BANK_CLABE,
        CDT_BANK: this.candidate.cdT_BANK,
      },
      address: {
        ADR_COUNTRY_ID: this.candidate.cdT_ADDRESS_INFO.adR_COUNTRY_ID,
        ADR_STATE_ID: this.candidate.cdT_ADDRESS_INFO.adR_STATE_ID,
        ADR_STATE_CUSTOM: this.candidate.cdT_ADDRESS_INFO.adR_STATE_CUSTOM,
        ADR_CITY_ID: this.candidate.cdT_ADDRESS_INFO.adR_CITY_ID,
        ADR_CITY_CUSTOM: this.candidate.cdT_ADDRESS_INFO.adR_CITY_CUSTOM,
        ADR_STREET: this.candidate.cdT_ADDRESS_INFO.adR_STREET,
        ADR_SUBURB: this.candidate.cdT_ADDRESS_INFO.adR_SUBURB,
        ADR_STREET_NUMBER: this.candidate.cdT_ADDRESS_INFO.adR_STREET_NUMBER,
        ADR_SUITE_NUMBER: this.candidate.cdT_ADDRESS_INFO.adR_SUITE_NUMBER,
        ADR_CP: this.candidate.cdT_ADDRESS_INFO.adR_CP
      }
    };
    this.puzzleService.editCandidateExternal(candidateUpdateData).subscribe(
      async response => {
        if (response.isSuccess) {
          if (this.userImage && this.imageFile) {
            const formData = new FormData();
            formData.append('image.file', this.imageFile, this.imageFile.name);
            formData.append('candidateId', candidateUpdateData.candidateId);
            await this.puzzleService.createCandidateExternalImage(formData).toPromise();
          }
          if (this.innerDocuments.length > 0) {
            const documentsSentSuccessfully: any = await this.sendDocs(this.candidate.cdT_ID);

            if (this.noPermittedFiles) {
              this.noPermittedFiles = false;
              this.loading.close();
              let title = this.translate.instant("extErrorNotPermitted")
              let message = "<br>" + this.noPermittedFilesArray.join("<br>") + "<br><br>" + this.translate.instant("extErrorNotPermittedTypes");
              this.noPermittedFilesArray = []
              this.openResultDialog(0, title, message);
              return;
            }

            if (!documentsSentSuccessfully) {
              this.loading.close();
              this.openResultDialog(0, this.translate.instant("extDocError"));
              return;
            }

            this.showSuccessAndReload();
          } else {
            this.showSuccessAndReload();
          }

        } else {
          this.openResultDialog(0, response.message);
        }
      },
      error => {
        console.error('Error updating candidate details:', error);
        this.openResultDialog(0, 'Error updating candidate details');
      }
    );
  }

  async sendDocs(id?) {
    if (this.innerDocuments.length != 0) {
      if (!this.loading) {
        const loadingMessage = this.translate.instant('candidateDetailAlertLoading');
        this.loading = this.openResultDialog(2, loadingMessage);
      }
      let documentsToSend: DocumentsToSend = {} as DocumentsToSend;
      documentsToSend.coDOC_CANDIDATE_ID = this.candidate.cdT_ID;
      documentsToSend.coDOC_TOTAL = this.documentsFormsList.length;
      let responseList = [];
      if (this.documentsFormsList.length > 0) {
        for (let i = 0; i < this.documentsFormsList.length; i++) {
          this.documentsFormsList[i].append(
            'companyId',
            this.candidate.cdT_COMPANY_ID
          );
          this.documentsFormsList[i].append(
            'candidateId',
            this.candidate.cdT_ID
          );
          this.documentsFormsList[i].append(
            'cdOC_RECRUITER_ACTIVE',
            true
          );
          const form = this.documentsFormsList[i];
          const fileName = form.get('fileWrapper.file').name;

          try {
            const response = await this.puzzleService.sendDocsExternal(this.documentsFormsList[i]).toPromise();
            responseList.push(fileName + (response.isSuccess ? ' - Éxito' : ' - Falló'));
            if (!response.isSuccess && response.message.includes('extensión no permitida')) {
              this.noPermittedFiles = true;
              this.noPermittedFilesArray.push(fileName);
              this.innerDocuments = this.innerDocuments.filter(doc => doc.originalName !== fileName);
              this.documentsFormsList = this.documentsFormsList.filter(form => form.get('fileWrapper.file').name !== fileName);
              i--;
            }
          } catch (error) {
            responseList.push(fileName + ' - Falló');
          }
        }

        this.puzzleService.createNotificationExternalToken(id).subscribe(() => {
          this.loading.close();
        });
      }

      let successCount = responseList.filter(r => r.includes('Éxito')).length;
      let failedCount = responseList.length - successCount;

      return failedCount === 0;
    } else {
      this.loading.close();
      this.translate.get('candidateDocumentsUploadNoChoose').subscribe((translatedText: string) => {
        this.openResultDialog(0, translatedText);
      });
    }
  }

  showSuccessAndReload() {
    this.translate.get('candidateExternalCompleteSave').subscribe((translatedText: string) => {
      this.openResultDialog(1, translatedText).afterClosed().subscribe(() => {
        location.reload();
      });
    });
  }

  removeSpecialCharactersAndSpaces(text: string): string {
    text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return text.replace(/[^a-zA-Z0-9]/g, '');
  }

  validateToken() {
    let validateToken: TokenValidationBody = {} as TokenValidationBody;
    validateToken.tkCD_TOKEN = this.id;
    this.puzzleService.validateCandidateToken(validateToken).subscribe((response) => {
      if (response.isSuccess) {
        this.showControls = true;
        this.candidate = response.data;
        this.fetchCandidateDetails(response.data.cdT_ID);
        this.getCandidateInfo(response.data.cdT_ID)
        this.candidateName =

          response.data.cdT_NAME +
          ' ' +
          response.data.cdT_PATERNAL_SURNAME +
          ' ' +
          response.data.cdT_MATERNAL_SURNAME;

        this.companyName = this.candidate.cdT_COMPANY_INFO.comP_NAME;

        const compName = this.removeSpecialCharactersAndSpaces(this.companyName);

        if (compName) {
          // Validar si la url tiene el companyName
          const url = window.location.href;
          const hasCompanyName = url.includes(compName);

          if (!hasCompanyName) {
            this.router.navigate([compName + '/CD'], { queryParams: { id: this.id } });
          }
        }
        this.translate.get('candidateDocumentsWelcomeMessage', { name: this.candidateName, company: this.companyName }).subscribe((translatedText: string) => {
          this.bilboard = translatedText;
        });

        if (
          this.candidate != null &&
          this.candidate.cdT_COMPANY_INFO != null &&
          this.candidate.cdT_COMPANY_INFO.comP_LOGO_ROUTE != null
        ) {
          this.logoImage = this.sanitizer.bypassSecurityTrustResourceUrl(
            environment.imageUrl +
            this.candidate.cdT_COMPANY_INFO.comP_LOGO_ROUTE
          );
        }
        this.getInnerDocs(this.candidate.cdT_COMPANY_ID, this.candidate.cdT_ID);
        this.getCandidateDocuments();
      } else {
        this.showControls = false;
        this.bilboard = response.message;

        this.candidateName = '-';
        this.router.navigate(['/login']);
        this.openResultDialog(0, response.message);
        if (response.data.tcdS_REQUESTED_ACCESS_LINK) {
          this.openResultDialog(0, response.message);
          this.requestedAccessLink = true;
        } else {
          this.requestedAccessLink = true;
          if (response.data.tcdS_CANDIDATE_ID) {
            this.accessLink.tcdS_CANDIDATE_ID = response.data.tcdS_CANDIDATE_ID;
            this.accessLink.CANDIDATE_FULL_NAME = response.data.CANDIDATE_FULL_NAME;
            this.showRequestAccessLink = true;
            this.requestedAccessLink = false;
          } else {
            this.openResultDialog(0, response.message);
            this.showRequestAccessLink = false;
          }
        }
      }
    });
  }

  getCandidateInfo(id: string) {
    this.puzzleService.getCandidateExternalInfo(id).subscribe((response) => {
      if (response.candidateImageInfo?.rpC_IMG_ROUTE) {
        this.userImage = this.sanitizer.bypassSecurityTrustResourceUrl(environment.imageUrl + response.candidateImageInfo?.rpC_IMG_ROUTE);
      }
    });
  }

  readURL(event): void {
    if (event.target.files && event.target.files[0]) {
      // abrir dialogo de crop
      const dialogRef = this.dialog.open(ImageCropDialogComponent, {
        width: '500px',
        maxHeight: '90vh',
        data: {
          event: event
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        this.inputFileLogo.nativeElement.value = '';
        if (result) {
          this.imageFile = this.base64toFile(result, 'profilePicture.png');
          this.userImage = this.sanitizer.bypassSecurityTrustResourceUrl(result);
        }
      });
    }
  }

  opendoc(route: string) {
    if (route != null) {
      let dialog = this.dialog.open(DocuViewerComponent, {
        panelClass: 'custom-dialog-cv',
        maxWidth: '100vw !important',
        data: { data: route, header: 'Curriculum vitáe' },
      });

      dialog.afterClosed().subscribe((result) => { });
    }
  }

  getInnerDocs(companyId: string, candidateId: string) {
    this.puzzleService.getInnerDocumentsExternal(companyId, candidateId).subscribe((response) => {
      this.innerDocs = response.map(doc => ({ ...doc, pdT_VISIBLE: true }));
      this.updateFilteredInnerDocs();
    });
  }

  fetchCandidateDetails(candidateId: string): void {
    this.puzzleService.getCandidateExternal(candidateId).subscribe(
      candidate => {
        this.updateCandidateDetails(candidate);
      },
      error => {
        console.error('Error fetching candidate details:', error);
      }
    );
  }

  async updateCandidateDetails(candidate: Candidate): Promise<void> {
    if (!candidate.cdT_ADDRESS_INFO) {
      candidate.cdT_ADDRESS_INFO = {
        adR_CITY_ID: '',
        adR_STATE_ID: '',
        adR_COUNTRY_ID: '066a18bc-9bc4-4a7d-8a38-3cc63fe2bfa0',
        adR_STATE_CUSTOM: false,
        adR_CITY_CUSTOM: false,
        adR_STREET: '',
        adR_SUBURB: '',
        adR_CP: '',
        adR_SUITE_NUMBER: '',
        adR_STREET_NUMBER: '',
        adR_COUNTRY_ID_INFO: null,
        adR_STATE_ID_INFO: null,
        adR_CITY_ID_INFO: null
      };
    }

    await this.getCountries();

    this.candidate = candidate;

    this.selectedState = this.states?.find(s => s.stS_ID === candidate.cdT_ADDRESS_INFO.adR_STATE_ID);
    this.selectedBirthState = this.states2?.find(s => s.stS_ID === candidate.cdT_BIRTH_STATE_ID);

    if (this.selectedState) {
      this.cities = this.selectedState.stS_CITIES;
    }
    if (this.selectedBirthState) {
      this.cities2 = this.selectedBirthState.stS_CITIES;
    }

    setTimeout(() => {
      this.selectedCity = this.cities.find(c => c.ctY_ID === candidate.cdT_ADDRESS_INFO.adR_CITY_ID);
      this.selectedBirthCity = this.cities2.find(c => c.ctY_ID === candidate.cdT_BIRTH_CITY_ID);
    }, 0);
  }


  handleKeydownAdress(event: KeyboardEvent) {
    if (event.code === 'Space') {
      event.preventDefault();
      this.toggleWizardContainerAdress();
    }
  }

  handleKeydownGeneralData(event: KeyboardEvent) {
    if (event.code === 'Space') {
      event.preventDefault();
      this.toggleWizardContainerGeneralOptional();
    }
  }

  handleKeydownContainerExpedient(event: KeyboardEvent) {
    if (event.code === 'Space') {
      event.preventDefault();
      this.toogleWizardContainerExpedient();
    }
  }

  getCountries(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.puzzleService.getCountryDemo().subscribe({
        next: (countries) => {
          this.countries = countries;
          this.states = this.countries.ctR_STATES;
          this.states2 = this.countries.ctR_STATES;
          this.initStatesAndCities();
          resolve(); // Resuelve la promesa una vez que se ha completado la lógica.
        },
        error: (err) => {
          reject(err); // Rechaza la promesa si ocurre un error.
        },
      });
    });
  }

  initStatesAndCities() {
    if (this.candidate && this.candidate.cdT_ADDRESS_INFO) {
      const stateId = this.candidate.cdT_ADDRESS_INFO.adR_STATE_ID;
      const cityId = this.candidate.cdT_ADDRESS_INFO.adR_CITY_ID;

      // Asumiendo que `adR_STATE_ID` y `adR_CITY_ID` son índices o identificadores reales
      this.selectedState = this.states.find(s => s.stS_ID === stateId);
      this.selectedCity = this.selectedState ? this.selectedState.stS_CITIES.find(c => c.ctY_ID === cityId) : null;
    }

    if (this.candidate && this.candidate.cdT_BIRTH_STATE_ID) {
      const birthStateId = this.candidate.cdT_BIRTH_STATE_ID;
      this.selectedBirthState = this.states2.find(s => s.stS_ID === birthStateId);
      this.selectedBirthCity = this.selectedBirthState ? this.selectedBirthState.stS_CITIES.find(c => c.ctY_ID === this.candidate.cdT_BIRTH_CITY_ID) : null;
    }

    if (!this.selectedState && this.states.length > 0) {
      this.selectedState = this.states[0];
      this.cities = this.selectedState.stS_CITIES;
    }

    if (!this.selectedBirthState && this.states2.length > 0) {
      this.selectedBirthState = this.states2[0];
      this.cities2 = this.selectedBirthState.stS_CITIES;
    }

    if (this.states.length > 0 && !this.selectedState) {
      this.selectedState = this.states[0];
      this.cities = this.selectedState.stS_CITIES;
    }
    if (this.states2.length > 0 && !this.selectedBirthState) {
      this.selectedBirthState = this.states2[0];
      this.cities2 = this.selectedBirthState.stS_CITIES;
    }
  }

  loadStates(id: number) {
    if (this.countries.ctR_CUSTOM_INFO_ACTIVE == true) {
      this.customstatesandcities = true;
    } else {
      this.customstatesandcities = false;
      this.states = this.countries.ctR_STATES;
    }
  }

  loadCities(id: number) {
    this.cities = this.states[id].stS_CITIES;
  }


  onStateChange(event: MatSelectChange) {
    this.selectedState = event.value;
    this.candidate.cdT_ADDRESS_INFO.adR_STATE_ID = this.selectedState.stS_ID;
  }

  onCityMatSelectChange(event: MatSelectChange) {
    this.selectedCity = event.value;
    this.candidate.cdT_ADDRESS_INFO.adR_CITY_ID = this.selectedCity.ctY_ID;
  }

  onCityMatSelectChange2(event: MatSelectChange) {
    this.selectedBirthCity = event.value;
    this.candidate.cdT_BIRTH_CITY_ID = this.selectedBirthCity ? this.selectedBirthCity.ctY_ID : null;
  }

  onStateMatSelectChange(event: MatSelectChange) {
    this.selectedState = event.value;
    this.cities = this.selectedState.stS_CITIES;

    // Actualiza el ID del estado en el modelo del candidato
    if (this.candidate.cdT_ADDRESS_INFO) {
      this.candidate.cdT_ADDRESS_INFO.adR_STATE_ID = this.selectedState.stS_ID;
    } else {
      this.candidate.cdT_ADDRESS_INFO = {
        adR_STATE_ID: this.selectedState.stS_ID,
        adR_CITY_ID: '',
        adR_COUNTRY_ID: '066a18bc-9bc4-4a7d-8a38-3cc63fe2bfa0',
        adR_STATE_CUSTOM: false,
        adR_CITY_CUSTOM: false,
        adR_STREET: '',
        adR_SUBURB: '',
        adR_CP: '',
        adR_SUITE_NUMBER: '',
        adR_STREET_NUMBER: '',
        adR_COUNTRY_ID_INFO: null,
        adR_STATE_ID_INFO: null,
        adR_CITY_ID_INFO: null
      };
    }

    // Asegúrate de asignar el id de ciudad correctamente si es pre-seleccionado
    if (this.candidate.cdT_ADDRESS_INFO.adR_CITY_ID) {
      this.selectedCity = this.cities.find(city => city.ctY_ID === this.candidate.cdT_ADDRESS_INFO.adR_CITY_ID);
    } else {
      this.selectedCity = null;
      this.candidate.cdT_ADDRESS_INFO.adR_CITY_ID = null;
    }

    this.selectedCity = null;
    this.candidate.cdT_ADDRESS_INFO.adR_CITY_ID = null;
    this.candidate.cdT_ADDRESS_INFO.adR_CITY_CUSTOM = null;
  }


  onStateMatSelectChange2(event: MatSelectChange) {
    this.selectedBirthState = event.value;
    this.cities2 = this.selectedBirthState.stS_CITIES;
    this.candidate.cdT_BIRTH_STATE_ID = this.selectedBirthState.stS_ID;

    if (this.candidate.cdT_BIRTH_CITY_ID) {
      this.selectedBirthCity = this.cities2.find(city => city.ctY_ID === this.candidate.cdT_BIRTH_CITY_ID);
    } else {
      this.selectedBirthCity = null;
      this.candidate.cdT_BIRTH_CITY_ID = null;
    }

    this.selectedBirthCity = null;
    this.candidate.cdT_BIRTH_CITY_ID = null;
    this.candidate.cdT_BIRTH_CITY_CUSTOM = null;
  }

  loadCities2(id: number) {
    this.cities2 = this.states2[id].stS_CITIES;
  }

  loadcitiesid(id) {
    for (let i = 0; i < this.states.length; i++) {
      if (this.states[i].stS_ID == id) {
        this.cities = this.states[i].stS_CITIES;
        break;
      }
    }
  }

  loadcitiesid2(id) {
    for (let i = 0; i < this.states2.length; i++) {
      if (this.states2[i].stS_ID == id) {
        this.cities2 = this.states2[i].stS_CITIES;
        break;
      }
    }
  }

  updateFilteredInnerDocs() {
    if (!this.innerDocuments) {
      this.innerDocuments = [];
    }
    this.filteredInnerDocs = this.innerDocs.filter(doc =>
      !this.innerDocuments.some(innerDoc => innerDoc.cdoC_DOCUMENT_NAME === doc.pdT_NAME)
    );
  }

  deletedoc(thedoc: CandidateDocument, mode) {
    event.stopPropagation();
    if (thedoc.cdoC_ID != null) {

      const waitMessage = this.translate.instant('candidateDocumentsWait');
      let loading = this.openResultDialog(2, waitMessage);

      thedoc.cdoC_ACTIVE = false;
      this.puzzleService.deleteDocExternal(thedoc).subscribe((response) => {
        loading.close();
        if (response.isSuccess) {
          this.translate.get(response.message).subscribe((translatedText: string) => {
            this.openResultDialog(1, translatedText);
          });
          location.reload();
        } else {
          this.translate.get(response.message).subscribe((translatedText: string) => {
            this.openResultDialog(0, translatedText);
          });
        }
      });
    } else {
      let indextopop = [];

      if (mode == 0) {
        for (let i = 0; i < this.innerDocuments.length; i++) {
          if (
            this.innerDocuments[i].cdoC_DOCUMENT_NAME ==
            thedoc.cdoC_DOCUMENT_NAME
          ) {
            indextopop.push(i);
            break;
          }
        }

        for (let i = 0; i < indextopop.length; i++) {
          this.innerDocuments.splice(indextopop[i], 1);
        }

        for (let i = 0; i < this.innerDocs.length; i++) {
          if (this.innerDocs[i].pdT_NAME == thedoc.cdoC_DOCUMENT_NAME) {
            this.innerDocs[i].pdT_VISIBLE = true;
          }
        }
      }

      this.documentsFormsList.splice(thedoc.cdoC_INDEX, 1);
    }
  }

  getCandidateDocuments() {
    this.puzzleService
      .getCandidateDocumentsExternal(this.candidate.cdT_ID)
      .subscribe((response) => {
        let indextopop = [];

        for (let i = 0; i < response.length; i++) {
          if (response[i].cdoC_ACTIVE == false) {
            indextopop.push(i);
          }
        }

        for (let i = 0; i < indextopop.length; i++) {
          response.splice(indextopop[i], 1);
        }

        for (let i = 0; i < response.length; i++) {
          if (
            response[i].cdoC_DOCUMENT_TYPE_ID == constants.candidateDocumentId
          ) {
            this.innerDocuments.push(response[i]);
          } else {
          }
        }

        if (this.innerDocs != null && this.innerDocs.length > 0) {
          for (let i = 0; i < this.innerDocuments.length; i++) {
            for (let e = 0; e < this.innerDocs.length; e++) {
              if (
                this.innerDocs[e].pdT_NAME ==
                this.innerDocuments[i].cdoC_DOCUMENT_NAME
              ) {
                this.innerDocs[e].pdT_VISIBLE = false;
              }
            }
          }
        }
      });
  }

  requestAccessLink() {
    this.puzzleService
      .requestAccessLink(this.accessLink)
      .subscribe((response) => {
        if (response.isSuccess) {
          let dialogRef = this.openResultDialog(1, response.message)
          dialogRef.afterClosed().subscribe(
            result => window.location.reload()
          )
        }
        else {
          this.openResultDialog(2, response.message)
        }

        this.youCantClick = true;

      });
  }

  get visibleInnerDocs() {
    if (!this.innerDocs) {
      return [];
    }
    return this.innerDocs.filter(doc => doc.pdT_VISIBLE !== false);
  }

  toggleWizardContainerAdress() {
    this.isWizardContainerExpandedAdress = !this.isWizardContainerExpandedAdress;
  }

  toggleWizardContainerGeneralOptional() {
    this.isWizardContainerGeneralOptionalExpanded = !this.isWizardContainerGeneralOptionalExpanded;
  }

  toogleWizardContainerExpedient() {
    this.isWizardContainerDigitalExpedientExpanded = !this.isWizardContainerDigitalExpedientExpanded;
  }

  trackByStateFn(index, item) {
    return item.stS_ID;
  }

  trackByBirthStateFn(index, item) {
    return item.stS_ID;
  }
  trackByCityFn2(index, item) {
    return item.ctY_ID;
  }

  base64toFile(data, filename) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault(); // Previene el comportamiento por defecto (abrir archivo en el navegador)
    this.dragging = true;
  }

  onDragLeave(event: DragEvent): void {
    this.dragging = false;
  }

  onDrop(event: DragEvent, mode: number = 0): void {
    event.preventDefault();
    this.dragging = false;

    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      this.fileChangeEvent({ target: { files: [file] } });
    }
  }
}

import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private lastErrorTime = 0;
  private reloadAttempts = 0;
  private maxReloadAttempts = 2; // Evita que se recargue infinitamente

  handleError(error: any): void {
    console.error('Global error caught:', error);

    if (this.shouldHardRefresh(error)) {
      const currentTime = Date.now();

      if (
        this.reloadAttempts < this.maxReloadAttempts &&
        currentTime - this.lastErrorTime > 3000 // Al menos 3 segundos entre intentos
      ) {
        this.reloadAttempts++;
        this.lastErrorTime = currentTime;
        console.warn(`Reload attempt #${this.reloadAttempts}`);

        this.clearCacheAndReload(); // Borra caché, desregistra SW y recarga
      } else {
        console.error('Max reload attempts reached. Not reloading again.');
      }
    }
  }

  private shouldHardRefresh(error: any): boolean {
    const errorMessage = error?.message || '';

    return (
      errorMessage.includes('Cannot access') ||
      errorMessage.includes('before initialization') ||
      errorMessage.includes(
        "Cannot read properties of undefined (reading 'call')",
      )
    );
  }

  private clearCacheAndReload(): void {
    console.warn(
      'Clearing cache and unregistering service worker before reload.',
    );

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => registration.unregister());
      });
    }

    if ('caches' in window) {
      caches
        .keys()
        .then((keys) => {
          return Promise.all(keys.map((key) => caches.delete(key)));
        })
        .then(() => {
          console.warn('Cache cleared, reloading page...');
          window.location.reload();
        });
    }
  }
}

<div>
    <div class="ml-auto w-full text-right justify-end -mt-5 -mr-5">
        <button id="closeModalChangeStatus2" mat-icon-button (click)="closeModal()" tabindex="-1">
            <mat-icon class="close-x-primary">close</mat-icon>
        </button>
    </div>

    <!-- Header -->
    <div class="pb-2 text-center title-text-modal">
        {{ 'newMailGmail' | translate }}
    </div>

    <form [formGroup]="miFormulario">

        <div class="mb-3">
            <!-- Participantes -->
            <div class="flex flex-col w-full">
                <div class="pb-1 title-input-text flex items-center">
                    <span class="title-input-text">{{'emailTo' | translate}}:</span>
                </div>
                <div>
                    <div class="w-full flex items-center gap-4">
                        <mat-select #select4 id="addParticipantsShare" [(ngModel)]="selectedParticipant"
                            name="participantSelection" class="borde p-3 w-full centered-text"
                            (selectionChange)="onParticipantSelected($event)" (focus)="onFocus($event)"
                            (blur)="onBlur($event)" disableOptionCentering tabindex="0"
                            [placeholder]="'emailTo' | translate">
                            <ngx-mat-select-search class="searcher" [formControl]="userSearchControl"
                                [placeholderLabel]="'search' | translate"
                                [noEntriesFoundLabel]="'NO_RESULTADOS' | translate"></ngx-mat-select-search>
                            <mat-option *ngFor="let usuario of usuariosFiltrados" [value]="usuario.email">
                                {{usuario.nombre}} ({{usuario.email}})
                            </mat-option>
                            <mat-option [value]="userSearchControl.value">
                                {{ 'emailAdd' | translate }} {{ userSearchControl.value }}
                            </mat-option>
                        </mat-select>

                        <div *ngIf="!activeCC" class="flex items-center gap-3 text-gray-500">
                            <div (click)="activeActionCC()" class="cursor-pointer">
                                {{ 'emailCC' | translate }}
                            </div>
                            <div (click)="activeActionCC()" class="cursor-pointer">
                                {{ 'emailBCC' | translate }}
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="toError && selectedUsers.length < 1" class="text-red-500 text-xs mt-1">
                    <span>
                        {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('emailTo' | translate) } }}
                    </span>
                </div>
            </div>

            <!-- Participantes seleccionados -->
            <div class="flex flex-wrap">
                <span *ngFor="let mail of selectedUsers; let i = index;"
                    class="tag-primary px-2.5 py-0.5 rounded-full mt-3 mr-2 flex items-center">
                    <span>{{ mail }}</span>
                    <div class="flex items-center justify-center">
                        <mat-icon id="removeUserVacancyDialog3" (click)="removeUser(mail)"
                            class="cursor-pointer close-x">cancel</mat-icon>
                    </div>
                </span>
            </div>
        </div>

        <div *ngIf="activeCC" class="mb-3">
            <!-- Participantes -->
            <div class="flex flex-col w-full">
                <div class="pb-1 title-input-text flex items-center">
                    <span class="title-input-text">{{'emailCC' | translate}}:</span>
                </div>
                <div>
                    <div class="w-full flex items-center gap-4">
                        <mat-select #select3 id="addParticipantsShare" [(ngModel)]="selectedParticipant2"
                            name="participantSelection" class="borde p-3 w-full centered-text"
                            (selectionChange)="onParticipantSelected2($event)" (focus)="onFocus($event)"
                            (blur)="onBlur($event)" disableOptionCentering tabindex="0"
                            [placeholder]="'emailCC' | translate">
                            <ngx-mat-select-search class="searcher" [formControl]="userSearchControl2"
                                [placeholderLabel]="'search' | translate"
                                [noEntriesFoundLabel]="'NO_RESULTADOS' | translate"></ngx-mat-select-search>
                            <mat-option *ngFor="let usuario of usuariosFiltrados2" [value]="usuario.email">
                                {{usuario.nombre}} ({{usuario.email}})
                            </mat-option>
                            <mat-option [value]="userSearchControl2.value">
                                {{ 'emailAdd' | translate }} {{ userSearchControl2.value }}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
            </div>

            <!-- Participantes seleccionados -->
            <div class="flex flex-wrap">
                <span *ngFor="let mail of selectedUsers2; let i = index;"
                    class="tag-primary px-2.5 py-0.5 rounded-full mt-3 mr-2 flex items-center">
                    <span>{{ mail }}</span>
                    <div class="flex items-center justify-center">
                        <mat-icon id="removeUserVacancyDialog3" (click)="removeUser2(mail)"
                            class="cursor-pointer close-x">cancel</mat-icon>
                    </div>
                </span>
            </div>
        </div>

        <div *ngIf="activeCC" class="mb-3">
            <!-- Participantes -->
            <div class="flex flex-col w-full">
                <div class="pb-1 title-input-text flex items-center">
                    <span class="title-input-text">{{'emailBCC' | translate}}:</span>
                </div>
                <div>
                    <div class="w-full flex items-center gap-4">
                        <mat-select #select2 id="addParticipantsShare" [(ngModel)]="selectedParticipant3"
                            name="participantSelection" class="borde p-3 w-full centered-text"
                            (selectionChange)="onParticipantSelected3($event)" (focus)="onFocus($event)"
                            (blur)="onBlur($event)" disableOptionCentering tabindex="0"
                            [placeholder]="'emailBCC' | translate">
                            <ngx-mat-select-search class="searcher" [formControl]="userSearchControl3"
                                [placeholderLabel]="'search' | translate"
                                [noEntriesFoundLabel]="'NO_RESULTADOS' | translate"></ngx-mat-select-search>
                            <mat-option *ngFor="let usuario of usuariosFiltrados3" [value]="usuario.email">
                                {{usuario.nombre}} ({{usuario.email}})
                            </mat-option>
                            <mat-option [value]="userSearchControl3.value">
                                {{ 'emailAdd' | translate }} {{ userSearchControl3.value }}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
            </div>

            <!-- Participantes seleccionados -->
            <div class="flex flex-wrap">
                <span *ngFor="let mail of selectedUsers3; let i = index;"
                    class="tag-primary px-2.5 py-0.5 rounded-full mt-3 mr-2 flex items-center">
                    <span>{{ mail }}</span>
                    <div class="flex items-center justify-center">
                        <mat-icon id="removeUserVacancyDialog3" (click)="removeUser3(mail)"
                            class="cursor-pointer close-x">cancel</mat-icon>
                    </div>
                </span>
            </div>
        </div>

        <!-- Campo Asunto -->
        <div class="flex flex-col mb-3">
            <div class="pb-1 title-input-text flex items-center">
                <span class="title-input-text">{{'emailSubject' | translate}}:</span>
            </div>
            <div class="flex">
                <input id="emailSubject" formControlName="subject" class="input-h p-2 w-full mt-1 borde" type="text"
                    tabindex="1" [placeholder]="('emailSubject' | translate)" name="subject" maxlength="100"
                    oninput='this.value = this.value.trimStart();' />
            </div>
            <div *ngIf="miFormulario.get('subject').invalid && (miFormulario.get('subject').dirty || miFormulario.get('subject').touched)"
                class="text-red-500 text-xs mt-1">
                <span *ngIf="miFormulario.get('subject').errors['required']">
                    {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('emailSubject' | translate) } }}
                </span>
            </div>
        </div>

        <!-- Editor de texto con ngx-quill -->
        <div class="mb-3">
            <div class="pb-1 title-input-text flex items-center">
                <span class="title-input-text">{{'emailMessage' | translate}}:</span>
            </div>
            <quill-editor #quillEditor [modules]="editorOptions" placeholder=""
                [styles]="{borderRadius: '0px 0px 8px 8px', height: '30vh'}" formControlName="body" format="html" theme="snow" (onEditorCreated)="onEditorCreated($event)"></quill-editor>

            <div *ngIf="miFormulario.get('body').invalid && (miFormulario.get('body').dirty || miFormulario.get('body').touched);"
                class="text-red-500 text-xs mt-1">
                <span *ngIf="miFormulario.get('body').errors['required']">
                    {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('emailMessage' | translate) } }}
                </span>
            </div>
        </div>

        <!-- Adjuntar archivos -->
        <div class="w-full mb-3">
            <input #fileSelect id="fileSelect" type="file"
                accept=".pdf,.doc,.docx,application/msword,application/vnd.ms-excel,.xlsx,.xls,image/*,application/pdf"
                (click)="fileSelect.value = null" (change)="fileChangeEvent($event)" class="hidden">
            <div class="flex flex-col w-full">
                <div class="pb-1 title-input-text flex items-center">
                    <span class="title-input-text">{{'attachFiles' | translate}}:</span>
                </div>
                <div class="flex items-center w-full">
                    <div class="w-full drag-drop-area border-dashed border-2 rounded-lg p-4 text-center cursor-pointer"
                        [ngClass]="dragging ? 'dragging' : 'border-gray-400'" (dragover)="onDragOver($event)"
                        (dragleave)="onDragLeave($event)" (drop)="onDrop($event)" (click)="fileSelectClick()">
                        <div class="py-10">
                            <div class="text-gray-500 m-0">
                                {{ "dragAndDrop" | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-gray-500 text-xs mt-1">
                <span>
                    {{ 'emailFilesText' | translate }}
                </span>
            </div>

            <!-- Lista de archivos adjuntos -->
            <div class="flex flex-wrap">
                <span *ngFor="let file of addedFiles; let i = index;"
                    class="tag-primary px-2.5 py-0.5 rounded-full mt-3 mr-2 flex items-center">
                    <span>{{ file.name }}</span>
                    <div class="flex items-center">
                        <mat-icon id="removeFile" (click)="removeFile(i)"
                            class="cursor-pointer close-x">cancel</mat-icon>
                    </div>
                </span>

            </div>
        </div>

        <!-- Botón Enviar -->
        <div class="mt-6 flex justify-center">
            <button (click)="sendEmail()"
                class="bg-primary text-white px-6 py-2 rounded-lg hover:bg-yellow-800 transition-all ease-in-out w-full md:w-auto">
                <span *ngIf="!loading">{{ "sendMail" | translate }}</span>
                <div *ngIf="loading" class="w-full flex justify-center">
                    <mat-spinner diameter="20" color="primary"></mat-spinner>
                </div>
            </button>
        </div>
    </form>
</div>
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-google-account',
  templateUrl: './google-account.component.html',
  styleUrls: ['./google-account.component.css'],
})
export class GoogleAccountComponent implements OnInit {
  googleIcon: SafeResourceUrl = '../../../assets/img/google-g.png';

  constructor(private dialogRef: MatDialogRef<GoogleAccountComponent>) {}

  ngOnInit(): void {}

  closeModal() {
    this.dialogRef.close();
  }

  connectGoogleAccount() {
    window.location.href = environment.imageUrl + 'api/google/link-gmail';
  }
}

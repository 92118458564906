import {
  Component,
  Inject,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { LocalService } from 'src/app/services/local.service';
import { PuzzleService } from 'src/app/services/puzzle.service';
import { ResponseDialogComponent } from '../response-dialog/response-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { ResponseSmallDialogComponent } from '../response-small-dialog/response-small-dialog.component';
import { constants } from 'src/app/services/constants';
import { QuillEditorComponent } from 'ngx-quill';
import Quill from 'quill';

export function descriptionRequiredValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    // si hay un img o video no se valida
    if (control.value?.includes('<img') || control.value?.includes('<video')) {
      return null;
    }
    const cleanedContent = control.value?.replace(/<[^>]*>/g, '').trim();
    return cleanedContent && cleanedContent.length > 0 ? null : { required: true };
  };
}

@Component({
  selector: 'app-mail-dialog',
  templateUrl: './mail-dialog.component.html',
  styleUrls: ['./mail-dialog.component.css'],
})
export class MailDialogComponent implements OnInit {
  emailForm: FormGroup;
  recipients: string[] = [];
  totalUsers = 0;
  selectedUsers = [];
  selectedUsers2 = [];
  selectedUsers3 = [];
  allUsers = [];
  usuariosFiltrados = [];
  usuariosFiltrados2 = [];
  usuariosFiltrados3 = [];
  pageSize = 1000;
  pageIndex = 0;
  id: string;
  currentUser: string;
  userSearchControl = new FormControl();
  userSearchControl2 = new FormControl();
  userSearchControl3 = new FormControl();
  selectedParticipant: string;
  selectedParticipant2: string;
  selectedParticipant3: string;
  addedFiles = [];
  fileloader: HTMLElement;
  dragging: boolean = false;
  toError: boolean = false;
  loading: boolean = false;
  activeCC: boolean = false;
  @ViewChild('select4') select4: MatSelect;
  @ViewChild('select3') select3: MatSelect;
  @ViewChild('select2') select2: MatSelect;
  @ViewChild('quillEditor') quillEditor!: QuillEditorComponent;

  miFormulario: FormGroup = this.fb.group({
    subject: ['', Validators.required],
    body: ['', [descriptionRequiredValidator()]],
  });

  editorOptions = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ font: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['clean'],
      ['image'],
    ],
  };

  constructor(
    private fb: FormBuilder,
    private puzzleService: PuzzleService,
    private localService: LocalService,
    private renderer: Renderer2,
    private dialog: MatDialog,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<MailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data?.to) {
      const emails = data.to;
      // quitar los que no son email
      this.selectedUsers = emails.filter((email) => this.validateEmail(email));
    }

    if (data?.subject) {
      this.miFormulario.get('subject').setValue(data.subject);
    }

    this.emailForm = this.fb.group({
      to: [''],
      subject: [''],
      body: [''],
    });

    let tokencito = this.localService.getJsonValue('token');
    if (tokencito.data.companyId != null) {
      this.id = tokencito.data.companyId;
    }

    this.currentUser = tokencito.data.id.toLowerCase();
  }

  ngOnInit(): void {
    this.getUsers();
  }

  onEditorCreated(quill: Quill) {
    quill.root.addEventListener('paste', (e: ClipboardEvent) => {
      const clipboardData = e.clipboardData;
      if (clipboardData?.items) {
        for (let i = 0; i < clipboardData.items.length; i++) {
          const item = clipboardData.items[i];
          // Comprobamos si es un tipo de imagen
          if (item.type.indexOf('image') !== -1) {
            const file = item.getAsFile();
            if (!file) {
              continue;
            }
            const reader = new FileReader();
            reader.onload = (event: any) => {
              const base64Image = event.target.result;
              // Insertamos la imagen donde esté el cursor
              const range = quill.getSelection(true);
              quill.insertEmbed(
                range.index,
                'image',
                base64Image,
                'user',
              );
            };
            reader.readAsDataURL(file);
            // Evitar el pegado por defecto de la imagen en bruto
            e.preventDefault();
            break;
          }
        }
      }
    });
  }

  getUsers(): void {
    this.puzzleService
      .getAllUsers(
        this.pageSize,
        this.pageIndex,
        null,
        'Activos',
        this.id,
        null,
        true,
      )
      .subscribe(
        (users) => {
          this.allUsers = users.map((user) => ({
            id: user.id.toLowerCase(),
            nombre: user.anU_FULL_NAME,
            email: user.email,
          }));

          //ordenar por nombre
          this.allUsers.sort((a, b) => a.nombre.localeCompare(b.nombre));

          // quitar si ya se selecciono
          this.usuariosFiltrados = this.allUsers.filter(
            (usuario) =>
              !this.selectedUsers.find(
                (selectedUser) => selectedUser === usuario.email,
              ),
          );

          // quitar si ya se selecciono
          this.usuariosFiltrados2 = this.allUsers.filter(
            (usuario) =>
              !this.selectedUsers2.find(
                (selectedUser) => selectedUser === usuario.email,
              ),
          );

          // quitar si ya se selecciono
          this.usuariosFiltrados3 = this.allUsers.filter(
            (usuario) =>
              !this.selectedUsers3.find(
                (selectedUser) => selectedUser === usuario.email,
              ),
          );

          this.totalUsers = this.usuariosFiltrados.length;

          this.updateFilteredUsers();
        },
        (error) => {},
      );
  }

  validateEmail(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  onParticipantSelected(event: MatSelectChange): void {
    const email = event.value;

    // validar si es un email valido
    if (!this.validateEmail(email)) {
      this.openResponseDialog('Error', this.translate.instant('invalidEmail'));
      this.selectedParticipant = '';
      if (this.select4) {
        this.select4.value = null;
        this.select4.writeValue(null);
        if (this.select4?.ngControl?.control) {
          this.select4.ngControl.control.setValue(null, { emitEvent: false });
        }
      }
      return;
    }

    // resetear el input
    if (!this.selectedUsers.some((u) => u === email)) {
      this.selectedUsers.push(email);
      this.updateFilteredUsers();
    }
    // resetear el input
    this.selectedParticipant = '';
    if (this.select4) {
      this.select4.value = null;
      this.select4.writeValue(null);
      if (this.select4?.ngControl?.control) {
        this.select4.ngControl.control.setValue(null, { emitEvent: false });
      }
    }
  }

  onParticipantSelected2(event: MatSelectChange): void {
    const email = event.value;

    // validar si es un email valido
    if (!this.validateEmail(email)) {
      this.openResponseDialog('Error', this.translate.instant('invalidEmail'));
      // resetear el input
      this.selectedParticipant2 = '';
      if (this.select3) {
        this.select3.value = null;
        this.select3.writeValue(null);
        if (this.select3?.ngControl?.control) {
          this.select3.ngControl.control.setValue(null, { emitEvent: false });
        }
      }
      return;
    }

    // resetear el input
    if (!this.selectedUsers2.some((u) => u === email)) {
      this.selectedUsers2.push(email);
      this.updateFilteredUsers();
    }
    // resetear el input
    this.selectedParticipant2 = '';
    if (this.select3) {
      this.select3.value = null;
      this.select3.writeValue(null);
      if (this.select3?.ngControl?.control) {
        this.select3.ngControl.control.setValue(null, { emitEvent: false });
      }
    }
  }

  onParticipantSelected3(event: MatSelectChange): void {
    const email = event.value;

    // validar si es un email valido
    if (!this.validateEmail(email)) {
      this.openResponseDialog('Error', this.translate.instant('invalidEmail'));
      // resetear el input
      this.selectedParticipant3 = '';
      if (this.select2) {
        this.select2.value = null;
        this.select2.writeValue(null);
        if (this.select2?.ngControl?.control) {
          this.select2.ngControl.control.setValue(null, { emitEvent: false });
        }
      }
      return;
    }

    // resetear el input
    if (!this.selectedUsers3.some((u) => u === email)) {
      this.selectedUsers3.push(email);
      this.updateFilteredUsers();
    }
    // resetear el input
    this.selectedParticipant3 = '';
    if (this.select2) {
      this.select2.value = null;
      this.select2.writeValue(null);
      if (this.select2?.ngControl?.control) {
        this.select2.ngControl.control.setValue(null, { emitEvent: false });
      }
    }
  }

  updateFilteredUsers(): void {
    this.usuariosFiltrados = this.allUsers.filter(
      (usuario) =>
        !this.selectedUsers.find(
          (selectedUser) => selectedUser === usuario.email,
        ),
    );

    this.usuariosFiltrados2 = this.allUsers.filter(
      (usuario) =>
        !this.selectedUsers2.find(
          (selectedUser) => selectedUser === usuario.email,
        ),
    );

    this.usuariosFiltrados3 = this.allUsers.filter(
      (usuario) =>
        !this.selectedUsers3.find(
          (selectedUser) => selectedUser === usuario.email,
        ),
    );

    //ordenar por nombre
    this.usuariosFiltrados.sort((a, b) => a.nombre.localeCompare(b.nombre));
    this.usuariosFiltrados2.sort((a, b) => a.nombre.localeCompare(b.nombre));
    this.usuariosFiltrados3.sort((a, b) => a.nombre.localeCompare(b.nombre));

    this.totalUsers = this.usuariosFiltrados.length;

    const usersAux = this.usuariosFiltrados;

    this.userSearchControl.valueChanges.subscribe((search) => {
      this.usuariosFiltrados = usersAux.filter((element) =>
        `${element.nombre} (${element.email})`
          .toLowerCase()
          .includes(search?.toLowerCase() || ''),
      );
    });

    const usersAux2 = this.usuariosFiltrados2;

    this.userSearchControl2.valueChanges.subscribe((search) => {
      this.usuariosFiltrados2 = usersAux2.filter((element) =>
        `${element.nombre} (${element.email})`
          .toLowerCase()
          .includes(search?.toLowerCase() || ''),
      );
    });

    const usersAux3 = this.usuariosFiltrados3;

    this.userSearchControl3.valueChanges.subscribe((search) => {
      this.usuariosFiltrados3 = usersAux3.filter((element) =>
        `${element.nombre} (${element.email})`
          .toLowerCase()
          .includes(search?.toLowerCase() || ''),
      );
    });
  }

  removeUser(email) {
    this.selectedUsers = this.selectedUsers.filter(
      (selectedUser) => selectedUser !== email,
    );
    this.usuariosFiltrados = this.allUsers.filter(
      (usuario) =>
        !this.selectedUsers.find(
          (selectedUser) => selectedUser === usuario.email,
        ),
    );
  }

  removeUser2(email) {
    this.selectedUsers2 = this.selectedUsers2.filter(
      (selectedUser) => selectedUser !== email,
    );
    this.usuariosFiltrados2 = this.allUsers.filter(
      (usuario) =>
        !this.selectedUsers2.find(
          (selectedUser) => selectedUser === usuario.email,
        ),
    );
  }

  removeUser3(email) {
    this.selectedUsers3 = this.selectedUsers3.filter(
      (selectedUser) => selectedUser !== email,
    );
    this.usuariosFiltrados3 = this.allUsers.filter(
      (usuario) =>
        !this.selectedUsers3.find(
          (selectedUser) => selectedUser === usuario.email,
        ),
    );
  }

  sendEmail() {
    if (this.loading) {
      return;
    }

    this.miFormulario.markAllAsTouched();

    if (this.selectedUsers.length === 0) {
      this.toError = true;
      return;
    }

    if (this.miFormulario.invalid) {
      return;
    }

    this.loading = true;

    // Verificamos si hay archivos adjuntos antes de convertirlos
    if (this.addedFiles.length > 0) {
      this.convertFilesToBase64(this.addedFiles)
        .then((attachments) => {
          this.sendMailWithAttachments(attachments);
        })
        .catch((error) => {
          this.loading = false;
          console.error('Error converting files to base64', error);
        });
    } else {
      // Si no hay archivos, enviamos el email sin adjuntos
      this.sendMailWithAttachments([]);
    }
  }

  /**
   * Envia el correo con los archivos adjuntos (o sin ellos si la lista está vacía).
   */
  sendMailWithAttachments(attachments: any[]) {
    let bodyHtml = this.miFormulario.get('body').value;
    const inlineImages = [];
    const imageRegex =
      /<img([^>]+)src=["'](data:image\/(png|jpeg|jpg|gif);base64,([^"']+))["']([^>]*)>/g;
    let imageIndex = 1;

    // Reemplazamos todas las imágenes base64 en el body y las agregamos a inlineImages
    bodyHtml = bodyHtml.replace(
      imageRegex,
      (match, beforeSrc, fullMatch, mimeType, base64Data, afterSrc) => {
        const contentId = `imagen${imageIndex}`;
        const fileName = `imagen${imageIndex}.${mimeType}`;

        // Agregar la imagen a inlineImages
        inlineImages.push({
          contentId: contentId,
          mimeType: `image/${mimeType}`,
          fileName: fileName,
          base64Data: base64Data,
        });

        imageIndex++;
        return `<img${beforeSrc}src="cid:${contentId}"${afterSrc}>`; // Reemplazo asegurando que la estructura de la etiqueta no cambie
      },
    );

    const mailData = {
      to: this.selectedUsers,
      cc: this.selectedUsers2,
      bcc: this.selectedUsers3,
      subject: this.miFormulario.get('subject').value,
      body: bodyHtml,
      inlineImages: inlineImages,
      attachments: attachments,
    };

    this.puzzleService.sendEmailGoogleAccount(mailData).subscribe(
      (response) => {
        if (response.isSuccess) {
          this.dialogRef.close({
            isSuccess: true,
            message: this.translate.instant('emailSentSuccessfully'),
          });
        } else {
          this.openResponseDialog(
            'Error',
            this.translate.instant('errorSendingEmail'),
          );
        }
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.openResponseDialog('Error', error);
      },
    );
  }

  /**
   * Convierte una lista de archivos a base64.
   */
  convertFilesToBase64(files: File[]): Promise<any[]> {
    const promises = files.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve({
            fileName: file.name,
            base64Data: (reader.result as string).split(',')[1], // Remueve el prefijo data:mime;base64,
            mimeType: file.type,
          });
        };
        reader.onerror = (error) => reject(error);
      });
    });

    return Promise.all(promises);
  }

  closeModal() {
    this.dialogRef.close();
  }

  onFocus(event: FocusEvent) {
    const target = event.target as HTMLElement;
    this.renderer.addClass(target, 'focused');
  }

  onBlur(event: FocusEvent) {
    const target = event.target as HTMLElement;
    this.renderer.removeClass(target, 'focused');
  }

  onFileSelected(event) {
    const files = event.target.files;
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        this.addedFiles.push(files[i]);
      }
    }
  }

  removeFile(index) {
    this.addedFiles.splice(index, 1);
  }

  fileChangeEvent(event) {
    const files = event.target.files;
    const maxSize = 25 * 1024 * 1024; // 25MB en bytes
    const invalidFiles = [];
    const allowedExts = constants.allowedExt; // Lista de extensiones permitidas

    // Procesar cada archivo solo si no se alcanzó el límite
    for (let i = 0; i < files.length; i++) {
      // Si ya se tienen 10 archivos, se detiene el ciclo
      if (this.addedFiles.length >= 10) {
        const title = this.translate.instant('maxFilesExceededTitle');
        const message = this.translate.instant('maxFilesExceededMessage');
        this.openResultDialog(0, title, message);
        break;
      }

      const file = files[i];
      const fileSize = file.size;
      const fileName = file.name;
      const ext = fileName.split('.').pop().toLowerCase();

      // Validar si la extensión del archivo es permitida
      if (!allowedExts.includes(ext)) {
        const title = this.translate.instant('extErrorNotPermitted');
        const message =
          `<br>${fileName}<br><br>` +
          this.translate.instant('extErrorNotPermittedTypes');
        this.openResultDialog(0, title, message);
        continue;
      }

      // Validar si es un archivo temporal de Office (~$) y pesa menos de 500 bytes
      if (fileName.startsWith('~$') && fileSize < 500) {
        const title = this.translate.instant('extErrorTempFile');
        const message =
          `<br>${fileName}<br><br>` +
          this.translate.instant('extErrorTempFileMessage');
        this.openResultDialog(0, title, message);
        continue;
      }

      // Validar si el archivo está vacío
      if (fileSize === 0) {
        const title = this.translate.instant('extErrorEmptyFile');
        const message =
          `<br>${fileName}<br><br>` +
          this.translate.instant('extErrorEmptyFileMessage');
        this.openResultDialog(0, title, message);
        continue;
      }

      // Validar si el archivo supera el tamaño máximo permitido
      if (fileSize > maxSize) {
        invalidFiles.push(fileName);
        continue;
      }

      // Si pasa todas las validaciones, agregar el archivo
      this.addedFiles.push(file);
    }

    // Mostrar diálogo si hay archivos inválidos por tamaño
    if (invalidFiles.length > 0) {
      this.openResponseDialog(
        'Error',
        `${this.translate.instant('emailLimitFiles')} ${invalidFiles.join(', ')}`,
      );
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault(); // Previene el comportamiento por defecto (abrir archivo en el navegador)
    this.dragging = true;
  }

  onDragLeave(event: DragEvent): void {
    this.dragging = false;
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    this.dragging = false;

    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      const files = Array.from(event.dataTransfer.files); // Convertimos FileList a array
      this.fileChangeEvent({ target: { files } }); // Pasamos todos los archivos
    }
  }

  fileSelectClick() {
    if (this.fileloader == null) {
      this.fileloader = document.getElementById('fileSelect');
    }

    if (this.fileloader) {
      this.fileloader.click();
    }
  }

  openResponseDialog(response: string, message?: string) {
    let data: string[] = [];
    if (response == 'Error') {
      data.push('Error');
      data.push(message);
      const dialogRef = this.dialog.open(ResponseDialogComponent, {
        width: '500px',
        /* height: '400px', */
        data: data,
      });
    } else if (response == 'Exito') {
      data.push('Exito');
      data.push(message);

      const dialogRef = this.dialog.open(ResponseDialogComponent, {
        width: '500px',
        /* height: '400px', */
        data: data,
      });
    }
  }

  openResultDialog(mode: number, message?: string, content?: string) {
    let data: string[] = [];
    switch (mode) {
      case 0:
        {
          data.push('Error');
          data.push(message);
          if (content) data.push(content);
          return this.dialog.open(ResponseSmallDialogComponent, {
            width: '500px',
            data: data,
          });
        }
        break;
      case 1:
        {
          data.push('Exito');
          data.push(message);
          if (content) data.push(content);
          return this.dialog.open(ResponseSmallDialogComponent, {
            width: '500px',
            data: data,
          });
        }
        break;
      case 2:
        {
          data.push('loading');
          data.push(message);
          if (content) data.push(content);
          return this.dialog.open(ResponseSmallDialogComponent, {
            width: '500px',
            data: data,
            disableClose: true,
          });
        }
        break;
    }
  }

  activeActionCC() {
    this.activeCC = true;
  }
}

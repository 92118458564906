import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PuzzleService } from '../services/puzzle.service';
import { UserLogin } from '../interfaces/user-login';
import { LocalService } from '../services/local.service';
import { Token } from '../interfaces/token';
import { MatDialog } from '@angular/material/dialog';
import { ResponseDialogComponent } from '../dialogs/response-dialog/response-dialog.component';
import { SwUpdate } from '@angular/service-worker';
import { DocuViewerComponent } from '../dialogs/docu-viewer/docu-viewer.component';
import { BannerViewerComponent } from '../dialogs/banner-viewer/banner-viewer.component';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import { Country } from '../interfaces/country';
import { UserLimits } from '../interfaces/UserLimits';
import { DemoRequest } from '../interfaces/DemoRequest';
import { ResponseSmallDialogComponent } from '../dialogs/response-small-dialog/response-small-dialog.component';
import { constants } from '../services/constants';
import { TranslateService } from '@ngx-translate/core';
import { LoginDialogComponent } from '../dialogs/login-dialog/login-dialog.component';
import { Meta, SafeResourceUrl, Title } from '@angular/platform-browser';
import { title } from 'process';
import { RecaptchaLoaderService } from '../services/recaptcha-loader.service';
import { RecaptchaComponent } from 'ng-recaptcha';
import { RegisterDialogComponent } from '../dialogs/register-dialog/register-dialog.component';
import { ViewportScroller } from '@angular/common';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Subscription } from 'rxjs';
import { MatMenuTrigger } from '@angular/material/menu';
import { CountUp } from 'countup.js';
import AOS from 'aos';

export interface PlanFeature {
  name: string;
  entry: boolean;
  entryHH: boolean;
  mid: boolean;
  midHH: boolean;
  enterprise: boolean;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
  userLogin: UserLogin = {
    UserName: null,
    Password: null,
    AccessToken: null,
    RefreshToken: null,
  };
  hide: boolean = true;
  user: string;
  password: string;
  token: Token;
  spin: boolean = false;
  recapcha: string;
  sending: boolean = false;
  sendText: string = 'Siguiente';
  countries: Country[];
  userLimits: UserLimits[];
  demoRequest: DemoRequest = {} as DemoRequest;
  loginNamePlaceHolder: string;
  candidateFinalDatePlaceholder: string;
  loginLastName: string;
  loginEmail: string;
  loginCellPhone: string;
  loginBussines: string;
  loginNumberEmployees: string;
  loginCountry: string;
  loginCode: string;
  userName: string;
  passwordName: string;
  currentLang: string;
  summaryText: string;
  currentImageId: number = 1;
  activeIndex: number = 0;
  dropdownOpen = false;
  isMenuOpen = false;
  recaptchaReady = false;
  subscriptionType: string = 'semestral';
  scrolled: boolean = false;
  multicompany: boolean = false;
  sendToNotifications: boolean = false;
  haibuImage: SafeResourceUrl = '../../assets/icons/haibu-icon.svg';
  smartPhoneImage: SafeResourceUrl = '../../assets/icons/smartphone-icon.svg';
  whatsappImage: SafeResourceUrl = '../../assets/icons/whatsapp-icon.svg';
  languageImage: SafeResourceUrl = '../../assets/icons/language-icon.svg';
  leftArrowImage: SafeResourceUrl = '../../assets/icons/left-arrorw-icon.svg';
  rightArrowImage: SafeResourceUrl = '../../assets/icons/right-arrow-icon.svg';
  closeIcon: SafeResourceUrl = '../../assets/icons/close-icon.svg';
  menuIcon: SafeResourceUrl = '../../assets/icons/men-icon.svg';
  fbIcon: SafeResourceUrl = '../../assets/icons/facebook-icon.svg';
  igIcon: SafeResourceUrl = '../../assets/icons/ig-icon.svg';
  linkedinIcon: SafeResourceUrl = '../../assets/icons/linkedin-icon.svg';
  ytIcon: SafeResourceUrl = '../../assets/icons/yt-icon.svg';
  americanExpressIcon: SafeResourceUrl =
    '../../assets/icons/american-exp-icon.svg';
  visaIcon: SafeResourceUrl = '../../assets/icons/visa-icon.svg';
  mastercardIcon: SafeResourceUrl = '../../assets/icons/mastercard-icon.svg';
  stripeIcon: SafeResourceUrl = '../../assets/icons/stripe-icon.svg';

  teamSettingsIcon: SafeResourceUrl =
    '../../assets/icons/settings-team-icon.svg';
  teamSquareIcon: SafeResourceUrl = '../../assets/icons/square-team-icon.svg';
  teamPuzzleIcon: SafeResourceUrl = '../../assets/icons/team-puzzle.svg';
  checkIcon: SafeResourceUrl = '../../assets/icons/checkl-icon.svg';
  gifIcon: SafeResourceUrl = '../../assets/animations/Haibu-celular.png';
  actualImage: string = '../../assets/icons/haibu-icon.svg';
  candidateIcon: SafeResourceUrl = '../../assets/animations/candidato.gif';
  userIcon: SafeResourceUrl = '../../assets/animations/acceso.gif';
  clientIcon: SafeResourceUrl = '../../assets/animations/apreton-de-manos.gif';
  whatsappImageGray: SafeResourceUrl =
    '../../assets/icons/whatsapp-icon-gray.svg';
  smartPhoneImageGray: SafeResourceUrl =
    '../../../assets/icons/smartphone-candidate-icon.svg';
  @ViewChild('navDesktop') navDesktop: any;
  @ViewChild('navLaptop') navLaptop: any;
  @ViewChild('navTablet') navTablet: any;
  @ViewChild('navMobile') navMobile: any;
  @ViewChild('captchaComponent') captchaComponent: RecaptchaComponent;
  @ViewChild('nameInput') nameInput: any;
  @ViewChild('priceCards') priceCards: any;
  demoForm: FormGroup;
  dataSource: PlanFeature[] = [
    {
      name: 'features.1',
      entry: true,
      entryHH: true,
      mid: true,
      midHH: true,
      enterprise: true,
    },
    {
      name: 'features.2',
      entry: true,
      entryHH: true,
      mid: true,
      midHH: true,
      enterprise: true,
    },
    {
      name: 'features.3',
      entry: true,
      entryHH: true,
      mid: true,
      midHH: true,
      enterprise: true,
    },
    {
      name: 'features.4',
      entry: true,
      entryHH: true,
      mid: true,
      midHH: true,
      enterprise: true,
    },
    {
      name: 'features.11',
      entry: false,
      entryHH: true,
      mid: false,
      midHH: true,
      enterprise: true,
    },
    {
      name: 'features.10',
      entry: false,
      entryHH: true,
      mid: false,
      midHH: true,
      enterprise: true,
    },
    {
      name: 'features.6',
      entry: true,
      entryHH: true,
      mid: true,
      midHH: true,
      enterprise: true,
    },
    {
      name: 'features.7',
      entry: true,
      entryHH: true,
      mid: true,
      midHH: true,
      enterprise: true,
    },
    {
      name: 'features.12',
      entry: false,
      entryHH: true,
      mid: false,
      midHH: true,
      enterprise: true,
    },
    {
      name: 'features.9',
      entry: false,
      entryHH: false,
      mid: true,
      midHH: true,
      enterprise: true,
    },
    {
      name: 'features.14',
      entry: false,
      entryHH: true,
      mid: false,
      midHH: true,
      enterprise: true,
    },
    {
      name: 'features.15',
      entry: false,
      entryHH: false,
      mid: true,
      midHH: true,
      enterprise: true,
    },
    {
      name: 'features.8',
      entry: false,
      entryHH: false,
      mid: true,
      midHH: true,
      enterprise: true,
    },
    {
      name: 'features.13',
      entry: true,
      entryHH: true,
      mid: true,
      midHH: true,
      enterprise: true,
    },
    {
      name: 'features.5',
      entry: false,
      entryHH: false,
      mid: false,
      midHH: false,
      enterprise: true,
    },
    {
      name: 'features.16',
      entry: true,
      entryHH: true,
      mid: true,
      midHH: true,
      enterprise: true,
    },
  ];
  displayedColumns: string[] = [
    'name',
    'entry',
    'entryHH',
    'mid',
    'midHH',
    'enterprise',
  ];

  stats: any = {};
  fragment: string | null = null;

  companiesCarousel = [
    { comP_NAME: 'Empresa 1', comP_LOGO_ROUTE: '/assets/img/carousel/1.png' },
    /* { comP_NAME: "Banregio", comP_LOGO_ROUTE: "/assets/img/carousel/23.png" }, */
    { comP_NAME: 'Nata', comP_LOGO_ROUTE: '/assets/img/carousel/25.png' },
    { comP_NAME: 'Empresa 6', comP_LOGO_ROUTE: '/assets/img/carousel/6.png' },
    { comP_NAME: 'Empresa 9', comP_LOGO_ROUTE: '/assets/img/carousel/9.png' },
    { comP_NAME: 'Empresa 12', comP_LOGO_ROUTE: '/assets/img/carousel/12.png' },
    { comP_NAME: 'Empresa 16', comP_LOGO_ROUTE: '/assets/img/carousel/16.png' },
    { comP_NAME: 'Empresa 18', comP_LOGO_ROUTE: '/assets/img/carousel/18.png' },
    { comP_NAME: 'Empresa 19', comP_LOGO_ROUTE: '/assets/img/carousel/19.png' },
    { comP_NAME: 'Empresa 21', comP_LOGO_ROUTE: '/assets/img/carousel/21.png' },
    /* { comP_NAME: "Empresa 22", comP_LOGO_ROUTE: "/assets/img/carousel/22.png" } */
  ];

  landingCarrouselMobile = {
    eng: [
      {
        comP_NAME: 'Empresa 2',
        Logo_route: '/assets/img/carrouselLandingMobileEng/11.webp',
        link: 'demo',
      },
      {
        comP_NAME: 'Empresa 2',
        Logo_route: '/assets/img/carrouselLandingMobileEng/14.webp',
        link: 'demo',
      },
      {
        comP_NAME: 'Empresa 5',
        Logo_route: '/assets/img/carrouselLandingMobileEng/13.webp',
        link: 'demo',
      },
      {
        comP_NAME: 'Empresa 5',
        Logo_route: '/assets/img/carrouselLandingMobileEng/4.webp',
        link: 'blog',
      },
    ],
    esp: [
      {
        comP_NAME: 'Empresa 2',
        Logo_route: '/assets/img/carrouselLandingMobileEsp/11.webp',
        link: 'demo',
      },
      {
        comP_NAME: 'Empresa 2',
        Logo_route: '/assets/img/carrouselLandingMobileEsp/9.webp',
        link: 'demo',
      },
      {
        comP_NAME: 'Empresa 1',
        Logo_route: '/assets/img/carrouselLandingMobileEsp/13.webp',
        link: 'demo',
      },
      {
        comP_NAME: 'Empresa 5',
        Logo_route: '/assets/img/carrouselLandingMobileEsp/4.webp',
        link: 'blog',
      },
    ],
  };

  landingCarrousel = {
    eng: [
      {
        comP_NAME: 'Empresa 2',
        Logo_route: '/assets/img/carrouselLandingEng/1.webp',
        link: 'demo',
      },
      {
        comP_NAME: 'Empresa 2',
        Logo_route: '/assets/img/carrouselLandingEng/9.webp',
        link: 'demo',
      },
      {
        comP_NAME: 'Empresa 1',
        Logo_route: '/assets/img/carrouselLandingEng/2.webp',
        link: 'demo',
      },
      {
        comP_NAME: 'Empresa 1',
        Logo_route: '/assets/img/carrouselLandingEng/4.webp',
        link: 'blog',
      },
    ],
    esp: [
      {
        comP_NAME: 'Empresa 2',
        Logo_route: '/assets/img/carrouselLandingEsp/1.webp',
        link: 'demo',
      },
      {
        comP_NAME: 'Empresa 2',
        Logo_route: '/assets/img/carrouselLandingEsp/9.webp',
        link: 'demo',
      },
      {
        comP_NAME: 'Empresa 1',
        Logo_route: '/assets/img/carrouselLandingEsp/2.webp',
        link: 'demo',
      },
      {
        comP_NAME: 'Empresa 1',
        Logo_route: '/assets/img/carrouselLandingEsp/4.webp',
        link: 'blog',
      },
    ],
  };
  isMobileScreen: boolean = false;
  customOptions: OwlOptions = {
    loop: true,
    margin: 10,
    autoplay: true,
    autoplayTimeout: 1500,
    autoplayHoverPause: true,
    nav: false,
    dots: false,
    lazyLoad: true,
    responsive: {
      0: { items: 2 },
      300: { items: 3 },
      600: { items: 4 },
      1000: { items: 6 },
    },
  };
  customOptionsCarrouselLanding: OwlOptions = {
    loop: true,
    margin: 10,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: false,
    nav: false,
    dots: false,
    lazyLoad: true,
    responsive: {
      0: { items: 1 },
      600: { items: 1 },
      1000: { items: 1 },
    },
  };
  isJobs: boolean = false;
  langChangeSubscription: Subscription;

  observer!: IntersectionObserver;
  alreadyCounted = false; // Para evitar que se repita
  windowHeight: number = window.innerHeight;

  @ViewChild('contactMenuTrigger', { read: ElementRef })
  menuTrigger!: ElementRef;
  @ViewChild(MatMenuTrigger) matMenuTrigger!: MatMenuTrigger;
  @ViewChild('counterText1', { static: true }) counterText1!: ElementRef;
  @ViewChild('counterText2', { static: true }) counterText2!: ElementRef;
  @ViewChild('counterText3', { static: true }) counterText3!: ElementRef;
  @ViewChild('counterText4', { static: true }) counterText4!: ElementRef;
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;

  constructor(
    private swUpdate: SwUpdate,
    public dialog: MatDialog,
    private router: Router,
    private puzzleService: PuzzleService,
    private localService: LocalService,
    private translate: TranslateService,
    private recaptchaLoader: RecaptchaLoaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private viewportScroller: ViewportScroller,
    private titleService: Title,
    private metaService: Meta,
  ) {
    this.swUpdate.checkForUpdate();
    this.setSubscription(this.subscriptionType);
    this.createForm();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // this.checkScreenSize();
  }

  // detectar scroll
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    AOS.refresh();
    if (window.scrollY > 0) {
      this.scrolled = true;
    } else {
      this.scrolled = false;
    }
  }

  ngAfterViewInit() {
    this.matMenuTrigger.menuOpened.subscribe(() => {
      if (this.menuTrigger) {
        const buttonWidth = this.menuTrigger.nativeElement.offsetWidth;
        document.documentElement.style.setProperty(
          '--menu-width',
          `${buttonWidth}px`,
        );
      }
    });
  }

  animateScrollHint() {
    const scrollDiv = this.scrollContainer.nativeElement;
    const left = scrollDiv.scrollLeft + 100; // Desplaza un poco a la derecha
    scrollDiv.scrollTo({ left: left, behavior: 'smooth' }); // Desplaza un poco a la derecha
  }

  checkScreenSize() {
    this.isMobileScreen = window.innerWidth < 768;
  }

  getHaibuStats() {
    this.puzzleService.getHaibuStats().subscribe((response) => {
      this.stats = response[0];
      this.setupObserver();
    });
  }

  ngOnInit(): void {
    // this.titleService.setTitle('Login - Haibu');
    this.metaService.addTags([
      {
        name: 'description',
        content:
          'Haibu es un software de reclutamiento y selección de personal que te ayuda a encontrar al candidato ideal para tu empresa.',
      },
      {
        name: 'keywords',
        content:
          'reclutamiento, selección, personal, candidato, empresa, software',
      },
      { name: 'robots', content: 'index, follow' },
    ]);
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize.bind(this));

    this.recaptchaLoader.isRecaptchaReady.subscribe((ready) => {
      this.recaptchaReady = ready;
    });
    this.getHaibuStats();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let notifications = urlParams.get('notifications');
    if (notifications != null && notifications != '' && notifications) {
      this.sendToNotifications = true;
    }
    this.initializeLanguage();
    let processid = urlParams.get('processid');
    this.langChangeSubscription = this.translate.onLangChange.subscribe(
      (event) => {
        this.currentLang = event.lang;
        this.updateCarrouselImages();
      },
    );
    this.updateTranslations();
    this.router.events.forEach((event) => {});

    let tokencito = this.localService.getJsonValue('token');

    if (tokencito) {
      this.token = tokencito;

      if (this.token.data.roleId == constants.superadmin) {
        this.router.navigate(['home/clientlist']);
      } else {
        if (this.sendToNotifications) {
          this.router.navigate(['home/myprofile']);
        } else {
          this.router.navigate(['home/dashboard']);
        }
      }
    } else {
      /*       this.openbanner("/assets/img/portada.png"); */
      this.getUserLimits();
      this.getCountries();

      let register = urlParams.get('register');
      if (register != null && register != '') {
        this.isJobs = true;
        this.openRegisterModal();
      }

      let login = urlParams.get('login');
      if (login != null && login != '') {
        this.openLoginModal();
      }
    }

    this.route.fragment.subscribe((fragment) => {
      this.fragment = fragment;
      if (this.fragment) {
        this.scrollToElement(this.fragment);
      }
    });
  }

  setupObserver() {
    this.observer = new IntersectionObserver(
      (entries) => {
        if (
          entries.some((entry) => entry.isIntersecting) &&
          !this.alreadyCounted
        ) {
          this.alreadyCounted = true;
          this.startCountUp();
        }
      },
      { threshold: 0.5 },
    );

    // Observamos todos los contadores
    this.observer.observe(this.counterText1.nativeElement);
    this.observer.observe(this.counterText2.nativeElement);
    this.observer.observe(this.counterText3.nativeElement);
    this.observer.observe(this.counterText4.nativeElement);
  }

  startCountUp() {
    this.animateCounter(
      this.counterText1.nativeElement,
      this.stats?.totalCandidates,
    );
    this.animateCounter(
      this.counterText2.nativeElement,
      this.stats?.totalUsers,
    );
    this.animateCounter(
      this.counterText3.nativeElement,
      this.stats?.totalCompanies,
    );
    this.animateCounter(this.counterText4.nativeElement, 985);
  }

  animateCounter(element: HTMLElement, value: number) {
    const countUp = new CountUp(element, value, {
      duration: 2, // Duración en segundos
      useEasing: true,
    });

    if (!countUp.error) {
      countUp.start();
    } else {
      console.error(countUp.error);
    }
  }

  ngOnDestroy(): void {
    // Cancelar la suscripción
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  goToLink(url: string) {
    if (url == 'blog') {
      this.router.navigate(['blog']);
    } else if (url == 'demo') {
      this.openRegisterModal(false, 'Mid + Head Hunter');
    }
  }

  public noWhitespaceValidator(control: FormControl) {
    return (control.value || '').trim().length ? null : { whitespace: true };
  }

  public noSpecialCharactersValidator(control: FormControl) {
    const value = control.value || '';
    // Expresión regular que permite letras (de cualquier alfabeto), números y espacios en blanco
    const regex = /^[\p{L}\p{N}\s.]*$/u;

    if (!regex.test(value)) {
      return { noSpecialCharacters: true };
    }

    return null;
  }

  public isEmailValid(control: FormControl) {
    const value = control.value || '';
    // Expresión regular que permite letras (de cualquier alfabeto), números y espacios en blanco
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!regex.test(value)) {
      return { isEmailValid: true };
    }

    return null;
  }

  public isPhoneValid(control: FormControl) {
    const value = control.value || '';
    const regex = /^[0-9]*$/;

    if (!regex.test(value)) {
      return { isPhoneValid: true };
    }

    if (value.length === 10 && value.substring(0, 3) === '000') {
      return { isPhoneValid: true };
    }

    return null;
  }

  createForm() {
    this.demoForm = this.fb.group({
      names: [
        '',
        [
          Validators.required,
          this.noWhitespaceValidator,
          this.noSpecialCharactersValidator,
        ],
      ],
      lastnames: [
        '',
        [
          Validators.required,
          this.noWhitespaceValidator,
          this.noSpecialCharactersValidator,
        ],
      ],
      email: ['', [Validators.required, Validators.email, this.isEmailValid]],
      phone: ['', [Validators.required, this.isPhoneValid]],
      company: [
        '',
        [
          Validators.required,
          this.noWhitespaceValidator,
          this.noSpecialCharactersValidator,
        ],
      ],
      userLimit: ['', Validators.required],
      country: ['', Validators.required],
      code: [''],
      terms: [false, Validators.requiredTrue],
    });
  }

  setSubscription(type: string) {
    this.subscriptionType = type;
  }

  getSuscriptionUrl(value) {
    if (this.subscriptionType === 'semestral') {
      switch (value) {
        case 1:
          return 'https://buy.stripe.com/8wM9D5d9a7he6EE147';
        case 2:
          return 'https://buy.stripe.com/14keXp2uw9pm0gg9AH';
        case 3:
          return 'https://buy.stripe.com/5kAdTl7OQ452gfe5kt';
        case 4:
          return 'https://buy.stripe.com/28o02v0mocBy2oobIT';
        case 5:
          return 'https://meet.goodtime.io/w/haibuhrcom/jgasteasoro/video-call';
      }
    } else {
      switch (value) {
        case 1:
          return 'https://buy.stripe.com/bIYg1tfhi4525AA4gk';
        case 2:
          return 'https://buy.stripe.com/8wM9D55GI6daaUUaEK';
        case 3:
          return 'https://buy.stripe.com/6oE5mP1qsfNKgfe7sA';
        case 4:
          return 'https://buy.stripe.com/cN23eH0mo30Yd32eV4';
        case 5:
          return 'https://meet.goodtime.io/w/haibuhrcom/jgasteasoro/video-call';
      }
    }
  }

  changeLanguage(lang: string): void {
    this.translate.use(lang).subscribe(() => {
      this.updateTranslations();
      this.updateCarrouselImages();
    });
    const newLang = lang;
    this.translate.use(newLang);
    this.currentLang = newLang;
    this.dropdownOpen = false;
    this.localService.setJsonValue('lang', newLang);
  }

  initializeLanguage() {
    if (this.localService.getJsonValue('lang')) {
      this.translate.use(this.localService.getJsonValue('lang'));
      this.currentLang = this.localService.getJsonValue('lang');
    } else {
      const browserLang = this.translate.getBrowserLang();
      let appLang = this.mapBrowserLangToAppLang(browserLang);
      this.translate.use(appLang);
      this.currentLang = appLang;
      this.localService.setJsonValue('lang', appLang);
    }
    this.updateCarrouselImages();
  }

  updateCarrouselImages(): void {
    this.currentLang = this.translate.currentLang || 'esp';
    this.landingCarrousel = { ...this.landingCarrousel };
  }

  get currentLandingCarrousel(): any[] {
    return this.landingCarrousel[this.currentLang] || [];
  }

  get currentLandingCarrouselMobile(): any[] {
    return this.landingCarrouselMobile[this.currentLang] || [];
  }

  updateCurrentImageIndex(imageId: number) {
    this.currentImageId = imageId;
  }

  changeImage(newImage: string) {
    this.actualImage = newImage;
  }

  updateTranslations() {
    this.translate.get('loginAskDemoName').subscribe((res: string) => {
      this.loginNamePlaceHolder = res;
    });
    this.translate.get('loginAskDemoLastName').subscribe((res: string) => {
      this.loginLastName = res;
    });
    this.translate.get('loginAskDemoEmail').subscribe((res: string) => {
      this.loginEmail = res;
    });
    this.translate.get('loginAskDemoPhone').subscribe((res: string) => {
      this.loginCellPhone = res;
    });
    this.translate.get('loginAskDemoCompany').subscribe((res: string) => {
      this.loginBussines = res;
    });
    this.translate.get('loginAskCountNumber').subscribe((res: string) => {
      this.loginNumberEmployees = res;
    });
    this.translate.get('loginAskCountry').subscribe((res: string) => {
      this.loginCountry = res;
    });
    this.translate.get('loginAskDemoCode').subscribe((res: string) => {
      this.loginCode = res;
    });

    this.translate.get('loginPLaceHolderUser').subscribe((res: string) => {
      this.userName = res;
    });
    this.translate.get('loginPLaceHolderPassword').subscribe((res: string) => {
      this.passwordName = res;
    });
    this.translate.get('loginPuzzleMeaning').subscribe((res: string) => {
      this.summaryText = res;
    });
  }

  mapBrowserLangToAppLang(browserLang: string): string {
    const langMap = {
      en: 'eng',
      es: 'esp',
    };
    return langMap[browserLang] || 'eng';
  }

  get currentLangShort(): string {
    return this.currentLang === 'eng' ? 'EN' : 'ES';
  }

  toggleDropdown(): void {
    this.dropdownOpen = !this.dropdownOpen;
  }

  toggleMenu() {
    return new Promise((resolve) => {
      this.isMenuOpen = !this.isMenuOpen;
      setTimeout(resolve, 350);
    });
  }

  getLoginText(): string {
    return this.translate.instant('loginFollowForyou');
  }

  sendMail() {
    if (this.sending) {
      return;
    }
    this.sending = true;
    this.sendText = 'Enviando';

    if (!this.demoForm.valid) {
      this.openSmallResponseDialog(
        'Error',
        this.translate.instant('loginAlertForm'),
      );
      Object.values(this.demoForm.controls).forEach((control) => {
        control.markAsTouched();
      });
      this.sending = false;
      this.sendText = 'Siguiente';
    } else {
      if (this.recapcha == null || this.recapcha == '') {
        this.openSmallResponseDialog(
          'Error',
          this.translate.instant('pleaseAcceptRecaptcha'),
        );
        this.sending = false;
        this.sendText = 'Siguiente';
      } else {
        if (!this.demoForm.get('terms').value) {
          this.openSmallResponseDialog(
            'Error',
            this.translate.instant('pleaseAcceptTerms'),
          );
          this.sending = false;
          this.sendText = 'Siguiente';
        } else {
          this.demoRequest = {
            Names: this.demoForm.get('names').value,
            LastNames: this.demoForm.get('lastnames').value,
            Email: this.demoForm.get('email').value,
            CellPhone: this.demoForm.get('phone').value,
            CompanyName: this.demoForm.get('company').value,
            UserLimit: this.demoForm.get('userLimit').value,
            CountryName: this.demoForm.get('country').value,
            DiscountCode: this.demoForm.get('code').value,
            TermsAndConditionsAccepted: true,
            Recaptcha: this.recapcha,
          };
          this.puzzleService.demoRequest(this.demoRequest).subscribe(
            (response) => {
              if (response.isSuccess) {
                if (
                  response.message ==
                  'La solicitud de demo fue enviada correctamente, en breve nos comunicaremos contigo.'
                ) {
                  response.message = this.translate.instant(
                    'loginDemoRequestSuccess',
                  );
                }
                this.openSmallResponseDialog('Exito', response.message);
                this.demoRequest = {} as DemoRequest;
                this.demoForm.reset();
              } else {
                this.openSmallResponseDialog('Error', response.message);
              }
              this.sending = false;
              this.sendText = 'Siguiente';
              this.captchaComponent.reset();
              this.demoForm.reset();
            },
            (error) => {
              this.openSmallResponseDialog('Error', error);
              this.sending = false;
              this.sendText = 'Siguiente';
              this.captchaComponent.reset();
            },
          );
        }
      }
    }
  }

  getCountries() {
    this.puzzleService.getDemoCountries().subscribe((response) => {
      this.countries = response;
    });
  }

  getUserLimits() {
    this.puzzleService.getUserLimits().subscribe((response) => {
      this.userLimits = response;
      // this.checkScreenSize();
    });
  }

  resolved(captchaResponse: string) {
    this.recapcha = captchaResponse;
  }

  scrollToElement(element: string) {
    if (element === 'blog') {
      this.router.navigate(['blog']).then(() => {
        this.viewportScroller.scrollToPosition([0, 0]);
      });
      return;
    }
    this.toggleMenu().then(() => {
      this.smoothScrollToElement(element);
    });

    // selected element
    if (element === 'rompe') {
      this.puzzleService.setSelected(1);
    } else if (element === 'modules') {
      this.puzzleService.setSelected(2);
    } else if (element === 'price') {
      this.puzzleService.setSelected(3);
    } else if (element === 'demo') {
      this.puzzleService.setSelected(4);
    } else if (element === 'blog') {
      this.puzzleService.setSelected(5);
    } else {
      this.puzzleService.setSelected(0);
    }
  }

  smoothScrollToElement(element: string): Promise<void> {
    return new Promise((resolve) => {
      const targetElement = document.getElementById(element);
      const navbarHeight = this.scrolled ? 60 : 120;

      if (targetElement) {
        const offsetTop = targetElement.offsetTop - navbarHeight;

        window.scrollTo({
          top: offsetTop,
          behavior: 'smooth',
        });

        // A small delay to ensure the scroll has finished
        setTimeout(() => {
          resolve();
          if (element === 'demo' && this.nameInput?.nativeElement) {
            this.nameInput.nativeElement.focus();
          }
        }, 1000); // Adjust the delay as needed
      } else {
        resolve();
      }
    });
  }

  Login(user: string, password: string) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let processid = urlParams.get('processid');

    this.userLogin.UserName = user;
    this.userLogin.Password = password;
    this.puzzleService.login(this.userLogin).subscribe(
      (token) => {
        if (token.isSuccess) {
          this.localService.setJsonValue('token', token);
          this.token = this.localService.getJsonValue('token');

          if (this.token.data.roleId == constants.superadmin) {
            this.router.navigate(['home/clientlist']);
          } else {
            if (this.sendToNotifications) {
              this.router.navigate(['home/myprofile']);
            } else if (processid != null && processid != '') {
              this.router.navigate(['home/redirect'], {
                queryParams: { processid: processid },
              });
            } else {
              this.router.navigate(['home/candidateslist']);
            }
          }
        } else {
          // Manejamos los errores específicos traducidos
          if (token.messageCode === 'USER_NOT_FOUND') {
            this.openResponseDialog(
              'Error',
              this.translate.instant('loginUserNotExist'),
            );
          } else if (token.messageCode === 'INVALID_CREDENTIALS') {
            this.openResponseDialog(
              'Error',
              this.translate.instant('loginIncorrectCredentials'),
            );
          } else if (token.messageCode === 'COMPANY_NOT_ACTIVE') {
            this.openResponseDialog(
              'Error',
              this.translate.instant('loginIncorrectCompanyNoActive'),
            );
          } else if (token?.message?.includes('Timeout expired')) {
            this.openResponseDialog(
              'Error',
              this.translate.instant('loginTimeoutExpired'),
            );
          } else {
            // Para otros errores que no estén traducidos, mostrar el mensaje del servidor
            this.openResponseDialog('Error', token.message);
          }
        }
      },
      (err) => {},
    );
  }

  openLoginModal() {
    const dialogRef = this.dialog.open(LoginDialogComponent, {
      panelClass: 'login-dialog',
      width: '376px',
      height: '546px',
      maxWidth: '95vw',
      maxHeight: '95vh',
      data: { user: this.user, password: this.password, jobs: this.isJobs },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  openResponseDialog(response: string, status?: string) {
    let data: string[] = [];
    if (status) {
      data.push(response);
      data.push(status);
    } else {
      if (response == 'Error') {
        data.push('Error');
        data.push('Ocurrió un error, favor de intentarlo nuevamente');
      } else if (response == 'Exito') {
        data.push('Exito');
        data.push('La empresa ha sido creada correctamente');
      }
    }

    const dialogRef = this.dialog.open(ResponseDialogComponent, {
      width: '500px',
      /* height: '400px', */
      data: data,
    });
  }

  openSmallResponseDialog(response: string, status?: string) {
    let data: string[] = [];
    if (status) {
      data.push(response);
      data.push(status);
    } else {
      if (response == 'Error') {
        data.push('Error');
        data.push('Ocurrió un error, favor de intentarlo nuevamente');
      } else if (response == 'Exito') {
        data.push('Exito');
        data.push('La empresa ha sido creada correctamente');
      }
    }

    const dialogRef = this.dialog.open(ResponseSmallDialogComponent, {
      width: '500px',
      /* height: '400px', */
      data: data,
    });
  }

  setSubscriptionType(type: string) {
    this.subscriptionType = type;
  }

  scrollLeft() {
    this.priceCards.nativeElement.scrollLeft -= 316;
  }

  scrollRight() {
    this.priceCards.nativeElement.scrollLeft += 316;
  }

  openRegisterModal(
    disable: boolean = false,
    plan: string = 'Mid + Head Hunter',
  ) {
    this.dialog.open(RegisterDialogComponent, {
      width: '700px',
      maxWidth: '95vw',
      maxHeight: '95vh',
      data: { prev: this.isJobs, isJobs: this.isJobs, plan: plan },
      disableClose: disable,
    });
  }

  openWhatsApp() {
    const phoneNumber = '5215542004088'; // Reemplaza con el número correcto
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, '_blank');
  }

  scheduleCall() {
    const calendarUrl =
      'https://meet.goodtime.io/w/haibuhrcom/jgasteasoro/video-call';
    window.open(calendarUrl, '_blank');
  }

  /*   openbanner(route: string) {
      if (route != null) {
        let dialog = this.dialog.open(BannerViewerComponent, {
          panelClass: 'custom-dialog-banner',
          maxWidth: '100vw !important',
          data: { data: route },
        });

        dialog.afterClosed().subscribe(result => {

        });
      }

    } */
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SafeResourceUrl } from '@angular/platform-browser';
import { CandidateAccount } from 'src/app/interfaces/CandidateAccount';
import { DocuViewerComponent } from '../docu-viewer/docu-viewer.component';
import { APIResponse } from 'src/app/interfaces/response';
import { LocalService } from 'src/app/services/local.service';
import { PuzzleService } from 'src/app/services/puzzle.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-talent-account-detail',
  templateUrl: './talent-account-detail.component.html',
  styleUrls: ['./talent-account-detail.component.css']
})
export class TalentAccountDetailComponent implements OnInit {
  candidate: CandidateAccount;
  isAddingMode: boolean = false;
  sending: boolean = false;
  companies: any[] = [];
  selectedCompany: any;
  vacancies: any[] = [];
  selectedVacancy: any;
  canCreate: boolean = true;
  companyRequiredError: string;
  vacancyRequiredError: string;
  companyEmptyError: string;
  token: APIResponse = this.localService.getJsonValue('token');
  defaultpfp: SafeResourceUrl = '../../../assets/icons/default-pfp-icon.svg';
  mailIcon: SafeResourceUrl = '../../../assets/icons/candidate-mail-icon.svg';
  phoneLogo: SafeResourceUrl = '../../../assets/icons/candidate-phone-icon.svg';
  locationLogo: SafeResourceUrl = '../../../assets/icons/location-icon.svg';
  cvLogo: SafeResourceUrl = '../../../assets/icons/cv-icon.svg';
  nocvLogo: SafeResourceUrl = '../../../assets/icons/noCV-icon.svg';


  constructor(@Inject(MAT_DIALOG_DATA) public data: { candidate: CandidateAccount },
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TalentAccountDetailComponent>,
    private translate: TranslateService,
    private localService: LocalService,
    private puzzleService: PuzzleService
  ) {
    this.candidate = data.candidate;
  }
  ngOnInit(): void {
    this.getCompaniesList()
  }

  getCompaniesList() {
    this.puzzleService.getUserCompanies().subscribe(res => {
      this.companies = res.map((company: any) => {
        return {
          value: company.comP_ID,
          label: company.comP_NAME
        }
      });
      this.selectedCompany = this.companies[0].value;
      this.getVacancies({ value: this.selectedCompany });
    });
  }

  getVacancies(event: any) {
    this.selectedCompany = event.value;
    this.vacancies = [];
    this.canCreate = true;
    this.companyRequiredError = '';
    this.vacancyRequiredError = '';
    this.companyEmptyError = '';

    const user = {
      UserId: this.token.data.id,
      CompanyId: this.selectedCompany
    }

    this.puzzleService.getTokenForCompany(user).subscribe(res => {
      if (res.isSuccess) {
        const relevantPermissions = ['v005', 'v006'];
        const userPermissions = res.data.permissions
          .map(permission => permission.code)
          .filter(code => relevantPermissions.includes(code));

        let permissionCode = '';
        if (userPermissions.includes('v006')) {
          permissionCode = 'v006'; // ver solo donde participa
        } else if (userPermissions.includes('v005')) {
          permissionCode = 'v005'; // ver todos
        }

        // checar si tiene permiso cd004
        this.canCreate = res.data.permissions.filter(permission => permission.code === 'cd004').length > 0;

        if (this.canCreate) {
          this.puzzleService.getVacancies(this.selectedCompany, true, true, 1, 1000, undefined, undefined, undefined, undefined, undefined, undefined, undefined, permissionCode).subscribe(res => {
            this.vacancies = res.map((vacancy: any) => {
              return {
                value: vacancy.vacanT_ID,
                label: vacancy.jobInfo.joB_NAME + ' - ' + vacancy.departmentInfo.deP_NAME + ' - ' + vacancy.branchOfficeInfo.broF_NAME
              }
            });
            if (this.vacancies.length === 0) {
              this.companyEmptyError = this.translate.instant('companyEmptyError');
            }
          });
        }
      }
    });
  }

  // Método para manejar el error de carga de imagen
  onImageError(event: Event): void {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = this.defaultpfp.toString();
  }

  openCv(element) {
    let dialog = this.dialog.open(DocuViewerComponent, {
      width: '90vw',
      height: '90vh',
      maxHeight: '90vh',
      maxWidth: '90vw',
      panelClass: 'custom-dialog-cv',
      data: { data: element, header: 'Curriculum vitáe' },
    });

    dialog.afterClosed().subscribe((result) => { });
  }

  toggleSearchMode() {
    this.isAddingMode = !this.isAddingMode;
  }

  confirmAction() {
    this.companyRequiredError = '';
    this.vacancyRequiredError = '';
    if (!this.selectedCompany) {
      this.companyRequiredError = this.translate.instant('companyRequiredError');
      return;
    }
    if (!this.selectedVacancy) {
      this.vacancyRequiredError = this.translate.instant('vacancyRequiredError');
      return;
    }

    this.sending = true;

    const payload = {
      vacancyId: this.selectedVacancy,
      companyId: this.selectedCompany,
      candidateAccountId: this.candidate.candidateAccountId
    };

    this.puzzleService
      .addCandidateAccountToCompanyVacancy(payload.vacancyId, payload.companyId, payload.candidateAccountId)
      .subscribe(
        (response) => {
          this.sending = false;
          if (response.isSuccess) {
            this.dialogRef.close({ isSuccess: true, message: response.message });
          } else {
            console.error('Error al postular candidato:', response.message);
            this.dialogRef.close({ isSuccess: false, message: response.message });
          }
        },
        (error) => {
          this.sending = false;
          console.error('Error en la solicitud:', error);
          this.dialogRef.close({ isSuccess: false, message: 'genericError' });
        }
      );
  }

  closeModal() {
    this.dialogRef.close();
  }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { countries } from '../../../../assets/utils/lada';
import { FormControl } from '@angular/forms';

interface Country {
  lada: string;
  country: string;
  code: string;
}

@Component({
  selector: 'app-phone-lada-select',
  templateUrl: './phone-lada-select.component.html',
  styleUrls: ['./phone-lada-select.component.css']
})

export class PhoneLadaSelectComponent implements OnInit, OnChanges {
  @Input() placeholder: string = '';
  @Input() tabindex1: number = 0;
  @Input() tabindex2: number = 0;
  @Input() phone: string = '';
  @Input() disabled: boolean = false;
  @Output() phoneval = new EventEmitter<any>();
  customclass: string = 'phone-border';

  lada: any = "52";
  value: string = '';
  countries = countries;
  filteredCountries: any[] = [];
  ladaSearchControl = new FormControl();

  ngOnInit(): void {
    this.phone = this.transformValueNumber(this.phone);
    this.countries = this.countries.sort((a, b) => a.country.localeCompare(b.country)).filter((country: Country) => country.country !== "Mexico");
    this.countries.unshift({ lada: "52", country: "Mexico", code: "MX" });
    this.initializeValues();

    this.filteredCountries = this.countries;
    
    this.ladaSearchControl.valueChanges.subscribe((search) => {
      this.filteredCountries = this.countries.filter((element) =>
        `${element.country} (${element.lada })`
          .toLowerCase()
          .includes(search?.toLowerCase() || ''),
      );
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Verifica si el valor de phone ha cambiado
    if (changes.phone && !changes.phone.firstChange) {
      this.initializeValues();
    }
  }

  initializeValues() {
    if (this.phone && this.phone.length > 0) {
      // obtener numero sin lada
      this.value = this.phone.slice(-10);
      // obtener lada
      const lada = this.phone.slice(0, this.phone.length - 10);

      if (this.phone.length > 10) {
        this.lada = this.countries.find((country: Country) => country.lada === lada);
      }
    }

    if (!this.lada?.lada) {
      this.lada = this.countries.find((country: Country) => country.lada === "52");
    }

    this.sendValue();
  }

  sendValue() {
    if (this.value == null || this.value == undefined || this.value == '') {
      this.phoneval.emit(null);
      return;
    }
    if (this.value.length < 10) {
      this.phoneval.emit(this.value);
      return;
    }
    const phoneWithLada = this.lada.lada + this.value;
    this.phoneval.emit(phoneWithLada);
  }

  private transformValueNumber(value: string): string {
    if (value == null) {
      return '';
    }
    return value.replace(/[^0-9]/g, '').trimStart();
  }

  onFocus() {
    this.customclass = 'phone-border-focus';
  }

  onBlur() {
    this.customclass = 'phone-border';
  }
}


<div class="ml-auto w-full text-right justify-end -mt-5 -mr-5">
    <div class="flex justify-end">
        <button id="closeModalChangeStatus2" mat-icon-button (click)="closeModal()" tabindex="-1">
            <mat-icon class="close-x-primary">close</mat-icon>
        </button>
    </div>
</div>

<div class="calendar-controls mb-6 pt-2">
    <div class="grid grid-cols-2 md:grid-cols-3 items-center">
        <div class="flex items-center justify-start">
            <!-- Header -->
            <div class="hidden md:flex w-full pb-2 text-start title-text-modal">
                {{ getFormattedDate() }}
            </div>
            <div class="flex md:hidden w-full pb-2 text-start title-text-modal-mobile">
                {{ getFormattedDate() }}
            </div>
        </div>

        <div class="hidden md:flex tabs-container justify-center items-center">
            <!-- Botón Mes -->
            <button class="tab-button" [class.active]="view === 'month'" (click)="setView('month')">
                {{ 'Mes' | translate }}
            </button>

            <!-- Botón Semana -->
            <button class="tab-button" [class.active]="view === 'week'" (click)="setView('week')">
                {{ 'Semana' | translate }}
            </button>
        </div>

        <div class="flex justify-end items-center">
            <div class="flex items-center justify-center gap-2">
                <button
                    class="bg-white text-primary border border-primary p-1 rounded-md flex items-center justify-center hover:bg-yellow-800 transition-all ease-in-out"
                    (click)="prevPeriod()">
                    <mat-icon>
                        arrow_back
                    </mat-icon>
                </button>
                <button
                    class="bg-white text-primary border border-primary p-1 rounded-md flex items-center justify-center hover:bg-yellow-800 transition-all ease-in-out"
                    (click)="nextPeriod()">
                    <mat-icon>
                        arrow_forward
                    </mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>

<div class="hidden md:block calendar-container">
    <!-- Vista de mes -->
    <mwl-calendar-month-view *ngIf="view === 'month'" [viewDate]="viewDate" [events]="events"
        [cellTemplate]="customMonthCell" (dayClicked)="openModal($event.day.date)"
        (eventClicked)="openModal($event.event)" [locale]="locale" [headerTemplate]="headerTemplateD">
    </mwl-calendar-month-view>

    <!-- Template para el header -->
    <ng-template #headerTemplateD let-days="days">
        <div class="calendar-header flex items-center w-full justify-around mb-1">
            <!-- days es un array de objetos con la información de cada día de la semana -->
            <div class="day-header" *ngFor="let day of days">
                {{ getCustomDayLetter(day.date) }}
            </div>
        </div>
    </ng-template>

    <ng-template #customMonthCell let-day="day">
        <div class="custom-month-cell">
            <div class="date">{{ day.date | date: 'd' }}</div>
            <div *ngIf="day.events.length > 0" class="flex flex-col gap-1 relative">
                <!-- Se muestran los primeros 3 eventos si hay más de 4, o todos si no -->
                <ng-container
                    *ngFor="let event of (day.events | slice:0:(day.events.length > 4 ? 3 : day.events.length))">
                    <div class="event-item" (click)="handleEventClick(event, $event)"
                        title="{{ event.title }} {{ event.allDay ? '' : (' - ' + (event.start | date: 'h:mma')) }}">

                        <span class="event-title">
                            <span class="dot"></span>

                            <!-- Si el evento NO es de día completo, mostramos la hora -->
                            <span *ngIf="!event.allDay" class="event-time">
                                <span *ngIf="isDifferentDay(event.start, day.date)" class="font-bold">
                                    ({{ formatDate(event.start) }})
                                </span>
                                {{ event.start | date: 'h:mma' }}
                            </span>

                            {{ event.title }}
                        </span>
                    </div>
                </ng-container>

                <!-- Si hay más de 5 eventos, se muestra el item de "+X más" -->
                <div *ngIf="day.events.length > 4" class="event-item more relative" (click)="toggleMenu($event, day)">
                    <span class="event-time">
                        <span class="dot"></span>
                        +{{ day.events.length - 3 }} {{ 'more' | translate }}
                    </span>
                </div>
            </div>
        </div>
    </ng-template>

    <!-- Vista de semana -->
    <mwl-calendar-week-view *ngIf="view === 'week'" [viewDate]="viewDate" [events]="events" [locale]="locale"
        (hourSegmentClicked)="openModal($event.date, null, true)" (eventClicked)="openModal($event.event)"
        (dayHeaderClicked)="openModal($event.day?.date)">
    </mwl-calendar-week-view>
</div>

<!-- Mobile Calendar -->
<div class="block md:hidden calendar-container mt-2 calendar-mobile">
    <!-- Vista de mes -->
    <mwl-calendar-month-view [viewDate]="viewDate" [events]="events" [cellTemplate]="customMonthCellMobile"
        (dayClicked)="openModal($event.day.date)" (eventClicked)="openModal($event.event)" [locale]="locale"
        [headerTemplate]="headerTemplate">
    </mwl-calendar-month-view>

    <!-- Template para el header -->
    <ng-template #headerTemplate let-days="days">
        <div class="calendar-header flex items-center w-full justify-around mb-1">
            <!-- days es un array de objetos con la información de cada día de la semana -->
            <div class="day-header" *ngFor="let day of days">
                {{ getCustomDayLetter(day.date) }}
            </div>
        </div>
    </ng-template>

    <ng-template #customMonthCellMobile let-day="day">
        <div class="custom-month-cell">
            <div class="date">{{ day.date | date: 'd' }}</div>
            <div *ngIf="day.events.length > 0" class="flex justify-center">
                <div class="text-center bg-primary10 w-6 h-6 rounded-full flex items-center justify-center"
                    (click)="toggleMenu($event, day)">
                    <span class="text-xs">
                        {{ day.events.length }}
                    </span>
                </div>
            </div>
        </div>
    </ng-template>
</div>
<app-navbar></app-navbar>
<div id="home" class="flex flex-col md:pt-0  md:justify-center items-center border-gray-50 min-w-full no-zoom bg-white">
  <div class="w-full flex flex-col justify-center items-center">
    <div data-aos="fade-down"
      class="relative mb-1 w-full flex flex-col md:flex-row gap-y-10 items-center justify-between bg-gradient-hero px-6 md:px-12 lg:px-28 pb-28 nav-transition"
      [ngClass]="scrolled ? 'sliders-1' : 'sliders'">
      <div class="md:w-2/5 w-full">
        <h1 [innerHTML]="'loginTitleHero' | translate">
        </h1>
        <p class="reclutamiento-text pb-1 pt-3">
          {{ 'loginSubTitleHero' | translate }}
        </p>
        <div
          class="px-5 py-2.5 text-center text-white text-lg bg-primary rounded-lg mt-4 w-fit btn-primary cursor-pointer"
          (click)="openRegisterModal()">
          {{ 'loginFreeTrial' | translate }}
        </div>
      </div>
      <div class="md:w-3/5 w-full flex justify-center items-center md:-mr-10 lg:-mr-24">
        <img class="w-full h-auto"
          [src]="currentLang == 'esp' ? '../../assets/img/login/header.png' : '../../assets/img/login/header1.png'"
          alt="Heroe">
      </div>
    </div>

    <!-- Carousel -->
    <div data-aos="fade-left" class="px-6 md:px-12 lg:px-24 -mt-24 w-full py-6">
      <div *ngIf="companiesCarousel.length > 0"
        class="relative mb-1 mt-4 w-full bg-white shadow-2xl rounded-2xl px-6 md:px-12">
        <owl-carousel-o #owlElement [options]="customOptions">
          <ng-container *ngFor="let company of companiesCarousel; let i = index;">
            <ng-template carouselSlide>
              <div class="flex justify-center items-center p-4">
                <img class="h-16 md:h-24 w-auto object-contain object-center grayscale" [src]="company.comP_LOGO_ROUTE"
                  [alt]="company.comP_NAME" />
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>

    <div id="rompe" class="flex flex-wrap px-6 md:px-12 lg:px-24 py-12 bg-white">
      <div data-aos="fade-down" [attr.data-aos-offset]="windowHeight * 0.36" class="w-full">
        <div class="w-full pt-8 pb-4 green-title green-title-size text-center">
          {{"loginChooseHaibu" | translate}}
        </div>
        <div class="w-full mt-2 subtitle-gray font-medium text-center">
          {{"loginChooseHaibuText" | translate}}
        </div>
        <!-- Cards -->
        <div class="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-full">
          <div class="bg-cards rounded-lg p-5 w-full">
            <div>
              <img class="w-16 h-auto" src="../../assets/img/login/card1.svg" alt="">
            </div>
            <div class="mb-2 mt-4 darkgray-title text-2xl font-medium">
              {{"Digitalizate" | translate}}
            </div>
            <div class="darkgray-text font-light text-lg">
              {{"DigitalizateText" | translate}}
            </div>
          </div>
          <div class="bg-cards rounded-lg p-5 w-full">
            <div>
              <img class="w-16 h-auto" src="../../assets/img/login/card2.svg" alt="">
            </div>
            <div class="mb-2 mt-4 darkgray-title text-2xl font-medium">
              {{"TrabajoEquipo" | translate}}
            </div>
            <div class="darkgray-text font-light text-lg">
              {{"TrabajoEquipoText" | translate}}
            </div>
          </div>
          <div class="bg-cards rounded-lg p-5 w-full">
            <div>
              <img class="w-16 h-auto" src="../../assets/img/login/card3.svg" alt="">
            </div>
            <div class="mb-2 mt-4 darkgray-title text-2xl font-medium">
              {{"InfoImporta" | translate}}
            </div>
            <div class="darkgray-text font-light text-lg">
              {{"InfoImportaText" | translate}}
            </div>
          </div>
          <div class="bg-cards rounded-lg p-5 w-full">
            <div>
              <img class="w-16 h-auto" src="../../assets/img/login/card4.svg" alt="">
            </div>
            <div class="mb-2 mt-4 darkgray-title text-2xl font-medium">
              {{"FuncionesSencillas" | translate}}
            </div>
            <div class="darkgray-text font-light text-lg">
              {{"FuncionesSencillasText" | translate}}
            </div>
          </div>
        </div>
        <div class="mt-4 mb-8 w-full flex justify-center">
          <div
            class="px-5 py-2.5 text-center text-white text-lg bg-primary rounded-lg mt-4 w-fit btn-primary cursor-pointer"
            (click)="scrollToElement('demo')">
            {{ 'loginRequestDemo' | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="px-6 md:px-12 lg:px-24 py-12 bg-green-light w-full">
      <div class="w-full flex flex-col-reverse md:flex-row items-center justify-between px-12 gap-8">
        <div class="w-full flex justify-center">
          <div class="w-fit grid grid-cols-2 gap-x-24 gap-y-10" data-aos="fade-up">
            <div>
              <div #counter1 class="darkgray-title text-4xl font-semibold flex">
                <div #counterText1></div>+
              </div>
              <div class="darkgray-text2 text-lg font-light">
                {{"loginCandidates" | translate}}
              </div>
            </div>

            <div>
              <div #counter2 class="darkgray-title text-4xl font-semibold flex">
                <div #counterText2></div>+
              </div>
              <div class="darkgray-text2 text-lg font-light">
                {{"loginUsers" | translate}}
              </div>
            </div>


            <div>
              <div #counter3 class="darkgray-title text-4xl font-semibold flex">
                <div #counterText3></div>+
              </div>
              <div class="darkgray-text2 text-lg font-light">
                {{"loginCompanies" | translate}}
              </div>
            </div>


            <div>
              <div #counter4 class="darkgray-title text-4xl font-semibold flex">
                <div #counterText4></div>+
              </div>
              <div class="darkgray-text2 text-lg font-light">
                {{"loginVacancies" | translate}}
              </div>
            </div>

          </div>
        </div>
        <div data-aos="fade-left" class="stats-text w-full text-center md:text-left"
          [innerHTML]="'loginStatsText' | translate">
        </div>
      </div>
    </div>

    <div id="modules" class="w-full px-6 md:px-12 lg:px-24 py-12">
      <div data-aos="fade-right" class="flex flex-col lg:flex-row gap-8">
        <div class="w-full lg:w-5/12 pt-4 flex flex-col items-center lg:items-start">
          <div class="w-full pt-8 pb-4 green-title green-title-size text-center md:text-left">
            {{"loginAdapt" | translate}}
          </div>
          <div class="w-full mt-2 subtitle-blue font-normal text-left" [innerHTML]="'loginAdaptText' | translate">
          </div>
          <div
            class="px-5 py-2.5 text-center text-white text-lg bg-primary rounded-lg mt-6 w-fit btn-primary cursor-pointer"
            (click)="openRegisterModal()">
            {{ 'loginFreeTrial' | translate }}
          </div>
        </div>
        <div class="w-full lg:w-7/12">
          <div class="mt-8 grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
            <div class="bg-cards rounded-lg p-5 w-full">
              <div>
                <img class="w-16 h-auto" src="../../assets/img/login/card5.svg" alt="">
              </div>
              <div class="mb-2 mt-4 darkgray-title text-2xl font-medium">
                {{"DondeSea" | translate}}
              </div>
              <div class="darkgray-text font-light text-lg">
                {{"DondeSeaText" | translate}}
              </div>
            </div>
            <div class="bg-cards rounded-lg p-5 w-full">
              <div>
                <img class="w-16 h-auto" src="../../assets/img/login/card7.svg" alt="">
              </div>
              <div class="mb-2 mt-4 darkgray-title text-2xl font-medium">
                {{"TiempoReal" | translate}}
              </div>
              <div class="darkgray-text font-light text-lg">
                {{"TiempoRealText" | translate}}
              </div>
            </div>
            <div class="bg-cards rounded-lg p-5 w-full">
              <div>
                <img class="w-16 h-auto" src="../../assets/img/login/card6.svg" alt="">
              </div>
              <div class="mb-2 mt-4 darkgray-title text-2xl font-medium">
                {{"Personalizacion" | translate}}
              </div>
              <div class="darkgray-text font-light text-lg">
                {{"PersonalizacionText" | translate}}
              </div>
            </div>
            <div class="bg-cards rounded-lg p-5 w-full">
              <div>
                <img class="w-16 h-auto" src="../../assets/img/login/card8.svg" alt="">
              </div>
              <div class="mb-2 mt-4 darkgray-title text-2xl font-medium">
                {{"MultiplesVersiones" | translate}}
              </div>
              <div class="darkgray-text font-light text-lg">
                {{"MultiplesVersionesText" | translate}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="px-6 md:px-12 lg:px-24 py-12 pb-20 md:pb-12 bg-gradient-hero w-full">
      <div class="w-full pt-8 pb-4 green-title green-title-size text-center">
        {{"loginFeatures" | translate}}
      </div>
      <div data-aos="flip-left" class="max-w-3xl mx-auto mt-16 md:mt-4 relative flex items-center justify-center">
        <div>
          <img class="w-full h-auto" src="../../assets/img/login/animation/bg-guy.png"
            alt="Features">
        </div>

        <!-- IMG G Suite -->
        <div class="absolute g-suite">
          <div class="relative cont-feature">
            <div class="p-2 md:p-3 lg:p-4 bg-white rounded-full shadow-2xl img-feature">
              <img class="w-16 md:w-28 lg:w-32" src="../../assets/img/login/animation/google-suite-logo.svg"
                alt="Google Suite">
            </div>

            <!-- Gmail -->
            <div class="absolute gmail-feature img-feature-child">
              <img class="w-5 md:w-8 lg:w-10"
                src="../../assets/img/login/animation/official-gmail-icon-2020-.svg" alt="Gmail">
            </div>

            <!-- Calendar -->
            <div class="absolute calendar-feature img-feature-child">
              <img class="w-5 md:w-8 lg:w-10"
                src="../../assets/img/login/animation/google-calendar-icon-2020-.svg" alt="Calendar">
            </div>

            <!-- Drive -->
            <div class="absolute drive-feature img-feature-child">
              <img class="w-5 md:w-8 lg:w-10" src="../../assets/img/login/animation/google-drive-icon-2020.svg"
                alt="Drive">
            </div>

            <!-- Meet -->
            <div class="absolute meet-feature -top-20 -left-2 img-feature-child">
              <img class="w-5 md:w-8 lg:w-10" src="../../assets/img/login/animation/google-meet-icon-2020-.svg"
                alt="Meet">
            </div>
          </div>
        </div>

        <!-- Like -->
        <div class="absolute feature-like">
          <div class="p-1 md:p-2 bg-white rounded-full shadow-2xl img-feature">
            <img class="w-4 md:w-7 lg:w-9" src="../../assets/img/login/animation/like-icon.png"
              alt="Google Suite">
          </div>
        </div>

        <!-- Whatsapp -->
        <div class="absolute wsp-feature">
          <div class="relative cont-feature">
            <div class="p-1 bg-white rounded-full shadow-2xl img-feature">
              <img class="w-9 md:w-16 lg:w-20" src="../../assets/img/login/animation/whatsapp-3.svg"
                alt="Whatsapp">
            </div>

            <!-- Cuadro texto -->
            <div class="absolute wsp-text img-feature-child">
              <div class="bg-white p-2 md:p-4 rounded-2xl shadow-2xl text-center">
                <div class="text-gray-500 text-xs md:text-base w-28 md:w-40" [innerHTML]="'loginWhatsappFeature' | translate"></div>
              </div>
            </div>
          </div>
        </div>

        <!-- Likedin -->
        <div class="absolute linkedin-feature">
          <div class="relative cont-feature">
            <div class="p-2 lg:p-4 bg-white rounded-full shadow-2xl img-feature">
              <img class="w-8 md:w-16 lg:w-20" src="../../assets/img/login/animation/linkedin-icon.svg"
                alt="Likedin">
            </div>

            <!-- Cuadro texto -->
            <div class="absolute linkedin-text img-feature-child">
              <div class="bg-white p-2 md:p-4 rounded-2xl shadow-2xl text-center">
                <div class="text-gray-500 text-xs md:text-base w-28 md:w-40" [innerHTML]="'loginLinkedinFeature' | translate"></div>
              </div>
            </div>
          </div>
        </div>

        <!-- Microsoft -->
        <div class="absolute microsoft-suite">
          <div class="relative cont-feature">
            <div class="p-2 md:p-3 lg:p-4 bg-white rounded-xl lg:rounded-full shadow-2xl img-feature">
              <img class="w-20 md:w-36 lg:w-40" src="../../assets/img/login/animation/microsoft-6.svg"
                alt="Microsoft">
            </div>

            <!-- Cuadro texto -->
            <div class="absolute microsoft-text img-feature-child">
              <div class="bg-white p-2 md:p-4 rounded-2xl shadow-2xl text-center">
                <div class="text-gray-500 text-xs md:text-base w-24 md:w-48" [innerHTML]="'loginMicrosoftFeature' | translate"></div>
              </div>
            </div>

            <!-- Calendar -->
            <div class="absolute micro-calendar img-feature-child">
              <div class="bg-white p-2 md:p-3 rounded-full shadow-2xl">
                <img class="w-5 md:w-8 lg:w-10"
                  src="../../assets/img/login/animation/icons8-outlook-calendar.svg" alt="Calendar">
              </div>
            </div>

            <!-- Outlook -->
            <div class="absolute outlook-feature img-feature-child">
              <div class="bg-white p-2 md:p-3 rounded-full shadow-2xl">
                <img class="w-5 md:w-8 lg:w-10" src="../../assets/img/login/animation/outlook-icon.svg"
                  alt="Outlook">
              </div>
            </div>
          </div>
        </div>

        <!-- Haibu Jobs -->
        <div class="absolute jobs-feature">
          <div class="relative cont-feature">
            <div class="p-1 md:p-2 lg:p-4 bg-white rounded-full shadow-2xl img-feature overflow-hidden">
              <img class="w-9 md:w-16 lg:w-20" src="../../assets/img/login/animation/haibu-jobs.png"
                alt="Haibu Jobs">
            </div>

            <!-- Cuadro texto -->
            <div class="absolute linkedin-text img-feature-child">
              <div class="bg-white p-2 md:p-4 rounded-2xl shadow-2xl text-center">
                <div class="text-gray-500 text-xs md:text-base w-28 md:w-40" [innerHTML]="'loginJobsFeature' | translate"></div>
              </div>
            </div>
          </div>
        </div>

        <!-- Bell -->
        <div class="absolute feature-bell">
          <div class="p-2 bg-white rounded-full shadow-2xl img-feature">
            <img class="w-4 md:w-7 lg:w-9" src="../../assets/img/login/animation/bell.png"
              alt="Bell">
          </div>
        </div>

        <!-- Chart -->
        <div class="absolute feature-chart">
          <div class="p-2 bg-white rounded-full shadow-2xl img-feature">
            <img class="w-10 md:w-14 lg:w-20" src="../../assets/img/login/animation/chart.png"
              alt="Chart">
          </div>
        </div>

        <!-- Share -->
        <div class="absolute feature-share">
          <div class="p-2 bg-white rounded-full shadow-2xl img-feature">
            <img class="w-4 md:w-7 lg:w-9" src="../../assets/img/login/animation/share.png"
              alt="Share">
          </div>
        </div>
      </div>
    </div>

    <div class="px-6 md:px-12 lg:px-24 py-12 bg-white w-full">
      <a name="price"></a>
      <div id="price" class="px-5 md:px-8">
        <div class="pt-5 pb-6">
          <div class="green-title green-title-size text-center">
            {{"loginOneUser3" | translate}}
          </div>
        </div>
        <div>
          <div class="subtitle-blue font-normal text-center" [innerHTML]="'loginOneUserPrice3' | translate">
          </div>
        </div>
        <div class="my-8 flex justify-center">
          <div class="flex items-center text-center rounded-lg border border-primary bg-primary overflow-hidden">
            <div
              class="py-1.5 px-6 w-36 text-white bg-primary text-lg font-normal rounded-md transition-all duration-300 h-full cursor-pointer hover:bg-yellow-700"
              [ngClass]="{'bg-white text-primary': subscriptionType === 'mensual'}"
              (click)="setSubscriptionType('mensual')">
              {{ 'mensual' | translate }}
            </div>
            <div
              class="py-1.5 px-6 w-36 text-white font-normal bg-primary text-lg rounded-md transition-all duration-300 h-full cursor-pointer hover:bg-yellow-700"
              [ngClass]="{'bg-white text-primary': subscriptionType === 'semestral'}"
              (click)="setSubscriptionType('semestral')">
              {{ 'semestral' | translate }}
            </div>
          </div>
        </div>
        <div class="multicompany w-full flex justify-center">
          <mat-slide-toggle id="multicompany" [(ngModel)]="multicompany" color="primary" class="flex items-center">
            <span class="text-gray-700 font-medium text-base">{{
              'loginMulticompany' | translate }}</span>
          </mat-slide-toggle>
        </div>
      </div>
    </div>

    <div class="px-6 md:px-12 lg:px-24 pb-12 bg-green-light w-full">
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-4 -mt-4 justify-center">
        <div data-aos="fade-up" data-aos-duration="1000"
          class="w-full bg-white rounded-lg border border-bluecard p-2 shadow-2xl">
          <div class="bg-bluecard w-full rounded-md py-2 text-center tracking-widest uppercase">
            ENTRY <span *ngIf="multicompany">{{ 'loginMulticompany2' | translate }}</span>
          </div>

          <div class="w-full h-full flex flex-col flex-grow -mt-9">
            <div class="w-full max-w-lg mx-auto pt-16 pb-4">
              <div class="w-full max-w-lg mx-auto">
                <div *ngIf="subscriptionType === 'semestral'" class="flex justify-center mt-2 mb-5">
                  <div class="price-text2 text-secondary"> {{ (multicompany ? 'price.2.canceled' : 'price.1.canceled') |
                    translate }}</div>
                  <div class="bycompany-text2 ml-1 text-secondary">MXN*</div>
                </div>
                <div class="w-full max-w-lg mx-auto pb-2">
                  <p *ngIf="subscriptionType === 'semestral'"
                    class="text-center mb-6 pb-2 text-base gray-prices font-light">
                    {{ 'byCompany.' + subscriptionType | translate }}
                  </p>
                </div>
                <div class="flex justify-center mb-2 pb-2">
                  <div class="price-text gray-title"> {{ (multicompany ? 'price.2.' : 'price.1.') + subscriptionType |
                    translate }}</div>
                  <div class="bycompany-text gray-title ml-2">MXN*</div>
                </div>

                <div class="w-full max-w-lg mx-auto pt-4">
                  <p class="text-center bycompany-text gray-prices2">
                    {{ 'mensual' | translate }}
                  </p>
                </div>
              </div>
            </div>

            <div class="w-full h-full flex items-end">
              <div class="w-full h-auto">
                <div class="border-b border-bluecard w-full max-w-lg mx-auto pb-2">
                </div>

                <div class="w-full">
                  <!-- Botón o acción -->
                  <div class="mt-4">
                    <div
                      class="px-5 py-2.5 text-center border border-primary text-lg rounded-lg mt-4 w-full btn-secondary cursor-pointer"
                      (click)="!multicompany ? openRegisterModal(false, 'Entry') : openRegisterModal(false, 'Head Hunter')">
                      {{ 'callToAction.' + subscriptionType | translate }}
                    </div>
                  </div>
                  <p class="text-center align-bottom pt-5 text-base gray-prices font-light">
                    {{ 'loginNoCreditCard'| translate }}
                  </p>
                  <p class="text-center align-bottom text-sm font-semibold gray-prices">
                    *{{ 'loginNoTax'| translate }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" data-aos-duration="1500"
          class="w-full bg-white rounded-lg border border-bluecard2 p-2 shadow-2xl">
          <div class="bg-bluecard2 w-full rounded-md py-2 text-center tracking-widest uppercase">
            MID <span *ngIf="multicompany">{{ 'loginMulticompany2' | translate }}</span>
          </div>

          <div class="w-full h-full flex flex-col flex-grow -mt-9">
            <div class="w-full max-w-lg mx-auto pt-16 pb-4">
              <div *ngIf="subscriptionType === 'semestral'" class="flex justify-center mt-2 mb-5">
                <div class="price-text2 text-secondary"> {{ (multicompany ? 'price.4.canceled' : 'price.3.canceled') |
                  translate }}</div>
                <div class="bycompany-text2 ml-1 text-secondary">MXN*</div>
              </div>
              <div class="w-full max-w-lg mx-auto pb-2">
                <p *ngIf="subscriptionType === 'semestral'"
                  class="text-center mb-6 pb-2 text-base gray-prices font-light">
                  {{ 'byCompany.' + subscriptionType | translate }}
                </p>
              </div>
              <div class="flex justify-center mb-2 pb-2">
                <div class="price-text gray-title"> {{ (multicompany ? 'price.4.' : 'price.3.') + subscriptionType |
                  translate }}</div>
                <div class="bycompany-text gray-title ml-2">MXN*</div>
              </div>

              <div class="w-full max-w-lg mx-auto pt-4">
                <p class="text-center bycompany-text gray-prices2">
                  {{ 'mensual' | translate }}
                </p>
              </div>
            </div>

            <div class="w-full h-full flex items-end">
              <div class="w-full h-auto">
                <div class="border-b border-bluecard2 w-full max-w-lg mx-auto pb-2">
                </div>

                <div class="w-full">
                  <!-- Botón o acción -->
                  <div class="mt-4">
                    <div
                      class="px-5 py-2.5 text-center border border-primary text-lg rounded-lg mt-4 w-full btn-secondary cursor-pointer"
                      (click)="!multicompany ? openRegisterModal(false, 'Mid') : openRegisterModal(false, 'Mid + Head Hunter')">
                      {{ 'callToAction.' + subscriptionType | translate }}
                    </div>
                  </div>
                  <p class="text-center align-bottom pt-5 text-base gray-prices font-light">
                    {{ 'loginNoCreditCard'| translate }}
                  </p>
                  <p class="text-center align-bottom text-sm font-semibold gray-prices">
                    *{{ 'loginNoTax'| translate }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" data-aos-duration="2000"
          class="w-full bg-white rounded-lg border border-sand p-2 shadow-2xl">
          <div class="bg-sand w-full rounded-md py-2 text-center tracking-widest">
            ENTERPRISE
          </div>

          <div class="ww-full h-full flex flex-col flex-grow -mt-9">
            <div class="w-full max-w-lg mx-auto pt-16 pb-4">
              <div *ngIf="subscriptionType === 'semestral'" class="flex justify-center mt-2 mb-5">
                <div class="price-text2 text-secondary">{{ 'personalizedPlan' | translate }}</div>
              </div>
              <div class="flex justify-center mb-2 pb-2">
                <div class="gray-title contact-price-text text-center"> {{ 'price.5.' + subscriptionType | translate }}
                </div>
              </div>
            </div>

            <div class="w-full h-full flex items-end">
              <div class="w-full h-auto">
                <div class="border-b border-sand w-full max-w-lg mx-auto pb-2">
                </div>

                <div class="w-full">
                  <!-- Botón o acción -->
                  <div class="mt-4">
                    <a #contactMenuTrigger id="subscriptionUrl" [matMenuTriggerFor]="contactMenu" target="_blank">
                      <div
                        class="px-5 py-2.5 text-center border border-primary text-lg rounded-lg mt-4 w-full btn-secondary cursor-pointer">
                        {{ 'loginContacta' | translate }}
                      </div>
                    </a>

                    <mat-menu #contactMenu="matMenu" class="custom-menu-login" mat-stretch-trigger>
                      <button mat-menu-item (click)="openWhatsApp()">
                        <div class="flex items-center gap-2">
                          <img [src]="whatsappImageGray" alt="Link" class="h-5 w-5">
                          <span>{{ 'loginContactWsp'| translate }}</span>
                        </div>
                      </button>
                      <button mat-menu-item (click)="scheduleCall()">
                        <div class="flex items-center gap-2">
                          <img [src]="smartPhoneImageGray" alt="Link" class="h-5 w-5">
                          <span>{{ 'loginContactSchedule'| translate }}</span>
                        </div>
                      </button>
                    </mat-menu>
                  </div>
                  <p class="text-center align-bottom pt-5 text-base gray-prices font-light">
                    {{ 'loginNoCreditCard'| translate }}
                  </p>
                  <p class="text-center align-bottom text-sm font-semibold gray-prices">
                    *{{ 'loginNoTax'| translate }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-full mt-8 subtitle-blue2 font-normal text-center" [innerHTML]="'loginIntroPrices' | translate">
      </div>
    </div>

    <div class="px-0 md:px-12 lg:px-24 py-12 bg-green-light w-full flex justify-center">
      <div class="bg-white px-6 md:px-16 py-10 rounded-xl shadow-2xl max-w-full">
        <div class="mb-12 mt-8">
          <div class="w-full green-title green-title-size text-center">
            {{"loginPlansCompare" | translate}}
          </div>
        </div>
        <div class="flex md:hidden w-full justify-end mb-4 -mt-4">
          <div
            class="px-3 py-1 text-center text-gray-500 text-sm table-bg btn-table-bg rounded-lg mt-4 w-fit cursor-pointer flex items-center gap-2"
            (click)="animateScrollHint()">
            Ver más
            <mat-icon>arrow_forward</mat-icon>
          </div>
        </div>
        <div class="w-full overflow-hidden flex justify-start lg:justify-center">
          <div #scrollContainer class="overflow-x-auto">
            <table class="min-w-full">
              <thead>
                <tr>
                  <th
                    class="px-4 py-2 text-center font-normal border-b border-gray-200 tracking-widest tex plan-text sticky -left-2 bg-white">
                    DESCRIPCIÓN
                  </th>
                  <th class="px-4 py-2 text-center font-bold border-b border-gray-200 tracking-widest tex plan-label">
                    ENTRY</th>
                  <th
                    class="px-4 py-2 text-center font-bold border-b border-gray-200 tracking-widest tex plan-label uppercase">
                    ENTRY {{ 'loginMulticompany3' | translate }}
                  </th>
                  <th class="px-4 py-2 text-center font-bold border-b border-gray-200 tracking-widest tex plan-label">
                    MID</th>
                  <th
                    class="px-4 py-2 text-center font-bold border-b border-gray-200 tracking-widest tex plan-label uppercase">
                    MID {{ 'loginMulticompany3' | translate }}
                  </th>
                  <th class="px-4 py-2 text-center font-bold border-b border-gray-200 tracking-widest tex plan-label">
                    ENTERPRISE</th>
                </tr>
              </thead>
              <tbody>
                <tr class="border-t border-b border-gray-200" [class.table-bg]="i % 2 === 0"
                  *ngFor="let feature of dataSource; let i = index">
                  <td
                    class="px-4 py-1 border-t border-b border-gray-200 font-medium gray-prices text-base sticky -left-2"
                    [class.table-bg]="i % 2 === 0" [class.bg-white]="i % 2 !== 0">
                    {{ feature.name | translate }}
                  </td>
                  <td class="px-4 py-1 text-center border-t border-b border-gray-200 font-medium gray-prices">
                    <span data-aos="zoom-in" *ngIf="feature.entry" class="dot1 text-2xl">&#9679;</span>
                  </td>
                  <td class="px-4 py-1 text-center border-t border-b border-gray-200 font-medium gray-prices">
                    <span data-aos="zoom-in" *ngIf="feature.entryHH" class="dot2 text-2xl">&#9679;</span>
                  </td>
                  <td class="px-4 py-1 text-center border-t border-b border-gray-200 font-medium gray-prices">
                    <span data-aos="zoom-in" *ngIf="feature.mid" class="dot3 text-2xl">&#9679;</span>
                  </td>
                  <td class="px-4 py-1 text-center border-t border-b border-gray-200 font-medium gray-prices">
                    <span data-aos="zoom-in" *ngIf="feature.midHH" class="dot4 text-2xl">&#9679;</span>
                  </td>
                  <td class="px-4 py-1 text-center border-t border-b border-gray-200 font-medium gray-prices">
                    <span data-aos="zoom-in" *ngIf="feature.enterprise" class="dot5 text-2xl">&#9679;</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="flex items-center justify-between mt-4">
          <div class="text-xs text-gray-400 text-left w-full">*{{ 'adviceFeatures' | translate }}</div>
          <div class="flex md:hidden w-full justify-end items-center">
            <div
              class="px-3 py-1 text-center text-gray-500 text-sm table-bg btn-table-bg rounded-lg w-fit cursor-pointer flex items-center gap-2"
              (click)="animateScrollHint()">
              Ver más
              <mat-icon>arrow_forward</mat-icon>
            </div>
          </div>
        </div>

        <div class="w-full">
          <div
            class="mx-auto px-5 py-2.5 text-center text-white text-lg bg-primary rounded-lg mt-6 w-fit btn-primary cursor-pointer"
            (click)="openRegisterModal()">
            {{ 'loginFreeTrial' | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="px-6 md:px-12 lg:px-24 py-4 bg-image w-full">
      <div class="bg-blur p-5 w-fit rounded-xl text-white text-lg text-center mx-auto md:mx-0">
        <div [innerHTML]="'knowHaibu' | translate"></div>

        <a href="https://haibujobs.com/" target="_blank"
          class="mx-auto px-5 py-2.5 text-center text-white text-lg bg-primary rounded-lg mt-4 w-fit btn-primary cursor-pointer block">
          {{ 'knowHaibuBtn' | translate }}
        </a>
      </div>
    </div>
  </div>

  <div class="px-6 md:px-12 lg:px-24 background-green pt-16 justify-center items-center w-full">
    <div id="demo"
      class="py-16 bg-white rounded-xl p-6 shadow-md w-full flex items-start justify-center gap-6 flex-col md:flex-row -mb-6">
      <div class="w-full md:w-5/12 md:px-6">
        <div class="subtitle-gray text-center md:text-left pt-4 uppercase tracking-widest">
          {{"loginAskForADemo" | translate}}
        </div>
        <div class="benefits-text text-center md:text-left pt-4 pb-4">
          {{"loginWantAllBenefits" | translate}}
        </div>
        <div class="text-form text-form-gray text-sm text-center md:text-left"
          [innerHTML]="'loginFillAllForm' | translate">
        </div>

        <a [href]="getSuscriptionUrl(5)" target="_blank"
          class="mx-auto md:mx-0 px-5 py-2.5 text-center text-white text-base bg-primary rounded-lg mt-4 w-fit btn-primary cursor-pointer block">
          {{ 'wantACall' | translate }}
        </a>
      </div>
      <div class="w-full md:w-7/12">
        <div class="text-form text-form-gray text-sm mb-3" [innerHTML]="'loginFillAllForm2' | translate">
        </div>
        <form [formGroup]="demoForm" class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full form-login">
          <div class="pb-3">
            <span class="block text-sm font-medium text-gray-600 mb-1 inter">
              {{ 'loginAskDemoName' | translate }}
            </span>
            <mat-form-field appearance="outline" class="w-full border-radius" floatLabel="always">
              <input #nameInput id="loginNameInput" maxlength="40" formControlName="names" matInput type="text"
                class="validate w-full border-radius rounded-lg" [placeholder]="'loginAskDemoNamePlace' | translate"
                required oninput="this.value = this.value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();">
            </mat-form-field>
            <div *ngIf="demoForm.get('names').invalid && (demoForm.get('names').dirty || demoForm.get('names').touched)"
              class="text-red-500 text-xs mt-3 -mb-6">
              <span *ngIf="demoForm.get('names').errors['required']">
                {{ 'hasWhiteSpaceKeyForm' | translate: { key: 'loginAskDemoName' | translate } }}
              </span>
              <span *ngIf="demoForm.get('names').errors['noSpecialCharacters']">
                {{ 'invalidCharactersKeyForm' | translate: { key: 'loginAskDemoName' | translate } }}
              </span>
            </div>
          </div>

          <div class="pb-3">
            <span class="block text-sm font-medium text-gray-600 mb-1 inter">
              {{ 'loginAskDemoLastName' | translate }}
            </span>
            <mat-form-field appearance="outline" class="w-full">
              <input id="loginLastNameInput" maxlength="40" formControlName="lastnames" matInput type="text"
                class="validate w-full" [placeholder]="'loginAskDemoLastNamePlace' | translate" required
                oninput="this.value = this.value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();">
            </mat-form-field>
            <div
              *ngIf="demoForm.get('lastnames').invalid && (demoForm.get('lastnames').dirty || demoForm.get('lastnames').touched)"
              class="text-red-500 text-xs mt-3 -mb-6">
              <span *ngIf="demoForm.get('lastnames').errors['required']">
                {{ 'hasWhiteSpaceKeyForm' | translate: { key: 'loginAskDemoLastName' | translate } }}
              </span>
              <span *ngIf="demoForm.get('lastnames').errors['noSpecialCharacters']">
                {{ 'invalidCharactersKeyForm' | translate: { key: 'loginAskDemoLastName' | translate } }}
              </span>
            </div>
          </div>

          <div class="pb-3">
            <span class="block text-sm font-medium text-gray-600 mb-1 inter">
              {{ 'loginAskDemoEmail' | translate }}
            </span>
            <mat-form-field appearance="outline" class="w-full">
              <input id="loginEmailInput" maxlength="80" formControlName="email" matInput type="text" class="validate"
                [placeholder]="'loginAskDemoEmailPlace' | translate" required oninput="this.value = this.value.trim();">
            </mat-form-field>
            <div *ngIf="demoForm.get('email').invalid && (demoForm.get('email').dirty || demoForm.get('email').touched)"
              class="text-red-500 text-xs mt-3 -mb-6">
              <span *ngIf="demoForm.get('email').errors['required']">{{ 'hasWhiteSpaceKeyForm' | translate: { key:
                'loginAskDemoEmail' | translate } }}</span>
              <span
                *ngIf="!demoForm.get('email').errors['required'] && (demoForm.get('email').errors['email'] || demoForm.get('email').errors['isEmailValid'])">{{
                'emailMassiveLinkValid' | translate }}</span>
            </div>
          </div>

          <div class="pb-3">
            <span class="block text-sm font-medium text-gray-600 mb-1 inter">
              {{ 'loginAskDemoPhone' | translate }}
            </span>
            <mat-form-field appearance="outline" class="w-full">
              <input id="loginCellPhoneInput" maxlength="10" minlength="10"
                (keypress)="($event.charCode >= 48 && $event.charCode < 58)" formControlName="phone" matInput
                type="text" class="validate" [placeholder]="'loginAskDemoPhonePlace' | translate" required
                oninput="this.value = this.value.replace(/[^0-9]/g, '').trim();">
            </mat-form-field>
            <div *ngIf="demoForm.get('phone').invalid && (demoForm.get('phone').dirty || demoForm.get('phone').touched)"
              class="text-red-500 text-xs mt-3 -mb-6">
              <span *ngIf="demoForm.get('phone').errors['required']">
                {{ 'hasWhiteSpaceKeyForm' | translate: { key: 'loginAskDemoPhone' | translate } }}
              </span>
              <span *ngIf="!demoForm.get('phone').errors['minlength'] && demoForm.get('phone').errors['isPhoneValid']">
                {{ 'phoneNumberInvalid' | translate }}
              </span>
              <span *ngIf="demoForm.get('phone').errors['minlength']">
                {{ 'invalidAtLeastLengthKeyForm' | translate: { key: 'loginAskDemoPhone' | translate, number: 10 }
                }}
              </span>
            </div>
          </div>

          <div class="pb-3">
            <span class="block text-sm font-medium text-gray-600 mb-1 inter">
              {{ 'loginAskDemoCompany' | translate }}
            </span>
            <mat-form-field appearance="outline" class="w-full">
              <input id="loginBussinesInput" maxlength="100" formControlName="company" matInput type="text"
                class="validate" [placeholder]="'loginAskDemoCompanyPlace' | translate" required
                oninput="this.value = this.value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();">
            </mat-form-field>
            <div
              *ngIf="demoForm.get('company').invalid && (demoForm.get('company').dirty || demoForm.get('company').touched)"
              class="text-red-500 text-xs mt-3 -mb-6">
              <span *ngIf="demoForm.get('company').errors['required']">
                {{ 'hasWhiteSpaceKeyForm' | translate: { key: 'loginAskDemoCompany' | translate } }}
              </span>
              <span *ngIf="demoForm.get('company').errors['noSpecialCharacters']">
                {{ 'invalidCharactersKeyForm' | translate: { key: 'loginAskDemoCompany' | translate } }}
              </span>
            </div>
          </div>

          <div class="pb-3">
            <span class="block text-sm font-medium text-gray-600 mb-1 inter">
              {{ 'loginAskCountNumber' | translate }}
            </span>
            <mat-form-field appearance="outline" class="w-full logindepaddme">
              <mat-select id="loginNumberEmployeesSelect" formControlName="userLimit"
                [placeholder]="'loginAskCountNumberPlace' | translate" required>
                <mat-option *ngFor="let userLimit of userLimits; let i = index" [value]="userLimit.usrlD_DESCRIPTION">
                  {{ userLimit.usrlD_DESCRIPTION }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="demoForm.get('userLimit').invalid && (demoForm.get('userLimit').dirty || demoForm.get('userLimit').touched)"
              class="text-red-500 text-xs mt-4 -mb-6">
              <span *ngIf="demoForm.get('userLimit').errors['required']">
                {{ 'hasWhiteSpaceKeyForm' | translate: { key: 'loginAskCountNumber' | translate } }}
              </span>
            </div>
          </div>

          <div class="pb-3 col-span-1 md:col-span-2">
            <span class="block text-sm font-medium text-gray-600 mb-1 inter">
              {{ 'loginAskCountry' | translate }}
            </span>
            <mat-form-field appearance="outline" class="w-full logindepaddme">
              <mat-select id="loginCountrySelect" formControlName="country"
                [placeholder]="'loginAskCountryPlace' | translate" required>
                <mat-option *ngFor="let country of countries; let i = index" [value]="country.ctrD_NAME">
                  {{ country.ctrD_NAME }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="demoForm.get('country').invalid && (demoForm.get('country').dirty || demoForm.get('country').touched)"
              class="text-red-500 text-xs mt-4 -mb-4">
              <span *ngIf="demoForm.get('country').errors['required']">
                {{ 'hasWhiteSpaceKeyForm' | translate: { key: 'loginAskCountry' | translate } }}
              </span>
            </div>
          </div>

          <div class="pt-3 col-span-1 md:col-span-2">
            <re-captcha #captchaComponent (resolved)="resolved($event)" name="captcha"
              siteKey="6LdhgoYpAAAAAKlDW2aRdu6nH_Gmk0KTTU7tcoMr"></re-captcha>
          </div>

          <div class="py-2 col-span-1 md:col-span-2">
            <input id="termsAndConditionsAccepted" formControlName="terms" type="checkbox" id="terms" name="terms"
              required>
            <label for="terms">
              {{ "loginAskAcept" | translate }}
              <a class="cursor-pointer color-haibu" href="/terms" target="_blank">{{ "loginTermsAndCondition" |
                translate }}</a>
            </label>
            <br>
            <mat-error class="error-label" *ngIf="demoForm.controls.terms.invalid && demoForm.controls.terms.touched">
              {{ 'mustAcceptTerms' | translate }}
            </mat-error>
          </div>
        </form>

        <div id="loginWantACall"
          class="px-5 py-2.5 text-center text-white text-lg bg-primary rounded-lg mt-4 w-fit btn-primary cursor-pointer"
          (click)="sendMail()">
          {{ 'loginSentEmail' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div
    class="text-black pt-12 pb-6 flex flex-col justify-center items-center md:flex-row gap-x-4 gap-y-2 text-sm font-normal px-6 md:px-12 lg:px-24 text-center">
    <span>Haibu HR, S.A.P.I. de C.V. Calle 44 #48, Campestre, Mérida, Yucatán, 97120.</span>
    <a class="text-primary" href="mailto:contacto@haibuhr.com">contacto&#64;haibuhr.com</a>
  </div>

  <div class="w-full flex flex-col md:flex-row justify-between items-center px-6 md:px-12 lg:px-24 bg-drak-green py-3">
    <div class="flex flex-col md:flex-row justify-start gap-3 gap-x-8">
      <div class="text-white">
        © 2024 Haibu. {{ 'rightsReserved' | translate }}
      </div>

      <div class="flex justify-center">
        <a id="loginPrivacyAdvice" href="/notice" target="_blank" class="color-haibu uppercase tracking-widest">
          {{"loginPrivacyAdvice" | translate}}
        </a>
      </div>
    </div>
    <div class="flex justify-start gap-2.5 items-center">
      <a id="linkedinIcon" class="pt-2 pb-2" href="https://www.linkedin.com/company/haibuhr/" target="_blank">
        <img class="h-7 w-7" [src]="linkedinIcon" alt="">
      </a>
      <a id="fbIcon" class="pt-2 pb-2" href="https://www.facebook.com/haibu.hr" target="_blank">
        <img class="h-7 w-7" [src]="fbIcon" alt="">
      </a>
      <a id="igIcon" class="pt-2 pb-2" href="https://www.instagram.com/haibu.hr/" target="_blank">
        <img class="h-7 w-7" [src]="igIcon" alt="">
      </a>
      <a id="ytIcon" class="pt-2 pb-2" href="https://www.youtube.com/@HaibuHR" target="_blank">
        <img class="h-7 w-7" [src]="ytIcon" alt="">
      </a>
    </div>
  </div>
</div>
import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, SecurityContext, ViewChild, } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PuzzleService } from '../../services/puzzle.service';
import { Country } from '../../interfaces/country';
import { ctR_STATES } from '../../interfaces/ctrstate';
import { stS_CITIES } from '../../interfaces/stscity';
import { LocalService } from '../../services/local.service';
import { APIResponse } from '../../interfaces/response';
import { FormGroup, NgForm, } from '@angular/forms';
import { Candidate } from 'src/app/interfaces/Candidate';
import { Gender } from 'src/app/interfaces/Gender';
import { Job } from 'src/app/interfaces/job';
import { Department } from 'src/app/interfaces/department';
import { Branch } from 'src/app/interfaces/branch';
import { comP_ADDRESS_ID_INFO } from 'src/app/interfaces/com-p-address-id-info';
import { DocumentationTypeInfo } from 'src/app/interfaces/DocumentationTypeInfo';
import { CandidateDocument } from 'src/app/interfaces/CandidateDocument';
import { constants } from 'src/app/services/constants';
import { DocuViewerComponent } from 'src/app/dialogs/docu-viewer/docu-viewer.component';
import { CurrencyPipe, DatePipe, Location } from '@angular/common';
import { SwUpdate } from '@angular/service-worker';
import { NewEvaluationComponent } from '../../dialogs/new-evaluation/new-evaluation.component';
import { ResponseSmallDialogComponent } from '../../dialogs/response-small-dialog/response-small-dialog.component';
import { CandidateBassetDocument } from '../../interfaces/candidate-basset-document';
import { Observable, forkJoin } from 'rxjs';
import { ResponseListDialogComponent } from '../../dialogs/response-list-dialog/response-list-dialog.component';
import { of } from 'rxjs';
import { ResponseAnchorDialogComponent } from '../../dialogs/response-anchor-dialog/response-anchor-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { Vacancy } from 'src/app/interfaces/vacancy';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { adR_STATE_ID_INFO } from 'src/app/interfaces/adr-state-id-info';
import { MatSelectChange } from '@angular/material/select';
import { environment } from 'src/environments/environment';
import { MessagerChatComponent } from 'src/app/dialogs/messager-chat/messager-chat.component';
import { ConfirmDialogComponent } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { UpdateCandidateStaPro } from 'src/app/interfaces/UpdateCandidateStaPro';
import { ChangeStatusComponent } from 'src/app/dialogs/change-status/change-status.component';
import { CandidateCommentsSummary } from 'src/app/interfaces/candidate-comments-summary';
import { User } from 'src/app/interfaces/user';
import { Source } from 'src/app/interfaces/source';
import { ImageCropDialogComponent } from 'src/app/dialogs/image-crop-dialog/image-crop-dialog.component';
import { MultiPhotoDialogComponent } from 'src/app/dialogs/multi-photo-dialog/multi-photo-dialog.component';
import { QuestionnaireViewerComponent } from 'src/app/dialogs/questionnaire-viewer/questionnaire-viewer.component';
import { DuplicateCandidateComponent } from 'src/app/dialogs/duplicate-candidate/duplicate-candidate.component';
import { FreemiumDialogComponent } from 'src/app/dialogs/freemium-dialog/freemium-dialog.component';

@Component({
  selector: 'app-candidate-detail',
  templateUrl: './candidate-detail.component.html',
  styleUrls: ['./candidate-detail.component.css'],
})
export class CandidateDetailComponent implements OnInit {

  @ViewChild('wizardContainer', { static: false }) wizardContainer: ElementRef;
  @ViewChild('wizardContainerAdress', { static: false }) wizardContainerAdress: ElementRef;
  @ViewChild('wizardContainerGenerapOptional', { static: false }) wizardContainerGenerapOptional: ElementRef;
  @ViewChild('wizardContainerDigitalExpedient', { static: false }) wizardContainerDigitalExpedient: ElementRef;
  @ViewChild('wizardContainerEvaluations', { static: false }) wizardContainerEvaluations: ElementRef;
  @ViewChild('select1') select1: ElementRef;
  @ViewChild('select2') select2: ElementRef;
  @ViewChild('select3') select3: ElementRef;
  @ViewChild('select4') select4: ElementRef;
  @ViewChild('select5') select5: ElementRef;
  @ViewChild('inputFileLogo') inputFileLogo: ElementRef;
  minDate = '1940-01-01';
  maxDate = new Date();
  isWizardContainerExpanded: boolean = true;
  isWizardContainerExpandedAdress: boolean = false;
  isWizardContainerGeneralOptionalExpanded: boolean = false;
  isWizardContainerDigitalExpedientExpanded: boolean = false;
  isWizardContainerEvaluationsExpanded: boolean = false;
  newCandidate: boolean = false;
  disabledBasset: boolean = false;
  isPhoneNumberOneValid: boolean = true;
  isPhoneNumberTwoValid: boolean = true;
  id: string;
  candidate$: Observable<Candidate>;
  basset: string;
  iduser: string;
  formattedSalary: string = '';
  actualSalary: number = 0;
  token: APIResponse = this.localService.getJsonValue('token');
  userPermissions: string[] = [];
  countries: Country;
  states: ctR_STATES[];
  cities: stS_CITIES[];
  states2: ctR_STATES[];
  cities2: stS_CITIES[];
  genders: Gender[];
  jobs: Job[];
  departments: Department[];
  branchs: Branch[];
  dataSource: Vacancy[] = [];
  vacancy: Vacancy[] = [];
  innerDocs: DocumentationTypeInfo[];
  customstatesandcities: boolean = false;
  edit: boolean = false;
  candidate: Candidate;
  editedCandidate: Candidate;
  candidateBassetDocument: CandidateBassetDocument[];
  companyID: string = this.localService.getJsonValue('token').data.companyId;
  canYouBasset: boolean = false;
  newEvaluationDialogResponse: any;
  selectedVacancyId: string;
  selectedVacancy: any;
  showevaluations = false;
  showdocuments = false;
  innerDocuments: CandidateDocument[] = [];
  innerEvaluations: CandidateDocument[] = [];
  availableInnerDocs: any;
  formdocs = new FormData();
  formevals = new FormData();
  indexdocs = 0;
  indexevals = 0;
  totalindex = 0;
  documentsFormsList = [];
  shouldShowFinalAlert: boolean = false;
  loading: MatDialogRef<ResponseSmallDialogComponent>;
  formData = new FormData();
  sending = false;
  selectedInnerDoc: DocumentationTypeInfo;
  fileloader: HTMLElement;
  selectedCity: stS_CITIES;
  selectedState: adR_STATE_ID_INFO;
  selectedBirthCity: stS_CITIES;
  selectedBirthState: adR_STATE_ID_INFO;
  selectedGender: string;
  selectedSource: string;
  sources: Source[];
  isVacancyInvalid: boolean = false;
  isGenderInvalid: boolean = false;
  isPhoneOneInvalid: boolean = false;
  isPhoneTwoInvalid: boolean = false;
  vacancyForm: FormGroup;
  fileloader2: HTMLElement;
  formattedVacancies: string[] = [];
  selectedButton: string = 'button1';
  userLocal: APIResponse = this.localService.getJsonValue('token');
  editMode = false;
  userData: User = {} as User;
  currentLanguage: string;
  url: string = environment.apiUrlUnsafe;
  noPermittedFiles = false;
  noPermittedFilesArray = [];
  userImageId;
  userImage;
  userImageEdit;
  imageFile;
  studyLevels: any;
  yearsOfExperience: any;
  selectedStudyLevel: any;
  selectedYearsOfExperience: any;
  isStudyLevelInvalid: boolean = false;
  isYearsOfExperienceInvalid: boolean = false;
  questionnaireResults: any;
  isMini: boolean = false;
  isAgency: boolean = false;
  isFreemium: boolean = false;
  isHaibuJobs: boolean = false;
  duplicateDialogRef: any;
  dragging: boolean = false;
  backIcon: SafeResourceUrl = '../../../assets/icons/arrow-back-icon.svg';
  expandIcon: SafeResourceUrl = '../../../assets/icons/expand-more-icon.svg';
  expedientImage: SafeResourceUrl = '../../../assets/icons/expedient-not-found-icon.svg';
  deleteIcon: SafeResourceUrl = '../../../assets/icons/delete-orange-icon.svg';
  fileIcon: SafeResourceUrl = '../../../assets/icons/file-icon.svg';
  testIcon: SafeResourceUrl = '../../../assets/icons/psychology_alt.svg';
  mailIcon: SafeResourceUrl = '../../../assets/icons/mail-icon.svg';
  phoneIcon: SafeResourceUrl = '../../../assets/icons/smartphone-candidate-icon.svg';
  editIcon: SafeResourceUrl = '../../../assets/icons/edit-icon.svg';
  chatIcon: SafeResourceUrl = '../../../assets/icons/chat-icon.svg';
  cvLogo: SafeResourceUrl = '../../../assets/icons/cv-icon.svg';
  nocvLogo: SafeResourceUrl = '../../../assets/icons/noCV-icon.svg';
  moreIcon: SafeResourceUrl = '../../../assets/icons/more-icon.svg';
  linkIcon: SafeResourceUrl = '../../../assets/icons/link-icon.svg';
  linkCreatedIcon: SafeResourceUrl = '../../../assets/icons/link-created-icon.svg';
  downloadExpedientIcon: SafeResourceUrl = '../../../assets/icons/expedient-icon.svg';
  downloadExpedientIconPdf: SafeResourceUrl = '../../../assets/icons/file_present.svg';
  defaultpfp: SafeResourceUrl = '../../../assets/icons/default-pfp-icon.svg';
  cameraIcon: SafeResourceUrl = '../../../assets/icons/camera-icon.svg';

  constructor(
    private swUpdate: SwUpdate,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private puzzleService: PuzzleService,
    private localService: LocalService,
    private translate: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private currencyPipe: CurrencyPipe,
    public sanitizer: DomSanitizer,
    private renderer: Renderer2
  ) {
    this.swUpdate.checkForUpdate();
  }

  ngOnInit(): void {
    this.isFreemium = this.token.data.isFreemium;
    this.isHaibuJobs = this.token.data.isHaibuJobs;
    this.maxDate.setFullYear(this.maxDate.getFullYear() - 10);
    this.userPermissions = this.token.data.permissions.map((permission) => permission.code);
    if (this.token.data.isMiniCompany) {
      this.isMini = true;
    }
    if (this.token.data.isAgency) {
      this.isAgency = true;
    }
    this.getUserProfile();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.id = urlParams.get('id');
    this.basset = urlParams.get('basset');

    if (this.id) {
      this.getCandidate();
      this.puzzleService.updateCandidateViewStatus(this.id).subscribe();
      this.getResponses();
      this.newCandidate = false;
      this.edit = false;
      this.activatedRoute.queryParams.subscribe(params => {
        this.selectedButton = this.isHaibuJobs ? 'button1' : (params['select'] || 'button1');
      });

    } else {
      if (this.isFreemium) {
        this.dialog.open(FreemiumDialogComponent, {
          panelClass: 'freemium-dialog',
          width: '500px',
          maxWidth: '95vw',
        });
        this.router.navigate(['/home/candidateslist']);
        return;
      }
      if (!this.getPermission('cd004')) {
        this.router.navigate(['/home/candidateslist']);
      }
      this.candidate = {} as Candidate;
      this.editedCandidate = {} as Candidate;
      this.candidate.cdT_ADDRESS_INFO = {} as comP_ADDRESS_ID_INFO;
      this.editedCandidate.cdT_ADDRESS_INFO = {} as comP_ADDRESS_ID_INFO;
      this.newCandidate = true;
      this.getCatalogs();
      this.getVacancies();
      this.puzzleService.getCompanyById(this.companyID, false).subscribe((resp) => {
        this.canYouBasset = resp.comP_BASSET;
      });
      setTimeout(() => {
        this.isWizardContainerExpanded = true;
      }, 0);
    }
  }

  getResponses() {
    this.puzzleService.getCandidateQuestionnaireResults(this.id).subscribe((response: any) => {
      this.questionnaireResults = response.questionnaires;
    });
  }

  readURL(event): void {
    if (event.target.files && event.target.files[0]) {
      // abrir dialogo de crop
      const dialogRef = this.dialog.open(ImageCropDialogComponent, {
        width: '500px',
        maxHeight: '90vh',
        data: {
          event: event
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        this.inputFileLogo.nativeElement.value = '';
        if (result) {
          this.imageFile = this.base64toFile(result, 'profilePicture.png');
          this.userImageEdit = this.sanitizer.bypassSecurityTrustResourceUrl(result);
        }
      });
    }
  }

  deletePhoto() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: [
        this.translate.instant('candidateDetailDeletePhotoTitle'),
        this.translate.instant('candidateDetailDeletePhotoMessage'),
      ],
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        this.userImageEdit = null;
        this.imageFile = null;
        this.inputFileLogo.nativeElement.value = '';
      }
    });
  }

  downloadPdfCandidate(candidate) {
    // Abrir el cuadro de diálogo de confirmación
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: [
        this.translate.instant('candidateDownloadDataPdfTitle'), // Título del diálogo de confirmación
        this.translate.instant('candidateDownloadDataPdfMessage'), // Mensaje del diálogo de confirmación
        false
      ],
    });

    // Suscribirse al cierre del diálogo
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Si se confirma, proceder con la descarga
        this.puzzleService.downloadPdfCandidate(candidate.cdT_ID, candidate.cdT_COMPANY_ID).subscribe((response: any) => {
          const blob = new Blob([response], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = `Expediente ${candidate.cdT_NAME}${candidate.cdT_PATERNAL_SURNAME}${candidate.cdT_MATERNAL_SURNAME}.pdf`; // Nombre del archivo
          link.click();
          window.URL.revokeObjectURL(url); // Libera el objeto URL
        });
      }
    });
  }


  getCandidate() {
    this.puzzleService.getCandidate(this.id).subscribe((response) => {
      if (!response.cdT_ACTIVE || response.cdT_COMPANY_ID != this.token.data.companyId) {
        this.router.navigate(['/home/candidateslist']);
      }
      this.editedCandidate = response;
      this.initializeAddressInfo(response);
      this.candidate = JSON.parse(JSON.stringify(response));
      if (response.candidateImageInfo?.rpC_IMG_ROUTE) {
        this.userImageId = response.candidateImageInfo?.rpC_IMG_ID;
        this.userImage = this.sanitizer.bypassSecurityTrustResourceUrl(environment.imageUrl + response.candidateImageInfo?.rpC_IMG_ROUTE);
        this.userImageEdit = this.sanitizer.bypassSecurityTrustResourceUrl(environment.imageUrl + response.candidateImageInfo?.rpC_IMG_ROUTE);
      }
      this.selectedVacancyId = response.cdT_VACANT_ID;
      this.selectedGender = response.cdT_GENDER_ID;
      this.selectedSource = response.recruitmentSourceInfo?.recruitmenT_SOURCE_ID;

      this.selectedStudyLevel = response?.cdT_CANDIDATE_SCHOLARSHIP_INFO?.scH_ID;
      this.selectedYearsOfExperience = response?.cdT_CANDIDATE_YEARS_EXPERIENCE_INFO?.exP_ID;

      if (!response.recruitmentSourceInfo?.recruitmenT_SOURCE_ID && response.cdT_OTHER_REFERRED) {
        this.selectedSource = '0';
      }
      if (!response.recruitmentSourceInfo?.recruitmenT_SOURCE_ID && response.cdT_REFERRED_BY) {
        this.selectedSource = '1';
      }

      if (typeof response.cdT_SALARY_EXPECTED === 'number') {
        this.actualSalary = response.cdT_SALARY_EXPECTED;
        this.formattedSalary = this.currencyPipe.transform(response.cdT_SALARY_EXPECTED, 'USD', 'symbol', '1.2-2');
      }
      this.getInnerDocs();
      this.getCandidateDocuments();
      if (this.basset) {
        this.requestBassetEvaluation();
      }
      if (this.candidate.cdT_ADDRESS_INFO.adR_STATE_ID == null) {
        this.customstatesandcities = true;
      }
      if (this.editedCandidate && this.editedCandidate.cdT_VACANT_ID) {
        if (this.vacancy) {
          this.selectedVacancy = this.vacancy.find(vacancy => vacancy.vacanT_ID === this.editedCandidate.cdT_VACANT_ID);
        }
      }
      this.puzzleService
        .getAllByCandidateId(this.candidate.cdT_ID, false)
        .subscribe((resp) => {
          this.candidateBassetDocument = resp;
          let observables: Observable<any>[] = [];
          for (let i = 0; i < this.candidateBassetDocument.length; i++) {
            var formData = new FormData();
            formData.append('ID_Request', resp[i].cdbS_REQUEST_ID);
            formData.append('tipo_request', resp[i].cdbS_STUDY_TYPE);
            observables.push(this.puzzleService.getDocumentStatus(formData));
          }
          forkJoin(observables).subscribe(
            (response) => {
              for (let i = 0; i < response.length; i++) {
                this.candidateBassetDocument[i].status_id =
                  response[i].status_id;
                this.candidateBassetDocument[i].status = response[i].status;
              }
            },
            (error) => {
            }
          );
        });
      this.getCandidateDocuments();
      setTimeout(() => {
        this.isWizardContainerExpanded = true;
      }, 0);
      setTimeout(() => {
        window.scrollTo(0, 0); // Mover el scroll a la parte superior
      }, 100);
    });
  }

  getUserProfile() {
    this.puzzleService.getProfileById(this.userLocal.data.id).subscribe(response => {
      this.userData = response;
      this.currentLanguage = this.userData.languagePreference;

      this.formData.append("id", this.userData.id);
      this.formData.append("email", this.userData.email);
    });
  }

  getPermission(permission: string): boolean {
    return this.userPermissions.includes(permission);
  }

  initializeAddressInfo(candidate: Candidate) {
    if (!candidate.cdT_ADDRESS_INFO) {
      candidate.cdT_ADDRESS_INFO = {
        adR_STREET: '',
        adR_STREET_NUMBER: '',
        adR_SUITE_NUMBER: '',
        adR_SUBURB: '',
        adR_CP: '',
        adR_STATE_ID: '',
        adR_COUNTRY_ID: '',
      } as comP_ADDRESS_ID_INFO;
    }
  }


  getClassByStatus(status: string): string {
    if (status.includes('Entregado')) {
      return 'successLabel';
    } else if (status.includes('Cancelado')) {
      return 'errorLabel';
    } else {
      return 'pendingLabel';
    }
  }

  getCatalogs() {
    this.getCountries();
    this.getGenders();
    this.getJobs();
    this.getDepartmentsOrdered();
    this.getBranchsOrdered();
    this.getInnerDocs();
    this.getSources();
    this.getStudyLevels();
    this.getYearsOfExperience();
  }

  getStudyLevels() {
    this.puzzleService.getCandidateEducationalLevel().subscribe((response) => {
      this.studyLevels = response;
    });
  }

  getYearsOfExperience() {
    this.puzzleService.getCandidateExperienceYears().subscribe((response) => {
      this.yearsOfExperience = response;
    });
  }

  getSources() {
    this.puzzleService.getSources(100, 0, this.localService.getJsonValue('token').data.companyId).subscribe((response) => {
      this.sources = response;
    });
  }

  getVacancies() {
    this.puzzleService
      .getVacancies(this.localService.getJsonValue('token').data.companyId, true, true, 1, 1000)
      .subscribe((response) => {
        this.vacancy = response;
        this.formattedVacancies = this.vacancy.map(v =>
          /*         `${v.vacanT_NUM} - ${v.jobInfo.joB_NAME} - ${v.departmentInfo.deP_NAME} - ${v.branchOfficeInfo?.broF_NAME || 'Sin definir'}`
           */
          `${v.jobInfo.joB_NAME} - ${v.departmentInfo.deP_NAME} - ${v.branchOfficeInfo?.broF_NAME || 'Sin definir'}`
        );
        this.changeDetectorRef.detectChanges();
        this.dataSource = this.vacancy;
        if (this.edit && this.editedCandidate.cdT_VACANT_ID) {
          this.candidate.cdT_VACANT_ID = this.editedCandidate.cdT_VACANT_ID;
        }

        if (this.editedCandidate && this.editedCandidate.cdT_VACANT_ID) {
          this.selectedVacancy = this.vacancy.find(vacancy => vacancy.vacanT_ID === this.editedCandidate.cdT_VACANT_ID);
        }
      });
  }

  formatVacancyDropdownItem(vacancy) {
    let jobName = vacancy.jobInfo?.joB_NAME || 'Sin definir';
    let branchName = vacancy.branchOfficeInfo?.broF_NAME || 'Sin definir';
    let departmentName = vacancy.departmentInfo?.deP_NAME || 'Sin definir';
    /*     return `${vacancy.vacanT_NUM} - ${jobName} - ${departmentName} - ${branchName}`;
     */
    return `${jobName} - ${departmentName} - ${branchName}`;
  }

  onGenderSelected(event: MatSelectChange) {
    this.selectedGender = event.value;
  }

  onVacancyChange(event: MatSelectChange) {
    this.selectedVacancyId = event.value;
  }

  onStateChange(event: MatSelectChange) {
    this.selectedState = event.value;
  }

  onStateMatSelectChange(event: MatSelectChange) {
    const newSelection: adR_STATE_ID_INFO = event.value;
    this.selectedState = newSelection;

    this.candidate.cdT_ADDRESS_INFO.adR_STATE_ID = newSelection.stS_ID;

    if (newSelection && newSelection.stS_CITIES) {
      this.cities = newSelection.stS_CITIES;

      if (this.candidate.cdT_ADDRESS_INFO && this.candidate.cdT_ADDRESS_INFO.adR_CITY_ID) {
        this.selectedCity = this.cities.find(city => city.ctY_ID === this.candidate.cdT_ADDRESS_INFO.adR_CITY_ID);
      } else {
        this.selectedCity = null;
        this.candidate.cdT_ADDRESS_INFO.adR_CITY_ID = null;
      }
    } else {
      this.cities = [];
      this.selectedCity = null;
      this.candidate.cdT_ADDRESS_INFO.adR_CITY_ID = null;
    }

    this.selectedCity = null;
    this.candidate.cdT_ADDRESS_INFO.adR_CITY_ID = null;
    this.candidate.cdT_ADDRESS_INFO.adR_CITY_CUSTOM = null;
  }

  onStateMatSelectChange2(event: MatSelectChange) {
    const newSelection: adR_STATE_ID_INFO = event.value; // El estado seleccionado
    this.selectedBirthState = newSelection;
    this.candidate.cdT_BIRTH_STATE_ID = newSelection.stS_ID;

    this.cities2 = newSelection.stS_CITIES || [];

    if (this.candidate.cdT_BIRTH_CITY_ID) {
      this.selectedBirthCity = this.cities2.find(city => city.ctY_ID === this.candidate.cdT_BIRTH_CITY_ID);
    } else {
      this.selectedBirthCity = null;
      this.candidate.cdT_BIRTH_CITY_ID = null;
    }

    this.selectedBirthCity = null;
    this.candidate.cdT_BIRTH_CITY_ID = null;
    this.candidate.cdT_BIRTH_CITY_CUSTOM = null;
  }

  onCityMatSelectChange(event: MatSelectChange) {
    this.selectedCity = event.value;
    this.candidate.cdT_ADDRESS_INFO.adR_CITY_ID = this.selectedCity.ctY_ID;
  }

  onCityMatSelectChange2(event: MatSelectChange) {
    this.selectedBirthCity = event.value;
    this.candidate.cdT_BIRTH_CITY_ID = this.selectedBirthCity ? this.selectedBirthCity.ctY_ID : null;
  }

  loadStates(id: number) {
    if (this.countries.ctR_CUSTOM_INFO_ACTIVE == true) {
      this.customstatesandcities = true;
    } else {
      this.customstatesandcities = false;
      this.states = this.countries.ctR_STATES;
    }
  }

  loadCities(id: number) {
    this.cities = this.states[id].stS_CITIES;
  }

  loadCities2(id: number) {
    this.cities2 = this.states2[id].stS_CITIES;
  }

  loadcitiesid(id) {
    for (let i = 0; i < this.states.length; i++) {
      if (this.states[i].stS_ID == id) {
        this.cities = this.states[i].stS_CITIES;
        break;
      }
    }
  }

  loadcitiesid2(id) {
    for (let i = 0; i < this.states2.length; i++) {
      if (this.states2[i].stS_ID == id) {
        this.cities2 = this.states2[i].stS_CITIES;
        break;
      }
    }
  }

  editcandidate() {
    this.candidate = { ...this.editedCandidate };
    this.edit = !this.edit;
    this.selectedVacancyId = this.editedCandidate.cdT_VACANT_ID;
    this.getVacancies();
  }

  openResultDialog(mode: number, message?: string, content?: string) {
    let data: string[] = [];
    switch (mode) {
      case 0:
        {
          data.push('Error');
          data.push(message);
          if (content) data.push(content);
          return this.dialog.open(ResponseSmallDialogComponent, {
            width: '500px',
            data: data,
          });
        }
        break;
      case 1:
        {
          data.push('Exito');
          data.push(message);
          if (content) data.push(content);

          return this.dialog.open(ResponseSmallDialogComponent, {
            width: '500px',
            data: data,
          });
        }
        break;
      case 2:
        {
          data.push('loading');
          data.push(message);
          if (content) data.push(content);

          return this.dialog.open(ResponseSmallDialogComponent, {
            width: '500px',
            data: data,
          });
        }
        break;
    }
  }

  openResultAnchorDialog(mode: number, message?: string, content?: string) {
    let data: string[] = [];
    switch (mode) {
      case 0:
        {
          data.push('Error');
          data.push(message);
          if (content) data.push(content);
          return this.dialog.open(ResponseAnchorDialogComponent, {
            width: '500px',
            data: data,
          });
        }
        break;
      case 1:
        {
          data.push('Exito');
          data.push(message);
          if (content) data.push(content);

          return this.dialog.open(ResponseAnchorDialogComponent, {
            width: '500px',
            data: data,
          });
        }
        break;
      case 2:
        {
          data.push('loading');
          data.push(message);
          if (content) data.push(content);

          return this.dialog.open(ResponseAnchorDialogComponent, {
            width: '500px',
            data: data,
          });
        }
        break;
    }
  }

  getCandidateDocuments() {
    this.puzzleService
      .getCandidateDocuments(this.candidate.cdT_ID, false)
      .subscribe((response) => {

        this.innerDocuments = [];
        this.innerEvaluations = [];

        for (let i = 0; i < response.length; i++) {
          if (response[i].cdoC_ACTIVE !== false) {
            if (response[i].cdoC_DOCUMENT_TYPE_ID == constants.candidateDocumentId) {
              this.innerDocuments.push(response[i]);
            } else if (response[i].cdoC_DOCUMENT_TYPE_ID == constants.candidateEvaluationId) {
              this.innerEvaluations.push(response[i]);
            }
          }
        }

        this.updateInnerDocsVisibility();
      });
  }

  updateInnerDocsVisibility() {
    if (this.innerDocs && this.innerDocs.length > 0) {
      for (let i = 0; i < this.innerDocuments.length; i++) {
        for (let e = 0; e < this.innerDocs.length; e++) {
          if (this.innerDocs[e].pdT_NAME == this.innerDocuments[i].cdoC_DOCUMENT_NAME) {
            this.innerDocs[e].pdT_VISIBLE = false;
          }
        }
      }
    }
  }

  get visibleInnerDocs() {
    if (!this.innerDocs) return [];
    return this.innerDocs.filter(doc => doc.pdT_VISIBLE !== false);
  }

  getGenders() {
    this.puzzleService.getGenders().subscribe((response) => {
      this.genders = response;
    });
  }

  isValidDate(): boolean {
    const birthDate = new Date(this.candidate.cdT_BIRTH_DATE);
    const minYear = new Date('1940-01-01');
    return birthDate >= minYear;
  }


  getInnerDocs() {
    this.puzzleService
      .getInnerDocumentsExternal(this.localService.getJsonValue('token').data.companyId, this.candidate.cdT_ID)
      .subscribe((response) => {
        if (!this.innerDocuments) {
          this.innerDocuments = [];
        }
        this.innerDocs = response.filter(doc =>
          !this.innerDocuments.some(innerDoc => innerDoc.cdoC_DOCUMENT_NAME === doc.pdT_NAME)
        );
      });
  }

  getJobs() {
    this.puzzleService
      .getJobs(this.localService.getJsonValue('token').data.companyId, false)
      .subscribe((response) => {
        this.jobs = response;
      });
  }

  getDepartmentsOrdered() {
    this.puzzleService
      .getDepartmentsOrdered(
        this.localService.getJsonValue('token').data.companyId,
        false
      )
      .subscribe((response) => {
        this.departments = response;
      });
  }

  onVacancySelectionChanged(selectedVacancyId: string) {
    this.selectedVacancyId = selectedVacancyId;
    this.selectedVacancy = this.vacancy.find(vacancy => vacancy.vacanT_ID === selectedVacancyId);
  }

  getBranchsOrdered() {
    this.puzzleService
      .getBranchsOrdered(this.localService.getJsonValue('token').data.companyId, false)
      .subscribe((response) => {
        this.branchs = response;
      });
  }

  getCountries() {
    this.puzzleService.getCountriesOther(false).subscribe((countries) => {
      this.countries = countries;

      this.states2 = this.countries.ctR_STATES;

      if (
        this.editedCandidate &&
        this.editedCandidate.cdT_BIRTH_STATE_ID &&
        this.editedCandidate.cdT_BIRTH_CITY_ID
      ) {
        for (let i = 0; i < this.states2.length; i++) {
          if (
            this.states2[i].stS_ID == this.editedCandidate.cdT_BIRTH_STATE_ID
          ) {
            this.cities2 = this.states2[i].stS_CITIES;
            break;
          }
        }
      }

      if (
        this.editedCandidate &&
        this.editedCandidate.cdT_ADDRESS_INFO.adR_STATE_ID &&
        this.editedCandidate.cdT_ADDRESS_INFO.adR_CITY_ID_INFO
      ) {
        let indexStates = 0;
        let indexCities = this.countries.ctR_STATES.findIndex(
          (x) => x.stS_ID == this.editedCandidate.cdT_ADDRESS_INFO.adR_STATE_ID
        );

        this.loadStates(indexStates);
        this.loadCities(indexCities);
      } else {
        this.loadStates(0);
      }

      if (
        this.editedCandidate &&
        this.editedCandidate.cdT_ADDRESS_INFO.adR_STATE_ID
      ) {
        let indexStates = 0;
        this.loadStates(indexStates);
      } else {
        this.loadStates(0);
      }
    });
  }

  isPhoneNumberValid(phoneNumber: string): boolean {
    const isValid = phoneNumber && phoneNumber.length >= 10;
    return isValid;
  }

  onStudyLevelSelected(event: MatSelectChange) {
    this.selectedStudyLevel = event.value;
  }

  onYearsOfExperienceSelected(event: MatSelectChange) {
    this.selectedYearsOfExperience = event.value;
  }

  isSafeUrlEqual(url1: SafeUrl, url2: SafeUrl): boolean {
    const sanitizedUrl1 = this.sanitizer.sanitize(SecurityContext.URL, url1);
    const sanitizedUrl2 = this.sanitizer.sanitize(SecurityContext.URL, url2);
    return sanitizedUrl1 === sanitizedUrl2;
  }

  createEditCandidate(myForm: NgForm) {
    if (this.sending) {
      return;
    }
    this.isVacancyInvalid = !this.selectedVacancyId;
    this.isGenderInvalid = !this.selectedGender;
    this.isPhoneOneInvalid = !this.isPhoneNumberValid(this.candidate.cdT_PHONE_NUMBER_ONE);
    this.isPhoneTwoInvalid = this.candidate.cdT_PHONE_NUMBER_TWO && !this.isPhoneNumberValid(this.candidate.cdT_PHONE_NUMBER_TWO);
    this.isStudyLevelInvalid = !this.selectedStudyLevel;
    this.isYearsOfExperienceInvalid = !this.selectedYearsOfExperience;

    if (this.candidate.cdT_BANK_CLABE && this.candidate.cdT_BANK_CLABE.length < 18) {
      this.isWizardContainerGeneralOptionalExpanded = true;
      this.openResultDialog(0, this.translate.instant('extErrorClabeLength'));
      return;
    }

    // Validación de CURP, solo si tiene algún valor
    if (this.candidate.cdT_CURP && this.candidate.cdT_CURP.length < 18) {
      this.openResponseDialog('Error', this.translate.instant('invalidLengthKeyForm', { key: this.translate.instant('candidateDetailGeneralDataCURP'), number: 18 }));
      return;
    }

    // Validación de RFC, solo si tiene algún valor
    if (this.candidate.cdT_RFC && this.candidate.cdT_RFC.length < 12) {
      this.openResponseDialog('Error', this.translate.instant('invalidAtLeastLengthKeyForm', { key: this.translate.instant('candidateDetailGeneralDataRFC'), number: 12 }));
      return;
    }

    // Validación de NSS, solo si tiene algún valor
    if (this.candidate.cdT_SOCIAL_SECURITY_NUMBER && this.candidate.cdT_SOCIAL_SECURITY_NUMBER.length < 10) {
      this.openResponseDialog('Error', this.translate.instant('invalidLengthKeyForm', { key: this.translate.instant('candidateDetailGeneralDataNSS'), number: 10 }));
      return;
    }

    // Validación de Cédula Profesional, solo si tiene algún valor
    if (this.candidate.cdT_PROFESSIONAL_CERTIFICATE && this.candidate.cdT_PROFESSIONAL_CERTIFICATE.length < 8) {
      this.openResponseDialog('Error', this.translate.instant('invalidLengthKeyForm', { key: this.translate.instant('candidateDetailGeneralDataProfessionalLicense'), number: 8 }));
      return;
    }

    // Validación de Crédito, solo si tiene algún valor
    if (this.candidate.cdT_INFONAVIT_CREDIT && this.candidate.cdT_INFONAVIT_CREDIT.toString().length < 12) {
      this.openResponseDialog('Error', this.translate.instant('invalidLengthKeyForm', { key: this.translate.instant('candidateDetailGeneralDataCredit'), number: 12 }));
      return;
    }

    if (this.isPhoneOneInvalid) {
      this.openResultDialog(0, this.translate.instant('candidateDetailRequiredPhone'));
      return;
    }

    if (this.isPhoneTwoInvalid) {
      this.openResultDialog(0, this.translate.instant('candidateDetailPhoneTwo'));
      return;
    }

    if (this.candidate?.cdT_ADDRESS_INFO?.adR_CP && this.candidate?.cdT_ADDRESS_INFO?.adR_CP?.length < 5) {
      this.isWizardContainerExpandedAdress = true;
      this.openResultDialog(0, this.translate.instant('candidateDetailPostalCode'));
      return;
    }

    if (!myForm.valid || this.isVacancyInvalid || this.isGenderInvalid || this.isStudyLevelInvalid || this.isYearsOfExperienceInvalid) {
      const obligatoryFieldsMessage = this.translate.instant('candidateDetailAlert');
      this.openResultDialog(0, obligatoryFieldsMessage);
      Object.values(myForm.controls).forEach((control) => {
        control.markAllAsTouched();
      });
    } else {
      this.sending = true;
      this.shouldShowFinalAlert = true;
      this.loading = this.openResultDialog(
        2,
        this.translate.instant('candidateDetailAlertLoading')
      );

      if (
        this.candidate.cdT_ADDRESS_INFO.adR_CITY_ID != null &&
        this.candidate.cdT_ADDRESS_INFO.adR_STATE_ID != null
      ) {
        this.candidate.cdT_ADDRESS_INFO.adR_STATE_CUSTOM = null;
        this.candidate.cdT_ADDRESS_INFO.adR_CITY_CUSTOM = null;
      }
      if (
        this.candidate.cdT_ADDRESS_INFO.adR_CITY_CUSTOM != null &&
        this.candidate.cdT_ADDRESS_INFO.adR_STATE_CUSTOM != null
      ) {
        this.candidate.cdT_ADDRESS_INFO.adR_CITY_ID = null;
        this.candidate.cdT_ADDRESS_INFO.adR_STATE_ID = null;
      }

      this.candidate.cdT_VACANT_ID = this.selectedVacancyId;
      this.candidate.cdT_GENDER_ID = this.selectedGender;

      this.candidate.cdT_SALARY_EXPECTED = this.actualSalary;

      if (
        this.candidate.cdT_COMPANY_ID == null ||
        this.candidate.cdT_COMPANY_ID.trim() == ''
      ) {
        this.candidate.cdT_COMPANY_ID =
          this.localService.getJsonValue('token').data.companyId;
      }

      let dob = this.datepipe.transform(
        this.candidate.cdT_BIRTH_DATE,
        'MM/dd/yyyy'
      );

      this.candidate.cdT_BIRTH_DATE = dob;
      this.candidate.cdT_CANDIDATE_SCHOLARSHIP_INFO = {
        scH_ID: this.selectedStudyLevel
      }
      this.candidate.cdT_CANDIDATE_YEARS_EXPERIENCE_INFO = {
        exP_ID: this.selectedYearsOfExperience
      }

      this.loading.close();
      if (!this.candidate.cdT_VACANT_ID) {
        const noVacancyMessage = this.translate.instant('candidateDetailNoVacancyAlert');
        this.openResultDialog(0, noVacancyMessage);
        return;
      }
      if (this.selectedSource == '0' || this.selectedSource == '1') {
        this.selectedSource = null;
      }
      this.candidate.CDT_RECRUITMENT_SOURCE_ID = this.selectedSource;
      this.puzzleService.createEditCandidate(this.candidate).subscribe(
        async (response) => {
          if (response.isSuccess == true) {
            let success = true;
            const candidateId = response.data;
            // Save profile image
            if (this.userImageEdit && this.userImage ? !this.isSafeUrlEqual(this.userImageEdit, this.userImage) : true) {
              if (this.imageFile) {
                const formData = new FormData();
                formData.append('image.file', this.imageFile, this.imageFile.name);
                formData.append('candidateId', candidateId);
                await this.puzzleService.createCandidateImage(formData).toPromise();
              }
            } else if (this.userImageId && !this.userImageEdit) {
              const value = {
                imageId: this.userImageId,
                isActive: false
              }
              await this.puzzleService.deleteCandidateImage(value).toPromise();
            }
            if (this.innerDocuments.length > 0 || this.innerEvaluations.length > 0) {
              success = await this.sendDocs(candidateId);
              if (this.newEvaluationDialogResponse && this.newEvaluationDialogResponse.requestedEvaluations.length > 0)
                this.sendAsyncBassetEvaluations(candidateId, true);
              this.loading.close();
              this.showSuccessDialog(candidateId, response.message);
            } else {
              if (
                !this.id &&
                this.newEvaluationDialogResponse &&
                this.newEvaluationDialogResponse.requestedEvaluations.length > 0
              ) {
                this.sendAsyncBassetEvaluations(candidateId);
              } else {
                this.shouldShowFinalAlert = true;
                this.showSuccessDialog(candidateId, response.message);
              }
            }
          } else {
            this.sending = false;
            this.loading.close();
            const translatedSuccessMessage = this.translate.instant(response.message);
            this.openResultDialog(0, translatedSuccessMessage);
          }
        }
      );
    }
  }

  async sendDocs(id?) {
    if (!this.candidate.cdT_VACANT_ID) {
      return;
    }
    if (this.innerDocuments.length !== 0 || this.innerEvaluations.length !== 0) {
      if (!this.loading) {
        const translatedMessage = this.translate.instant('candidateDetailAlertLoading');
        this.loading = this.openResultDialog(2, translatedMessage);
      }

      let responseList = [];
      let processedFiles = new Set();

      for (let i = 0; i < this.documentsFormsList.length; i++) {
        const form = this.documentsFormsList[i];
        let fileName = '';

        if (this.selectedButton === 'button3') {
          fileName = form.get('fileWrapper.file').name;
        } else {
          fileName = form.get('name');
        }

        if (!processedFiles.has(fileName)) {
          processedFiles.add(fileName);

          if (this.id) {
            form.append('companyId', this.candidate.cdT_COMPANY_ID);
            form.append('candidateId', this.candidate.cdT_ID);
          } else if (id) {
            form.append('companyId', this.token.data.companyId);
            form.append('candidateId', id);
          }

          try {
            const response = await this.puzzleService.sendDocs(form).toPromise();
            responseList.push(fileName + (response.isSuccess ? ' - Éxito' : ' - Falló'));
            if (!response.isSuccess && response.message.includes('extensión no permitida')) {
              this.noPermittedFiles = true;
              this.noPermittedFilesArray.push(fileName);
              if (this.selectedButton === 'button3') {
                this.innerDocuments = this.innerDocuments.filter(doc => doc.originalName !== fileName);
                this.documentsFormsList = this.documentsFormsList.filter(form => form.get('fileWrapper.file').name !== fileName);
              } else {
                this.innerDocuments = this.innerDocuments.filter(doc => doc.cdoC_DOCUMENT_NAME !== fileName);
                this.documentsFormsList = this.documentsFormsList.filter(form => form.get('name') !== fileName);
              }
              i--;
            }
          } catch (error) {
            responseList.push(fileName + ' - Falló');
          }
        } else {
          form.append('companyId', this.candidate.cdT_COMPANY_ID);
          form.append('candidateId', this.candidate.cdT_ID);
        }
      }

      this.loading.close();

      let successCount = responseList.filter(r => r.includes('Éxito')).length;
      let failedCount = responseList.length - successCount;


      return failedCount === 0;
    }
  }

  async createEditCandidateFiles(myForm: NgForm) {
    if (!myForm.valid) {
      this.openResultDialog(0, this.translate.instant('candidateDetailDocumentAlert'));
      Object.values(myForm.controls).forEach((control) => {
        control.markAllAsTouched();
      });
      return;
    }

    this.sending = true;
    let loading = this.openResultDialog(2, this.translate.instant('candidateDetailAlertLoading'));
    this.candidate.CDT_RECRUITMENT_SOURCE_ID = this.candidate.recruitmentSourceInfo?.recruitmenT_SOURCE_ID;
    this.prepareFormData();


    if (this.innerDocuments.length > 0 || this.innerEvaluations.length > 0) {
      const documentsSentSuccessfully = await this.sendDocs(this.candidate.cdT_ID);

      if (this.noPermittedFiles) {
        this.noPermittedFiles = false;
        this.sending = false;
        loading.close();
        let title = this.translate.instant("extErrorNotPermitted")
        let message = "<br>" + this.noPermittedFilesArray.join("<br>") + "<br><br>" + this.translate.instant("extErrorNotPermittedTypes");
        this.noPermittedFilesArray = []
        this.openResultDialog(0, title, message);
        return;
      }

      if (!documentsSentSuccessfully) {
        this.sending = false;
        loading.close();
        this.openResultDialog(0, this.translate.instant("extDocError"));
        return;
      }
    }

    this.candidate.cdT_VACANT_ID = this.selectedVacancyId;

    this.puzzleService.createEditCandidateWithFiles(this.formData).subscribe(
      (response) => {
        this.sending = false;
        loading.close();
        if (response.isSuccess == true) {
          this.edit = false;
          this.newCandidate = false;
          this.openResultDialog(1, this.translate.instant('candidateDetailAlertRegister'));
          window.location.reload()
        } else {
          const translatedSuccessMessage = this.translate.instant(response.message);
          this.openResultDialog(0, translatedSuccessMessage);
        }
      },
      (error) => {
        this.sending = false;
        loading.close();
        console.error("Error de respuesta del servidor:", error);
        const translatedSuccessMessage = this.translate.instant(error.message);
        this.openResultDialog(0, translatedSuccessMessage);
      }
    );
  }

  prepareFormData() {
    this.formData = new FormData();
    for (let key in this.candidate) {
      if (this.candidate.hasOwnProperty(key)) {
        if (key === 'cdT_ADDRESS_INFO' || key === 'cdT_CANDIDATE_SCHOLARSHIP_INFO' || key === 'cdT_CANDIDATE_YEARS_EXPERIENCE_INFO') {
          // Añadir datos de la dirección (si es un objeto anidado)
          for (let addressKey in this.candidate[key]) {
            if (this.candidate[key][addressKey] != null) {
              this.formData.append(`${key}.${addressKey}`, this.candidate[key][addressKey]);
            }
          }
        } else {
          // Añadir otros datos del candidato
          if (this.candidate[key] != null) {
            if (key === 'cdT_BIRTH_DATE') {
              let dob = this.datepipe.transform(this.candidate[key], 'MM/dd/yyyy');
              this.formData.append(key, dob);
            } else {
              this.formData.append(key, this.candidate[key]);
            }
          }
        }
      }
    }

    if (
      this.candidate.cdT_ADDRESS_INFO.adR_CITY_ID != null &&
      this.candidate.cdT_ADDRESS_INFO.adR_STATE_ID != null
    ) {
      this.candidate.cdT_ADDRESS_INFO.adR_STATE_CUSTOM = null;
      this.candidate.cdT_ADDRESS_INFO.adR_CITY_CUSTOM = null;
    }
    if (
      this.candidate.cdT_ADDRESS_INFO.adR_CITY_CUSTOM != null &&
      this.candidate.cdT_ADDRESS_INFO.adR_STATE_CUSTOM != null
    ) {
      this.candidate.cdT_ADDRESS_INFO.adR_CITY_ID = null;
      this.candidate.cdT_ADDRESS_INFO.adR_STATE_ID = null;
    }

    if (this.selectedVacancy && this.selectedVacancy.vacanT_ID) {
      this.formData.append('cdT_VACANT_ID', this.selectedVacancy.vacanT_ID);
    }
  }

  showSuccessDialog(candidateId, message?: string) {
    this.edit = false;
    this.newCandidate = false;
    this.sending = false;
    this.loading.close();
    if (this.shouldShowFinalAlert) {
      this.shouldShowFinalAlert = false;
      if (message) {
        this.openResultDialog(1, this.translate.instant('candidateCreateSuccessAlert'));
      } else {
        this.openResultDialog(1, this.translate.instant('candidateDetailAlertRegister'));
      }
      this.router.navigate(['/home/candidatedetail'], { queryParams: { id: candidateId } }).then(() => {
        this.ngOnInit();
      });
    }
  }

  sendAsyncBassetEvaluations(id: string, callSendDocuments?: boolean) {
    let observables: Observable<any>[] = [];
    let formData = new FormData();
    formData.append('cliente_id', this.newEvaluationDialogResponse.cliente_id);
    formData.append('cliente_rs', this.newEvaluationDialogResponse.cliente_rs);
    formData.append('candidateId', id);
    if (
      this.newEvaluationDialogResponse.requestedEvaluations.some(
        (e) => e.id == '1'
      )
    ) {
      var localFormData = formData;
      localFormData.append(
        'include_investigacion',
        this.newEvaluationDialogResponse.investigacionlaboral ? '1' : '0'
      );
      localFormData.append(
        'urgente_flag',
        this.newEvaluationDialogResponse.urgente ? '1' : '0'
      );
      observables.push(this.puzzleService.requestES(localFormData));
    } else {
      observables.push(of(null));
    }
    if (
      this.newEvaluationDialogResponse.requestedEvaluations.some(
        (e) => e.id == '2'
      )
    ) {
      observables.push(this.puzzleService.requestRL(formData));
    } else {
      observables.push(of(null));
    }
    if (
      this.newEvaluationDialogResponse.requestedEvaluations.some(
        (e) => e.id == '3'
      )
    ) {
      observables.push(this.puzzleService.requestPI(formData));
    } else {
      observables.push(of(null));
    }
    if (
      this.newEvaluationDialogResponse.requestedEvaluations.some(
        (e) => e.id == '4'
      )
    ) {
      observables.push(this.puzzleService.requestIL(formData));
    } else {
      observables.push(of(null));
    }

    if (
      this.newEvaluationDialogResponse.requestedEvaluations.some(
        (e) => e.id == '5'
      )
    ) {
      var localFormData = formData;

      localFormData.append(
        'pertinentes',
        this.newEvaluationDialogResponse.evaluador ? '1' : '0'
      );

      if (!this.newEvaluationDialogResponse.evaluador) {
        localFormData.append(
          'pruebas',
          this.newEvaluationDialogResponse.requestedPruebas
        );
      }

      observables.push(this.puzzleService.requestPP(localFormData));
    } else {
      observables.push(of(null));
    }

    forkJoin(observables).subscribe(
      (multresp) => {
        let error: boolean = false;
        let errorString: string = '';
        for (let i = 0; i < multresp.length; i++) {
          if (multresp[i]) {
            if (multresp[i].isSuccess == false) {
              error = true;
              errorString += multresp[i].message;
            }
          }
        }
        if (callSendDocuments) {
          this.sendDocs(id)
        } else {
          this.edit = false;
          this.newCandidate = false;
          this.sending = false;
          this.loading.close();
          this.openResultDialog(1, this.translate.instant('candidateDetailAlertRegister'));
          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => {
              this.router.navigate(['/home/candidatedetail'], {
                queryParams: { id: id },
              });
            });
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  fileSelectClick() {
    event.preventDefault();
    event.stopPropagation();
    if (this.isFreemium) {
      this.dialog.open(FreemiumDialogComponent, {
        panelClass: 'freemium-dialog',
        width: '500px',
        maxWidth: '95vw',
      });
      return;
    }
    if (this.fileloader == null) {
      this.fileloader = document.getElementById('fileSelect');
    }

    if (this.selectedInnerDoc != null) {
      this.fileloader.click();
    } else {
      const translatedMessage = this.translate.instant('candidateDetailAlert');
      this.openResultDialog(0, translatedMessage);
    }
  }

  fileSelectClick2() {
    event.preventDefault();
    event.stopPropagation();
    if (this.isFreemium) {
      this.dialog.open(FreemiumDialogComponent, {
        panelClass: 'freemium-dialog',
        width: '500px',
        maxWidth: '95vw',
      });
      return;
    }
    if (this.fileloader2 == null) {
      this.fileloader2 = document.getElementById('fileSelect2');
    }

    this.fileloader2.click();
  }

  opendoc(route: string) {
    if (route != null) {
      let dialog = this.dialog.open(DocuViewerComponent, {
        panelClass: 'custom-dialog-cv',
        maxWidth: '100vw',
        data: { data: route, header: 'Curriculum vitáe' },
      });

      dialog.afterClosed().subscribe((result) => { });
    }
  }

  openquest(questionnaire: any) {
    let dialog = this.dialog.open(QuestionnaireViewerComponent, {
      maxHeight: '90vh',
      width: '900px',
      maxWidth: '90vw',
      panelClass: 'compact-dialog',
      data: { quest: questionnaire, candidate: this.candidate }
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        if (result.isSuccess) {
          let title = this.translate.instant("questSavedSuccessfully")
          this.openResultDialog(1, title);
          this.getResponses()
        } else {
          let title = this.translate.instant("questSavedError")
          this.openResultDialog(0, title);
        }
      }
    });
  }

  fileChangeEvent(event, mode) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let docToAdd = {} as CandidateDocument;

      let file: File = fileList[0];

      // revisar si la ext del archivo es permitida
      // '.jpg', '.jpeg', '.gif', '.png', '.webp', '.doc', '.docx', '.pdf', '.csv', '.xls', and '.xlsx'.
      let ext = file.name.split('.').pop();
      let allowedExts = constants.allowedExt;
      if (!allowedExts.includes(ext.toLowerCase())) {
        let title = this.translate.instant("extErrorNotPermitted")
        let message = "<br>" + file.name + "<br><br>" + this.translate.instant("extErrorNotPermittedTypes");
        this.openResultDialog(0, title, message);
        return;
      }

      let fileSize = file.size;
      let maxFileSize = constants.maxFileSize;
      if (fileSize > maxFileSize) {
        let title = this.translate.instant("extErrorFileSize")
        let message = "<br>" + file.name + "<br><br>" + this.translate.instant("extErrorFileSizeMessage");
        this.openResultDialog(0, title, message);
        return;
      }

      if (mode == 0) {
        // revisar si es imagen
        const imageExt = ['jpg', 'jpeg', 'gif', 'png', 'webp'];
        let isImage = imageExt.includes(ext.toLowerCase());
        if (isImage) {
          let dialog = this.dialog.open(MultiPhotoDialogComponent, {
            width: '600px',
            maxWidth: '90vw',
            maxHeight: '90vh',
            panelClass: 'compact-dialog',
            data: {
              name: this.selectedInnerDoc.pdT_NAME,
              file: file,
            },
          });

          dialog.afterClosed().subscribe((result: File) => {
            if (result) {
              file = result;

              let fileSize = file.size;
              let maxFileSize = constants.maxFileSize;
              if (fileSize > maxFileSize) {
                let title = this.translate.instant("extErrorFileSize")
                let message = "<br>" + file.name + "<br><br>" + this.translate.instant("extErrorFileSizeMessage");
                this.openResultDialog(0, title, message);
                return;
              }

              this.processFile(file);
            }
          });
          return;
        }

        let index = this.totalindex;
        this.totalindex++;

        docToAdd.cdoC_DOCUMENT_NAME = this.selectedInnerDoc.pdT_NAME;
        docToAdd.cdoC_INDEX = index;
        docToAdd.originalName = file.name;
        this.innerDocuments.push(docToAdd);

        let thisfrom = new FormData();

        thisfrom.append('cdOC_DOCUMENT_TYPE_ID', constants.candidateDocumentId);
        thisfrom.append(
          'cdOC_PERSONAL_DOCUMENTATION_TYPE_ID',
          this.selectedInnerDoc.pdT_ID
        );
        thisfrom.append('fileWrapper.file', file, file.name);
        thisfrom.append('name', this.selectedInnerDoc.pdT_NAME);

        this.documentsFormsList.push(thisfrom);

        for (let i = 0; i < this.innerDocs.length; i++) {
          if (this.innerDocs[i].pdT_ID == this.selectedInnerDoc.pdT_ID) {
            this.innerDocs[i].pdT_VISIBLE = false;
          }
        }
      } else {
        let index = this.totalindex;
        this.totalindex++;

        docToAdd.cdoC_DOCUMENT_NAME = file.name;
        docToAdd.cdoC_INDEX = index;

        this.innerEvaluations.push(docToAdd);

        let thisfrom = new FormData();

        thisfrom.append('name', file.name);
        thisfrom.append(
          'cdOC_DOCUMENT_TYPE_ID',
          constants.candidateEvaluationId
        );
        thisfrom.append('fileWrapper.file', file, file.name);

        this.documentsFormsList.push(thisfrom);
      }
    }

    this.selectedInnerDoc = null;
    this.updateInnerDocsVisibility();

  }

  processFile(file: File) {
    let index = this.totalindex;
    this.totalindex++;
    let docToAdd = {} as CandidateDocument;

    docToAdd.cdoC_DOCUMENT_NAME = this.selectedInnerDoc.pdT_NAME;
    docToAdd.cdoC_INDEX = index;
    docToAdd.originalName = file.name;

    this.innerDocuments.push(docToAdd);

    let thisfrom = new FormData();
    thisfrom.append('cdOC_DOCUMENT_TYPE_ID', constants.candidateDocumentId);
    thisfrom.append('cdOC_PERSONAL_DOCUMENTATION_TYPE_ID', this.selectedInnerDoc.pdT_ID);
    thisfrom.append('fileWrapper.file', file, file.name);
    thisfrom.append('name', this.selectedInnerDoc.pdT_NAME);

    this.documentsFormsList.push(thisfrom);

    for (let i = 0; i < this.innerDocs.length; i++) {
      if (this.innerDocs[i].pdT_ID == this.selectedInnerDoc.pdT_ID) {
        this.innerDocs[i].pdT_VISIBLE = false;
      }
    }

    this.selectedInnerDoc = null;
  }

  sendEvals() {
    if (this.innerEvaluations.length != 0) {
      if (!this.loading) {
        const loadingMessage = this.translate.instant('candidateDetailAlertLoading');
        this.loading = this.openResultDialog(2, loadingMessage);
      }

      this.formevals.append('companyId', this.candidate.cdT_COMPANY_ID);
      this.formevals.append('candidateId', this.candidate.cdT_ID);

      this.puzzleService.sendDocs(this.formevals).subscribe(
        (response) => {
          this.loading.close();
          if (response.isSuccess) {
            this.edit = false;
            this.newCandidate = false;
            this.loading.close();
            this.openResultDialog(1, response.message);
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => {
                this.router.navigate(['/home/candidatedetail'], {
                  queryParams: { id: this.id },
                });
              });
          } else {
            const translatedErrorMessage = this.translate.instant(response.message);
            this.openResultDialog(0, translatedErrorMessage);
          }
        },
        (error) => {
          this.loading.close();
          const translatedErrorMessage = this.translate.instant(error.message);
          this.openResultDialog(0, translatedErrorMessage);
        }
      );
    } else {
      const loadingMessage = this.translate.instant('candidateDetailDocumentsEvaluationAlert');
      this.openResultDialog(0, loadingMessage);
    }
  }

  hidedoc(thedoc: CandidateDocument) {
    event.stopPropagation();
    if (this.isFreemium) {
      this.dialog.open(FreemiumDialogComponent, {
        panelClass: 'freemium-dialog',
        width: '500px',
        maxWidth: '95vw',
      });
      return;
    }
    const doc = {
      cdOC_ID: thedoc.cdoC_ID,
      cdoC_RECRUITER_ACTIVE: !thedoc.cdoC_RECRUITER_ACTIVE
    }
    const confirmTitle = this.translate.instant('candidateHideDocumentTitle');
    let confirmMessage = ''
    if (!thedoc.cdoC_RECRUITER_ACTIVE) {
      confirmMessage = this.translate.instant('candidateShowDocumentMessage');
    } else {
      confirmMessage = this.translate.instant('candidateHideDocumentMessage');
    }

    const confirmDialog = this.openResultDialog(0, confirmTitle, confirmMessage);

    confirmDialog.afterClosed().subscribe(result => {
      if (result) {
        this.puzzleService.updateDocumentVisibility(doc).subscribe((response) => {
          this.getCandidate();
        });
      }
    });
  }

  deletedoc(thedoc: CandidateDocument, mode) {
    event.stopPropagation();
    const confirmTitle = this.translate.instant('candidateDeleteDocumentTitle');
    const confirmMessage = this.translate.instant('candidateDeleteDocumentMessage');
    const confirmDialog = this.openResultDialog(0, confirmTitle, confirmMessage);

    confirmDialog.afterClosed().subscribe(result => {
      if (result) {
        if (thedoc.cdoC_ID != null) {
          const loadingMessage = this.translate.instant('candidateDocumentsWait');
          let loading = this.openResultDialog(2, loadingMessage);

          thedoc.cdoC_ACTIVE = false;
          this.puzzleService.deleteDoc(thedoc).subscribe((response) => {
            loading.close();
            if (response.isSuccess) {
              this.edit = false;
              this.newCandidate = false;
              const translatedSuccessMessage = this.translate.instant(response.message);
              this.openResultDialog(1, translatedSuccessMessage);
              this.getCandidate();

              // devolver el documento a la lista de documentos disponibles
              if (mode == 0) {
                setTimeout(() => {
                  this.getInnerDocs();
                }, 500);
              }
            } else {
              const translatedSuccessMessage = this.translate.instant(response.message);
              this.openResultDialog(0, translatedSuccessMessage);
            }
          });
        } else {
          let indextopop = [];

          if (mode == 0) {
            for (let i = 0; i < this.innerDocuments.length; i++) {
              if (
                this.innerDocuments[i].cdoC_DOCUMENT_NAME ==
                thedoc.cdoC_DOCUMENT_NAME
              ) {
                indextopop.push(i);
                break;
              }
            }

            for (let i = 0; i < indextopop.length; i++) {
              this.innerDocuments.splice(indextopop[i], 1);
            }

            for (let i = 0; i < this.innerDocs.length; i++) {
              if (this.innerDocs[i].pdT_NAME == thedoc.cdoC_DOCUMENT_NAME) {
                this.innerDocs[i].pdT_VISIBLE = true;
              }
            }
          } else {
            for (let i = 0; i < this.innerEvaluations.length; i++) {
              if (
                this.innerEvaluations[i].cdoC_DOCUMENT_NAME ==
                thedoc.cdoC_DOCUMENT_NAME
              ) {
                indextopop.push(i);
              }
            }

            for (let i = 0; i < indextopop.length; i++) {
              this.innerEvaluations.splice(indextopop[i], 1);
            }
          }

          this.documentsFormsList.splice(thedoc.cdoC_INDEX, 1);
        }
      }
    });
  }

  editdocuments() {
    this.showdocuments = !this.showdocuments;
  }

  editevaluations() {
    this.showevaluations = !this.showevaluations;
  }

  requestBassetEvaluation() {
    this.puzzleService
      .validateCandidateFields(this.candidate)
      .subscribe((resp) => {
        if (resp.isSuccess) {
          let dialog = this.dialog.open(NewEvaluationComponent, {
            width: '500px',
            data: this.candidate,
          });
          dialog.afterClosed().subscribe((result) => {
            if (result) {
              if (
                !(typeof result == 'string') &&
                !(typeof result == 'object')
              ) {
                const loadingMessage = this.translate.instant('candidateDetailDocumentsSuccess');
                this.openResultDialog(1, loadingMessage);
                this.puzzleService
                  .getAllByCandidateId(this.candidate.cdT_ID)
                  .subscribe((resp) => {
                    this.candidateBassetDocument = resp;
                    let observables: Observable<any>[] = [];
                    for (
                      let i = 0;
                      i < this.candidateBassetDocument.length;
                      i++
                    ) {
                      var formData = new FormData();
                      formData.append('ID_Request', resp[i].cdbS_REQUEST_ID);
                      formData.append('tipo_request', resp[i].cdbS_STUDY_TYPE);
                      observables.push(
                        this.puzzleService.getDocumentStatus(formData)
                      );
                    }
                    forkJoin(observables).subscribe(
                      (response) => {
                        for (let i = 0; i < response.length; i++) {
                          this.candidateBassetDocument[i].status_id =
                            response[i].status_id;
                          this.candidateBassetDocument[i].status =
                            response[i].status;
                        }
                      },
                      (error) => {
                      }
                    );
                  });
              } else if (typeof result === 'object') {
                this.newEvaluationDialogResponse = result;
                this.disabledBasset = true;
              } else {

                this.openResultAnchorDialog(0, result);
              }
            }
          });
        } else {
          let data: string[] = ['Error', resp.message, resp.data];
          this.dialog.open(ResponseListDialogComponent, {
            width: '500px',
            data: data,
          });
        }
      });
  }

  toggleWizardContainer() {
    this.isWizardContainerExpanded = !this.isWizardContainerExpanded;
  }

  toggleWizardContainerAdress() {
    this.isWizardContainerExpandedAdress = !this.isWizardContainerExpandedAdress;
  }

  toggleWizardContainerGeneralOptional() {
    this.isWizardContainerGeneralOptionalExpanded = !this.isWizardContainerGeneralOptionalExpanded;
  }

  toogleWizardContainerExpedient() {
    this.isWizardContainerDigitalExpedientExpanded = !this.isWizardContainerDigitalExpedientExpanded;
  }

  toogleWizardContainerEvaluations() {
    this.isWizardContainerEvaluationsExpanded = !this.isWizardContainerEvaluationsExpanded;
  }

  selectButton(buttonId: string) {
    this.selectedButton = buttonId;

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    urlParams.set('select', buttonId);
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
  }

  toggleEditMode() {
    this.edit = !this.edit;
    this.getCatalogs();
    this.getVacancies();
    const candidateObservable = this.puzzleService.getCandidate(this.id, false);
    const countriesObservable = this.puzzleService.getCountriesOther(false);
    const gendersObservable = this.puzzleService.getGenders();

    forkJoin({
      candidate: candidateObservable,
      countries: countriesObservable,
      genders: gendersObservable,
    }).subscribe(({ candidate, countries, genders }) => {
      this.initializeAddressInfo(candidate);
      this.candidate = candidate;
      this.countries = countries;
      this.genders = genders;
      this.states = countries.ctR_STATES;
      this.states2 = countries.ctR_STATES;

      const currentState = this.states.find(state => state.stS_ID === candidate.cdT_ADDRESS_INFO.adR_STATE_ID);
      if (currentState) {
        this.selectedState = currentState;
        this.cities = currentState.stS_CITIES || [];
        this.selectedCity = this.cities.find(city => city.ctY_ID === candidate.cdT_ADDRESS_INFO.adR_CITY_ID);
      }

      const birthState = this.states2.find(state => state.stS_ID === candidate.cdT_BIRTH_STATE_ID);
      if (birthState) {
        this.selectedBirthState = birthState;
        this.cities2 = birthState.stS_CITIES || [];
        this.selectedBirthCity = this.cities2.find(city => city.ctY_ID === candidate.cdT_BIRTH_CITY_ID);
      }

    });
  }

  onTab(event: KeyboardEvent, nextElementId: string) {
    event.preventDefault();
    const nextElement = document.getElementById(nextElementId);
    if (nextElement) {
      nextElement.focus();
    }
  }

  trackByFn(index, item) {
    return item.gndR_ID;
  }

  trackByVacancyFn(index, item) {
    return item.vacanT_ID;
  }

  trackByStateFn(index, item) {
    return item.stS_ID;
  }

  trackByCityFn2(index, item) {
    return item.ctY_ID;
  }

  trackByBirthStateFn(index, item) {
    return item.stS_ID;
  }

  handleKeydownAdress(event: KeyboardEvent) {
    if (event.code === 'Space' || event.code === 'Enter') {
      event.preventDefault();
      this.toggleWizardContainerAdress();
    }
  }

  handleKeydownGeneralData(event: KeyboardEvent) {
    if (event.code === 'Space' || event.code === 'Enter' || event.code === 'Enter') {
      event.preventDefault();
      this.toggleWizardContainerGeneralOptional();
    }
  }

  handleKeydownContainerExpedient(event: KeyboardEvent) {
    if (event.code === 'Space' || event.code === 'Enter') {
      event.preventDefault();
      this.toogleWizardContainerExpedient();
    }
  }

  handleKeydownContainerEvaluations(event: KeyboardEvent) {
    if (event.code === 'Space' || event.code === 'Enter') {
      event.preventDefault();
      this.toogleWizardContainerEvaluations();
    }
  }

  onSpacePress(event: KeyboardEvent) {
    if (document.activeElement === event.target) {
    }
    event.preventDefault();
  }

  onEnterPress(event: KeyboardEvent) {
    if (document.activeElement === event.target) {
    }
    event.preventDefault();
  }

  onFocus(event: FocusEvent) {
    const target = event.target as HTMLElement;
    this.renderer.addClass(target, 'focused');
  }

  onBlur(event: FocusEvent) {
    const target = event.target as HTMLElement;
    this.renderer.removeClass(target, 'focused');
  }

  getTranslatedStatus(status: string): string {
    const statusKey = status.replace(/\s+/g, '').toLowerCase();
    return this.translate.instant(statusKey);
  }

  openChat(element) {
    let dialog = this.dialog.open(MessagerChatComponent, {
      width: '90vw',
      maxHeight: '90vh',
      maxWidth: '90vw',
      data: { data: element },
    });

    dialog.afterClosed().subscribe((result) => { });
  }

  isTokenExpired(expirationDate) {
    let rightNow = new Date();
    let newDate = new Date(expirationDate);

    if (rightNow < newDate) {
      return true;
    } else {
      return false;
    }
  }

  createCandidateToken(candidateId: string) {
    if (this.isFreemium) {
      this.dialog.open(FreemiumDialogComponent, {
        panelClass: 'freemium-dialog',
        width: '500px',
        maxWidth: '95vw',
      });
      return;
    }
    this.puzzleService.createCandidateToken(candidateId).subscribe((response) => {
      if (response.isSuccess && response.data) {
        const token = response.data;
        this.openDialog(3, token);
        this.getCandidate();
      } else {
        const errorMessage = this.translate.instant(response.message);
        this.openDialog(0, errorMessage);
      }
    }, error => {
      console.error("Error al solicitar la creación del token:", error);
    });
  }

  removeSpecialCharactersAndSpaces(text: string): string {
    text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return text.replace(/[^a-zA-Z0-9]/g, '');
  }

  openDialog(mode: number, message: string) {
    let data: string[] = [];
    switch (mode) {
      case 0: {
        data.push('Error');
        data.push(message);
        const dialogRef = this.dialog.open(ResponseSmallDialogComponent, {
          width: '500px',
          /* height: '400px', */
          data: data,
        });
        return dialogRef;
      }
      case 1: {
        data.push('Exito');
        data.push(message);

        const dialogRef = this.dialog.open(ResponseSmallDialogComponent, {
          width: '500px',
          /* height: '400px', */
          data: data,
        });
        return dialogRef;
      }
      case 2: {
        data.push('loading');
        data.push(message);

        const dialogRef = this.dialog.open(ResponseSmallDialogComponent, {
          width: '500px',
          /* height: '400px', */
          data: data,
        });
        return dialogRef;
      }
      case 3: {
        if (this.isFreemium) {
          this.dialog.open(FreemiumDialogComponent, {
            panelClass: 'freemium-dialog',
            width: '500px',
            maxWidth: '95vw',
          });
          return;
        }
        var dummy = document.createElement('input');
        document.body.appendChild(dummy);
        const userToken = this.localService.getJsonValue('token');
        const companyName = this.removeSpecialCharactersAndSpaces(userToken.data.companyName);
        const urlWithToken = environment.candidateUrl + companyName + '/CD?id=' + message;
        dummy.value = urlWithToken;
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);
        const clipboardMessage = this.translate.instant('candidateToken');
        const titleMessage = this.translate.instant('candidateDeleteStatusTitle');
        data.push('Exito');
        data.push(clipboardMessage);

        const dialogRef = this.dialog.open(ResponseSmallDialogComponent, {
          width: '500px',
          /* height: '400px', */
          data: data,
        });
        return dialogRef;
      }
    }
  }

  deleteCandidate(candidate: Candidate) {

    const deleteTitle = this.translate.instant('candidateDeleteCandidateTitle');
    const deleteConfirmMessage = this.translate.instant('candidateDeleteCandidateMessage', { name: candidate.cdT_NAME });

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: [deleteTitle, deleteConfirmMessage],
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let request = new UpdateCandidateStaPro();
        request.cdT_ACTIVE = false;
        request.cdT_ID = candidate.cdT_ID;
        this.puzzleService.deleteCandidate(request).subscribe((response) => {
          if (response.isSuccess) {
            const successMessage = this.translate.instant(response.message);
            this.openResponseDialog(this.translate.instant('candidateDeleteStatusTitle'), successMessage);
          } else {
            const errorMessage = this.translate.instant(response.message);
            this.openResponseDialog(this.translate.instant('candidateDeleteStatusTitle2'), errorMessage);
          }
          this.router.navigate(['/home/candidateslist']);
        });
      }
    });
  }

  duplicateCandidate(candidate: Candidate) {

    this.duplicateDialogRef = this.dialog.open(DuplicateCandidateComponent, {
      width: '500px',
      data: candidate,
    });

    this.duplicateDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.isSuccess) {
          const successMessage = this.translate.instant(result.message);
          this.openResponseDialog('Exito', successMessage);
        } else {
          const errorMessage = this.translate.instant(result.message);
          this.openResponseDialog('Error', errorMessage);
        }
      }
    });
  }

  openResponseDialog(response: string, message?: string, message2?: string) {
    let data: string[] = [];
    if (response == 'Error') {
      data.push('Error');
      data.push(message);
      const dialogRef = this.dialog.open(ResponseSmallDialogComponent, {
        width: '500px',
        /* height: '400px', */
        data: data,
      });
    } else if (response == 'Exito') {
      data.push('Exito');
      data.push(message);
      if (message2) data.push(message2);
      const dialogRef = this.dialog.open(ResponseSmallDialogComponent, {
        width: '500px',
        /* height: '400px', */
        data: data,
      });
    }
  }

  statusChange(element, mode) {
    let dialog = this.dialog.open(ChangeStatusComponent, {
      width: '500px',
      data: { data: element, mode: mode },
    });

    dialog.afterClosed().subscribe((result) => {
      if (result == 1) {
        this.getCandidate();
      }
    });
  }

  toggleBadgeVisibility(element: Candidate) {
    if (element.candidateCommentsSummary) {
      if (element.candidateCommentsSummary.ccS_HAS_NEW_MESSAGE) {
        let candidateComments: CandidateCommentsSummary = {
          ccS_USER_ID:
            element.candidateCommentsSummary.ccS_USER_ID,
          ccS_CANDIDATE_ID:
            element.candidateCommentsSummary.ccS_CANDIDATE_ID,
        };
        this.puzzleService
          .resetCandidateComments(candidateComments)
          .subscribe((response) => {
            element.candidateCommentsSummary.ccS_HAS_NEW_MESSAGE = false;
          });
      }
    }
  }

  base64toFile(data, filename) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  goBack() {
    /* const allowedDomains = ['haibuhr.com', 'devel.haibuhr.com', 'localhost:4200'];

    // Obtener el referrer (página anterior)
    const previousUrl = document.referrer;

    // Si hay una URL anterior, verifica el dominio
    if (previousUrl) {
      const url = new URL(previousUrl);

      // Verifica si el dominio está en la lista permitida
      const isAllowedDomain = allowedDomains.some(domain => url.hostname.includes(domain));

      if (isAllowedDomain) {
        // Si el dominio es válido, regresa a la página anterior
        this.location.back();
        return;
      }
    } */

    // Si no hay referrer o no es un dominio permitido, redirige a candidatelist
    this.router.navigate(['/home/candidateslist']);
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault(); // Previene el comportamiento por defecto (abrir archivo en el navegador)
    this.dragging = true;
  }

  onDragLeave(event: DragEvent): void {
    this.dragging = false;
  }

  onDrop(event: DragEvent, mode: number = 0): void {
    event.preventDefault();
    this.dragging = false;

    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      this.fileChangeEvent({ target: { files: [file] } }, mode);
    }
  }
}

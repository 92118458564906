import {
  ChangeDetectorRef,
  Component,
  HostListener,
  NgZone,
  OnInit,
  Renderer2,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwUpdate } from '@angular/service-worker';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import { forkJoin } from 'rxjs';
import { LoadingDialogComponent } from './dialogs/loading-dialog/loading-dialog.component';
import { Meta, Title } from '@angular/platform-browser';
import { delay, filter } from 'rxjs/operators';
import { APIResponse } from './interfaces/response';
import { LocalService } from './services/local.service';
import { ViewportScroller } from '@angular/common';
import { UpdateDialogComponent } from './dialogs/update-dialog/update-dialog.component';
import AOS from 'aos';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  companyId: string = '';
  token: APIResponse = this.localService.getJsonValue('token');
  isMobileView: boolean = false;
  hasSubdomain: boolean = false;
  isMini: boolean = false;
  isAgency: boolean = false;

  constructor(
    private swUpdate: SwUpdate,
    private titleService: Title,
    private metaService: Meta,
    public dialog: MatDialog,
    private translate: TranslateService,
    private router: Router,
    private localService: LocalService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private viewportScroller: ViewportScroller,
    private zone: NgZone,
  ) {
    this.router.events
      .pipe(
        filter(
          (e): e is Scroll | NavigationEnd =>
            e instanceof Scroll || e instanceof NavigationEnd,
        ), // Definir el tipo de evento
      )
      .subscribe((e) => {
        const currentUrl = window.location.href;
        this.updateCanonicalUrl(currentUrl); // Actualizar la URL canónica
        setTimeout(() => {
          window.scrollTo(0, 0); // Mover el scroll a la parte superior
        }, 100);
      });

    this.initializeLanguage();
    if (this.token?.data?.comP_HAS_SUBDOMAIN) {
      this.hasSubdomain = this.token.data.comP_HAS_SUBDOMAIN;
    }
    if (this.token?.data?.isMiniCompany) {
      this.isMini = true;
    }
    if (this.token?.data?.isAgency) {
      this.isAgency = true;
    }
    this.swUpdate.available.subscribe(() => {
      localStorage.setItem('updateAccepted', 'true');
      this.clearCacheAndReload();
    });

    this.swUpdate.checkForUpdate();

    // this.showUpdateDialogIfNeeded();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.swUpdate.checkForUpdate();
        this.cdr.detectChanges();
        this.updateStyles();
      }
    });

    this.checkScreenSize();
  }

  clearCacheAndReload() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => {
          registration.unregister(); // Desregistra el Service Worker
        });

        if ('caches' in window) {
          caches
            .keys()
            .then((keys) => {
              return Promise.all(keys.map((key) => caches.delete(key)));
            })
            .then(() => {
              console.warn('Caché eliminada, recargando...');
              window.location.reload();
            });
        }
      });
    } else {
      window.location.reload();
    }
  }

  updateCanonicalUrl(url: string): void {
    const head = document.getElementsByTagName('head')[0];
    let link: HTMLLinkElement =
      head.querySelector(`link[rel='canonical']`) || null;

    if (!link) {
      link = this.renderer.createElement('link');
      this.renderer.setAttribute(link, 'rel', 'canonical');
      head.appendChild(link);
    }

    this.renderer.setAttribute(link, 'href', url);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkScreenSize();
  }

  checkScreenSize() {
    this.isMobileView = window.innerWidth <= 767;
  }

  ngOnInit(): void {
    AOS.init({
      duration: 1000, // Duración de la animación en milisegundos
      once: true, // Solo se ejecuta una vez
      offset: window.innerHeight * 0.2,
    });

    this.companyId = this.token?.data?.companyId;
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.updateSEO();
        window.scrollTo(0, 0);
      });
  }

  showUpdateDialogIfNeeded() {
    // localStorage.removeItem('updateAccepted');
    const updateAccepted = localStorage.getItem('updateAccepted');
    const url = window.location.href;

    if (updateAccepted && url.includes('home')) {
      forkJoin({
        title: this.translate.get('appUpdateTitle'),
        text: this.translate.get('appUpdateText'),
      }).subscribe((translations) => {
        let data: string[] = [translations.title, translations.text];

        let dialog = this.dialog.open(UpdateDialogComponent, {
          // LoadingDialogComponent
          panelClass: 'custom-update-dialog',
          width: 'auto',
          height: 'auto',
          maxHeight: '90vh',
          data: data,
        });

        dialog.afterClosed().subscribe((res) => {
          localStorage.removeItem('updateAccepted');
        });
      });
    }

    /* if (updateAccepted && url.includes('home')) {
      forkJoin({
        title: this.translate.get('appUpdateTitle'),
        text: this.translate.get('appUpdateText'),
      }).subscribe(translations => {
        let data: string[] = [
          translations.title,
          translations.text
        ];
  
        let dialog = this.dialog.open(LoadingDialogComponent, {
          width: '500px',
          data: data,
        });
  
        dialog.afterClosed().subscribe((res) => {
          if ((!this.token.data.anU_HAS_SEEN_TUTORIAL || res) && this.hasSubdomain) {
            //this.router.navigate(['/home/catalogues/catalogs'], { queryParams: { id: this.companyId, step: 0 } });
            // this.router.navigate(['/home/dashboard'], { queryParams: { tour: true } });
            // this.router.navigate(['/home/catalogues/catalogs'], { queryParams: { id: this.companyId, currentSelection: 'button4', tour: true } });
            // this.router.navigate(['/home/catalogues/catalogs'], { queryParams: { id: this.companyId, currentSelection: 'button7', tour: true } });
            // this.router.navigate(['/home/candidateslist'], { queryParams: { tour: 'duplicate' } });
            // this.router.navigate(['/home/candidateslist'], { queryParams: { tour: 'shareCandidates' } });
          }
        });
  
        localStorage.removeItem('updateAccepted');
      });
    } */
  }

  initializeLanguage() {
    const browserLang = this.translate.getBrowserLang();
    let appLang = this.mapBrowserLangToAppLang(browserLang);
    this.translate.use(appLang);
  }
  mapBrowserLangToAppLang(browserLang: string): string {
    const langMap = {
      en: 'eng',
      es: 'esp',
    };
    return langMap[browserLang] || 'eng';
  }

  title = 'Haibu Hr';

  updateStyles() {
    const stylesheet = document.getElementById(
      'main-styles',
    ) as HTMLLinkElement;
    if (stylesheet) {
      const href = stylesheet.href;
      stylesheet.href = ''; // Limpia el href
      setTimeout(() => {
        stylesheet.href = href; // Reestablece el href
      }, 50); // Pequeño retraso para garantizar la recarga
    }
  }

  updateSEO() {
    let currentRoute = this.router.routerState.snapshot.root;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }
    const title = currentRoute.data['title'];
    const description = currentRoute.data['description'];
    const keywords = currentRoute.data['keywords'];
    const author = currentRoute.data['author'];

    if (title) {
      this.titleService.setTitle(title);
    }

    if (description) {
      this.metaService.updateTag({ name: 'description', content: description });
    }

    if (keywords) {
      this.metaService.updateTag({ name: 'keywords', content: keywords });
    }

    if (author) {
      this.metaService.updateTag({ name: 'author', content: author });
    }

    const metaTags = ['description', 'keywords', 'author'];
    metaTags.forEach((tag) => {
      if (!currentRoute.data[tag]) {
        this.metaService.removeTag(`name='${tag}'`);
      }
    });
  }
}

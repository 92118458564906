import { Component, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import Quill from 'quill';
import { GoogleAccountService } from 'src/app/services/google-account.service';
import { LocalService } from 'src/app/services/local.service';
import { PuzzleService } from 'src/app/services/puzzle.service';
import { ResponseDialogComponent } from '../response-dialog/response-dialog.component';

export function descriptionRequiredValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    // si hay un img o video no se valida
    if (control.value?.includes('<img') || control.value?.includes('<video')) {
      return null;
    }
    const cleanedContent = control.value?.replace(/<[^>]*>/g, '').trim();
    return cleanedContent && cleanedContent.length > 0 ? null : { required: true };
  };
}

@Component({
  selector: 'app-share-candidates-dialog',
  templateUrl: './share-candidates-dialog.component.html',
  styleUrls: ['./share-candidates-dialog.component.css'],
})
export class ShareCandidatesDialogComponent implements OnInit {
  sending: boolean = false;
  usuariosFiltrados = [];
  usuariosFiltrados2 = [];
  usuariosFiltrados3 = [];
  allUsers = [];
  pageSize = 1000;
  pageIndex = 0;
  id: string;
  selectedUsers = [];
  selectedUsers2 = [];
  selectedUsers3 = [];
  selectedCandidates = [];
  currentUser: string;
  emptyUsers: boolean = false;
  editSubjectMode: boolean = false;
  companyLogoRoute: string = '';
  editIcon: SafeResourceUrl = '../../../assets/icons/edit-icon.svg';
  closeIcon: SafeResourceUrl = '../../assets/icons/close-icon.svg';
  miFormulario: FormGroup;
  subject: string;
  userSearchControl = new FormControl();
  userSearchControl2 = new FormControl();
  userSearchControl3 = new FormControl();
  totalUsers: number = 0;
  googleAccount: boolean = false;
  isAdvanced: boolean = false;
  editorOptions = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ font: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['clean'],
      ['image'],
    ],
  };
  activeCC: boolean = false;
  @ViewChild('select1') select1: MatSelect;
  @ViewChild('select2') select2: MatSelect;
  @ViewChild('select3') select3: MatSelect;

  constructor(
    public dialog: MatDialog,
    private translate: TranslateService,
    private puzzleService: PuzzleService,
    private localService: LocalService,
    private renderer: Renderer2,
    private fb: FormBuilder,
    private google: GoogleAccountService,
    private dialogRef: MatDialogRef<ShareCandidatesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.selectedCandidates = data.selectedCandidates; // Recibir los candidatos seleccionados
    this.subject = data.subject;

    let tokencito = this.localService.getJsonValue('token');
    if (tokencito.data.companyId != null) {
      this.id = tokencito.data.companyId;
      this.companyLogoRoute = tokencito.data.compLogoRoute;
    }
    this.isAdvanced = tokencito.data.isAdvanced;

    this.currentUser = tokencito.data.id.toLowerCase();
  }

  ngOnInit(): void {
    this.miFormulario = this.fb.group({
      message: ['', [descriptionRequiredValidator()]],
      subject: [
        { value: this.subject, disabled: true },
        [Validators.required],
      ], // Inyectar el subject y deshabilitar por defecto
    });
    this.getUsers();
    this.googleAccount = this.google.getGoogleAccount();
  }

  getUsers(): void {
    this.puzzleService
      .getAllUsers(
        this.pageSize,
        this.pageIndex,
        null,
        'Activos',
        this.id,
        null,
        true,
      )
      .subscribe(
        (users) => {
          this.allUsers = users.map((user) => ({
            id: user.id.toLowerCase(),
            nombre: user.anU_FULL_NAME,
            email: user.email,
          }));

          //ordenar por nombre
          this.allUsers.sort((a, b) => a.nombre.localeCompare(b.nombre));

          // quitar si ya se selecciono
          this.usuariosFiltrados = this.allUsers.filter(
            (usuario) =>
              !this.selectedUsers.find(
                (selectedUser) => selectedUser.id === usuario.id,
              ),
          );

          this.usuariosFiltrados2 = this.allUsers.filter(
            (usuario) =>
              !this.selectedUsers2.find(
                (selectedUser) => selectedUser.id === usuario.id,
              ) && usuario.id !== this.currentUser,
          );

          this.usuariosFiltrados3 = this.allUsers.filter(
            (usuario) =>
              !this.selectedUsers3.find(
                (selectedUser) => selectedUser.id === usuario.id,
              ) && usuario.id !== this.currentUser,
          );

          /* if (this.usuariosFiltrados.length === 0) {
            // close dialog and show message
            this.dialogRef.close({
              isSuccess: false,
              message: 'noUsersAvailable',
            });
          } */

          const usersAux = this.usuariosFiltrados;
          const usersAux2 = this.usuariosFiltrados2;
          const usersAux3 = this.usuariosFiltrados3;
          this.totalUsers = this.usuariosFiltrados.length;

          this.userSearchControl.valueChanges.subscribe((search) => {
            this.usuariosFiltrados = usersAux.filter((element) =>
              `${element.nombre} (${element.email})`
                .toLowerCase()
                .includes(search?.toLowerCase() || ''),
            );
          });

          this.userSearchControl2.valueChanges.subscribe((search) => {
            this.usuariosFiltrados2 = usersAux2.filter((element) =>
              `${element.nombre} (${element.email})`
                .toLowerCase()
                .includes(search?.toLowerCase() || ''),
            );
          });

          this.userSearchControl3.valueChanges.subscribe((search) => {
            this.usuariosFiltrados3 = usersAux3.filter((element) =>
              `${element.nombre} (${element.email})`
                .toLowerCase()
                .includes(search?.toLowerCase() || ''),
            );
          });
        },
        (error) => {},
      );
  }

  shareCandidates() {
    if (this.sending) {
      return;
    }
    this.miFormulario.markAllAsTouched();
    if (this.miFormulario.invalid || this.selectedUsers.length === 0) {
      if (this.selectedUsers.length === 0) {
        this.emptyUsers = true;
      }
      return;
    }
    this.sending = true;
    if (!this.googleAccount) {
      const data = {
        candidatesIds: this.selectedCandidates.map((candidate) => ({
          candidateId: candidate.cdT_ID,
          candidateName: `${candidate.cdT_NAME} ${candidate.cdT_PATERNAL_SURNAME} ${candidate.cdT_MATERNAL_SURNAME}`,
          cv: candidate.cv?.cdoC_DOCUMENT_ROUTE,
        })),
        userList: this.selectedUsers,
        message: this.miFormulario.get('message').value,
        subject: this.miFormulario.get('subject').value,
        companyLogoRoute: this.companyLogoRoute,
        companyId: this.id,
      };
      this.puzzleService.shareCandidatesMail(data).subscribe((response) => {
        this.dialogRef.close({
          isSuccess: response.isSuccess,
          message: response.message,
        });
      });
    } else {
      const gmailData = {
        candidatesIds: this.selectedCandidates.map((candidate) => ({
          candidateId: candidate.cdT_ID,
          candidateName: `${candidate.cdT_NAME} ${candidate.cdT_PATERNAL_SURNAME} ${candidate.cdT_MATERNAL_SURNAME}`,
          cv: candidate.cv?.cdoC_DOCUMENT_ROUTE,
        })),
        userList: this.selectedUsers,
        message: this.miFormulario.get('message').value,
        subject: this.miFormulario.get('subject').value,
        companyLogoRoute: this.companyLogoRoute,
        companyId: this.id,
        cc: this.selectedUsers2,
        bcc: this.selectedUsers3,
      };

      this.puzzleService
        .shareCandidatesGmail(gmailData)
        .subscribe((response) => {
          this.dialogRef.close({
            isSuccess: response.isSuccess,
            message: response.message,
          });
        });
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  toggleEditSubject() {
    this.editSubjectMode = !this.editSubjectMode;

    if (this.editSubjectMode) {
      // Habilitar el campo subject
      this.miFormulario.get('subject').enable();
    } else {
      // Deshabilitar el campo subject
      this.miFormulario.get('subject').disable();
    }
  }

  onParticipantSelected(event: MatSelectChange) {
    let user = event.value;
    // si es texto, se arma el objeto
    if (typeof user === 'string') {
      // validar si es un email valido
      if (!this.validateEmail(user)) {
        this.openResponseDialog(
          'Error',
          this.translate.instant('invalidEmail'),
        );
        if (this.select1) {
          this.select1.value = null;
          this.select1.writeValue(null);
          if (this.select1?.ngControl?.control) {
            this.select1.ngControl.control.setValue(null, { emitEvent: false });
          }
        }
        return;
      }

      const userAux = {
        id: user.toLowerCase(),
        nombre: user,
        email: user,
      };
      user = userAux;
    }
    // checar si ya esta seleccionado
    const index = this.selectedUsers.findIndex(
      (selectedUser) => selectedUser.email === user.email,
    );
    if (index === -1) {
      this.selectedUsers.push(user);
      this.usuariosFiltrados = this.usuariosFiltrados.filter(
        (usuario) => usuario.email !== user.email,
      );
    }

    this.updateFilteredUsersByEmail();

    if (this.select1) {
      this.select1.value = null;
      this.select1.writeValue(null);
      if (this.select1?.ngControl?.control) {
        this.select1.ngControl.control.setValue(null, { emitEvent: false });
      }
    }
  }

  updateFilteredUsersByEmail() {
    this.usuariosFiltrados = this.allUsers.filter(
      (usuario) =>
        !this.selectedUsers.find(
          (selectedUser) => selectedUser.email === usuario.email,
        ),
    );

    this.usuariosFiltrados2 = this.allUsers.filter(
      (usuario) =>
        !this.selectedUsers2.find(
          (selectedUser) => selectedUser.email === usuario.email,
        ),
    );

    this.usuariosFiltrados3 = this.allUsers.filter(
      (usuario) =>
        !this.selectedUsers3.find(
          (selectedUser) => selectedUser.email === usuario.email,
        ),
    );

    const usersAux = this.usuariosFiltrados;
    const usersAux2 = this.usuariosFiltrados2;
    const usersAux3 = this.usuariosFiltrados3;

    this.userSearchControl.valueChanges.subscribe((search) => {
      this.usuariosFiltrados = usersAux.filter((element) =>
        `${element.nombre} (${element.email})`
          .toLowerCase()
          .includes(search?.toLowerCase() || ''),
      );
    });

    this.userSearchControl2.valueChanges.subscribe((search) => {
      this.usuariosFiltrados2 = usersAux2.filter((element) =>
        `${element.nombre} (${element.email})`
          .toLowerCase()
          .includes(search?.toLowerCase() || ''),
      );
    });

    this.userSearchControl3.valueChanges.subscribe((search) => {
      this.usuariosFiltrados3 = usersAux3.filter((element) =>
        `${element.nombre} (${element.email})`
          .toLowerCase()
          .includes(search?.toLowerCase() || ''),
      );
    });
  }

  onParticipantSelected2(event: MatSelectChange) {
    let user = event.value;
    // si es texto, se arma el objeto
    if (typeof user === 'string') {
      // validar si es un email valido
      if (!this.validateEmail(user)) {
        this.openResponseDialog(
          'Error',
          this.translate.instant('invalidEmail'),
        );
        if (this.select2) {
          this.select2.value = null;
          this.select2.writeValue(null);
          if (this.select2?.ngControl?.control) {
            this.select2.ngControl.control.setValue(null, { emitEvent: false });
          }
        }
        return;
      }

      const userAux = {
        id: user.toLowerCase(),
        nombre: user,
        email: user,
      };
      user = userAux;
    }
    // checar si ya esta seleccionado
    const index = this.selectedUsers2.findIndex(
      (selectedUser) => selectedUser.email === user.email,
    );
    if (index === -1) {
      this.selectedUsers2.push(user);
      this.usuariosFiltrados2 = this.usuariosFiltrados2.filter(
        (usuario) => usuario.email !== user.email,
      );
    }

    this.updateFilteredUsersByEmail();

    if (this.select2) {
      this.select2.value = null;
      this.select2.writeValue(null);
      if (this.select2?.ngControl?.control) {
        this.select2.ngControl.control.setValue(null, { emitEvent: false });
      }
    }
  }

  onParticipantSelected3(event: MatSelectChange) {
    let user = event.value;
    // si es texto, se arma el objeto
    if (typeof user === 'string') {
      // validar si es un email valido
      if (!this.validateEmail(user)) {
        this.openResponseDialog(
          'Error',
          this.translate.instant('invalidEmail'),
        );
        if (this.select3) {
          this.select3.value = null;
          this.select3.writeValue(null);
          if (this.select3?.ngControl?.control) {
            this.select3.ngControl.control.setValue(null, { emitEvent: false });
          }
        }
        return;
      }

      const userAux = {
        id: user.toLowerCase(),
        nombre: user,
        email: user,
      };
      user = userAux;
    }
    // checar si ya esta seleccionado
    const index = this.selectedUsers3.findIndex(
      (selectedUser) => selectedUser.email === user.email,
    );
    if (index === -1) {
      this.selectedUsers3.push(user);
      this.usuariosFiltrados3 = this.usuariosFiltrados3.filter(
        (usuario) => usuario.email !== user.email,
      );
    }

    this.updateFilteredUsersByEmail();

    if (this.select3) {
      this.select3.value = null;
      this.select3.writeValue(null);
      if (this.select3?.ngControl?.control) {
        this.select3.ngControl.control.setValue(null, { emitEvent: false });
      }
    }
  }

  removeUser(user) {
    this.selectedUsers = this.selectedUsers.filter(
      (selectedUser) => selectedUser.id !== user.id,
    );
    this.usuariosFiltrados.push(JSON.parse(JSON.stringify(user)));
    //ordenar por nombre
    this.usuariosFiltrados.sort((a, b) => a.nombre.localeCompare(b.nombre));
  }

  removeUser2(user) {
    this.selectedUsers2 = this.selectedUsers2.filter(
      (selectedUser) => selectedUser.id !== user.id,
    );
    this.usuariosFiltrados2.push(JSON.parse(JSON.stringify(user)));
    //ordenar por nombre
    this.usuariosFiltrados2.sort((a, b) => a.nombre.localeCompare(b.nombre));
  }

  removeUser3(user) {
    this.selectedUsers3 = this.selectedUsers3.filter(
      (selectedUser) => selectedUser.id !== user.id,
    );
    this.usuariosFiltrados3.push(JSON.parse(JSON.stringify(user)));
    //ordenar por nombre
    this.usuariosFiltrados3.sort((a, b) => a.nombre.localeCompare(b.nombre));
  }

  onFocus(event: FocusEvent) {
    const target = event.target as HTMLElement;
    this.renderer.addClass(target, 'focused');
  }

  onBlur(event: FocusEvent) {
    const target = event.target as HTMLElement;
    this.renderer.removeClass(target, 'focused');
  }

  onEditorCreated(quill: Quill) {
    quill.root.addEventListener('paste', (e: ClipboardEvent) => {
      const clipboardData = e.clipboardData;
      if (clipboardData?.items) {
        for (let i = 0; i < clipboardData.items.length; i++) {
          const item = clipboardData.items[i];
          // Comprobamos si es un tipo de imagen
          if (item.type.indexOf('image') !== -1) {
            const file = item.getAsFile();
            if (!file) {
              continue;
            }
            const reader = new FileReader();
            reader.onload = (event: any) => {
              const base64Image = event.target.result;
              // Insertamos la imagen donde esté el cursor
              const range = quill.getSelection(true);
              quill.insertEmbed(range.index, 'image', base64Image, 'user');
            };
            reader.readAsDataURL(file);
            // Evitar el pegado por defecto de la imagen en bruto
            e.preventDefault();
            break;
          }
        }
      }
    });
  }

  activeActionCC() {
    this.activeCC = true;
  }

  validateEmail(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  openResponseDialog(response: string, message?: string) {
    let data: string[] = [];
    if (response == 'Error') {
      data.push('Error');
      data.push(message);
      const dialogRef = this.dialog.open(ResponseDialogComponent, {
        width: '500px',
        /* height: '400px', */
        data: data,
      });
    } else if (response == 'Exito') {
      data.push('Exito');
      data.push(message);

      const dialogRef = this.dialog.open(ResponseDialogComponent, {
        width: '500px',
        /* height: '400px', */
        data: data,
      });
    }
  }
}

<div class="ml-auto w-full text-right justify-end -mt-5 -mr-5">
    <button id="closeModalChangeStatus2" mat-icon-button (click)="closeModal()" tabindex="-1">
        <mat-icon class="close-x-primary">close</mat-icon>
    </button>
</div>

<!-- Contenedor principal -->
<div class="day-calendar-events-container">
    <!-- Encabezado con la fecha -->
    <div class="header">
        <div class="text-center w-full">
            <div class="weekday mb-1">
                {{ day.date | date:'EEE':undefined:currentLocale | uppercase }}
            </div>
            <div class="day-number">
                {{ day.date | date:'d':undefined:currentLocale }}
            </div>
        </div>
    </div>

    <!-- Lista de eventos -->
    <div class="events-list">
        <div class="event-item" *ngFor="let event of day.events" (click)="openEvent(event)">
            <!-- Puedes ajustar los estilos a tu gusto -->
            <div class="event-color-indicator" [style.backgroundColor]="event.color?.primary"></div>
            <div class="event-details">
                <!-- Hora de inicio y fin, si no es un evento de día completo -->
                <div class="event-time" *ngIf="!event.allDay">
                    <span *ngIf="isDifferentDay(event.start, day.date)" class="font-bold">
                        ({{ formatDate(event.start) }})
                    </span>
                    {{ event.start | date:'h:mma' }} -
                    <span *ngIf="isDifferentDay(event.start, event.end)" class="font-bold">
                        ({{ formatDate(event.end) }})
                    </span>
                    {{ event.end | date:'h:mma' }}
                </div>
                <!-- Título -->
                <div class="event-title">
                    {{ event.title }}
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { ResponseDialogComponent } from '../response-dialog/response-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { LocalService } from 'src/app/services/local.service';
import { PuzzleService } from 'src/app/services/puzzle.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-add-or-edit-event-calendar',
  templateUrl: './add-or-edit-event-calendar.component.html',
  styleUrls: ['./add-or-edit-event-calendar.component.css'],
})
export class AddOrEditEventCalendarComponent implements OnInit {
  event: any;
  miFormulario: FormGroup = this.fb.group({
    summary: ['', Validators.required],
    description: [''],
    location: [''],
    allDay: [false],
    hangoutLink: [''],
  });
  hourStartForm: FormGroup = this.fb.group({
    hour: [null],
    minute: [null],
    ampm: ['AM'],
  });
  hourEndForm: FormGroup = this.fb.group({
    hour: [null],
    minute: [null],
    ampm: ['AM'],
  });

  selectedParticipant: string;
  selectedUsers = [];
  usuariosFiltrados = [];
  allUsers = [];
  currentUser: string;
  totalUsers = 0;
  userSearchControl = new FormControl();
  id: string;
  pageSize = 1000;
  pageIndex = 0;
  toError: boolean = false;
  loading: boolean = false;
  startDate: string;
  endDate: string;
  isStartInvalid: boolean = false;
  isEndInvalid: boolean = false;
  currentLang: string;
  keepHour: boolean = false;
  @ViewChild('select4') select4: MatSelect;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private translate: TranslateService,
    private localService: LocalService,
    private puzzleService: PuzzleService,
    private renderer: Renderer2,
    private dialogRef: MatDialogRef<AddOrEditEventCalendarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data.event) {
      this.event = data.event;
    }

    if (data.emails) {
      const emails = data.emails;
      this.selectedUsers = emails.filter((email) => this.validateEmail(email));
    }

    if (data.summary) {
      this.miFormulario.get('summary').setValue(data.summary);
    }

    if (data.keepHour) {
      this.keepHour = data.keepHour;
    }

    if (this.event) {
      this.initEvent();
    } else {
      if (data.startDate) {
        this.initHours(data.startDate);
      } else {
        this.initHours(new Date());
      }
    }

    let tokencito = this.localService.getJsonValue('token');
    if (tokencito.data.companyId != null) {
      this.id = tokencito.data.companyId;
    }

    this.currentUser = tokencito.data.id.toLowerCase();
  }

  ngOnInit(): void {
    this.getUsers();

    // si hourStartForm cambia, sumar 30 min a hourEndForm
    this.hourStartForm.valueChanges.subscribe((value) => {
      const endHour = this.hourEndForm.get('hour').value;
      const endMinute = this.hourEndForm.get('minute').value;
      const endAmPm = this.hourEndForm.get('ampm').value;

      const { hours24, minutes } = this.convertTo24Hour(
        value.hour,
        value.minute,
        value.ampm,
      );

      // Si la hora de inicio es mayor que la de fin, sumar 30 min a la de fin
      if (
        hours24 > endHour ||
        (hours24 === endHour && minutes >= endMinute)
      ) {
        // Crear nuevo objeto Date a partir de la hora de inicio
        const newDate = new Date();
        newDate.setHours(hours24);
        newDate.setMinutes(minutes);
        newDate.setSeconds(0);

        // Sumar 30 min
        newDate.setMinutes(newDate.getMinutes() + 30);

        // Convertir a formato 12h
        const { hour12, ampm } = this.convert24To12(newDate.getHours());

        // Actualizar hora de fin
        this.hourEndForm.setValue({
          hour: hour12,
          minute: newDate.getMinutes(),
          ampm: ampm,
        });
      }
    });
  }

  initEvent(): void {
    this.miFormulario.reset({
      summary: this.event.title,
      description: this.event.description || '',
      location: this.event.location || '',
      allDay: this.event.allDay,
      hangoutLink: this.event.hangoutLink || '',
    });

    if (this.event.attendees) {
      this.selectedUsers = this.event.attendees.map(
        (attendee) => attendee.email,
      );
      this.updateFilteredUsers();
    }

    const start = new Date(this.event.start);
    const end = new Date(this.event.end);

    // ----- INICIO -----
    const startHour = start.getHours(); // 0–23
    let startMinute = start.getMinutes();
    const startAmPm = startHour >= 12 ? 'PM' : 'AM';

    // Convertir a formato 12 h
    let startHour12 = startHour;
    if (startHour === 0) {
      // 0 => 12 AM
      startHour12 = 12;
    } else if (startHour > 12) {
      // Ej. 13 => 1 PM
      startHour12 = startHour - 12;
    }

    this.hourStartForm.setValue({
      hour: startHour12,
      minute: startMinute,
      ampm: startAmPm,
    });

    // ----- FIN -----
    const endHour = end.getHours(); // 0–23
    let endMinute = end.getMinutes();
    const endAmPm = endHour >= 12 ? 'PM' : 'AM';

    // Convertir a formato 12 h
    let endHour12 = endHour;
    if (endHour === 0) {
      // 0 => 12 AM
      endHour12 = 12;
    } else if (endHour > 12) {
      // Ej. 13 => 1 PM
      endHour12 = endHour - 12;
    }

    this.hourEndForm.setValue({
      hour: endHour12,
      minute: endMinute,
      ampm: endAmPm,
    });

    // ----- FECHAS -----
    const startYear = start.getFullYear();
    const startMonth = String(start.getMonth() + 1).padStart(2, '0');
    const startDay = String(start.getDate()).padStart(2, '0');
    this.startDate = `${startYear}-${startMonth}-${startDay}`;

    const endYear = end.getFullYear();
    const endMonth = String(end.getMonth() + 1).padStart(2, '0');
    const endDay = String(end.getDate()).padStart(2, '0');
    this.endDate = `${endYear}-${endMonth}-${endDay}`;
  }

  initHours(date: Date): void {
    // Construye la fecha base en formato YYYY-MM-DD
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    // Inicialmente, startDate y endDate apuntan al mismo día
    this.startDate = `${year}-${month}-${day}`;
    this.endDate = `${year}-${month}-${day}`;

    if (!this.keepHour) {
      // ======== Caso: NO conservar la hora; usar la hora actual +1 ========
      const now = new Date(); // Hora local actual

      // START: hora actual
      const startHour = now.getHours();
      let startMinute = now.getMinutes();
      startMinute = Math.floor(startMinute / 5) * 5;
      const { hour12, ampm } = this.convert24To12(startHour);

      now.setMinutes(startMinute);

      this.hourStartForm.setValue({
        hour: hour12,
        minute: startMinute,
        ampm: ampm,
      });

      // END: crear un nuevo Date a partir de "now" y sumarle 30 minutos
      const endDateObj = new Date(now);
      endDateObj.setMinutes(endDateObj.getMinutes() + 30);

      // Si endDateObj cruza medianoche, su día aumentará automáticamente
      const endHour = endDateObj.getHours();
      let endMinute = endDateObj.getMinutes();
      endMinute = Math.floor(endMinute / 5) * 5;
      const { hour12: hour12End, ampm: ampmEnd } = this.convert24To12(endHour);

      endDateObj.setMinutes(endMinute);

      this.hourEndForm.setValue({
        hour: hour12End,
        minute: endMinute,
        ampm: ampmEnd,
      });

      const endH = endDateObj.getHours();
      // si es las 12:00 AM, se le suma un día
      if (endH === 0) {
        endDateObj.setDate(endDateObj.getDate() + 1);

        const endYear = endDateObj.getFullYear();
        const endMonth = String(endDateObj.getMonth() + 1).padStart(2, '0');
        const endDay2 = String(endDateObj.getDate()).padStart(2, '0');
        this.endDate = `${endYear}-${endMonth}-${endDay2}`;
      }
    } else {
      // ======== Caso: SÍ conservar la hora de "date" y sumarle 30 min al end ========
      // START: exactamente la hora de "date"
      const start = new Date(date);
      const startHour = start.getHours();
      const startMinute = start.getMinutes();
      const { hour12: startHour12, ampm: startAmPm } =
        this.convert24To12(startHour);

      this.hourStartForm.setValue({
        hour: startHour12,
        minute: startMinute,
        ampm: startAmPm,
      });

      // END: crear un nuevo Date a partir de "date" y sumarle 30 minutos
      const end = new Date(date);
      end.setMinutes(end.getMinutes() + 30);

      // Si pasa de 23:59, end saltará automáticamente al día siguiente
      const endHour = end.getHours();
      const endMinute = end.getMinutes();
      const { hour12: endHour12, ampm: endAmPm } = this.convert24To12(endHour);

      this.hourEndForm.setValue({
        hour: endHour12,
        minute: endMinute,
        ampm: endAmPm,
      });

      const endH = end.getHours();
      // si es las 12:00 AM, se le suma un día
      if (endH === 0) {
        const endYear = end.getFullYear();
        const endMonth = String(end.getMonth() + 1).padStart(2, '0');
        const endDay2 = String(end.getDate()).padStart(2, '0');
        this.endDate = `${endYear}-${endMonth}-${endDay2}`;
      }
    }
  }

  convert24To12(hour24: number): { hour12: number; ampm: 'AM' | 'PM' } {
    if (hour24 === 0) {
      // 0 => 12 AM
      return { hour12: 12, ampm: 'AM' };
    } else if (hour24 === 12) {
      // 12 => 12 PM
      return { hour12: 12, ampm: 'PM' };
    } else if (hour24 > 12) {
      // 13–23 => (13 - 12 = 1 PM), etc.
      return { hour12: hour24 - 12, ampm: 'PM' };
    } else {
      // 1–11 => AM
      return { hour12: hour24, ampm: 'AM' };
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  validateEmail(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  openResponseDialog(response: string, message?: string) {
    let data: string[] = [];
    if (response == 'Error') {
      data.push('Error');
      data.push(message);
      const dialogRef = this.dialog.open(ResponseDialogComponent, {
        width: '500px',
        /* height: '400px', */
        data: data,
      });
    } else if (response == 'Exito') {
      data.push('Exito');
      data.push(message);

      const dialogRef = this.dialog.open(ResponseDialogComponent, {
        width: '500px',
        /* height: '400px', */
        data: data,
      });
    }
  }

  onParticipantSelected(event: MatSelectChange): void {
    const email = event.value;

    // validar si es un email valido
    if (!this.validateEmail(email)) {
      this.openResponseDialog('Error', this.translate.instant('invalidEmail'));
      // resetear el input
      this.selectedParticipant = '';
      if (this.select4) {
        this.select4.value = null;
        this.select4.writeValue(null);
        if (this.select4?.ngControl?.control) {
          this.select4.ngControl.control.setValue(null, { emitEvent: false });
        }
      }
      return;
    }

    // resetear el input
    if (!this.selectedUsers.some((u) => u === email)) {
      this.selectedUsers.push(email);
      this.updateFilteredUsers();
    }
    // resetear el input
    this.selectedParticipant = '';
    if (this.select4) {
      this.select4.value = null;
      this.select4.writeValue(null);
      if (this.select4?.ngControl?.control) {
        this.select4.ngControl.control.setValue(null, { emitEvent: false });
      }
    }
  }

  updateFilteredUsers(): void {
    this.usuariosFiltrados = this.allUsers.filter(
      (usuario) =>
        !this.selectedUsers.find(
          (selectedUser) => selectedUser === usuario.email,
        ) && usuario.id !== this.currentUser,
    );

    //ordenar por nombre
    this.usuariosFiltrados.sort((a, b) => a.nombre.localeCompare(b.nombre));

    this.totalUsers = this.usuariosFiltrados.length;

    const usersAux = this.usuariosFiltrados;

    this.userSearchControl.valueChanges.subscribe((search) => {
      this.usuariosFiltrados = usersAux.filter((element) =>
        `${element.nombre} (${element.email})`
          .toLowerCase()
          .includes(search?.toLowerCase() || ''),
      );
    });
  }

  getUsers(): void {
    this.puzzleService
      .getAllUsers(
        this.pageSize,
        this.pageIndex,
        null,
        'Activos',
        this.id,
        null,
        true,
      )
      .subscribe(
        (users) => {
          this.allUsers = users.map((user) => ({
            id: user.id.toLowerCase(),
            nombre: user.anU_FULL_NAME,
            email: user.email,
          }));

          //ordenar por nombre
          this.allUsers.sort((a, b) => a.nombre.localeCompare(b.nombre));

          // quitar si ya se selecciono
          this.usuariosFiltrados = this.allUsers.filter(
            (usuario) =>
              !this.selectedUsers.find(
                (selectedUser) => selectedUser === usuario.email,
              ) && usuario.id !== this.currentUser,
          );

          this.totalUsers = this.usuariosFiltrados.length;

          this.updateFilteredUsers();
        },
        (error) => {},
      );
  }

  onFocus(event: FocusEvent) {
    const target = event.target as HTMLElement;
    this.renderer.addClass(target, 'focused');
  }

  onBlur(event: FocusEvent) {
    const target = event.target as HTMLElement;
    this.renderer.removeClass(target, 'focused');
  }

  sendEvent() {
    if (this.loading) {
      return;
    }

    this.miFormulario.markAllAsTouched();

    // Validar fechas
    let start = this.startDate;
    let end = this.endDate;
    const allDay = this.miFormulario.get('allDay').value;

    // Crear objetos Date a partir de las fechas
    const sDate = new Date(start);
    const eDate = new Date(end);

    if (sDate > eDate) {
      // Mostrar error y detener envío
      this.openResponseDialog(
        'Error',
        this.translate.instant('startDateGreaterThanEndDate'),
      );
      return;
    }

    // Si no es evento de todo el día, validar también las horas
    if (!allDay && sDate.getTime() === eDate.getTime()) {
      const startHour = Number(this.hourStartForm.get('hour').value);
      const startMinute = Number(this.hourStartForm.get('minute').value);
      const startAmpm = this.hourStartForm.get('ampm').value;
      const endHour = Number(this.hourEndForm.get('hour').value);
      const endMinute = Number(this.hourEndForm.get('minute').value);
      const endAmpm = this.hourEndForm.get('ampm').value;

      // Convertir hora de inicio a 24h
      let sHour24: number;
      if (startAmpm === 'AM') {
        // 12 AM => 0
        sHour24 = startHour === 12 ? 0 : startHour;
      } else {
        // PM
        // 12 PM => 12
        sHour24 = startHour === 12 ? 12 : startHour + 12;
      }

      // Convertir hora de fin a 24h
      let eHour24: number;
      if (endAmpm === 'AM') {
        // 12 AM => 0
        eHour24 = endHour === 12 ? 0 : endHour;
      } else {
        // PM
        // 12 PM => 12
        eHour24 = endHour === 12 ? 12 : endHour + 12;
      }

      // Validar que la hora de inicio no sea mayor (o igual en hora y minuto) que la de fin
      if (
        sHour24 > eHour24 ||
        (sHour24 === eHour24 && startMinute >= endMinute)
      ) {
        this.openResponseDialog(
          'Error',
          this.translate.instant('startTimeGreaterThanEndTime'),
        );
        return;
      }
    }

    if (this.miFormulario.invalid) {
      this.toError = true;
      return;
    }

    this.loading = true;

    if (this.event?.id) {
      this.updateEvent();
    } else {
      this.createEvent();
    }
  }

  // Convierte hora, minutos, y AM/PM a formato 24h
  convertTo24Hour(
    hour: number,
    minute: number,
    ampm: string,
  ): { hours24: number; minutes: number } {
    let hours24: number;

    if (ampm === 'AM') {
      // 12 AM es 0
      hours24 = hour === 12 ? 0 : hour;
    } else {
      // PM
      // 12 PM es 12 (no se le suma nada)
      hours24 = hour === 12 ? 12 : hour + 12;
    }

    return { hours24, minutes: minute };
  }

  createEvent(): void {
    let start = this.startDate;
    let end = this.endDate;

    if (!this.miFormulario.get('allDay').value) {
      // Tomar valores de inicio
      const startHour = +this.hourStartForm.get('hour').value;
      const startMinute = +this.hourStartForm.get('minute').value;
      const startAmPm = this.hourStartForm.get('ampm').value;

      // Convertir a 24h
      let startHour24: number;
      if (startAmPm === 'AM') {
        // 12 AM es 0
        startHour24 = startHour === 12 ? 0 : startHour;
      } else {
        // PM
        // 12 PM se queda en 12
        startHour24 = startHour === 12 ? 12 : startHour + 12;
      }

      // Formatear en dos dígitos
      const formattedHour = String(startHour24).padStart(2, '0');
      const formattedMinute = String(startMinute).padStart(2, '0');

      // Construir fecha/hora de inicio
      start = `${this.startDate}T${formattedHour}:${formattedMinute}:00.000Z`;

      // Tomar valores de fin
      const endHour = +this.hourEndForm.get('hour').value;
      const endMinute = +this.hourEndForm.get('minute').value;
      const endAmPm = this.hourEndForm.get('ampm').value;

      // Convertir a 24h
      let endHour24: number;
      if (endAmPm === 'AM') {
        // 12 AM es 0
        endHour24 = endHour === 12 ? 0 : endHour;
      } else {
        // PM
        // 12 PM se queda en 12
        endHour24 = endHour === 12 ? 12 : endHour + 12;
      }

      // Formatear en dos dígitos
      const formattedEndHour = String(endHour24).padStart(2, '0');
      const formattedEndMinute = String(endMinute).padStart(2, '0');

      // Construir fecha/hora de fin
      end = `${this.endDate}T${formattedEndHour}:${formattedEndMinute}:00.000Z`;
    }

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const eventData = {
      Summary: this.miFormulario.get('summary').value,
      Description: this.miFormulario.get('description').value,
      TimeZone: userTimeZone,
      Location: this.miFormulario.get('location').value,
      Attendees: this.selectedUsers,
    };

    if (this.miFormulario.get('allDay').value) {
      // si start y end son iguales, se le suma un día a end
      if (start === end) {
        const endAux = new Date(end);
        endAux.setDate(endAux.getDate() + 1);
        const endYear = endAux.getFullYear();
        const endMonth = String(endAux.getMonth() + 1).padStart(2, '0');
        const endDay = String(endAux.getDate()).padStart(2, '0');
        end = `${endYear}-${endMonth}-${endDay}`;
      }
      eventData['StartDate'] = start;
      eventData['EndDate'] = end;
    } else {
      eventData['StartDateTime'] = start;
      eventData['EndDateTime'] = end;
    }

    this.puzzleService.createCalendarEvent(eventData).subscribe({
      next: (response) => {
        this.openResponseDialog(
          'Exito',
          this.translate.instant('eventCreated'),
        );
        this.dialogRef.close({ isSuccess: true });
      },
      error: (error) => {
        this.openResponseDialog(
          'Error',
          this.translate.instant('errorCreatingEvent'),
        );
        this.loading = false;
      },
    });
  }

  updateEvent(): void {
    let start = this.startDate;
    let end = this.endDate;

    if (!this.miFormulario.get('allDay').value) {
      // Tomar valores de inicio
      const startHour = +this.hourStartForm.get('hour').value;
      const startMinute = +this.hourStartForm.get('minute').value;
      const startAmPm = this.hourStartForm.get('ampm').value;

      // Convertir a 24h
      let startHour24: number;
      if (startAmPm === 'AM') {
        // 12 AM es 0
        startHour24 = startHour === 12 ? 0 : startHour;
      } else {
        // PM
        // 12 PM se queda en 12
        startHour24 = startHour === 12 ? 12 : startHour + 12;
      }

      // Formatear en dos dígitos
      const formattedHour = String(startHour24).padStart(2, '0');
      const formattedMinute = String(startMinute).padStart(2, '0');

      // Construir fecha/hora de inicio
      start = `${this.startDate}T${formattedHour}:${formattedMinute}:00.000Z`;

      // Tomar valores de fin
      const endHour = +this.hourEndForm.get('hour').value;
      const endMinute = +this.hourEndForm.get('minute').value;
      const endAmPm = this.hourEndForm.get('ampm').value;

      // Convertir a 24h
      let endHour24: number;
      if (endAmPm === 'AM') {
        // 12 AM es 0
        endHour24 = endHour === 12 ? 0 : endHour;
      } else {
        // PM
        // 12 PM se queda en 12
        endHour24 = endHour === 12 ? 12 : endHour + 12;
      }

      // Formatear en dos dígitos
      const formattedEndHour = String(endHour24).padStart(2, '0');
      const formattedEndMinute = String(endMinute).padStart(2, '0');

      // Construir fecha/hora de fin
      end = `${this.endDate}T${formattedEndHour}:${formattedEndMinute}:00.000Z`;
    }

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const eventData = {
      EventId: this.event.id,
      Summary: this.miFormulario.get('summary').value,
      Description: this.miFormulario.get('description').value,
      TimeZone: userTimeZone,
      Location: this.miFormulario.get('location').value,
      Attendees: this.selectedUsers,
    };

    if (this.miFormulario.get('allDay').value) {
      // si start y end son iguales, se le suma un día a end
      if (start === end) {
        const endAux = new Date(end);
        endAux.setDate(endAux.getDate() + 1);
        const endYear = endAux.getFullYear();
        const endMonth = String(endAux.getMonth() + 1).padStart(2, '0');
        const endDay = String(endAux.getDate()).padStart(2, '0');
        end = `${endYear}-${endMonth}-${endDay}`;
      }
      eventData['StartDate'] = start;
      eventData['EndDate'] = end;
    } else {
      eventData['StartDateTime'] = start;
      eventData['EndDateTime'] = end;
    }
    this.puzzleService.editCalendarEvent(eventData).subscribe({
      next: (response) => {
        this.openResponseDialog('Exito', 'Evento actualizado correctamente');
        this.dialogRef.close({ isSuccess: true });
      },
      error: (error) => {
        this.openResponseDialog('Error', 'Error al editar evento');
        this.loading = false;
      },
    });
  }

  toggleAllDay(event: any): void {
    if (event.checked) {
      this.hourStartForm.disable();
      this.hourEndForm.disable();
    } else {
      this.hourStartForm.enable();
      this.hourEndForm.enable();

      // asignar hora actual
      // ======== Caso: NO conservar la hora; usar la hora actual +1 ========
      const now = new Date(); // Hora local actual

      // START: hora actual
      const startHour = now.getHours();
      let startMinute = now.getMinutes();
      startMinute = Math.floor(startMinute / 5) * 5;
      const { hour12, ampm } = this.convert24To12(startHour);

      now.setMinutes(startMinute);

      this.hourStartForm.setValue({
        hour: hour12,
        minute: startMinute,
        ampm: ampm,
      });

      // END: crear un nuevo Date a partir de "now" y sumarle 30 minutos
      const endDateObj = new Date(now);
      endDateObj.setMinutes(endDateObj.getMinutes() + 30);

      // Si endDateObj cruza medianoche, su día aumentará automáticamente
      const endHour = endDateObj.getHours();
      let endMinute = endDateObj.getMinutes();
      endMinute = Math.floor(endMinute / 5) * 5;
      const { hour12: hour12End, ampm: ampmEnd } = this.convert24To12(endHour);

      endDateObj.setMinutes(endMinute);

      this.hourEndForm.setValue({
        hour: hour12End,
        minute: endMinute,
        ampm: ampmEnd,
      });

      const endH = endDateObj.getHours();
      // si es las 12:00 AM, se le suma un día
      if (endH === 0) {
        endDateObj.setDate(endDateObj.getDate() + 1);

        const endYear = endDateObj.getFullYear();
        const endMonth = String(endDateObj.getMonth() + 1).padStart(2, '0');
        const endDay2 = String(endDateObj.getDate()).padStart(2, '0');
        this.endDate = `${endYear}-${endMonth}-${endDay2}`;
      }
    }
  }

  removeUser(email) {
    this.selectedUsers = this.selectedUsers.filter(
      (selectedUser) => selectedUser !== email,
    );
    this.usuariosFiltrados = this.allUsers.filter(
      (usuario) =>
        !this.selectedUsers.find(
          (selectedUser) => selectedUser === usuario.email,
        ) && usuario.id !== this.currentUser,
    );
  }

  deleteEvent(id: string): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: [
        this.translate.instant('confirmDeleteEvent'),
        this.translate.instant('confirmDeleteEventMessage'),
      ],
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.loading = true;
        this.puzzleService.deleteEventGoogle(id).subscribe({
          next: (response) => {
            this.openResponseDialog('Exito', this.translate.instant('eventDeleted'));
            this.dialogRef.close({ isSuccess: true });
          },
          error: (error) => {
            this.openResponseDialog('Error', this.translate.instant('errorDeletingEvent'));
          },
        });
      }
    });
  }
}

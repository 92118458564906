<div class="w-full pt-5 pb-3 px-3 md:px-16 no-horizontal-scroll">

  <div class="pb-4 max-w-full">
    <div class="flex justify-between pr-10">
      <div class="flex items-center py-3">
        <a id="backToCandidateList" (click)="goBack()">
          <img [src]="backIcon" alt="" width="24px" height="24px">
        </a>
      </div>
      <div class="flex-1 flex justify-center items-center py-3">
        <span class="pl-1 gray title-l flex items-end">
          {{ newCandidate ? ("candidateNewData" | translate) : ("candidateDetailData" | translate) }}
        </span>
      </div>
    </div>

    <div *ngIf="!edit && !newCandidate">

      <div *ngIf="candidate || loadingProfile" class="flex flex-col">
        <div *ngIf="!isHaibuJobs" class="flex justify-center items-center w-full space-x-2 pb-4 pt-3">
          <app-custom-primary-button id="candidateDetailPersonalInfo" class="flex-grow lg:flex-grow-0 lg:w-1/4"
            [applySelectionStyles3]="true" size="recluter-size" color="secondary"
            [isSelected]="selectedButton === 'button1'" width="100%" (click)="selectButton('button1')">
            {{ 'candidateDetailPersonalInfo' | translate }}
          </app-custom-primary-button>

          <app-custom-primary-button id="candidateDetailExpedientInfo" class="flex-grow lg:flex-grow-0 lg:w-1/4"
            [applySelectionStyles3]="true" size="recluter-size" color="secondary"
            [isSelected]="selectedButton === 'button2'" width="100%" (click)="selectButton('button2')">
            {{ 'candidateDetailExpedientInfo' | translate }}
          </app-custom-primary-button>

          <app-custom-primary-button id="candidateDetailEvaluationsInfo" class="flex-grow lg:flex-grow-0 lg:w-1/4"
            [applySelectionStyles3]="true" size="recluter-size" color="secondary"
            [isSelected]="selectedButton === 'button3'" width="100%" (click)="selectButton('button3')">
            {{ 'candidateDetailEvaluationsInfo' | translate }}
          </app-custom-primary-button>
        </div>

        <div *ngIf="selectedButton == 'button1'">

          <div *ngIf="!loadingProfile">
            <div class="flex flex-col md:flex-row items-center pb-3">
              <!-- Foto perfil -->
              <div class="flex pb-6 pt-2 md:pb-0 md:pt-0">
                <div class="w-36 h-36 md:w-20 md:h-20 relative bg-white rounded-full">
                  <!-- Borde redondo externo -->
                  <div class="w-full h-full absolute rounded-full border image-container z-10"></div>
                  <!-- Imagen del perfil del usuario -->
                  <img *ngIf="userImage" class="w-full h-full absolute rounded-full object-cover" [src]="userImage"
                    alt="">
                  <img *ngIf="!userImage" class="w-full h-full absolute rounded-full object-cover" [src]="defaultpfp"
                    alt="">
                </div>
              </div>
              <!-- Foto perfil END -->
              <div class="flex flex-col w-full px-2">
                <div class="flex justify-between items-center">
                  <div class="title-candidate-full-name flex gap-1 items-center">
                    {{ editedCandidate.cdT_NAME }} {{ editedCandidate.cdT_PATERNAL_SURNAME }} {{
                    editedCandidate.cdT_MATERNAL_SURNAME }}
                    <img *ngIf="editedCandidate.cdT_IS_FROM_LINKEDIN" [matTooltip]="'fromLinkedinTooltip' | translate"
                      [src]="linkedinIcon" alt="" class="w-4 h-4">
                  </div>
                  <div id="toggleEditModeCandidate" *appPermission="'cd003'" class="flex items-center "
                    (click)="toggleEditMode()">
                    <img [src]="editIcon" alt="Icono" class="w-5 h-5 mr-2 cursor-pointer">
                    <span class="edit-text cursor-pointer">{{"candidateDetailEditText" | translate}}</span>
                  </div>
                </div>
                <div class="flex flex-col lg:flex-row pt-3">
                  <div class="flex items-center">
                    <div
                      class="overflow-hidden w-1/2 md:w-full flex flex-row title-mail-phone-text cursor-pointer items-center pr-0 md:pr-3">
                      <img [src]="mailIcon" alt="mail" class="mr-2 w-5 h-5">
                      <app-email-dropdown *ngIf="getPermission('cc01')" id="emailMenuCandidateList"
                        class="w-full overflow-hidden" [email]="editedCandidate.cdT_EMAIL"></app-email-dropdown>
                      <span *ngIf="!getPermission('cc01')" class="pr-3">
                        {{ 'noPermission' | translate }}
                      </span>
                    </div>
                    <div class="w-1/2 md:w-full flex flex-row title-mail-phone-text cursor-pointer pr-0 md:pr-3">
                      <div class="flex items-center w-max">
                        <img [src]="phoneIcon" alt="phone" class="mr-1 ml-1 w-5 h-5">
                        <app-phone-dropdown *ngIf="getPermission('cc01')"
                          [phone]="editedCandidate.cdT_PHONE_NUMBER_ONE"></app-phone-dropdown>
                        <span *ngIf="!getPermission('cc01')">
                          {{ 'noPermission' | translate }}
                        </span>
                      </div>
                    </div>

                  </div>
                  <div class="flex flex-col lg:flex-row justify-between lg:items-center pt-2 md:pt-0 lg:mr-3 w-full">

                    <div class="order-2 md:order-1 md:flex mt-2 lg:mt-0">

                      <div class="flex flex-row">
                        <span class="title-status-text mr-1">
                          {{ 'candidateDetailDetailSalaryExpectation' | translate }} :
                        </span>
                        <div>
                          {{ editedCandidate.cdT_SALARY_EXPECTED ? (editedCandidate.cdT_SALARY_EXPECTED |
                          currency:'USD':'symbol':'1.2-2') : ('noDefined' | translate) }}
                        </div>
                      </div>

                      <div class="flex md:flex-row mt-1 md:ml-3 md:mt-0">
                        <span class="title-status-text mr-1">
                          {{ 'candidateDetailReferenceBydeta' | translate }} :
                        </span>
                        <div>
                          {{ editedCandidate.recruitmentSourceInfo?.recruitmenT_SOURCE_NAME ?
                          editedCandidate.recruitmentSourceInfo?.recruitmenT_SOURCE_NAME :
                          (editedCandidate.cdT_OTHER_REFERRED || editedCandidate.cdT_REFERRED_BY)
                          }}
                        </div>
                        <div
                          *ngIf="!editedCandidate.recruitmentSourceInfo?.recruitmenT_SOURCE_NAME && !editedCandidate.cdT_OTHER_REFERRED && !editedCandidate.cdT_REFERRED_BY"
                          class="break-all">
                          {{ ('noDefined' | translate) }}
                        </div>
                      </div>

                    </div>

                    <div class="order-1 md:order-2 flex items-center mt-0 md:mt-2 lg:mt-0">

                      <div class="flex flex-row title-status-text lg:ml-3">
                        <a id="toggleChangePhaseCandidateDetail" *appPermission="'cd005'"
                          (click)="statusChange(editedCandidate,0)" class="title-status-text cursor-pointer">
                          <span *ngIf="editedCandidate.cdT_STATUS_INFO"> {{ editedCandidate.cdT_STATUS_INFO.stS_NAME |
                            translate }}
                          </span>
                        </a>
                        <span *ngIf="editedCandidate.cdT_STATUS_INFO && !getPermission('cd005')"
                          class="title-status-text">{{ editedCandidate.cdT_STATUS_INFO.stS_NAME | translate }}</span>
                      </div>
                      <div class="flex flex-row title-status-text ml-3">
                        <a id="toggleChangeStatusCandidateDetail" *appPermission="'cd006'"
                          (click)="statusChange(editedCandidate,1)" class="title-status-text cursor-pointer"><span
                            *ngIf="editedCandidate.cdT_STATUS_CANDIDATE_INFO">{{
                            getTranslatedStatus(editedCandidate.cdT_STATUS_CANDIDATE_INFO.stsC_NAME) }}</span></a>
                        <span *ngIf="editedCandidate.cdT_STATUS_CANDIDATE_INFO && !getPermission('cd006')"
                          class="title-status-text">{{
                          getTranslatedStatus(editedCandidate.cdT_STATUS_CANDIDATE_INFO.stsC_NAME) }}</span>
                      </div>

                      <div id="openChatCandidateDetail"
                        (click)="openChat(editedCandidate); toggleBadgeVisibility(editedCandidate);"
                        matBadgeSize="small" matBadge="&#8288;"
                        [matBadgeHidden]="!editedCandidate.candidateCommentsSummary?.ccS_HAS_NEW_MESSAGE"
                        matBadgeColor="warn" class="icon-container ml-3">
                        <img [src]="chatIcon" class="cursor-pointer action-icons"
                          [matTooltip]="'candidateMessageButton' | translate">
                      </div>

                      <div *ngIf="editedCandidate.cv && editedCandidate.cv.cdoC_ACTIVE" class="icon-container"
                        class="cv-link-container ml-3 flex items-center justify-center option-hover-effect">
                        <a id="openCvCandidateDetail" (click)="opendoc(editedCandidate)"
                          [matTooltip]="'candidateSeeCV' | translate:{name: editedCandidate.cdT_NAME}">
                          <img [src]="cvLogo" class="cursor-pointer action-icons">
                        </a>
                      </div>

                      <div *ngIf="!editedCandidate.cv || !editedCandidate.cv.cdoC_ACTIVE"
                        class="icon-container cv-link-container ml-3 flex items-center justify-center option-hover-effect">
                        <a id="openNoCvCandidateDetail" (click)="selectedButton = 'button2'"
                          [matTooltip]="'candidateAddCV' | translate:{name: editedCandidate.cdT_NAME}">
                          <img [src]="nocvLogo" class="cursor-pointer action-icons">
                        </a>
                      </div>

                      <div
                        *ngIf="(isAgency || isMini && getPermission('cd004')) || getPermission('cd007') || getPermission('cd009') || getPermission('cd010') || getPermission('cd011')">
                        <div *appPermission="['cd004', 'cd007', 'cd009', 'cd010', 'cd011']"
                          class="flex items-center justify-center ml-2 md:py-0 option-hover-effect icon-container">
                          <a id="moreOptionsCandidateDetail" [matMenuTriggerFor]="options">
                            <img [src]="moreIcon" class="cursor-pointer action-icons"
                              [matTooltip]="'candidateMenuButton' | translate">
                          </a>
                        </div>
                      </div>

                      <mat-menu #options="matMenu" class="mi-menu-personalizado">
                        <div *ngIf="!isHaibuJobs">
                          <div *appPermission="'cd011'"
                            class="flex items-center justify-start px-1  py-1 w-full option-hover-effect">
                            <div id="copyLinkNoTokenActionCandidateDetail"
                              [matTooltip]="'candidateGenerateDocumentsLink' | translate"
                              class="flex items-center justify-around cursor-pointer"
                              *ngIf="editedCandidate.tkcD_TOKEN_CANDIDATE_DOCUMENT_INFO==null||!isTokenExpired(editedCandidate.tkcD_TOKEN_CANDIDATE_DOCUMENT_INFO.tkcD_DUE_DATE)"
                              (click)="createCandidateToken(editedCandidate.cdT_ID)">
                              <img [src]="linkIcon" alt="Link" class="link-icon">
                              <div class="px-2 actions-text md:flex">
                                {{ 'candidateGenerateLink' | translate }}
                              </div>
                            </div>
                            <div id="copyLinkTokenActionCandidateDetail"
                              *ngIf="editedCandidate.tkcD_TOKEN_CANDIDATE_DOCUMENT_INFO!=null && isTokenExpired(editedCandidate.tkcD_TOKEN_CANDIDATE_DOCUMENT_INFO.tkcD_DUE_DATE)"
                              (click)="openDialog(3,editedCandidate.tkcD_TOKEN_CANDIDATE_DOCUMENT_INFO.tkcD_TOKEN)"
                              [matTooltip]="'candidateGenerateDocumentsLink' | translate"
                              class="flex items-center justify-around cursor-pointer">
                              <img [src]="linkCreatedIcon" alt="Link" class="link-icon">
                              <div class="px-2 actions-text md:flex">
                                {{ 'candidateCopyLink' | translate }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *appPermission="'cd010'"
                          class="flex items-center justify-start px-1 py-1 w-full option-hover-effect"
                          [matMenuTriggerFor]="downloadMenu" (click)="$event.stopPropagation();">
                          <!-- Evita que el menú principal se cierre -->
                          <div class="flex items-center justify-around cursor-pointer">
                            <mat-icon class="gray-color">download</mat-icon>
                            <div class="px-2 actions-text md:flex">
                              {{ 'candidateDownloadData' | translate }}
                            </div>
                          </div>
                        </div>
                        <div *ngIf="isAgency || isMini">
                          <div *appPermission="'cd004'"
                            class="flex items-center  justify-start px-1  py-1  w-full option-hover-effect">
                            <div id="duplicateCandidateAction" [matTooltip]="'candidateDuplicate' | translate"
                              class="cursor-pointer  flex items-center justify-around"
                              (click)="duplicateCandidate(editedCandidate)">
                              <mat-icon class="gray-color">content_copy</mat-icon>
                              <div class="px-2 actions-text md:flex actions-text">
                                {{ 'candidateDuplicate' | translate }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *appPermission="'cd007'"
                          class="flex items-center  justify-start px-1  py-1  w-full option-hover-effect">
                          <div id="deleteCandidateActionCandidateDetail" [matTooltip]="'candidateDelete' | translate"
                            class="cursor-pointer  flex items-center justify-around"
                            (click)="deleteCandidate(editedCandidate)">
                            <img [src]="deleteIcon" alt="Delete" class="link-icon">
                            <div class="px-2 actions-text md:flex actions-text">
                              {{ 'candidateDelete' | translate }}
                            </div>
                          </div>
                        </div>
                        <div *appPermission="'cd009'">
                          <div id="requestBassetEvaluationsActionCandidateDetail"
                            *ngIf="editedCandidate.cdT_COMPANY_INFO?.comP_BASSET"
                            class="w-full flex flex-row items-center py-1 px-1 cursor-pointer option-hover-effect"
                            [matTooltip]="'candidateBassetPetition' | translate" (click)="requestBassetEvaluation()">
                            <button target="_blank" class="mx-.5 px-2 rounded-md "
                              style="background-color: #941c8e;color: white; height: 34px; width: 34px;">
                              <div class="flex items-center py-1">
                                <div>
                                  <img src="\assets\img\logobs.png" class="w-auto" style="height: 24px;  " alt="">
                                </div>
                              </div>
                            </button>
                            <div class="px-2 actions-text md:flex">
                              {{ 'candidateBassetPetition' | translate }}
                            </div>
                          </div>
                        </div>
                      </mat-menu>
                      <!-- Submenú de Descarga -->
                      <mat-menu #downloadMenu="matMenu" class="mi-submenu-personalizado">
                        <div *appPermission="'cd010'"
                          class="flex items-center justify-start px-1 py-1 w-full option-hover-effect">
                          <a id="downloadEmployeeFileActionCandidateDetail"
                            [matTooltip]="'candidateDownloadData' | translate"
                            href="{{url}}/api/CandidateDocumentsExternal/DownloadZipCandidateDocuments?candidateId={{editedCandidate.cdT_ID}}"
                            class="flex items-center justify-around cursor-pointer">
                            <img [src]="downloadExpedientIcon" alt="Download Zip" class="link-icon">
                            <div class="px-2 actions-text md:flex">
                              {{ 'candidateDownloadDataZip' | translate }}
                            </div>
                          </a>
                        </div>
                        <div *appPermission="'cd010'"
                          class="flex items-center justify-start px-1 py-1 w-full option-hover-effect">
                          <a id="downloadEmployeeFileActionCandidateDetailPdf"
                            [matTooltip]="'candidateDownloadDataPdf' | translate"
                            (click)="downloadPdfCandidate(editedCandidate)"
                            class="flex items-center justify-around cursor-pointer">
                            <img [src]="downloadExpedientIconPdf" alt="Download PDF" class="link-icon">
                            <div class="px-2 actions-text md:flex">
                              {{ 'candidateDownloadDataPdf' | translate }}
                            </div>
                          </a>
                        </div>
                      </mat-menu>
                      <div #downloadMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="downloadMenu"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex flex-col pt-3 md:pb-3 w-full px-6 container-personal-data">
              <div class="title-candidate-profile">
                {{"candidateDetailVacancyTitle" | translate}}
              </div>
              <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
                <!-- Item 1 -->
                <div class="py-1 pr-4">
                  <label for="lastname" class="title-input-text">
                    {{"candidateDetailVacancy" | translate}}
                  </label>
                  <div class="break-all" name="position">
                    {{ selectedVacancy?.jobInfo?.joB_NAME || editedCandidate.cdT_JOB_INFO?.joB_NAME }}
                  </div>
                </div>

                <!-- Item 2 -->
                <div class="py-1 pr-4">
                  <label for="lastname" class="title-input-text">
                    {{"candidateDetailBranch" | translate}}
                  </label>
                  <div class="break-all" name="branch">
                    {{ selectedVacancy?.branchOfficeInfo?.broF_NAME || editedCandidate.cdT_BRANCH_OFFICE_INFO?.broF_NAME
                    || 'No definido' }}
                  </div>
                </div>

                <!-- Item 3 -->
                <div class="py-1 pr-4">
                  <label for="lastname" class="title-input-text">
                    {{"candidateDetailDepartament" | translate}}
                  </label>
                  <div class="break-all" name="department">
                    {{ selectedVacancy?.departmentInfo?.deP_NAME || editedCandidate.cdT_DEPARTMENT_INFO?.deP_NAME }}
                  </div>
                </div>

                <!-- Item 4 -->
                <div class="py-1 pr-4">
                  <label for="dob" class="title-input-text">
                    {{"candidateDetailBoss" | translate}}
                  </label>
                  <div class="break-all" name="boss">
                    {{ selectedVacancy?.vacanT_DIRECT_BOSS || editedCandidate.cdT_IMMEDIATE_BOSS }}
                  </div>
                </div>

                <!-- Item 5 -->
                <div *appPermission="'vc010'" class="py-1 pr-4">
                  <label for="salary" class="title-input-text">
                    {{"candidateListSalaryVacancy" | translate}}
                  </label>
                  <div *ngIf="candidate?.offereD_SALARY" class="break-all" name="salary">
                    {{ candidate?.offereD_SALARY | currency:'USD':'symbol':'1.2-2' }}
                    {{ candidate?.paymentPeriodInfo?.pyP_NAME | translate }}
                    {{ candidate?.salarY_TYPE_DISPLAY | translate }}
                  </div>
                  <div *ngIf="!candidate?.offereD_SALARY" class="break-all" name="salary">
                    {{ 'candidateListSalaryNoExcpecteted' | translate }}
                  </div>
                </div>
              </div>
            </div>

            <div class="flex flex-col py-3 w-full px-6 container-personal-data mt-4">
              <div class="title-candidate-profile pb-2">
                {{"candidateDetailGeneraData" | translate}}
              </div>
              <div class="flex flex-col md:flex-row flex-wrap">

                <div class="flex w-full md:w-1/2 xl:w-1/3">
                  <div class="py-1 w-1/2 pr-4">
                    <label for="name" class="title-input-text">
                      {{"candidateDetailName" | translate}}
                    </label>
                    <div class="info-candidate-profile" name="name">
                      {{ editedCandidate.cdT_NAME }}
                    </div>
                  </div>
                  <div class="py-1 w-1/2 pr-4">
                    <label for="lastname" class="title-input-text">
                      {{"candidateDetailLastName" | translate}}
                    </label>
                    <div class="info-candidate-profile" name="lastname">
                      {{ editedCandidate.cdT_PATERNAL_SURNAME }} {{ editedCandidate.cdT_MATERNAL_SURNAME }}
                    </div>
                  </div>
                </div>
                <div class="flex w-full md:w-1/2 xl:w-1/3">
                  <div class="py-1 w-1/2 pr-4">
                    <label for="gender" class="title-input-text">
                      {{"candidateDetailGender" | translate}}
                    </label>
                    <div class="break-all" name="gender">
                      {{ editedCandidate?.cdT_GENDER_INFO?.gndR_NAME }}
                    </div>
                  </div>
                  <div class="py-1 w-1/2 pr-4">
                    <label for="dob" class="title-input-text">
                      {{"candidateDetailBirthdate" | translate}}
                    </label>
                    <div class="break-all" name="dob">
                      {{ editedCandidate.cdT_BIRTH_DATE ? (editedCandidate.cdT_BIRTH_DATE| dateFormat) : ('noDefined' |
                      translate) }}
                    </div>
                  </div>
                </div>
                <div class="flex w-full md:w-1/2 xl:w-1/3">
                  <div class="py-1 w-1/2 pr-4">
                    <label for="birthstate" class="title-input-text">
                      {{"candidateDetailBirthState" | translate}}
                    </label>
                    <div class="break-all" name="birthstate">
                      {{ editedCandidate.cdT_BIRTH_STATE_INFO ? editedCandidate.cdT_BIRTH_STATE_INFO.stS_NAME :
                      editedCandidate.cdT_BIRTH_STATE_CUSTOM }}
                      {{ editedCandidate.cdT_BIRTH_STATE_CUSTOM }}
                    </div>
                    <div
                      *ngIf="editedCandidate.cdT_BIRTH_STATE_INFO == null && editedCandidate.cdT_BIRTH_STATE_CUSTOM == null"
                      class="break-all">
                      {{ ('noDefined' | translate) }}
                    </div>
                  </div>
                  <div class="py-1 w-1/2 pr-4">
                    <label for="labelbirthcity" class="title-input-text">
                      {{"candidateDetailBirthCity" | translate}}
                    </label>
                    <div class=" break-all" name="labelbirthcity">
                      {{ editedCandidate.cdT_BIRTH_CITY_INFO ? editedCandidate.cdT_BIRTH_CITY_INFO.ctY_NAME :
                      editedCandidate.cdT_BIRTH_CITY_CUSTOM }}
                    </div>
                    <div
                      *ngIf="editedCandidate.cdT_BIRTH_CITY_INFO == null && editedCandidate.cdT_BIRTH_CITY_CUSTOM == null"
                      class="break-all">
                      {{ ('noDefined' | translate) }}
                    </div>
                  </div>
                </div>

                <div class="w-full mt-6"></div>
                <div class="left">{{ 'candidateDetailContactData' | translate}}</div>
                <div class="w-full pb-3"></div>

                <div class="flex w-full md:w-1/2 xl:w-1/3">
                  <div class="py-1 w-1/2 pr-4">
                    <label for="mail" class="title-input-text">
                      {{"candidateDetailEmail" | translate}}
                    </label>
                    <div *ngIf="getPermission('cc01')" class="px-1 break-all" name="mail">
                      {{ editedCandidate.cdT_EMAIL }}
                    </div>
                    <div *ngIf="!getPermission('cc01')" class="pr-3">
                      {{ 'noPermission' | translate }}
                    </div>
                  </div>
                  <div class="py-1 w-1/2 pr-4">
                    <label for="phone" class="title-input-text">
                      {{"candidateDetailCelphone" | translate}}
                    </label>
                    <div *ngIf="getPermission('cc01')" class="  break-all" name="phone">
                      {{ editedCandidate.cdT_PHONE_NUMBER_ONE }}
                    </div>
                    <div *ngIf="!getPermission('cc01')" class="pr-3">
                      {{ 'noPermission' | translate }}
                    </div>
                  </div>
                </div>
                <div class="flex w-full md:w-1/2 xl:w-1/3">
                  <div class="py-1 w-1/2 pr-4">
                    <label for="phone2" class="title-input-text">
                      {{"candidateDetailPhone" | translate}}
                    </label>
                    <div *ngIf="getPermission('cc01')" class="break-all" name="phone2">
                      {{ editedCandidate.cdT_PHONE_NUMBER_TWO ? editedCandidate.cdT_PHONE_NUMBER_TWO : ('noDefined' |
                      translate)}}
                    </div>
                    <div *ngIf="!getPermission('cc01')" class="pr-3">
                      {{ 'noPermission' | translate }}
                    </div>
                  </div>
                  <div class="py-1 w-1/2 pr-4">
                    <label for="portfolio" class="title-input-text">
                      {{"candidateDetailportfolio" | translate}}
                    </label>
                    <div *ngIf="editedCandidate.cdT_PORTFOLIO" class="break-all" name="portfolio">
                      <a [href]="editedCandidate.cdT_PORTFOLIO" target="_blank"
                        class="primary-color flex items-center gap-2">
                        <span>
                          {{"candidateDetailSeePortfolio" | translate}}
                          <mat-icon class="align-middle">
                            link
                          </mat-icon>
                        </span>
                      </a>
                    </div>
                    <div *ngIf="!editedCandidate.cdT_PORTFOLIO" class="break-all" name="portfolio">
                      {{ ('noDefined' | translate) }}
                    </div>
                  </div>
                </div>

                <div class="w-full mt-6"></div>
                <div class="left">{{ 'candidateDetailIdentityData' | translate}}</div>
                <div class="w-full pb-3"></div>


                <!-- CURP, RFC, NSS -->
                <div class="flex w-full md:w-1/2 xl:w-1/3">
                  <!-- CURP -->
                  <div class="py-1 w-1/2 pr-4">
                    <label for="curp" class="title-input-text">
                      {{"candidateDetailCURP" | translate}}
                    </label>
                    <div class="break-all" name="curp">
                      {{ editedCandidate.cdT_CURP ? editedCandidate.cdT_CURP : ('noDefined' | translate) }}
                    </div>
                  </div>

                  <!-- RFC -->
                  <div class="py-1 w-1/2 pr-4">
                    <label for="rfc" class="title-input-text">
                      {{"candidateDetailRFC" | translate}}
                    </label>
                    <div class="break-all" name="rfc">
                      {{ editedCandidate.cdT_RFC ? editedCandidate.cdT_RFC : ('noDefined' | translate) }}
                    </div>
                  </div>
                </div>

                <div class="flex w-full md:w-1/2 xl:w-1/3">
                  <!-- NSS -->
                  <div class="py-1 w-1/2 pr-4">
                    <label for="nss" class="title-input-text">
                      {{"candidateDetailNSS" | translate}}
                    </label>
                    <div class="break-all" name="nss">
                      {{ editedCandidate.cdT_SOCIAL_SECURITY_NUMBER ? editedCandidate.cdT_SOCIAL_SECURITY_NUMBER :
                      ('noDefined' | translate) }}
                    </div>
                  </div>

                  <!-- Certificado Profesional -->
                  <div class="py-1 w-1/2 pr-4">
                    <label for="labelprofesionalcertificate" class="title-input-text">
                      {{"candidateDetailProfessionalLicense" | translate}}
                    </label>
                    <div class="break-all" name="labelprofesionalcertificate">
                      {{ editedCandidate.cdT_PROFESSIONAL_CERTIFICATE ? editedCandidate.cdT_PROFESSIONAL_CERTIFICATE :
                      ('noDefined' | translate) }}
                    </div>
                  </div>
                </div>

                <!-- Crédito Infonavit -->
                <div class="flex w-full md:w-1/2 xl:w-1/3">
                  <div class="py-1 w-1/2 pr-4">
                    <label for="labelinfonavit" class="title-input-text">
                      {{"candidateDetailCredit" | translate}}
                    </label>
                    <div class="break-all" name="labelinfonavit">
                      {{ editedCandidate.cdT_INFONAVIT_CREDIT ? editedCandidate.cdT_INFONAVIT_CREDIT :
                      ('noDefined' | translate) }}
                    </div>
                  </div>

                  <!-- Espacio para mantener alineación o añadir futuro contenido -->
                  <div class="py-1 w-1/2 pr-4">
                    <div></div>
                  </div>
                </div>

                <!-- Formación academica -->
                <div class="w-full mt-6"></div>
                <div class="left">{{ 'candidateDetailEducationData' | translate}}</div>
                <div class="w-full pb-3"></div>

                <!-- Nivel de estudios, Años de experiencia -->
                <div class="flex w-full md:w-1/2 xl:w-1/3">
                  <!-- Nivel de estudios -->
                  <div class="py-1 w-1/2 pr-4">
                    <label for="curp" class="title-input-text">
                      {{"candidateStudyLevel" | translate}}
                    </label>
                    <div *ngIf="editedCandidate?.cdT_CANDIDATE_SCHOLARSHIP_INFO?.scH_NAME" class="break-all"
                      name="curp">
                      {{ 'study.' + editedCandidate?.cdT_CANDIDATE_SCHOLARSHIP_INFO?.scH_NAME | translate }}
                    </div>
                    <div *ngIf="editedCandidate?.cdT_CANDIDATE_SCHOLARSHIP_INFO == null" class="break-all" name="curp">
                      {{ ('noDefined' | translate) }}
                    </div>
                  </div>

                  <!-- Años de experiencia -->
                  <div class="py-1 w-1/2 pr-4">
                    <label for="rfc" class="title-input-text">
                      {{"candidateYearsOfExperience" | translate}}
                    </label>
                    <div *ngIf="editedCandidate?.cdT_CANDIDATE_YEARS_EXPERIENCE_INFO?.exP_NAME" class="break-all"
                      name="curp">
                      {{ 'experience.' + editedCandidate?.cdT_CANDIDATE_YEARS_EXPERIENCE_INFO?.exP_NAME | translate }}
                    </div>
                    <div *ngIf="editedCandidate?.cdT_CANDIDATE_YEARS_EXPERIENCE_INFO == null" class="break-all"
                      name="curp">
                      {{ ('noDefined' | translate) }}
                    </div>
                  </div>
                </div>

                <!-- Datos bancarios -->
                <div class="w-full mt-6"></div>
                <div class="left">{{ 'candidateDetailBankDetails' | translate}}</div>
                <div class="w-full pb-3"></div>

                <!-- CLABE, Banco -->
                <div class="flex w-full md:w-1/2 xl:w-1/3">
                  <!-- CLABE -->
                  <div class="py-1 w-1/2 pr-4">
                    <label for="clabe" class="title-input-text">
                      {{"candidateCLABE" | translate}}
                    </label>
                    <div class="break-all" name="clabe">
                      {{ editedCandidate?.cdT_BANK_CLABE ? editedCandidate?.cdT_BANK_CLABE :
                      ('noDefined' | translate) }}
                    </div>
                  </div>

                  <!-- Banco -->
                  <div class="py-1 w-1/2 pr-4">
                    <label for="bank" class="title-input-text">
                      {{"candidateBank" | translate}}
                    </label>
                    <div class="break-all" name="bank">
                      {{ editedCandidate?.cdT_BANK ? editedCandidate?.cdT_BANK :
                      ('noDefined' | translate) }}
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="flex flex-col py-3 w-full px-6 container-personal-data mt-4">
              <div class="title-candidate-profile">
                {{"candidateDetailAdressTitle" | translate}}
              </div>
              <div class="flex flex-col md:flex-row flex-wrap">
                <div class="flex w-full md:w-1/2 xl:w-2/6">
                  <div class="py-1 w-1/2 pr-4">
                    <label for="lastname" class="title-input-text">
                      {{"candidateDetailAdressStreet" | translate}}
                    </label>
                    <div class="info-candidate-profile" name="name">
                      {{ editedCandidate.cdT_ADDRESS_INFO?.adR_STREET ? editedCandidate.cdT_ADDRESS_INFO.adR_STREET :
                      ('noDefined' | translate) }}
                    </div>
                  </div>
                  <div class="py-1 w-1/2 pr-4">
                    <label for="lastname" class="title-input-text">
                      {{"candidateDetailAdressNumber" | translate}}
                    </label>
                    <div class="info-candidate-profile" name="lastname">
                      {{ editedCandidate.cdT_ADDRESS_INFO?.adR_STREET_NUMBER ?
                      editedCandidate.cdT_ADDRESS_INFO.adR_STREET_NUMBER :
                      ('noDefined' | translate) }}
                    </div>
                  </div>
                </div>

                <div class="flex w-full md:w-1/2 xl:w-2/6">
                  <div class="py-1 w-1/2 pr-4">
                    <label for="surname" class="title-input-text">
                      {{"candidateDetailAdressNumberInt" | translate}}
                    </label>
                    <div class=" break-all" name="surname">
                      {{ editedCandidate.cdT_ADDRESS_INFO?.adR_SUITE_NUMBER ?
                      editedCandidate.cdT_ADDRESS_INFO.adR_SUITE_NUMBER :
                      ('noDefined' | translate) }}
                    </div>
                  </div>
                  <div class="py-1 w-1/2 pr-4">
                    <label for="dob" class="title-input-text">
                      {{"candidateDetailAdressColony" | translate}}
                    </label>
                    <div class=" break-all" name="dob">
                      {{ editedCandidate.cdT_ADDRESS_INFO?.adR_SUBURB ? editedCandidate.cdT_ADDRESS_INFO.adR_SUBURB :
                      ('noDefined' | translate) }}
                    </div>
                  </div>
                </div>

                <div class="flex w-full md:w-1/2 xl:w-2/6">
                  <div class="py-1 w-1/2 pr-4">
                    <label for="birthstate" class="title-input-text">
                      {{"candidateDetailAddressState" | translate}}
                    </label>
                    <div class=" break-all" name="birthstate">
                      {{ editedCandidate.cdT_ADDRESS_INFO?.adR_STATE_ID_INFO ?
                      editedCandidate.cdT_ADDRESS_INFO.adR_STATE_ID_INFO.stS_NAME :
                      editedCandidate.cdT_ADDRESS_INFO.adR_STATE_CUSTOM }}
                    </div>
                    <div
                      *ngIf="editedCandidate.cdT_ADDRESS_INFO.adR_STATE_ID_INFO == null && editedCandidate.cdT_ADDRESS_INFO.adR_STATE_CUSTOM == null"
                      class="break-all">
                      {{ ('noDefined' | translate) }}
                    </div>
                  </div>

                  <div class="py-1 w-1/2 pr-4">
                    <label for="labelbirthcity" class="title-input-text">
                      {{"candidateDetailAddressCity" | translate}}
                    </label>
                    <div class=" break-all" name="labelbirthcity">
                      {{ editedCandidate.cdT_ADDRESS_INFO?.adR_CITY_ID_INFO ?
                      editedCandidate.cdT_ADDRESS_INFO.adR_CITY_ID_INFO.ctY_NAME :
                      editedCandidate.cdT_ADDRESS_INFO.adR_CITY_CUSTOM }}
                    </div>
                    <div
                      *ngIf="editedCandidate.cdT_ADDRESS_INFO.adR_CITY_ID_INFO == null && editedCandidate.cdT_ADDRESS_INFO.adR_CITY_CUSTOM == null"
                      class="break-all">
                      {{ ('noDefined' | translate) }}
                    </div>
                  </div>
                </div>

                <div class="py-1 w-full md:w-1/4 xl:w-1/6">
                  <label for="labelbirthcity" class="title-input-text">
                    {{"candidateDetailAdressPostalCode" | translate}}
                  </label>
                  <div class=" break-all" name="cp">
                    {{ editedCandidate.cdT_ADDRESS_INFO?.adR_CP ? editedCandidate.cdT_ADDRESS_INFO.adR_CP :
                    ('noDefined' | translate) }}
                  </div>
                </div>
                <div class="w-full mt-6"></div>

              </div>
            </div>
          </div>

          <div *ngIf="loadingProfile">
            <!-- Skeleton para perfil -->
            <div class="flex flex-col md:flex-row items-center pb-3 animate-pulse">
              <div class="w-36 h-36 md:w-20 md:h-20 bg-gray-300 rounded-full"></div>
              <div class="flex flex-col w-full px-2 space-y-2">
                <div class="h-6 bg-gray-300 rounded w-3/5"></div>
                <div class="h-6 bg-gray-300 rounded w-1/4"></div>
              </div>
            </div>

            <!-- Skeleton para detalles -->
            <div class="flex flex-col space-y-3 w-full px-6 mt-4">
              <div class="h-24 bg-gray-300 rounded-lg w-full"></div>
            </div>

            <!-- Skeleton para secciones adicionales -->
            <div class="flex flex-col space-y-3 w-full px-6 mt-6">
              <div class="h-40 bg-gray-300 rounded-lg w-full"></div>
            </div>

            <!-- Skeleton para última sección -->
            <div class="flex flex-col space-y-3 w-full px-6 mt-6">
              <div class="h-24 bg-gray-300 rounded-lg w-full"></div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="selectedButton == 'button2'">
        <div class="flex flex-col py-3 w-full  mb-4">
          <div class="flex flex-col w-full">
            <div class="py-2 px-6 w-full">
              <div class="flex justify-start items-center">
                <div class="text-left mr-4">
                  <label for="state" class="green-title pb-2 block">
                    {{ "candidateDetailExpedientTitle" | translate }}
                  </label>
                </div>
              </div>
              <input *appPermission="'cd008'" #fileSelect id="fileSelect" type="file"
                accept=".doc,.docx,application/msword,application/vnd.ms-excel,.xlsx,.xls,image/*,application/pdf,.pdf"
                (click)="fileSelect.value = null" (change)="fileChangeEvent($event,0)" class="hidden">

              <div *ngIf="!newCandidate || showdocuments" class="flex flex-col md:flex-row md:items-center w-full">
                <app-custom-dropdown *appPermission="'cd008'" [items]="visibleInnerDocs" [(ngModel)]="selectedInnerDoc"
                  [translateLabels]="true" name="innerdoc" width="100%" class="w-full mb-4 md:mb-0 py-2"
                  displayProperty="pdT_NAME">
                </app-custom-dropdown>

                <div *appPermission="'cd008'" class="w-full md:w-auto pl-0 md:pl-4 py-2">
                  <app-custom-primary-button size="excel-size" color="primary" type="button" class="w-full"
                    (click)="fileSelectClick()">
                    {{ "candidateDetailSaveDocuments" | translate }}
                  </app-custom-primary-button>
                </div>
              </div>

              <div *ngIf="selectedInnerDoc && getPermission('cd008')" class="my-2">
                <div class="drag-drop-area border-dashed border-2 rounded-lg p-4 text-center cursor-pointer"
                  [ngClass]="dragging ? 'dragging' : 'border-gray-400'" (dragover)="onDragOver($event)"
                  (dragleave)="onDragLeave($event)" (drop)="onDrop($event)" (click)="fileSelectClick()">
                  <div class="py-10">
                    <div class="text-gray-500 m-0">
                      {{ "dragAndDrop" | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="pb-4 pl-5 flex flex-wrap">
              <div *ngFor="let innerDoc of innerDocuments; let i = index" class="flex py-2 pr-5 md:w-1/2 w-full">
                <a (click)="opendoc(innerDoc.cdoC_DOCUMENT_ROUTE, 2)"
                  class="document-card w-full gray-subtitle flex items-center justify-between p-2">
                  <div class="flex items-center">
                    <img [src]="fileIcon" alt="Document Icon" class="mr-2">
                    {{ innerDoc.cdoC_DOCUMENT_NAME | translate }}
                  </div>
                  <div *ngIf="!showdocuments" class="flex items-center gap-2">
                    <div *ngIf="innerDoc.cdoC_ID">
                      <a *appPermission="'cd008'" (click)="hidedoc(innerDoc)" class="flex items-center">
                        <mat-icon class="cursor-pointer icon-password"
                          [ngClass]="innerDoc.cdoC_RECRUITER_ACTIVE ? 'primary-color' : 'gray-color'">
                          {{ innerDoc.cdoC_RECRUITER_ACTIVE ? 'visibility' : 'visibility_off' }}
                        </mat-icon>
                      </a>
                    </div>
                    <a *appPermission="'cd008'" (click)="deletedoc(innerDoc,0)" class="flex items-center">
                      <img [src]="deleteIcon" alt="trash">
                    </a>
                  </div>
                </a>
              </div>
            </div>

            <div *ngIf="innerDocuments?.length === 0" class="flex flex-col items-center justify-center px-6">
              <img [src]="expedientImage" alt="Sin documentos" class="w-60 h-40">
              <div class="text-left w-full">
                <div class="green-title pb-4 pt-4">{{ "candidateWithoutEvaluationsTitle" | translate }}</div>
                <div class="pb-2 gray-subtitle">
                  {{ "candidateWithoutEvaluationsMessage" | translate }}
                </div>
                <div class="gray-subtitle">
                  {{ "candidateDetailSubtitle" | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *appPermission="'cd008'">
          <app-custom-primary-button [disabled]="sending" *ngIf="id" color="primary" size="small" class="w-full"
            (click)="createEditCandidateFiles(myForm)">
            {{ id ? ('candidateDetailSaveButton' | translate) : ('candidateDetailCreateButton' | translate) }}
          </app-custom-primary-button>
        </div>

      </div>

      <div *ngIf="selectedButton == 'button3'">
        <div class="flex flex-col py-3 w-full px-6">
          <div class="flex flex-col md:flex-row justify-between  w-full pb-4">
            <!-- Título verde a la izquierda -->
            <div class="green-title text-left pl-2 mb-2 md:mb-0">
              <span class="text-left">{{ "candidateDetailTitleButton" | translate }}</span>
            </div>
            <!-- Contenedor para botones, en columna para móviles y fila para desktop -->
            <div class="flex flex-col md:flex-row md:items-center md:space-x-2 w-full md:w-auto">
              <!--              <a *ngIf="id && candidate && candidate.cdT_COMPANY_INFO?.comP_BASSET || canYouBasset"
                  [ngClass]="{'pointer-events-none': disabledBasset}" [disabled]="disabledBasset"
                  (click)="requestBassetEvaluation()" target="_blank"
                  class="flex px-2 rounded-md flex items-center md:hidden w-auto h-auto justify-between mb-2">
                  <img src="\assets\img\logobs.png" class="w-auto">
                  <a *ngIf="!newCandidate" (click)="editevaluations()" class="flex items-center w-auto">
                    <img [src]="editIcon" alt="Icono" class="w-5 h-5">
                  </a>
                </a> -->

              <!-- Botón de solicitud Basset para desktop y móviles -->
              <span *appPermission="'cd009'">
                <a *ngIf="id && candidate && candidate.cdT_COMPANY_INFO?.comP_BASSET || canYouBasset && newCandidate"
                  [ngClass]="{'pointer-events-none': disabledBasset, 'solicitud-basset-enlace': true}"
                  [disabled]="disabledBasset" (click)="requestBassetEvaluation()" target="_blank"
                  class="flex px-3 items-center justify-center custom-border w-full md:w-auto md:items-center mb-4 md:mb-0">
                  <img src="\assets\img\logobs.png" class="w-auto solicitud-basset-img mr-2">
                  <span>{{"candidateDetailAskBasset" | translate}}</span>
                </a>
              </span>
              <!-- Botón personalizado primario -->
              <span *appPermission="'cd009'">
                <app-custom-primary-button *ngIf="!newCandidate || showdocuments" size="excel-size" color="primary"
                  type="button" class="w-full md:w-auto" (click)="fileSelectClick2()">
                  {{ "candidateDetailTitleButton" | translate }}
                </app-custom-primary-button>
              </span>
            </div>
          </div>


          <input *appPermission="'cd009'" #fileSelect2 id="fileSelect2" type="file"
            accept=".doc,.docx,application/pdf,application/doc,application/docx,application/xls,application/xlsx,image/*,"
            (click)="fileSelect2.value = null" (change)="fileChangeEvent($event,1)" class="hidden fileInputProfile">

          <div *appPermission="'cd009'" class="my-2">
            <div class="drag-drop-area border-dashed border-2 rounded-lg p-4 text-center cursor-pointer"
              [ngClass]="dragging ? 'dragging' : 'border-gray-400'" (dragover)="onDragOver($event)"
              (dragleave)="onDragLeave($event)" (drop)="onDrop($event, 1)" (click)="fileSelectClick2()">
              <div class="py-10">
                <div class="text-gray-500 m-0">
                  {{ "dragAndDrop" | translate }}
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="innerEvaluations!=null&&innerEvaluations?.length>0">
            <div class="flex flex-wrap">
              <div *ngFor="let innerDoc of innerEvaluations; let i = index" class="flex py-2 justify-between w-full">
                <a (click)="opendoc(innerDoc.cdoC_DOCUMENT_ROUTE, 1)"
                  class="document-card w-full gray-subtitle flex items-center justify-between p-2">
                  <div class="pr-2 overflow-hidden">
                    <div class="flex items-center text-ellipsis overflow-hidden whitespace-nowrap">
                      <img [src]="fileIcon" alt="Document Icon" class="mr-2">
                      {{ innerDoc.cdoC_DOCUMENT_NAME }}
                    </div>
                  </div>
                  <div *ngIf="!showevaluations">
                    <a *appPermission="'cd009'" (click)="deletedoc(innerDoc,1)" class="flex items-center">
                      <img class="w-6 md:w-auto" [src]="deleteIcon" alt="trash">
                    </a>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div *ngIf="questionnaireResults!=null&&questionnaireResults?.length>0">
            <div class="flex flex-wrap">
              <div *ngFor="let questionnaire of questionnaireResults; let i = index"
                class="flex py-2 justify-between w-full">
                <a (click)="openquest(questionnaire)"
                  class="document-card w-full gray-subtitle flex items-center justify-between p-2">
                  <div class="flex items-center">
                    <img [src]="testIcon  " alt="Document Icon" class="mr-2">
                    {{ questionnaire.questionnaireName }}
                    <div class="ml-2 tag-score">
                      <div [ngClass]="'eval' + questionnaire.statusScore">
                        {{ questionnaire.score }} ({{ questionnaire.userScore }}%)
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="flex flex-col mt-2">
            <div *ngIf="candidateBassetDocument!=null && candidateBassetDocument?.length>0"
              class="flex flex-col pb-3 w-full bg-white">
              <div class="flex items-center justify-between contenedor-evaluaciones">
                <div class="w-full">
                  <div *ngIf="candidateBassetDocument!=null && candidateBassetDocument?.length>0">
                    <div class="pt-2 pb-4 flex flex-wrap justify-start px-4">
                      <div *ngFor="let innerDoc of candidateBassetDocument; let i = index"
                        class="flex py-2 justify-between w-full">
                        <a [ngClass]="{'cursor-default': innerDoc.cdbS_DOCUMENT_ROUTE == null}"
                          (click)="opendoc(innerDoc.cdbS_DOCUMENT_ROUTE)"
                          class="w-full flex font-medium document-cardd gray-subtitle">
                          {{
                          innerDoc.cdbS_STUDY_TYPE == 'RL' ? ('candidateDetailBassetLaboralReference' | translate) :
                          innerDoc.cdbS_STUDY_TYPE == 'ES' ? ('candidateDetailBassetSocioeconomic' | translate) :
                          innerDoc.cdbS_STUDY_TYPE == 'PI' ? ('candidateDetailBassetIntegrity' | translate) :
                          innerDoc.cdbS_STUDY_TYPE == 'IL' ? ('candidateDetailBassetInvestigation' | translate) :
                          innerDoc.cdbS_STUDY_TYPE == 'PP' ? ('candidateDetailBassetPsychometric' | translate) : ''
                          }}
                          <span class="ml-auto">{{ innerDoc.cdbS_CREATED_DATE | dateFormat }}</span>
                        </a>
                        <div *ngIf="innerDoc.status == '' || innerDoc.status"
                          class="flex justify-center items-center p-2 font-semibold w-full text-center"
                          [ngClass]="getClassByStatus(innerDoc.status)">
                          {{ innerDoc.status && innerDoc.status?.length > 0 ? innerDoc.status : 'Pendiente' }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="newCandidate && newEvaluationDialogResponse &&
                  newEvaluationDialogResponse.requestedEvaluations?.length > 0">
                    <div class="font-medium">
                      {{"candidateDetailBassetEvaluations" | translate}}
                    </div>
                    <div class="pt-2 pb-4 flex flex-wrap justify-start px-4">

                      <div *ngFor="let innerDoc of newEvaluationDialogResponse.requestedEvaluations; let i = index"
                        class="flex py-2 justify-between w-full">
                        <a [ngClass]="{
                          'cursor-default': innerDoc.cdbS_DOCUMENT_ROUTE == null
                        }" class="w-full flex font-medium document-cardd gray-subtitle">
                          {{
                          innerDoc.name
                          }}
                          <span class="ml-auto">{{ innerDoc.cdbS_CREATED_DATE | dateFormat }}</span>
                        </a>

                      </div>
                    </div>
                  </div>
                  <div *ngIf="showevaluations" class="flex md:flex-row flex-col md:px-3 px-6 w-full">
                    <div class="md:ml-auto md:mx-3 md:py-0 mx-0 py-2">
                    </div>
                    <div class="md:mx-3 md:py-0 mx-0 py-2">
                      <app-custom-primary-button (click)="sendDocs()" color="primary" size="small" class="w-full">
                        {{ "candidateDetailBassetSave" | translate }}
                      </app-custom-primary-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="(!innerEvaluations || innerEvaluations?.length === 0) && (!questionnaireResults || questionnaireResults?.length === 0) && (!candidateBassetDocument || candidateBassetDocument?.length === 0)"
            class="flex flex-col items-center justify-center px-6 pb-4 pt-4">
            <img [src]="expedientImage" alt="Sin documentos" class="w-60 h-40">
            <div class="text-left w-full">
              <div class="green-title pb-4 pt-4">{{ "candidateWithoutEvaluationsFilesTitle" | translate }}</div>
              <div class="pb-2 gray-subtitle">
                {{ "candidateWithoutEvaluationsFilesMessage" | translate }}
              </div>
              <div class="gray-subtitle">
                {{ "candidateDetailFilesSubtitle" | translate }}
              </div>
            </div>
          </div>


        </div>
        <div *appPermission="'cd009'" class="pt-4 px-6">
          <app-custom-primary-button [disabled]="sending" *ngIf="id" color="primary" size="small" class="w-full my-2"
            (click)="createEditCandidateFiles(myForm)">
            {{ id ? ('candidateDetailSaveButton' | translate) : ('candidateDetailCreateButton' | translate) }}
          </app-custom-primary-button>
        </div>
      </div>
    </div>
  </div>

  <form (ngSubmit)="createEditCandidate(myForm)" #myForm="ngForm" class="my-form pb-4">
    <!--Esta sección es para el nuevo candidato y editarlo-->
    <div *ngIf="edit || newCandidate" class="pb-4">
      <div class="custom-max-width mx-auto flex flex-col">
        <div class="text-center w-full green-title">{{"candidateAddData" | translate}}</div>
        <div class="text-left pl-5 w-full gray-subtitle pt-4 pb-4">{{"candidateSubtitle" | translate}}</div>

        <!--Esta sección es para el nuevo candidato de datos generales-->
        <div class=" flex flex-col py-3 w-full px-6 wizard-container mb-4">
          <div id="togglePersonalDataCandidate" class="flex justify-between items-center py-1.5 text-xl wizard-header"
            (click)="toggleWizardContainer()">
            <div class="dropdown-title pl-2">
              {{"candidateDetailGeneralData" | translate}}
            </div>
            <button type="button">
              <img [src]="expandIcon" alt="" [ngClass]="{'rotate-180': isWizardContainerExpanded}">
            </button>
          </div>
          <div *ngIf="isWizardContainerExpanded" class="flex flex-col md:flex-row flex-wrap p-4">

            <!-- Foto de perfil -->
            <div class="flex pb-2 w-full justify-center">
              <div class="w-44 h-44 relative">
                <!-- Borde redondo externo -->
                <div class="w-44 h-44 absolute rounded-full border image-container z-10"></div>

                <!-- Imagen del perfil del usuario -->
                <img *ngIf="userImageEdit" class="w-44 h-44 absolute rounded-full object-cover" [src]="userImageEdit"
                  alt="">
                <img *ngIf="!userImageEdit" class="w-44 h-44 absolute rounded-full object-cover" [src]="defaultpfp"
                  alt="">

                <!-- Indicador en la parte inferior derecha -->
                <div class="p-1 absolute camera-position z-20">
                  <div class="flex justify-center items-center bg-circle-color rounded-full w-10 h-10">
                    <div id="selectNewLogoPicture" (click)="inputFileLogo.click()"
                      class="cursor-pointer w-6 h-6 flex justify-center items-center">
                      <img [src]="cameraIcon" alt="Upload Icon" class="w-6 h-6">
                    </div>
                  </div>
                  <input #inputFileLogo type="file" accept="image/*" (change)="readURL($event)" class="hidden">
                </div>

                <!-- Eliminar -->
                <div *ngIf="userImageEdit" class="p-1 absolute trash-position z-20">
                  <div class="flex justify-center items-center bg-circle-color rounded-full w-10 h-10">
                    <div id="deleteLogoPicture" class="cursor-pointer w-6 h-6 flex justify-center items-center"
                      (click)="deletePhoto()">
                      <img [src]="deleteIcon" alt="Upload Icon" class="w-6 h-6">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Foto de perfil END -->

            <div class="flex flex-col md:flex-row flex-wrap">
              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="vacancy" class="title-input-text">
                  {{"candidateDetailGeneralDataVacancy" | translate}}*
                </label>
                <div [ngClass]="{'error-border': isVacancyInvalid}">
                  <div class="custom-dropdown">
                    <mat-select *ngIf="!loadingVacancies; else loaderVac" id="candidateVacancySelect" #select1
                      [(ngModel)]="selectedVacancyId" name="selectedVacancy"
                      class="borde p-3 w-full md:w-2/3 centered-text brown-border" (focus)="onFocus($event)"
                      (blur)="onBlur($event)" tabindex="1" disableOptionCentering>

                      <ngx-mat-select-search class="searcher" [formControl]="vacancySearchControl"
                        [placeholderLabel]="'search' | translate"
                        [noEntriesFoundLabel]="'NO_RESULTADOS' | translate"></ngx-mat-select-search>

                      <mat-option *ngFor="let vacancy of vacancy; trackBy: trackByVacancyFn"
                        [value]="vacancy.vacanT_ID">
                        {{ vacancy.joB_NAME }} - {{ vacancy.deP_NAME }} - {{
                        vacancy.broF_NAME || 'Sin definir' }}
                      </mat-option>
                    </mat-select>
                    <ng-template #loaderVac>
                      <div class="borde p-3 w-full centered-text brown-border">
                        {{ 'loading' | translate }}
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="nameInput" class="title-input-text">
                  {{ "candidateDetailGeneralDataName" | translate }}
                </label>
                <div class="input-container mobile-responsive">
                  <input id="nameInput" type="text" class="custom-input"
                    [placeholder]="'candidatePlaceHolderName' | translate" [(ngModel)]="candidate.cdT_NAME" name="name"
                    required (keydown.tab)="onTab($event, 'surnameInput')"
                    (keydown.tab)="onTab($event, 'lastNameInput')" tabindex="2" [maxlength]="30"
                    oninput="this.value = this.value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();">
                </div>
              </div>
              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="lastNameInput" class="title-input-text">
                  {{"candidateDetailGeneralDataLastName" | translate}}
                </label>
                <div class="input-container mobile-responsive">
                  <input id="lastNameInput" type="text" class="custom-input"
                    [placeholder]="'candidatePlaceHolderLastName' | translate"
                    [(ngModel)]="candidate.cdT_PATERNAL_SURNAME" name="lastname" required tabindex="3" [maxlength]="20"
                    oninput="this.value = this.value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();">
                </div>
              </div>
              <div class="py-1 px-1 w-full md:w-1/2  lg:w-1/3 pb-5">
                <label for="surnameInput" class="title-input-text">
                  {{"candidateDetailGeneralDataSecondLastName" | translate}}
                </label>
                <div class="input-container mobile-responsive">
                  <input id="surnameInput" type="text" class="custom-input"
                    [placeholder]="'candidatePlaceHolderSecondLastName' | translate"
                    [(ngModel)]="candidate.cdT_MATERNAL_SURNAME" name="surname" required tabindex="4" [maxlength]="20"
                    oninput="this.value = this.value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();">
                </div>
              </div>

              <div class="py-1 px-1 w-full md:w-1/2  lg:w-1/3 pb-5">
                <label for="genderSelect" class="title-input-text">
                  {{"candidateDetailGeneralDataGender" | translate}}
                </label>
                <div [ngClass]="{'error-border': isGenderInvalid}">
                  <div class="custom-dropdown">
                    <mat-select id="candidateGenderSelect" #select2 [(ngModel)]="selectedGender" name="genderSelection"
                      class="borde p-3 w-full md:w-2/3 centered-text brown-border" tabindex="5"
                      (selectionChange)="onGenderSelected($event)" (focus)="onFocus($event)" (blur)="onBlur($event)">
                      <mat-option *ngFor="let gender of genders; trackBy: trackByFn" [value]="gender.gndR_ID">
                        {{ 'gender.' + gender?.gndR_NAME | translate }}
                      </mat-option>
                    </mat-select>
                  </div>
                </div>
              </div>

              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="emailInput" class="title-input-text">
                  {{ "candidateDetailGeneralDataEmail" | translate }}
                </label>
                <div *ngIf="getPermission('cc01') || newCandidate" class="input-container mobile-responsive">
                  <input id="emailInput" type="email" class="custom-input"
                    [placeholder]="'candidatePlaceHolderEmail' | translate" [(ngModel)]="candidate.cdT_EMAIL"
                    name="mail" required email #emailInput="ngModel" maxlength="100" tabindex="6"
                    oninput="this.value = this.value.split('').filter(char => /^[a-zA-Z0-9._%+-@]*$/.test(char)).join('').trimStart();"
                    autocapitalize="none">
                  <div *ngIf="emailInput.invalid && (emailInput.dirty || emailInput.touched)" class="text-red-500">
                    <!--                  <div *ngIf="emailInput.errors?.required">
                      {{ 'candidateDetailRequiredEmail' | translate }}
                    </div> -->
                    <div *ngIf="emailInput.errors?.email">
                      {{ 'candidateDetailRequiredFormatEmail' | translate }}
                    </div>
                  </div>
                </div>
                <div *ngIf="!getPermission('cc01') && !newCandidate" class="input-container mobile-responsive">
                  <input id="emailInput" type="email" class="custom-input"
                    [placeholder]="'candidatePlaceHolderEmail' | translate" [value]="'noPermission' | translate"
                    name="mail" disabled>
                </div>
              </div>

              <div class="py-1 px-1 w-full md:w-1/2  lg:w-1/3 pb-5">
                <label for="phoneInput" class="title-input-text">
                  {{"candidateDetailGeneralDataCelphone" | translate}}
                </label>
                <div *ngIf="getPermission('cc01') || newCandidate">
                  <div [ngClass]="{'error-border': isPhoneOneInvalid}" class="input-container mobile-responsive">
                    <app-phone-lada-select [phone]="candidate.cdT_PHONE_NUMBER_ONE" tabindex1="7" tabindex2="8"
                      [placeholder]="'candidatePlaceHolderPhone' | translate"
                      (phoneval)="candidate.cdT_PHONE_NUMBER_ONE = $event"></app-phone-lada-select>
                  </div>
                  <div *ngIf="isPhoneOneInvalid" class="text-red-500 text-xs mt-1">
                    {{ "candidateDetailRequiredPhone" | translate }}
                  </div>
                </div>
                <div *ngIf="!getPermission('cc01') && !newCandidate" class="input-container mobile-responsive">
                  <input id="phoneInput" type="text" class="custom-input"
                    [placeholder]="'candidatePlaceHolderPhone' | translate" [value]="'noPermission' | translate"
                    name="phone" disabled>
                </div>
              </div>

              <div class="py-1 px-1 w-full md:w-1/2  lg:w-1/3 pb-5">
                <label for="phone2Input" class="title-input-text">
                  {{"candidateDetailGeneralDataPhone" | translate}}
                </label>
                <div *ngIf="getPermission('cc01') || newCandidate">
                  <div [ngClass]="{'error-border': isPhoneTwoInvalid}" class="input-container mobile-responsive">
                    <input id="phone2Input" type="text" class="custom-input"
                      [placeholder]="'candidatePlaceHolderPhone' | translate"
                      [(ngModel)]="candidate.cdT_PHONE_NUMBER_TWO" name="phone2" maxlength="10"
                      (keypress)="($event.charCode >= 48 && $event.charCode < 58)" tabindex="8"
                      oninput="this.value = this.value.replace(/[^0-9]/g, '').trimStart();">
                  </div>
                  <div *ngIf="isPhoneTwoInvalid" class="text-red-500 text-xs mt-1">
                    {{ "candidateDetailPhoneTwo" | translate }}
                  </div>
                </div>
                <div *ngIf="!getPermission('cc01') && !newCandidate" class="input-container mobile-responsive">
                  <input id="phone2Input" type="text" class="custom-input"
                    [placeholder]="'candidatePlaceHolderPhone' | translate" [value]="'noPermission' | translate"
                    name="phone2" disabled>
                </div>
              </div>

              <!-- Nivel de estudios -->
              <div class="py-1 px-1 w-full md:w-1/2  lg:w-1/3 pb-5">
                <label for="genderSelect" class="title-input-text">
                  {{"candidateStudyLevel" | translate}}*
                </label>
                <div [ngClass]="{'error-border': isStudyLevelInvalid}">
                  <div class="custom-dropdown">
                    <mat-select [(ngModel)]="selectedStudyLevel" name="studySelection"
                      class="borde p-3 w-full md:w-2/3 centered-text brown-border custom-input" tabindex="9"
                      (selectionChange)="onStudyLevelSelected($event)" (focus)="onFocus($event)"
                      (blur)="onBlur($event)">
                      <mat-option *ngFor="let option of studyLevels" [value]="option.scH_ID">
                        {{ ('study.' + option.scH_NAME) | translate }}
                      </mat-option>
                    </mat-select>
                  </div>
                </div>
              </div>

              <!-- Experiencia -->
              <div class="py-1 px-1 w-full md:w-1/2  lg:w-1/3 pb-5">
                <label for="genderSelect" class="title-input-text">
                  {{"candidateYearsOfExperience" | translate}}*
                </label>
                <div [ngClass]="{'error-border': isYearsOfExperienceInvalid}">
                  <div class="custom-dropdown">
                    <mat-select [(ngModel)]="selectedYearsOfExperience" name="yearsSelection"
                      class="borde p-3 w-full md:w-2/3 centered-text brown-border custom-input" tabindex="10"
                      (selectionChange)="onYearsOfExperienceSelected($event)" (focus)="onFocus($event)"
                      (blur)="onBlur($event)">
                      <mat-option *ngFor="let option of yearsOfExperience" [value]="option.exP_ID">
                        {{ 'experience.' + option.exP_NAME | translate }}
                      </mat-option>
                    </mat-select>
                  </div>
                </div>
              </div>

              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="candidateDetailDetailSalaryExpectation" class="title-input-text">
                  {{ "candidateDetailDetailSalaryExpectation" | translate }}
                </label>
                <div class="input-container mobile-responsive">
                  <input id="candidateDetailDetailSalaryExpectation" type="text" name="salary" class="custom-input"
                    [placeholder]="'candidateDetailSalaryPlaceHolder' | translate" [(ngModel)]="formattedSalary"
                    (numericValue)="actualSalary = $event" appFormatCurrencyDirective tabindex="11" [maxlength]="13">
                </div>
              </div>

              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="candidatePortfolio" class="title-input-text">
                  {{"candidatePortfolio" | translate}}
                </label>
                <div class="input-container mobile-responsive">
                  <input id="candidatePortfolio" type="text" class="custom-input"
                    [placeholder]="'candidatePortfolioPlaceHolder' | translate" [(ngModel)]="candidate.cdT_PORTFOLIO"
                    name="candidatePortfolio" tabindex="12" maxlength="120">
                </div>
              </div>

              <div *ngIf="newCandidate || !edit" class="py-1 px-1 w-full md:w-1/2  lg:w-1/3 pb-5">
                <label for="candidateDetailReferenceBy2" class="title-input-text">
                  {{"candidateDetailReferenceBydeta" | translate}}
                </label>
                <div>
                  <div class="custom-dropdown">
                    <mat-select #select3 id="candidateDetailReferenceBy2" [(ngModel)]="selectedSource"
                      name="candidateDetailReferenceBy2" class="borde p-3 w-full md:w-2/3 centered-text brown-border"
                      tabindex="13" [disabled]="!newCandidate || edit" (focus)="onFocus($event)"
                      (blur)="onBlur($event)">
                      <mat-option *ngFor="let source of sources; trackBy: trackByFn"
                        [value]="source.recruitmenT_SOURCE_ID">
                        {{ source.recruitmenT_SOURCE_NAME }}
                      </mat-option>
                      <mat-option value="1">
                        {{ "candidateDetailReferred" | translate }}
                      </mat-option>
                      <mat-option value="0">
                        {{ "candidateDetailOther" | translate }}
                      </mat-option>
                    </mat-select>
                  </div>
                </div>
              </div>

              <div *ngIf="!newCandidate || edit" class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="candidateDetailReferenceByV" class="title-input-text">
                  {{"candidateDetailReferenceBydeta" | translate}}
                </label>
                <div class="input-container mobile-responsive">
                  <input *ngIf="selectedSource != '0' && selectedSource != '1'" id="candidateDetailReferenceByV"
                    type="text" class="custom-input"
                    [placeholder]="editedCandidate.cdT_IMMEDIATE_BOSS ? editedCandidate.cdT_IMMEDIATE_BOSS : 'candidateDetailReferenceByPlaceHolder' | translate"
                    [value]="editedCandidate.recruitmentSourceInfo?.recruitmenT_SOURCE_NAME ? editedCandidate.recruitmentSourceInfo.recruitmenT_SOURCE_NAME : null"
                    name="candidateDetailReferenceByV" tabindex="13" [maxlength]="50" disabled>
                  <input *ngIf="selectedSource == '0'" id="candidateDetailReferenceByV2" type="text"
                    class="custom-input"
                    [placeholder]="editedCandidate.cdT_IMMEDIATE_BOSS ? editedCandidate.cdT_IMMEDIATE_BOSS : 'candidateDetailReferenceByPlaceHolder' | translate"
                    [value]="'candidateDetailOther' | translate" name="candidateDetailReferenceByV" tabindex="13"
                    [maxlength]="50" disabled>
                  <input *ngIf="selectedSource == '1'" id="candidateDetailReferenceByV2" type="text"
                    class="custom-input"
                    [placeholder]="editedCandidate.cdT_IMMEDIATE_BOSS ? editedCandidate.cdT_IMMEDIATE_BOSS : 'candidateDetailReferenceByPlaceHolder' | translate"
                    [value]="'candidateDetailReferred' | translate" name="candidateDetailReferenceByV" tabindex="13"
                    [maxlength]="50" disabled>
                </div>
              </div>

              <div *ngIf="selectedSource == '0'" class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="candidateDetailOtherReference" class="title-input-text">
                  {{"candidateDetailOtherReference" | translate}}
                </label>
                <div class="input-container mobile-responsive">
                  <input id="candidateDetailOtherReference" type="text" class="custom-input"
                    [placeholder]="'candidateDetailOtherReferencePlaceHolder' | translate"
                    [(ngModel)]="candidate.cdT_OTHER_REFERRED" name="candidateDetailOtherReference" tabindex="14"
                    [maxlength]="50" [disabled]="!newCandidate || edit" required
                    oninput="this.value = this.value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();">
                </div>
              </div>
              <div *ngIf="selectedSource == '1'" class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="candidateDetailReferenceBy" class="title-input-text">
                  {{"candidateDetailReferenceBy3" | translate}}
                </label>
                <div class="input-container mobile-responsive">
                  <input id="candidateDetailReferenceBy" type="text" class="custom-input"
                    [placeholder]="candidate.cdT_IMMEDIATE_BOSS ? candidate.cdT_IMMEDIATE_BOSS : 'candidateDetailReferenceByPlaceHolder' | translate"
                    [(ngModel)]="candidate.cdT_REFERRED_BY" name="candidateDetailReferenceBy" tabindex="14"
                    [maxlength]="50" [disabled]="!newCandidate || edit" required
                    oninput="this.value = this.value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();">
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Esta sección es para el nuevo candidato Información de dirección (Opcional)-->
        <div class="flex flex-col py-3 w-full px-6 wizard-container mb-4">
          <div id="toggleAddresStreetSection" class="flex justify-between items-center py-1.5 text-xl wizard-header"
            (click)="toggleWizardContainerAdress()" (keydown)="handleKeydownAdress($event)" tabindex="15">
            <div class="dropdown-title pl-2">
              {{"candidateDetailAdressData" | translate}}
            </div>
            <button type="button">
              <img [src]="expandIcon" alt="" [ngClass]="{'rotate-180': isWizardContainerExpandedAdress}">
            </button>
          </div>
          <div *ngIf="isWizardContainerExpandedAdress" class="w-full flex flex-col md:flex-row flex-wrap p-4">
            <div class="w-full flex flex-col md:flex-row flex-wrap">

              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="streetInput" class="title-input-text">
                  {{"candidateDetailGeneralDataAdressStreet" | translate}}
                </label>
                <div class="input-container mobile-responsive">
                  <input id="streetInput" type="text" class="custom-input input-width-height"
                    [placeholder]="'candidatePlaceHolderStreet' | translate"
                    [(ngModel)]="candidate.cdT_ADDRESS_INFO.adR_STREET" name="street" maxlength="35" tabindex="15"
                    oninput="this.value = this.value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();">
                </div>
              </div>
              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="extnumInput" class="title-input-text">
                  {{"candidateDetailGeneralDataAdressNumber" | translate}}
                </label>
                <div class="input-container mobile-responsive">
                  <input id="extnumInput" type="text" class="custom-input input-width-height"
                    [placeholder]="'candidatePlaceHolderExtNumber' | translate"
                    [(ngModel)]="candidate.cdT_ADDRESS_INFO.adR_STREET_NUMBER" name="extnum" maxlength="20"
                    tabindex="16"
                    oninput="this.value = this.value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();">
                </div>
              </div>
              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="intnumInput" class="title-input-text">
                  {{"candidateDetailGeneralDataAdressNumberInt" | translate}}
                </label>
                <div class="input-container mobile-responsive">
                  <input id="intnumInput" type="text" class="custom-input input-width-height"
                    [placeholder]="'candidatePlaceHolderIntNumber' | translate"
                    [(ngModel)]="candidate.cdT_ADDRESS_INFO.adR_SUITE_NUMBER" name="intnum" maxlength="20" tabindex="17"
                    oninput="this.value = this.value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();">
                </div>
              </div>
              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="suburbInput" class="title-input-text">
                  {{"candidateDetailGeneralDataAdressColony" | translate}}
                </label>
                <div class="input-container mobile-responsive">
                  <input id="suburbInput" type="text" class="custom-input input-width-height"
                    [placeholder]="'candidatePlaceHolderSuburb' | translate"
                    [(ngModel)]="candidate.cdT_ADDRESS_INFO.adR_SUBURB" name="suburb" maxlength="35" tabindex="18"
                    oninput="this.value = this.value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();">
                </div>
              </div>

              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <div>
                  <label for="stateSelect" class="title-input-text">
                    {{"candidateDetailGeneralDataAddressState" | translate}}
                  </label>
                  <mat-select #select4 id="candidateAdressStateSelect" [(ngModel)]="selectedState" name="stateSelection"
                    class="borde p-3 w-full md:w-2/3 centered-text brown-border" tabindex="19"
                    (selectionChange)="onStateMatSelectChange($event)" (focus)="onFocus($event)" (blur)="onBlur($event)"
                    disableOptionCentering>
                    <ngx-mat-select-search class="searcher" [formControl]="stateSearchControl"
                      [placeholderLabel]="'search' | translate"
                      [noEntriesFoundLabel]="'NO_RESULTADOS' | translate"></ngx-mat-select-search>

                    <mat-option *ngFor="let state of states; trackBy: trackByStateFn" [value]="state">
                      {{ state.stS_NAME }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>

              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="citySelect" class="title-input-text">
                  {{"candidateDetailGeneralDataAddressCity" | translate}}
                </label>
                <mat-select #select5 id="candidateAdressCitySelect" [(ngModel)]="selectedCity" name="citySelection"
                  class="borde p-3 w-full md:w-2/3 centered-text brown-border" tabindex="20"
                  (selectionChange)="onCityMatSelectChange($event)" (focus)="onFocus($event)" (blur)="onBlur($event)"
                  disableOptionCentering>
                  <ngx-mat-select-search class="searcher" [formControl]="citySearchControl"
                    [placeholderLabel]="'search' | translate"
                    [noEntriesFoundLabel]="'NO_RESULTADOS' | translate"></ngx-mat-select-search>
                  <mat-option *ngFor="let city of cities; trackBy: trackByCityFn" [value]="city">
                    {{ city.ctY_NAME }}
                  </mat-option>
                </mat-select>
              </div>
              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="cpInput" class="title-input-text">
                  {{ "candidateDetailGeneralDataAdressPostalCode" | translate }}
                </label>
                <div
                  [ngClass]="{'error-border': candidate?.cdT_ADDRESS_INFO?.adR_CP && candidate?.cdT_ADDRESS_INFO?.adR_CP?.length < 5}"
                  class="input-container mobile-responsive">
                  <input id="cpInput" type="text" class="custom-input input-width-height"
                    [placeholder]="'candidatePlaceHolderPostalCode' | translate"
                    [(ngModel)]="candidate.cdT_ADDRESS_INFO.adR_CP" name="cp" maxlength="5"
                    (keypress)="($event.charCode >= 48 && $event.charCode < 58 )" tabindex="21"
                    oninput="this.value = this.value.replace(/[^0-9]/g, '').trimStart();">
                </div>
                <div *ngIf="candidate?.cdT_ADDRESS_INFO?.adR_CP && candidate?.cdT_ADDRESS_INFO?.adR_CP?.length < 5"
                  class="text-red-500 text-xs mt-1">
                  {{ "candidateDetailPostalCode" | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Esta sección es para el nuevo candidato Datos personales (Opcional)-->
        <div class="flex flex-col py-3 w-full px-6 wizard-container mb-4">
          <div id="tooglePersonalData2Candidate" class="flex justify-between items-center py-1.5 text-xl wizard-header"
            (click)="toggleWizardContainerGeneralOptional()" (keydown)="handleKeydownGeneralData($event)" tabindex="22">
            <div class="dropdown-title pl-2">
              {{"candidateDetailOptional" | translate}}
            </div>
            <button type="button">
              <img [src]="expandIcon" alt="" [ngClass]="{'rotate-180': isWizardContainerGeneralOptionalExpanded}">
            </button>
          </div>
          <div *ngIf="isWizardContainerGeneralOptionalExpanded" class="flex flex-col md:flex-row flex-wrap p-4">
            <div class="flex flex-col md:flex-row flex-wrap">

              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="streetInput" class="title-input-text">
                  {{"candidateDetailGeneralDataCURP" | translate}}
                </label>
                <div class="input-container mobile-responsive">
                  <input id="curpInput" type="text" class="custom-input input-width-height"
                    [placeholder]="'candidatePlaceHolderCURP' | translate" [(ngModel)]="candidate.cdT_CURP" name="curp"
                    maxlength="18" tabindex="23"
                    oninput="this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s]/g, '').trim().toUpperCase();">
                </div>
              </div>
              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="extnumInput" class="title-input-text">
                  {{"candidateDetailGeneralDataRFC" | translate}}
                </label>
                <div class="input-container mobile-responsive">
                  <input id="rfcInput" type="text" class="custom-input input-width-height"
                    [placeholder]="'candidatePlaceHolderRFC' | translate" [(ngModel)]="candidate.cdT_RFC" name="rfc"
                    maxlength="13" tabindex="24"
                    oninput="this.value = this.value.replace(/[^a-zA-ZÑñ\d]/g, '').toUpperCase();">
                </div>
              </div>

              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="intnumInput" class="title-input-text">
                  {{"candidateDetailGeneralDataNSS" | translate}}
                </label>
                <div class="input-container mobile-responsive">
                  <input id="nssInput" type="text" class="custom-input input-width-height"
                    [placeholder]="'candidatePlaceHolderNSS' | translate"
                    (keypress)="($event.charCode >= 48 && $event.charCode <= 57)"
                    [(ngModel)]="candidate.cdT_SOCIAL_SECURITY_NUMBER" name="nss" maxlength="11" tabindex="25"
                    oninput="this.value = this.value.replace(/[^0-9]/g, '').trim();">
                </div>
              </div>
              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="suburbInput" class="title-input-text">
                  {{"candidateDetailGeneralDataBirthdate" | translate}}
                </label>
                <app-lang-datepicker [date]="candidate.cdT_BIRTH_DATE" [max]="maxDate" [tabindex]="26"
                  (datechange)="candidate.cdT_BIRTH_DATE = $event"></app-lang-datepicker>
              </div>

              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <div>
                  <label for="stateSelect" class="title-input-text">
                    {{"candidateDetailGeneralDataBirthState" | translate}}
                  </label>
                  <mat-select id="candidateBirthStateSelect" [(ngModel)]="selectedBirthState" name="birthstateSelection"
                    (selectionChange)="onStateMatSelectChange2($event)" (focus)="onFocus($event)"
                    (blur)="onBlur($event)" class="borde p-3 w-full md:w-2/3 centered-text brown-border" tabindex="27"
                    disableOptionCentering>
                    <ngx-mat-select-search class="searcher" [formControl]="stateSearchControl2"
                      [placeholderLabel]="'search' | translate"
                      [noEntriesFoundLabel]="'NO_RESULTADOS' | translate"></ngx-mat-select-search>
                    <mat-option *ngFor="let state of states2; trackBy: trackByBirthStateFn" [value]="state">
                      {{ state.stS_NAME }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>

              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="birthcitySelect" class="title-input-text">
                  {{"candidateDetailGeneralDataBirthCity" | translate}}
                </label>
                <mat-select id="candidateBirthCitySelect" [(ngModel)]="selectedBirthCity" name="birthcitySelection"
                  (selectionChange)="onCityMatSelectChange2($event)" (focus)="onFocus($event)" (blur)="onBlur($event)"
                  class="borde p-3 w-full md:w-2/3 centered-text brown-border" tabindex="28" disableOptionCentering>
                  <ngx-mat-select-search class="searcher" [formControl]="citySearchControl2"
                    [placeholderLabel]="'search' | translate"
                    [noEntriesFoundLabel]="'NO_RESULTADOS' | translate"></ngx-mat-select-search>
                  <mat-option *ngFor="let city of cities2; trackBy: trackByCityFn2" [value]="city">
                    {{ city.ctY_NAME }}
                  </mat-option>
                </mat-select>
              </div>

              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <div>
                  <label for="stateSelect" class="title-input-text">
                    {{"candidateDetailGeneralDataProfessionalLicense" | translate}}
                  </label>
                  <input id="cedulaprofesionalInput" type="text" class="custom-input input-width-height"
                    [placeholder]="'candidatePlaceHolderProfessionalLicense' | translate"
                    (keypress)="($event.charCode >= 48 && $event.charCode < 58 )"
                    [(ngModel)]="candidate.cdT_PROFESSIONAL_CERTIFICATE" name="cedulaprofesional" maxlength="8"
                    tabindex="29" oninput="this.value = this.value.replace(/[^0-9]/g, '').trimStart();">
                </div>
              </div>

              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="birthcitySelect" class="title-input-text">
                  {{"candidateDetailGeneralDataCredit" | translate}}
                </label>
                <input id="creditoinfonavitInput" type="text" class="custom-input input-width-height"
                  [placeholder]="'candidatePlaceHolderCredit' | translate"
                  (keypress)="($event.charCode >= 48 && $event.charCode < 58 )"
                  [(ngModel)]="candidate.cdT_INFONAVIT_CREDIT" name="creditoinfonavit" maxlength="12" tabindex="30"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '').trim();">
              </div>

              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="bankInput" class="title-input-text">
                  {{"candidateBank" | translate}}
                </label>
                <input id="BankInput" type="text" class="custom-input input-width-height"
                  [placeholder]="'candidatePlaceHolderBank' | translate" [(ngModel)]="candidate.cdT_BANK"
                  name="bankInput" maxlength="30" tabindex="31"
                  oninput="this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s]/g, '').trimStart().toUpperCase();">
              </div>

              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5 clabe">
                <label for="clabeInput" class="title-input-text">
                  {{"candidateCLABE" | translate}}
                </label>
                <input id="creditoCLABEInput" type="text" class="custom-input input-width-height"
                  [placeholder]="'candidatePlaceHolderCLABE' | translate"
                  (keypress)="($event.charCode >= 48 && $event.charCode < 58 )" [(ngModel)]="candidate.cdT_BANK_CLABE"
                  name="clabeInput" maxlength="18" tabindex="32" minlength="18"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '').trim();">
              </div>

            </div>
          </div>
        </div>

        <!--Esta sección es para el nuevo candidato Subir documentos (Opcional)-->
        <div *ngIf="!isHaibuJobs && getPermission('cd008')"
          class="flex flex-col py-3 w-full px-6 wizard-container mb-4">
          <div id="toggleDigitalFileSection" class="flex justify-between items-center py-1.5 text-xl wizard-header"
            (click)="toogleWizardContainerExpedient()" (keydown)="handleKeydownContainerExpedient($event)"
            tabindex="33">
            <div class="dropdown-title pl-2">
              {{"candidateDetailExpedient" | translate}}
            </div>
            <button type="button">
              <img [src]="expandIcon" alt="" [ngClass]="{'rotate-180': isWizardContainerDigitalExpedientExpanded}">
            </button>
          </div>
          <div *ngIf="isWizardContainerDigitalExpedientExpanded" class="flex flex-col md:flex-row flex-wrap p-4">
            <div class="flex flex-col w-full">
              <div class="py-2 px-6 w-full">
                <div class="flex justify-start items-center">
                  <div class="text-left mr-4">
                    <label for="state" class="gray-subtitle pb-2 block">
                      {{ "candidateDetailCatalogDocuments" | translate }}
                    </label>
                  </div>
                </div>
                <input #fileSelect id="fileSelect" type="file"
                  accept=".doc,.docx,application/msword,application/vnd.ms-excel,.xlsx,.xls,image/*,application/pdf,.pdf"
                  (click)="fileSelect.value = null" (change)="fileChangeEvent($event,0)" class="hidden">

                <div *ngIf="edit || newCandidate" class="flex flex-col md:flex-row md:items-center w-full">
                  <app-custom-dropdown id="fileCatalogCandidateDetailDropdown" [items]="innerDocs"
                    [(ngModel)]="selectedInnerDoc" [translateLabels]="true" name="innerdoc" width="100%"
                    class="w-full mb-4 md:mb-0" displayProperty="pdT_NAME" tabindex="34">
                  </app-custom-dropdown>

                  <div class="w-full md:w-auto pl-0 md:pl-4">
                    <app-custom-primary-button id="openFileSelectorCandidateDetail" size="excel-size" color="secondary"
                      type="button" class="w-full" (click)="fileSelectClick()">
                      {{ "candidateDetailSaveDocuments" | translate }}
                    </app-custom-primary-button>
                  </div>
                </div>

                <div *ngIf="selectedInnerDoc && getPermission('cd008')" class="my-2">
                  <div class="drag-drop-area border-dashed border-2 rounded-lg p-4 text-center cursor-pointer"
                    [ngClass]="dragging ? 'dragging' : 'border-gray-400'" (dragover)="onDragOver($event)"
                    (dragleave)="onDragLeave($event)" (drop)="onDrop($event)" (click)="fileSelectClick()">
                    <div class="py-10">
                      <div class="text-gray-500 m-0">
                        {{ "dragAndDrop" | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="py-4 pl-5 flex flex-wrap">
                <div *ngFor="let innerDoc of innerDocuments; let i = index" class="flex py-2 pr-5 md:w-1/2 w-full">
                  <a id="openDocCandidateDetail" (click)="opendoc(innerDoc.cdoC_DOCUMENT_ROUTE)"
                    class="document-card w-full gray-subtitle flex items-center justify-between p-2">
                    <div class="flex items-center">
                      <img [src]="fileIcon" alt="Document Icon" class="mr-2">
                      {{ innerDoc.cdoC_DOCUMENT_NAME | translate }}
                    </div>
                    <a id="deleteDocCandidateDetail" *ngIf="!showdocuments" (click)="deletedoc(innerDoc,0)"
                      class="flex items-center">
                      <img [src]="deleteIcon" alt="trash">
                    </a>
                  </a>
                </div>
              </div>

              <div *ngIf="innerDocuments?.length === 0" class="flex flex-col items-center justify-center px-6">
                <img [src]="expedientImage" alt="Sin documentos" class="w-60 h-40">
                <div class="text-left w-full">
                  <div class="green-title pb-4 pt-4">{{ "candidateWithoutEvaluationsTitle" | translate }}</div>
                  <div class="pb-2 gray-subtitle">
                    {{ "candidateWithoutEvaluationsMessage" | translate }}
                  </div>
                  <div class="gray-subtitle">
                    {{ "candidateDetailSubtitle" | translate }}
                  </div>
                </div>
              </div>

              <div *ngIf="showdocuments" class="flex md:flex-row flex-col md:px-3 px-6 w-full">
                <div class="md:ml-auto md:mx-3 md:py-0 mx-0 py-2">
                </div>
                <div class="md:mx-3 md:py-0 mx-0 py-2">
                  <app-custom-primary-button id="uploadDocumentsCandidateDetail" color="primary" size="small"
                    class="w-full" (click)="sendDocs()">
                    {{ "candidateDetailSaveDocuments" | translate }}
                  </app-custom-primary-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Esta sección es para el nuevo candidato Subir evaluaciones (Opcional)-->
        <div *ngIf="!isHaibuJobs && getPermission('cd009')" class="flex flex-col py-3 w-full px-6 wizard-container">
          <div id="toggleEvaluationsSection" class="flex justify-between items-center py-1.5 text-xl wizard-header"
            (click)="toogleWizardContainerEvaluations()" (keydown)="handleKeydownContainerEvaluations($event)"
            tabindex="35">
            <div class="dropdown-title pl-2">
              {{"candidateDetalEvaluations" | translate}}
            </div>
            <button type="button">
              <img [src]="expandIcon" alt="" [ngClass]="{'rotate-180': isWizardContainerEvaluationsExpanded}">
            </button>
          </div>
          <div *ngIf="isWizardContainerEvaluationsExpanded" class="flex flex-col md:flex-row flex-wrap p-4">
            <div class="flex flex-col w-full">
              <div class="py-2 px-6 w-full text-right justify-end">
                <div *ngIf="newCandidate || showdocuments"
                  class="flex flex-col md:flex-row items-center justify-end w-full">
                  <div class="w-full md:w-auto md:ml-4">
                    <app-custom-primary-button id="evaluationsCandidateDetail" size="excel-size" color="secondary"
                      type="button" class="block md:inline-block w-full md:w-auto" (click)="fileSelectClick2()"
                      tabindex="36">
                      {{ "candidateDetailTitleButton" | translate }}
                    </app-custom-primary-button>
                  </div>
                </div>
              </div>

              <input #fileSelect2 id="fileSelect2" type="file"
                accept=".doc,.docx,application/pdf,application/doc,application/docx,application/xls,application/xlsx,image/*,"
                (click)="fileSelect2.value = null" (change)="fileChangeEvent($event,1)" class="hidden fileInputProfile">

              <div *appPermission="'cd009'" class="my-2">
                <div class="drag-drop-area border-dashed border-2 rounded-lg p-4 text-center cursor-pointer"
                  [ngClass]="dragging ? 'dragging' : 'border-gray-400'" (dragover)="onDragOver($event)"
                  (dragleave)="onDragLeave($event)" (drop)="onDrop($event, 1)" (click)="fileSelectClick2()">
                  <div class="py-10">
                    <div class="text-gray-500 m-0">
                      {{ "dragAndDrop" | translate }}
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="innerEvaluations!=null&&innerEvaluations?.length>0" class="pr-5">
                <div class="py-4 pl-5 flex flex-wrap">
                  <div *ngFor="let innerDoc of innerEvaluations; let i = index"
                    class="flex py-2 justify-between w-full">
                    <a id="openEvaluationCandidateDetail" (click)="opendoc(innerDoc.cdoC_DOCUMENT_ROUTE)"
                      class="document-card w-full gray-subtitle flex items-center justify-between p-2">
                      <div class="flex items-center">
                        <img [src]="fileIcon  " alt="Document Icon" class="mr-2">
                        {{ innerDoc.cdoC_DOCUMENT_NAME }}
                      </div>
                      <a id="deleteEvaluationCandidateDetail" *ngIf="!showevaluations" (click)="deletedoc(innerDoc,1)"
                        class="flex items-center">
                        <img [src]="deleteIcon" alt="trash">
                      </a>
                    </a>
                  </div>
                </div>
              </div>

              <div *ngIf="innerEvaluations?.length === 0" class="flex flex-col items-center justify-center px-6">
                <img [src]="expedientImage" alt="Sin documentos" class="w-60 h-40">
                <div class="text-left w-full">
                  <div class="green-title pb-4 pt-4">{{ "candidateWithoutEvaluationsFilesTitle" | translate }}
                  </div>
                  <div class="pb-2 gray-subtitle">
                    {{ "candidateWithoutEvaluationsFilesMessage" | translate }}
                  </div>
                  <div class="gray-subtitle">
                    {{ "candidateDetailFilesSubtitle" | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!newCandidate || id" class="flex sm:flex-row flex-col pt-8">
          <div *ngIf="id" class=" sm:py-0 mx-0 py-2">
            <app-custom-primary-button id="saveCandidateDetailData" [disabled]="sending" color="primary" size="small"
              class="w-full" tabindex="37">
              <span *ngIf="!sending">{{ id ? ('candidateDetailSaveButton' | translate) : ('candidateDetailCreateButton'
                | translate) }}</span>
              <div *ngIf="sending" class="w-full flex justify-center">
                <mat-spinner diameter="20" color="primary"></mat-spinner>
              </div>
            </app-custom-primary-button>
          </div>

          <div *ngIf="!newCandidate" class="sm:mx-3 sm:py-0 mx-0 py-2">
            <app-custom-primary-button id="cancelCandidateDetailData" color="secondary" size="small" class="w-full"
              (click)="edit = !edit" [disabled]="sending">
              {{ "candidateDetailCancellButton" | translate }}
            </app-custom-primary-button>
          </div>
        </div>
      </div>
    </div>

    <div class="custom-max-width mx-auto">
      <div class="w-full md:w-1/2 flex justify-center md:justify-start">
        <app-custom-primary-button id="createCandidateDetailData" *ngIf="newCandidate" type="submit" color="primary"
          size="small" class="float-left w-auto" [disabled]="sending">
          <span *ngIf="!sending">{{ "clientDetailConfCreate" | translate }}</span>
          <div *ngIf="sending" class="w-full flex justify-center">
            <mat-spinner diameter="20" color="primary"></mat-spinner>
          </div>
        </app-custom-primary-button>
      </div>
    </div>
  </form>
</div>